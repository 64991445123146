<template>
  <z-dialogo ref="dialogo" titulo="Escolher Cor" largura="400px">
    <template v-slot:conteudo>

      <v-row>
        <template v-for="(cor, index) in cores">
          <v-col cols="12" sm="2" :key="index" align="center">
            <!-- <v-card :color="cor" width="36px" height="36px" title="Nome da Cor" @click="escolher(cor)"></v-card> -->
            <!-- <v-icon x-large :color="cor" title="Nome da Cor">mdi-circle</v-icon> -->
            <v-btn small fab :color="cor.value" :title="cor.name" @click="escolher(cor.value)"></v-btn>
          </v-col>
        </template>
      </v-row>

    </template>

    <template v-slot:actions_right>
      <v-btn text color="primary" @click="fechar">Cancelar</v-btn>
    </template>
  </z-dialogo>
</template>

<script>
  import cores from './cores'
  export default {

    props: {
      value: {
        type: String,
        default: ''
      },
    },

    data() {
      return {
        cores: cores,
      }
    },

    methods: {
      abrir() {
        this.$refs.dialogo.abrir();
      },

      fechar() {
        this.$refs.dialogo.fechar();
      },

      escolher(cor) {
        this.fechar();
        this.$emit('input', cor);
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>
