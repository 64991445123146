import GruposLista from '@/pages/tabelas/grupos/GruposLista'

export default [
  {
    path: '/tabelas/grupos',
    name: 'GruposLista',
    component: GruposLista,
    meta: { permissao: 'grupospecas_acessar' }
  }
];
