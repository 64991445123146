import movimentosApi from "@/app/services/estoque";
import movimentoestoque from "@/app/store/models/movimentoestoque";

import produtosApi from "@/app/services/pecas";
import { PAGINATION_OPTIONS } from "@/app/vuetifyConfig";

import { hoje, clone } from "@/app/helpers/utils";

export default {
    namespaced: true,
    state: {
      movimentoestoque: {...movimentoestoque},
      movimentosestoque: [],
      produtos: [],
      busca: "",
      total_itens: 0,
      paginacao: PAGINATION_OPTIONS,
    },

    mutations: {
      LISTAR(state, movimentosestoque) {
        state.movimentosestoque = movimentosestoque;
      },

      ABRIR(state, movimento) {
        state.movimentoestoque = movimento;
      },

      ADICIONAR(state, movimento) {
        state.movimentosestoque.unshift(movimento);
      },

      ATUALIZAR(state, movimento) {
        let index = state.movimentosestoque.findIndex(movimento_ => movimento_.id == movimento.id);
        if (index > -1) {
          state.movimentosestoque.splice(index, 1, movimento);
        }
      },

      EXCLUIR(state, id) {
        let index = state.movimentosestoque.findIndex(movimento => movimento.id == id);
        if (index > -1) {
          state.movimentosestoque.splice(index, 1);
        }
      },

      UNSET_MOVIMENTO(state) {
        state.movimento = null;
      },

      SET_BUSCA(state, busca) {
        state.busca = busca;
      },
  
      SET_STATUS(state, status) {
        state.status = status;
      },
  
      SET_PAGE(state, page) {
        state.paginacao.page = page;
      },
  
      SET_TOTAL_ITENS(state, total_itens) {
        state.total_itens = total_itens;
      },

      UPDATE_PAGINACAO(state, paginacao) {
        state.paginacao = paginacao;
      },

      ZERAR(state) {
        state.movimentoestoque = clone(movimentoestoque);
        state.movimentoestoque.data = hoje();
        // state.movimentoestoque.tipomovimento_id = 3;
      }
    },

    actions: {
      async listar({commit, state}) {
        commit('LOADING_ON', null, { root: true });

        const { sortBy, sortDesc, page, itemsPerPage } = state.paginacao;

        const paginacao = {
          page, 
          itemsPerPage,
          sortBy: sortBy[0], 
          sortDesc: sortDesc[0],
          status: state.status
        }

        if (state.busca) {
          paginacao.busca = state.busca;
        }

        let response = await movimentosApi.listar(paginacao);
        commit('LISTAR', response.data);
        commit('SET_TOTAL_ITENS', response.total);
        commit('LOADING_OFF', null, {root: true});

      },

      async abrir({commit}, id) {
        commit('LOADING_ON', null, {root: true});
        let movimento = await movimentosApi.abrir(id);
        commit('ABRIR', movimento);
        commit('LOADING_OFF', null, {root: true});
      },

      async salvar({commit}, movimento) {
        let novo = await movimentosApi.salvar(movimento);
        commit('ADICIONAR', novo);
      },

      async atualizar({commit}, movimento) {
        let movimento_salvo = await movimentosApi.atualizar(movimento);
        commit('ATUALIZAR', movimento_salvo);
      },

      async excluir({commit}, id) {
        await movimentosApi.excluir(id);
        commit('EXCLUIR', id);
      },

      buscarProdutoPorReferencia({commit}, referencia) {
        return produtosApi.buscarProdutoPorReferencia(referencia);
      },
    }
}
