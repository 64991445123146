function queryBuilder() {
  const query = {
    select: [],
    selectraw: "",
    with: [],
    without: [],
    where: "",
    order_by: [],
    model: ""
  };

  return {
    select(...fields) {
      query.select.push(...fields);
      return this;
    },

    selectraw(raw) {
      query.selectraw = raw;
      return this;
    },

    from(model) {
      query.model = model;
      return this;
    },

    orderBy(...fields) {
      query.order_by.push(...fields);
      return this;
    },

    with(...relations) {
      query.with.push(...relations);
      return this;
    },

    where(condition) {
      query.where = condition;
      return this;
    },

    build() {
      const filteredQuery = {};
      for (const key in query) {
        if (Array.isArray(query[key]) && query[key].length > 0) {
          filteredQuery[key] = query[key];
        } else if (typeof query[key] === 'string' && query[key].trim() !== "") {
          filteredQuery[key] = query[key];
        }
      }
      return filteredQuery;
    }
  };
}

export default queryBuilder;

// Example usage:
// const query = queryBuilder()
//   .select("id", "razao")
//   .selectraw("id as value, concat(id, ' - ', razao) as text")
//   .from("Cliente")
//   .orderBy("-razao", "id")
//   .with("vendedor")
//   .where("id=1")
//   .build();

// console.log(query);
