<template>
  <z-expansion-config-item :loading="isLoading" titulo="Cadastro de Serviços" subtitulo="Gerar código do serviço automático?">
    <v-switch
    right
      v-model="store.configuracoes.APP_CODIGO_SERVICO_AUTOMATICO"
      :label="store.configuracoes.APP_CODIGO_SERVICO_AUTOMATICO ? 'Automático' : 'Manual'"
      hide-details
      class="mt-0 pt-0"
      @change="updateConfig"
    ></v-switch>
  </z-expansion-config-item>
</template>

<script setup>
import { computed } from 'vue';
import { useConfigStore } from '@/app/store/pinia/configuracoes'

import { zExpansionConfigItem } from "zcomponents";

const store = useConfigStore();

const isLoading = computed(() => {
  return store.conf.origin == 'CODIGO_SERVICO_AUTOMATICO' && store.conf.loading == true;
})

function updateConfig(value) {
  store.conf.origin = 'CODIGO_SERVICO_AUTOMATICO';
}

</script>

<style lang="scss" scoped>

</style>
