<template>
  <v-card flat>
    <v-toolbar dense flat tile color="white" v-if="ordemservico.aprovado != 1">
      <v-btn depressed small color="primary" @click="adicionarPeca">Adicionar Peça</v-btn>
    </v-toolbar>

    <v-divider v-if="ordemservico.aprovado != 1"></v-divider>

    <v-card-text class="pa-0">
      <v-data-table
        mobile-breakpoint="0"
        hide-default-footer
        disable-pagination
        disable-sort
        fixed-header
        no-data-text="Para adicionar uma peça clique no botão adicionar peça"
        height="383px"
        :headers="headers"
        :items="ordemservico.pecas"
      >
        <template v-slot:item="{ item: peca, index }">
          <tr>
            <td>{{ index + 1 }}</td>
            <td>{{ peca.codigo }}</td>
            <td>{{ peca.descricao || '' }}</td>
            <td class="text-right">{{ peca.quantidade }}</td>
            <td class="text-right">{{ peca.valor | valorBR }}</td>
            <td class="text-right">{{ peca.subtotal | valorBR }}</td>
            <td class="text-center">
              <v-btn icon @click="editar(peca, index)" class="ma-0">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <span v-if="ordemservico.aprovado != 1">
                <v-btn icon @click="perguntarExcluir(index)" class="ma-0">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </span>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>

    <dialogo-buscar-pecas ref="DialogoBuscarPecas" @busca:selecionar="selecionarPeca"></dialogo-buscar-pecas>
    <dialogo-buscar-fornecedores ref="DialogoBuscarFornecedor" @busca:selecionar="selecionarFornecedor"></dialogo-buscar-fornecedores>

    <z-dialogo-sim-nao ref="dialogoRemover" titulo="Remover Peça" botaoSim="Remover" botaoNao="Cancelar" :loading="excluindo">
      A peça será removida desta ordem de serviço.
    </z-dialogo-sim-nao>

    <z-dialogo ref="dialogoAdicionarPeca" largura="900px" titulo="Peça" dividers persistent>
      <template v-slot:conteudo>
        <v-form ref="formulario" v-model="form_valido" lazy-validation>
          <v-row dense>
            <v-col cols="12" sm="3">
              <v-text-field
                v-bind="$v.TEXT_FIELDS"
                v-model="peca.codigo"
                :rules="[regras.campoObrigatorio]"
                autofocus
                ref="codigo"
                label="Código"
                append-icon="mdi-magnify"
                hint="F8 - Para pesquisar"
                placeholder=" "
                :loading="buscando"
                :disabled="buscando"
                @click:append="$refs.DialogoBuscarPecas.abrir()"
                @keyup.enter="buscarPecaPorCodigo"
                @keyup.esc="onKeyUpEscAdicionarPeca"
                @focus="(event) => event.target.select()"
                @keyup.f8="$refs.DialogoBuscarPecas.abrir()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <z-text-field v-model="peca.descricao" ref="descricao" label="Descrição" :rules="[regras.campoObrigatorio]" placeholder=" " readonly></z-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <z-campo-valor
                v-model="peca.quantidade"
                :suffix="peca.unidade"
                ref="quantidade"
                label="Quantidade"
                :rules="[regras.maiorQueZero]"
                placeholder=" "
                :decimais="0"
                @keyup.enter="$refs.valor.focus()"
                @input="calcularSubTotalPeca"
              ></z-campo-valor>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" sm="3">
              <z-campo-valor
                v-model="peca.valor"
                ref="valor"
                label="Valor"
                :rules="[regras.maiorQueZero]"
                placeholder=" "
                @input="calcularSubTotalPeca"
                @keyup.enter="$refs.data.focus()"
              ></z-campo-valor>
            </v-col>
            <v-col cols="12" sm="3">
              <z-campo-valor v-model="peca.valor_custo" ref="valor_custo" label="Valor Custo" placeholder=" " readonly></z-campo-valor>
            </v-col>
            <v-col cols="12" sm="3">
              <z-campo-valor v-model="peca.subtotal" ref="subtotal" label="SubTotal" placeholder=" " readonly></z-campo-valor>
            </v-col>
            <v-col cols="12" sm="3">
              <z-date-picker-menu v-model="peca.data" ref="data" label="Data" :rules="[regras.campoObrigatorio]" placeholder=" " @keyup.enter="$refs.fornecedor_id.focus()"></z-date-picker-menu>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" sm="3">
              <v-text-field
                v-bind="$v.TEXT_FIELDS"
                v-model="peca.fornecedor_id"
                ref="fornecedor_id"
                label="Código Fornecedor"
                append-icon="mdi-magnify"
                hint="F8 - Para pesquisar"
                placeholder=" "
                :loading="buscando_fornecedor"
                :disabled="buscando_fornecedor"
                @click:append="$refs.DialogoBuscarFornecedor.abrir()"
                @keyup.enter="buscarFornecedorPeloCodigo"
                @focus="(event) => event.target.select()"
                @keyup.f8="$refs.DialogoBuscarFornecedor.abrir()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <z-text-field v-model="peca.fornecedor.razao" ref="fornecedor" label="Fornecedor" placeholder=" " :disabled="buscando" readonly></z-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <z-select :items="$globals.TIPOS_ENTRADA" v-model="peca.tipo_entrada" ref="tipo_entrada" label="Tipo Entrada"></z-select>
            </v-col>
          </v-row>
        </v-form>
      </template>

      <template v-slot:actions_right>
        <v-btn text @click="editando_index = -1;$refs.dialogoAdicionarPeca.fechar();">
          Cancelar
        </v-btn>
        <v-btn
          v-if="ordemservico.aprovado != 1"
          text
          color="primary"
          ref="btnConfirmar"
          :disabled="!form_valido"
          @click="confirmarPeca"
        >
          {{ editando_index == -1 ? "Adicionar" : "Salvar" }}
        </v-btn>
      </template>
    </z-dialogo>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';

import pecasApi from '@/app/services/pecas';
import fornecedoresApi from '@/app/services/fornecedores';

import { trunc } from '@/app/helpers/conversoes';
import { clone, setFocus } from '@/app/helpers/utils';
import '@/components/buscas';

import * as regras from '@/app/helpers/validacoes';

const peca = {
  codigo: '',
  descricao: '',
  quantidade: 0,
  valor: 0,
  valor_custo: 0,
  subtotal: 0,
  tipo_entrada: 2,
  fornecedor_id: null,
  data: '',
  fornecedor: {
    razao: '',
  },
};

export default {
  name: 'TabPecas',
  props: {
    pecas: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      peca: { ...peca },
      headers: [
        { text: 'Item', value: '', width: '50px' },
        { text: 'Código', value: 'codigo' },
        { text: 'Descrição', value: 'descricao' },
        { text: 'Quantidade', value: 'quantidade', align: 'right' },
        { text: 'Valor', value: 'valor', align: 'right' },
        { text: 'SubTotal', value: 'subtotal', align: 'right' },
        { text: 'Opções', value: '', width: '200px', align: 'center' },
      ],
      buscando: false,
      buscando_fornecedor: false,
      excluindo: false,
      editando_index: -1,
      regras: { ...regras },
      form_valido: true,
    };
  },

  computed: {
    ...mapState('ordemservico', ['ordemservico']),
  },

  methods: {
    selecionarPeca(peca) {
      this.peca.codigo = peca.codigo;
      this.peca.descricao = peca.descricao;
      this.peca.quantidade = 1;
      this.peca.valor = peca.valor_venda;
      this.peca.valor_custo = peca.valor_custo;
      this.peca.subtotal = this.peca.quantidade * peca.valor_venda;
      this.peca.estoque = peca.estoque;

      setFocus(this.$refs['quantidade'], 300);
    },

    async buscarPecaPorCodigo(event) {
      if (!this.peca.codigo) {
        this.$refs.DialogoBuscarPecas.abrir();
        return false;
      }

      try {
        this.buscando = true;
        let peca = await pecasApi.buscarPecaPorCodigo(this.peca.codigo);
        if (peca) {
          this.selecionarPeca(peca);
        }
      } catch (error) {
        this.$refs.DialogoBuscarPecas.busca = this.peca.codigo;
        this.$refs.DialogoBuscarPecas.abrir();
        this.$refs.DialogoBuscarPecas.buscar();
        // this.$eventbus.notificarErro('Peça não encontrada.', error);
        // setFocus(this.$refs['codigo']);
        // event.target.select();
      } finally {
        this.buscando = false;
      }
    },

    selecionarFornecedor(fornecedor) {
      this.peca.fornecedor_id = fornecedor.id;
      this.peca.fornecedor.razao = fornecedor.razao;
      setFocus(this.$refs['tipo_entrada'], 100);
    },

    async buscarFornecedorPeloCodigo(event) {
      if (!this.peca.fornecedor_id) {
        this.$refs.DialogoBuscarFornecedor.abrir();
        return false;
      }

      try {
        this.buscando_fornecedor = true;
        let fornecedor = await fornecedoresApi.buscarPeloCodigo(this.peca.fornecedor_id);
        if (fornecedor) {
          this.selecionarFornecedor(fornecedor);
        }
      } catch (error) {
        this.$refs.DialogoBuscarFornecedor.busca = this.peca.fornecedor_id;
        this.$refs.DialogoBuscarFornecedor.abrir();
        this.$refs.DialogoBuscarFornecedor.buscar();
        // this.$eventbus.notificarErro('Fornecedor não encontrado.');
        // setFocus(this.$refs['fornecedor_id']);
        // event.target.select();
      } finally {
        this.buscando_fornecedor = false;
      }
    },

    confirmarPeca() {
      if (!this.$refs.formulario.validate()) {
        return false;
      }

      let peca = clone(this.peca);
      // delete peca.estoque;

      if (this.estaAdicionado(peca.codigo, this.ordemservico.pecas)) {
        this.$eventbus.alertar('Esta peça já foi adicionada a ordem de serviço.');
        return false;
      }

      // if (this.peca.quantidade > this.peca.estoque) {
      //   this.$eventbus.alertar('Esse produto não possuie estoque suficiente.');
      //   return false;
      // }

      if (this.editando_index > -1) {
        this.ordemservico.pecas.splice(this.editando_index, 1, peca);
      } else {
        this.ordemservico.pecas.push(peca);
      }
      // this.calcularTotais();

      this.zerarPeca();
      this.$refs.formulario.resetValidation();
      setFocus(this.$refs['codigo']);

      this.$emit('update:pecas', this.ordemservico.pecas);

      if (this.editando_index > -1) {
        this.$refs.dialogoAdicionarPeca.fechar();
      }

      this.editando_index = -1;
      // this.hidedetails = true;
    },

    estaAdicionado(codigo, pecas) {
      if (this.editando_index > -1) {
        return false;
      }

      const index = pecas.findIndex((peca) => peca.codigo === codigo);
      return index > -1 ? true : false;
    },

    validarQuantidade() {
      if (this.peca.quantidade <= 0) {
        this.$eventbus.notificarErro('A quantidade não pode ser zero.');
        setFocus(this.$refs['quantidade']);
        return false;
      }
      return true;
    },

    adicionarPeca() {
      this.zerarPeca();
      this.$refs.formulario.resetValidation();
      this.$refs.dialogoAdicionarPeca.abrir();
    },

    calcularSubTotalPeca() {
      this.peca.subtotal = trunc(Number(this.peca.quantidade) * Number(this.peca.valor));
    },

    onKeyUpEscAdicionarPeca() {
      this.zerarPeca();
      setFocus(this.$refs['codigo']);
    },

    editar(peca, index) {
      this.editando_index = index;
      this.peca = clone(peca);
      if (!this.peca.fornecedor_id) {
        this.peca.fornecedor = {
          razao: '',
        };
      }
      this.$refs.formulario.resetValidation();
      this.$refs.dialogoAdicionarPeca.abrir();
    },

    async perguntarExcluir(index) {
      let resposta = await this.$refs.dialogoRemover.abrir();

      if (resposta == true) {
        this.ordemservico.pecas.splice(index, 1);
        this.$emit('update:pecas', this.ordemservico.pecas);
        this.$refs.dialogoRemover.fechar();
      }
    },

    zerarPeca() {
      this.peca = clone(peca);
      this.peca.data = this.hoje();
    },
  },
};
</script>

<style scoped></style>
