<template>
  <div>
    <z-centralized-toolbar>
      <v-toolbar-items>
        <v-btn text color="primary" :to="{ name: 'VeiculosLista' }" exact>
          <v-icon left>mdi-arrow-left</v-icon>
          Voltar
        </v-btn>
      </v-toolbar-items>

      <v-spacer></v-spacer>
      <v-btn text @click="$router.push({ name: 'VeiculosLista' })" :disabled="salvando">Cancelar</v-btn>
      <v-btn color="primary" @click="confirmar" :loading="salvando" :disabled="!form_valido">Salvar</v-btn>
    </z-centralized-toolbar>

    <v-divider></v-divider>

    <z-scroll-layout :top-position="$v.TOP_POSITION">
      <z-container>
        <v-alert transition="slide-y-transition" dismissible outlined text type="error" :value="!!error.message" @input="error.message = ''">{{ error.message }} </v-alert>

        <z-cabecalho>{{ $store.state.titulo_tela }}</z-cabecalho>

        <v-form ref="formulario" v-model="form_valido" lazy-validation>
          <v-card tile class="mb-6">
            <v-card-title class="text-subtitle-2">
              Dados do Veículo
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text>
              <v-row dense>
                <!-- <v-col cols="12" sm="12">
                  <z-text-field label="Descrição" v-model="veiculo.descricao" :rules="[regras.campoObrigatorio]"></z-text-field>
                </v-col> -->

                <v-col cols="12" sm="3">
                  <z-text-field label="Placa" v-mask="$v.PLACA_MASK" v-model="veiculo.placa" :rules="[regras.campoObrigatorio]"></z-text-field>
                </v-col>

                <v-col cols="12" sm="3">
                  <z-text-field label="Marca" v-model="veiculo.marca" :rules="[regras.campoObrigatorio]"></z-text-field>
                </v-col>

                <v-col cols="12" sm="3">
                  <z-text-field label="Modelo" v-model="veiculo.modelo" :rules="[regras.campoObrigatorio]"></z-text-field>
                </v-col>

                <v-col cols="12" sm="3">
                  <z-text-field label="Cor" v-model="veiculo.cor"></z-text-field>
                </v-col>

                <v-col cols="12" sm="3">
                  <z-text-field label="Ano" v-model="veiculo.ano"></z-text-field>
                </v-col>

                <v-col cols="12" sm="3">
                  <z-select :items="$globals.COMBUSTIVEIS" label="Combustível" v-model="veiculo.combustivel"></z-select>
                </v-col>

                <v-col cols="12" sm="3">
                  <z-text-field label="Quilometragem" v-model="veiculo.km"></z-text-field>
                </v-col>

                <v-col cols="12" sm="3">
                  <z-text-field label="Chassi" v-model="veiculo.chassi"></z-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-form>
      </z-container>
    </z-scroll-layout>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { clone } from '@/app/helpers/utils';
import veiculo from '@/app/store/models/veiculo';

import * as regras from '@/app/helpers/validacoes';

const teste = (x) => x + 1;

export default {
  name: 'VeiculosForm',

  data() {
    return {
      veiculo: veiculo,
      salvando: false,
      regras: {},
      tab: 0,
      form_valido: true,
      regras: { ...regras },
      error: {
        message: '',
      },
    };
  },

  computed: {
    titulo() {
      let titulo = /Editar/.test(this.$route.name) ? 'Editar' : 'Adicionar';
      return titulo + ' ' + 'Veículo';
    },
  },

  async mounted() {
    this.setTitulo(this.titulo);

    if (this.$route.name == 'VeiculosEditar') {
      if (!this.$store.state.veiculos.veiculo.id) {
        if (this.$route.params.id) {
          await this.abrir(this.$route.params.id);
        }
      }
      this.veiculo = clone(this.$store.state.veiculos.veiculo);
    }
  },

  methods: {
    ...mapMutations(['setTitulo']),
    ...mapMutations('veiculos', ['ZERAR']),
    ...mapActions('veiculos', ['abrir', 'atualizar', 'salvar']),

    async confirmar() {
      this.error.message = '';

      if (!this.$refs.formulario.validate()) {
        return false;
      }

      try {
        this.salvando = true;

        if (this.$route.params.id) {
          await this.atualizar(this.veiculo);
        } else {
          await this.salvar(this.veiculo);
        }
        this.$router.push({ name: 'VeiculosLista' });
        this.$eventbus.notificar('Veículo salvo com sucesso');
      } catch (error) {
        if (error.status == 422) {
          this.error.message = error.data.errors.placa[0];
          return;
        }

        this.$eventbus.notificarErro('Não foi possível salvar este veículo. Verifique os dados preenchidos.', error);
        throw error;
      } finally {
        this.salvando = false;
      }
    },
  },
};
</script>

<style scoped></style>
