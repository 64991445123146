<template>
  <div>

    <z-container class="mt-10">
      <v-row justify="center">

        <v-col cols="12"  align="center" class="mb-4">
          <v-avatar color="primary" size="200">
            <v-icon size="150" dark>{{ config.APP_ICON }}</v-icon>
          </v-avatar>
        </v-col>

        <v-col cols="12" class="mb-4" align="center">
          <h1 class="display-2 mb-3">
            <div>Bem-vindo!</div>
            <div class="font-weight-bold my-4" style="height: 50px">{{ config.APP_TITLE }}&nbsp;</div>
          </h1>
          <p class="overline" style="font-size: 16px !important; height: 32px">
            {{ config.APP_SUBTITLE }}
          </p>
        </v-col>
      </v-row>

      <test-component v-if="!$config.IS_PRODUCTION"></test-component>

    </z-container>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, onBeforeMount, computed } from "vue";

import { useConfigStore } from '@/app/store/pinia/configuracoes'

import TestComponent from './TestComponent.vue';


const store = useConfigStore();

// onBeforeMount(async () => {
//   await store.carregarConfiguracoes();
//   await store.carregarListaConfiguracoes();
// })

const config = computed(() => {
  return {...store.configuracoes};
})


</script>

<style>

</style>
