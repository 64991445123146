const ESTADOS = ['AC', 'AL', 'AM', 'AP', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RO', 'RS', 'RR', 'SC', 'SE', 'SP', 'TO'];

const UNIDADES = ['UN', 'KG', 'CX', 'MT', 'LT', 'PC', 'SC'];

// const FORMAS_DE_PAGAMENTO = ['À Vista', 'Cheque', 'Duplicata', 'Consignado', 'Cartão de Crédito', 'Cartão de Débito', 'Boleto', 'Depósito Bancário', 'Boleto/Cheque', 'Boleto Bancário', 'Fiado'];
const FORMAS_DE_PAGAMENTO = [
  'À Vista',
  'Dinheiro',
  'Transferência',
  'Cheque',
  'Duplicata',
  'Cartão de Crédito',
  'Cartão de Débito',
  'Boleto Bancário',
  'Depósito Bancário',
  'Haver'
];

const FORMAS_DE_PAGAMENTO_CAIXA = [
  'Dinheiro',
  'Depósito Bancário',
  'Cheque',
  'Transferência',
  'Marcadoria',
  'Cartão de Crédito',
  'Cartão de Débito',
  'Boleto Bancário',
  'Haver'
];

const PRAZOS_DE_PAGAMENTO = [{text: '30/60', value: 1}, {text: '30/60/90', value: 2}, {text: '30/60/90/120', value: 3}, {text: '15/30/45', value: 4}];

const TIPO_PESSOA = [{text: 'Física', value: 'F'}, {text: 'Jurídica', value: 'J'}];

const GRUPOS_PRODUTOS = [{text: 'Calças', value: '1'}, {text: 'Bermudas', value: '2'}, {text: 'Camisas', value: '3'}];

const PERFIS = ['Administrador', 'Gerente', 'Vendedor'];

const STATUS_CHEQUE = ['Em Caixa', 'Devolvido', 'Repassado', 'Compensado'];

const SITUACAO = ['Todos', 'Abertos', 'Pagos', 'Vencidos', 'A Vencer'];

const TIPO_LANCAMENTO = ['Entrada', 'Saída'];

const ESTADO_CIVIL = ['Solteiro', 'Casado', 'Divorciado', 'Viúvo', 'Outro'];

const SEXO = [
  { text: 'MASCULINO', value: 'M' },
  { text: 'FEMININO', value: 'F' }
];

const TIPO_CLIENTE = [
  { text: 'CLIENTE ESPECIAL', value: 1 },
  { text: 'EMPRESA ESPECIAL', value: 2 },
  { text: 'CLIENTE SUJEITO AO SPC', value: 3 },
  { text: 'CLIENTE', value: 4 },
];

const TIPO_OS = [
  { text: 'EXTERNA', value: 1 },
  { text: 'INTERNA', value: 2 },
  { text: 'GARANTIA', value: 3 },
  { text: 'RETORNO', value: 4 },
];

const COMBUSTIVEIS = [
  'Gasolina',
  'Alcool',
  'Diesel',
  'Flex',
  'Elétrico',
  'Híbrido'
];

const TIPOS_ENTRADA = [
  { text: 'NF', value: 1 },
  { text: 'PEDIDO', value: 2 }
];

const MESES = [
  { text: "Janeiro", value: 1 },
  { text: "Fevereiro", value: 2 },
  { text: "Março", value: 3 },
  { text: "Abril", value: 4 },
  { text: "Maio", value: 5 },
  { text: "Junho", value: 6 },
  { text: "Julho", value: 7 },
  { text: "Agosto", value: 8 },
  { text: "Setembro", value: 9 },
  { text: "Outubro", value: 10 },
  { text: "Novembro", value: 11 },
  { text: "Dezembro", value: 12 },
];

const globals = {
    ESTADOS,
    UNIDADES,
    FORMAS_DE_PAGAMENTO,
    FORMAS_DE_PAGAMENTO_CAIXA,
    PRAZOS_DE_PAGAMENTO,
    TIPO_PESSOA,
    GRUPOS_PRODUTOS,
    PERFIS,
    STATUS_CHEQUE,
    SITUACAO,
    TIPO_LANCAMENTO,
    ESTADO_CIVIL,
    TIPO_CLIENTE,
    TIPO_OS,
    SEXO,
    COMBUSTIVEIS,
    TIPOS_ENTRADA,
    MESES
}

export {globals};

export default globals;
