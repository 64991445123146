import { defineStore } from 'pinia'

import apiFolha from "@/app/services/folhapagamento";
import { range, mesExtenso, hoje } from '@/app/helpers/utils';


export const useFolhaPagamentoStore = defineStore('folhaPagamento', {

  state: () => ({
    folhaspagamento: [],
    folhapagamento: {
      mes: new Date().getMonth() + 1,
      ano: new Date().getFullYear(),
      descricao: `Folha de pagamento ${mesExtenso(new Date().getMonth() + 1)} de ${new Date().getFullYear()}`
    },
    lancamento: {
      data: '',
      descricao: '',
      valor: 0,
      tipo: 'Provento',
    },
    funcionario: {},
    lancamentos: [],
    titulo: "Folha de Pagamento",
    loading: false,
  }),

  actions: {

    zerarLancamento() {
      this.lancamento = {
        data: hoje(),
        descricao: '',
        valor: 0,
        tipo: 'Provento',
      }
    },

    async listar() {
      let folhas = await apiFolha.listar();
      this.folhaspagamento = folhas.data;
    },

    async salvar(folha) {
      let folhaResponse = await apiFolha.salvar(folha);
      this.folhaspagamento.push(folhaResponse);
    },

    async atualizar(folha) {
      let folhaResponse = await apiFolha.atualizar(folha);

      let index = this.folhaspagamento.findIndex(f => f.id == folhaResponse.id);
      this.folhaspagamento[index] = folhaResponse;
    },

    async abrir(id) {
      this.folhapagamento = await apiFolha.abrir(id);
    },

    async excluir(id) {
      await apiFolha.excluir(id);
      this.folhaspagamento = this.folhaspagamento.filter(f => f.id != id);
    },

    novo() {
      this.folhapagamento = {
        mes: new Date().getMonth() + 1,
        ano: new Date().getFullYear(),
        descricao: `Folha de pagamento ${mesExtenso(new Date().getMonth() + 1)} de ${new Date().getFullYear()}`
      }
    },

    async salvarItem(item) {
      let folhaResponse = await apiFolha.salvarItem(this.folhapagamento, item);
      this.folhaspagamento.push(folhaResponse);
    },

    async carregarLancamentos(funcionario) {
      this.funcionario = funcionario;
      let folhaResponse = await apiFolha.lancamentosFolha(this.folhapagamento, funcionario);
      this.lancamentos = folhaResponse;
    },

    novoLancamento() {
      this.zerarLancamento();
    },

    editarLancamento(lancamento) {
      this.lancamento = lancamento;
    },

    async salvarLancamento() {
      let lancamentoResponse = await apiFolha.salvarLancamento(this.folhapagamento, this.lancamento);
      this.lancamentos.push(lancamentoResponse);
    },

    async atualizarLancamento() {
      let lancamentoResponse = await apiFolha.atualizarLancamento(this.folhapagamento, this.lancamento);
      let index = this.lancamentos.findIndex(l => l.id == lancamentoResponse.id);
      this.lancamentos[index] = lancamentoResponse;
    },

    async excluirLancamento(lancamento) {
      await apiFolha.excluirLancamento(this.folhapagamento, lancamento);
      this.lancamentos = this.lancamentos.filter(l => l.id != lancamento.id);
    },

  },
})
