export default [
  {name: 'Vermelho', value: '#f44336'},
  {name: 'Rosa', value: '#e91e63'},
  {name: 'Roxo', value: '#9c27b0'},
  {name: 'Roxo Escuro', value: '#673ab7'},
  {name: 'Indigo', value: '#3f51b5'},
  {name: 'Azul', value: '#2196f3'},

  {name: 'Azul Claro', value: '#03a9f4'},
  {name: 'Ciano', value: '#00bcd4'},
  {name: 'Azul Petróleo', value: '#009688'},
  {name: 'Verde', value: '#4caf50'},
  {name: 'Verde Claro', value: '#8bc34a'},
  {name: 'Limão', value: '#cddc39'},

  {name: 'Amarelo', value: '#ffeb3b'},
  {name: 'Ambar', value: '#ffc107'},
  {name: 'Laranja', value: '#ff9800'},
  {name: 'Laranja Escuro', value: '#ff5722'},
  {name: 'Marrom', value: '#795548'},
  {name: 'Cinza Azulado', value: '#607d8b'},

  {name: 'Cinza', value: '#9e9e9e'},
]