import ConfiguracoesSistema from '@/pages/sistema/configuracoes/ConfiguracoesSistema'

export default [
  {
    path: '/sistema/configuracoes',
    name: 'ConfiguracoesSistema',
    component: ConfiguracoesSistema,
    meta: { layout: 'principal', permissao: 'sistema_configuracoes', public: true }
  },
  {
    path: '/sistema/configuracoes/:id',
    name: 'ConfiguracoesSistem2',
    component: ConfiguracoesSistema,
    meta: { layout: 'principal', permissao: 'sistema_configuracoes', public: true }
  },

]
