<template>
  <z-expansion-config-item titulo="Economia de Bateria" :loading="isLoading">
    <v-select
      v-model="store.configuracoes.APP_BATERIA"
      :items="['Nunca', '10%', '20%', '30%', '40%', '50%', 'Sempre']"
      dense
      solo
      hide-details
      @change="updateConfig"
    ></v-select>
  </z-expansion-config-item>
</template>

<script setup>
import { computed } from 'vue';
import { useConfigStore } from '@/app/store/pinia/configuracoes'

import { zExpansionConfigItem } from "zcomponents";

const store = useConfigStore();

const isLoading = computed(() => {
  return store.conf.origin == 'EconomiaBateria' && store.conf.loading == true;
})

function updateConfig(value) {
  store.conf.origin = 'EconomiaBateria';
}

</script>

<style lang="scss" scoped>

</style>
