const createCookie = function() {

  const setCookie = (key, value, days = 1) => {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `${key}=${value};${days ? `${expires};` : ''}path=/`;
  }

  const getCookie = (key) => {
    const name = `${key}=`;
    const cookies = document.cookie.split(';');

    for (let cookie of cookies) {
      cookie = cookie.trim();

      if (cookie.startsWith(name)) {
        return cookie.substring(name.length);
      }
    }

    return "";
  }

  const deleteCookie = (key) => {
    setCookie(key, '', -1);
  }

  return {
    setCookie,
    getCookie,
    deleteCookie
  }
}

export default createCookie;
