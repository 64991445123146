<template>
  <z-expansion-config-item :loading="isLoading" titulo="Hotspot Móvel" subtitulo="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Dolores minima consectetur veritatis corporis quas est enim, omnis id.">
    <v-switch
      v-model="store.configuracoes.APP_HOTSPOT"
      :label="store.configuracoes.APP_HOTSPOT ? 'Ativo' : 'Inativo'"
      hide-details
      class="mt-0 pt-0"
      @change="updateConfig"
    ></v-switch>
  </z-expansion-config-item>
</template>

<script setup>
import { computed } from 'vue';
import { useConfigStore } from '@/app/store/pinia/configuracoes'

import { zExpansionConfigItem } from "zcomponents";

const store = useConfigStore();

const isLoading = computed(() => {
  return store.conf.origin == 'Hotspot' && store.conf.loading == true;
})

function updateConfig(value) {
  store.conf.origin = 'Hotspot';
}

</script>

<style lang="scss" scoped>

</style>
