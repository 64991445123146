<template>
  <z-expansion-config
    titulo="Personalização do sistema"
    subtitulo="Configurações de identificação do sistema e ícone"
    :loading="isLoading"
  >
    <z-expansion-config-item no-space empty titulo="Configurações do Sistema">
      <v-row>
        <v-col cols="12">
          <z-text-field
            v-model="store.configuracoes.APP_NAME"
            label="Nome da Aplicação"
            hide-details
            @input="updateConfig"
          ></z-text-field>
        </v-col>
        <v-col cols="12">
          <z-text-field
            v-model="store.configuracoes.APP_TITLE"
            label="Título Sistema"
            hide-details
            @input="updateConfig"
          ></z-text-field>
        </v-col>
        <v-col cols="12">
          <z-text-field
            v-model="store.configuracoes.APP_SUBTITLE"
            label="SubTítulo Sistema"
            hide-details
            @input="updateConfig"
          ></z-text-field>
        </v-col>
        <v-col cols="12">
          <z-text-field
            v-model="store.configuracoes.APP_ICON"
            label="Ícone"
            hide-details
            @input="updateConfig"
          ></z-text-field>
        </v-col>
      </v-row>
    </z-expansion-config-item>
  </z-expansion-config>
</template>

<script setup>
import { reactive, watch, computed } from 'vue';
import { useConfigStore } from '@/app/store/pinia/configuracoes'

import { zExpansionConfig } from "zcomponents";
import { zExpansionConfigItem } from "zcomponents";

const store = useConfigStore();

const isLoading = computed(() => {
  return store.conf.origin == 'PersonalizacaoSistema' && store.conf.loading == true;
})

function updateConfig() {
  store.conf.origin = 'PersonalizacaoSistema';
  document.title = store.configuracoes.APP_TITLE;
}

</script>

<style lang="scss" scoped>

</style>
