import createAxios from '@/app/core/AxiosCore';
import config from '@/app/config/app';

const axios = createAxios(config);

function comissoesServicos(filtro) {
  return axios.get('consultas/comissoes/servicos', {params: filtro})
    .then(response => response.data);
}

function comissoesServicosConsolidado(filtro) {
  return axios.get('consultas/comissoes/servicosconsolidado', {params: filtro})
    .then(response => response.data);
}


export default {
  comissoesServicos,
  comissoesServicosConsolidado

}
