import apiAgenda from "@/app/services/agenda";
import { PAGINATION_OPTIONS } from "@/app/vuetifyConfig";

import { hoje } from "../../helpers/utils"

export default {
  namespaced: true,
  state: {
    evento: {
      id: '',
      nome: '',
      telefone: '',
      placa: '',
      data: '',
      hora: '',
      observacao: ''
    },
    eventos: [],
    eventos_agrupados: {
      agendados_dia: [],
      agendados: []
    },
    busca: "",
    total_itens: 0,
    paginacao: {
      page: 1,
      itemsPerPage: 10,
      sortBy: ['id'],
      sortDesc: [true]
    },
    today_events_count: 0,
    currentMonth: 0,
    period: ''
  },

  getters: {
    agendados_dia(state) {
      const agendados_dia = state.eventos.filter(evento => {
        return evento.data == hoje();
      });

      return agendados_dia
    },

    agendados(state) {
      const agendados_dia = state.eventos.filter(evento => {
        return evento.data == hoje();
      });

      const agendados = state.eventos.filter(evento => {
        return agendados_dia.findIndex(evento2 => evento2.id === evento.id) === -1;
      });

      return agendados
    }
  },

  mutations: {
    LISTAR(state, eventos) {
      state.eventos = eventos;

      state.eventos_agrupados.agendados_dia = eventos.filter(evento => {
        return evento.data == hoje();
      });

      state.eventos_agrupados.agendados = eventos.filter(evento => {
        return state.eventos_agrupados.agendados_dia.findIndex(evento2 => evento2.id === evento.id) === -1;
      });
    },

    ABRIR(state, evento) {
      state.evento = evento;
    },

    ADICIONAR(state, evento) {
      state.eventos.push(evento);
    },

    ATUALIZAR(state, evento) {
      let index = state.eventos.findIndex(peca_ => peca_.id == evento.id);
      if (index > -1) {
        state.eventos.splice(index, 1, evento);
      }
    },

    CONFIRMAR(state, evento) {
      evento.confirmado = !evento.confirmado;
    },

    EXCLUIR(state, id) {
      let index = state.eventos.findIndex(evento => evento.id == id);
      if (index > -1) {
        state.eventos.splice(index, 1);
      }
    },

    SET_BUSCA(state, busca) {
      state.busca = busca;
    },

    SET_PAGE(state, page) {
      state.paginacao.page = page;
    },

    SET_TOTAL_ITENS(state, total_itens) {
      state.total_itens = total_itens;
    },

    UPDATE_PAGINACAO(state, paginacao) {
      state.paginacao = paginacao;
    },

    SET_TODAYEVENTSCOUNT(state, count) {
      state.today_events_count = count;
    },

    SET_CURRENT_MONTH(state, period) {
      state.currentMonth = period.currentMonth;
    },

    SET_PERIOD(state, period) {
      state.period = period;
    },

    ZERAR(state, data) {
      state.evento = {
        id: null,
        nome: '',
        telefone: '',
        placa: '',
        data: !!data ? data : hoje(),
        hora: new Date().toLocaleTimeString({ timeZone: 'America/Recife' }).substring(0,5),
        observacao: ''
      };
    }
  },

  actions: {
    async listar({ commit, state }, period) {
      commit('LOADING_ON', null, { root: true });

      const { sortBy, sortDesc, page, itemsPerPage } = state.paginacao;

      const paginacao = {
        page,
        itemsPerPage,
        sortBy: sortBy[0],
        sortDesc: sortDesc[0]
      }

      if (state.busca) {
        paginacao.busca = state.busca;
      }

      let response = await apiAgenda.listar(period);
      commit('LISTAR', response);
      // commit('SET_TOTAL_ITENS', response.total);
      commit('LOADING_OFF', null, { root: true });
    },

    async listarByMonth({ commit, state }, period) {
      commit('LOADING_ON', null, { root: true });

      commit('SET_CURRENT_MONTH', period);
      let response = await apiAgenda.listarByMonth(period);
      commit('LISTAR', response.events);
      commit('SET_PERIOD', response.period);
      commit('LOADING_OFF', null, { root: true });
    },

    async abrir({ commit }, id) {
      commit('LOADING_ON', null, { root: true });
      let evento = await apiAgenda.abrir(id);
      commit('ABRIR', evento);
      commit('LOADING_OFF', null, { root: true });
    },

    async salvar({ commit, dispatch }, evento) {
      let novo_evento = await apiAgenda.salvar(evento);
      commit('ADICIONAR', novo_evento);

      dispatch('todayEventsCount');
    },

    async atualizar({ commit, dispatch }, evento) {
      await apiAgenda.atualizar(evento);
      commit('ATUALIZAR', evento);
      dispatch('todayEventsCount');
    },

    async excluir({ commit }, id) {
      await apiAgenda.excluir(id);
      commit('EXCLUIR', id);
    },

    async confirmarEvento({ commit }, evento) {
      commit('CONFIRMAR', evento);
       await apiAgenda.confirmarEvento(evento);
    },

    async todayEventsCount({ commit }) {
      let today_events_count = await apiAgenda.todayEventsCount();
      commit('SET_TODAYEVENTSCOUNT', today_events_count);
    },

  }
}
