<template>
  <v-form v-model="form_valido" lazy-validation ref="formulario">
    <z-dialogo ref="dialogoAdicionar" largura="700px" :titulo="tituloForm" dividers persistent @abrir="$refs.formulario.resetValidation()">
      <template v-slot:conteudo>
        <v-row dense>
          <v-col cols="12" sm="12">
            <z-text-field upper label="Nome Cliente" v-model="evento.nome" ref="descricao" placeholder=" " :rules="[regras.campoObrigatorio]"></z-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <z-text-field label="Telefone" v-model="evento.telefone" v-mask="$v.TELEFONE_MASK"></z-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <z-text-field label="Placa" v-mask="$v.PLACA_MASK" v-model="evento.placa"></z-text-field>
          </v-col>
          <v-col cols="12" sm="4">
            <z-date-picker-menu v-model="evento.data" ref="data" label="Data" :rules="[regras.campoObrigatorio]"></z-date-picker-menu>
          </v-col>

          <!-- <v-col cols="12" sm="6">
            <z-time-picker v-model="evento.hora" ref="data" label="Hora" :rules="[regras.campoObrigatorio]"></z-time-picker>
          </v-col> -->
          <!-- <v-col cols="12" sm="3">
            <z-text-field label="Modelo" v-model="evento.modelo"></z-text-field>
          </v-col> -->
          <!-- <v-col cols="12" sm="9">
            <z-text-field label="Decrição Carro" placeholder=" " disabled></z-text-field>
          </v-col> -->
        </v-row>

        <v-row dense>
          <v-col cols="12" sm="12">
            <z-textarea upper label="Serviço" v-model="evento.observacao" rows="2"></z-textarea>
          </v-col>
        </v-row>
      </template>

      <template v-slot:actions_left>
        <v-btn v-if="evento.id" color="red" dark @click="perguntarExcluir(evento)" :disabled="salvando">Remover</v-btn>
      </template>
      <template v-slot:actions_right>
        <v-btn text @click="$refs.dialogoAdicionar.fechar()" :disabled="salvando">Cancelar</v-btn>
        <v-btn color="primary" ref="btnConfirmar" @click="confirmar" :loading="salvando" :disabled="!form_valido">Confirmar</v-btn>
      </template>
    </z-dialogo>
  </v-form>
</template>

<script>
  import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
  import * as regras from '@/app/helpers/validacoes';

  export default {
    name: 'AgendaForm',

    data() {
      return {
        focus: new Date().toISOString().substring(0,10),
        excluindo: false,
        salvando: false,
        form_valido: true,
        regras: { ...regras },
        horario_agendado: false
      }
    },

    computed: {
      ...mapState('agenda', ['eventos', 'evento', 'total_itens']),

      tituloForm() {
        return this.evento.id ? 'Editar Agendamento' : 'Novo Agendamento';
      },
    },

    methods: {

      ...mapActions('agenda', ['abrir', 'atualizar', 'salvar', 'excluir']),

      abrir() {
        this.$refs.dialogoAdicionar.abrir();
      },

      fechar() {
        this.$refs.dialogoAdicionar.fechar();
      },

      async confirmar() {
        if (!this.$refs.formulario.validate()) {
          return false;
        }

        try {
          this.salvando = true;

          if (this.evento.id) {
            await this.atualizar(this.evento);
          } else {
            await this.salvar(this.evento);
          }

          this.$store.dispatch('agenda/todayEventsCount');
          this.fechar();
          this.$eventbus.notificar('Evento salvo com sucesso');
        } catch (error) {
          this.$eventbus.notificarErro('Não foi possível salvar este evento. Verifique os dados preenchidos.', error);
          throw error;
        } finally {
          this.salvando = false;
        }
      },

      async perguntarExcluir(evento) {
        let resposta = await this.$root.dialogo_pergunta.abrir({
          titulo: 'Remover evento?',
          texto: 'O evento será removido do sistema.',
          botoes: ['Cancelar', 'Remover'],
          padrao: 'Remover',
          cor: 'red',
        });

        if (resposta == 'Remover') {
          this.$root.dialogo_pergunta.carregando();
          await this.confirmarExcluir(evento);
          this.$root.dialogo_pergunta.fechar();
        } else {
          this.$root.dialogo_pergunta.fechar();
        }
      },

      async confirmarExcluir(evento) {
        try {
          this.excluindo = true;
          await this.excluir(evento.id);
          this.$eventbus.notificar('Evento excluído com sucesso!');
          this.fechar();
        } catch (error) {
          this.$eventbus.notificar('Não foi possível excluir o evento!');
          throw error;
        } finally {
          this.excluindo = false;
        }
      },

    },
  }
</script>

<style lang="scss" scoped>

</style>
