<template>
  <v-navigation-drawer
    v-bind="$attrs"
    disable-resize-watcher
    app
    fixed
    :clipped="clipped"
    :mini-variant.sync="miniVariant"
    :value="drawer"
    @input="$emit('update:drawer', $event)"
  >

    <template v-slot:prepend>
      <v-toolbar dark color="primary">
        <v-btn icon @click="goHome">
          <v-icon>mdi-car</v-icon>
        </v-btn>

        <v-toolbar-title>
          <span class="mr-2 apontador" @click="goHome">{{ config.APP_NAME }}</span>
          <!-- <span class="text-caption mr-2">{{ config.APP_VERSION }}</span> -->
        </v-toolbar-title>
      </v-toolbar>
    </template>

    <app-menu :mini-variant="miniVariant"></app-menu>

    <template v-slot:append>
      <v-divider></v-divider>
      <v-row>
        <v-col cols="12" class="text-center text-subtitle-2">
          <span>{{ $config.APP_VERSION }}</span>
        </v-col>
      </v-row>
    </template>

  </v-navigation-drawer>
</template>

<script>

  import AppMenu from "../menus/AppMenu";

  import { mapStores } from 'pinia'
  import { useConfigStore } from '@/app/store/pinia/configuracoes'

  export default {
    components: {
      AppMenu
    },

    props: {
      drawer: {
        type: Boolean,
        default: true
      },
      permanent: {
        type: Boolean,
        default: true
      }
    },

    data() {
      return {
        clipped: false,
        fixed: true,
        miniVariant: false,
        dataehora: '',
      }
    },

    computed: {
      ...mapStores(useConfigStore),

      config() {
        return this.configStore.configuracoes;
      },
    },

    mounted() {
      // this.startTimer();
    },

    methods: {

      goHome() {
        this.$router.push({ name: 'Home'}).catch(error => {});
      },

      startTimer() {
        setInterval(() => {
          let d = new Date();
          // let hora = new Date().toLocaleTimeString('pt-BR', {hour: "numeric", minute: "numeric", second: "numeric"});
          this.dataehora = d.toLocaleDateString('pt-BR') + ' '+ d.getHours() + ':' + d.getMinutes()+ ':' + d.getSeconds();
        }, 1000);
      }
    },
  }
</script>

<style scoped>

</style>
