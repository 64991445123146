import createAxios from '@/app/core/AxiosCore';
import config from '@/app/config/app';

const axios = createAxios(config);
import qs from "qs";


function listar(paginacao) {
  return axios.get(`tiposcliente`, {params: paginacao})
    .then(response => response.data);
}

function buscar(busca) {
  return axios.get(`tiposcliente/buscar?busca=${busca}`)
    .then(response => response.data);
}

function buscarPorCodigo(codigo) {
  return axios.get(`tiposcliente/buscarCodigo?codigo=${codigo}`)
    .then(response => response.data);
}

function abrir(id) {
  return axios.get(`/tiposcliente/${id}`)
    .then(response => response.data);
}

function salvar(tipocliente) {
  return axios.post('/tiposcliente', tipocliente)
    .then(response => response.data);
}

function atualizar(tipocliente) {
  return axios.put(`/tiposcliente/${tipocliente.id}`, tipocliente)
    .then(response => response.data)
}

function excluir(id) {
  return axios.delete(`/tiposcliente/${id}`)
    .then(response => response.data);
}


export default {
  listar,
  buscar,
  buscarPorCodigo,
  abrir,
  salvar,
  atualizar,
  excluir
}
