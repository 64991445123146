import { render, staticRenderFns } from "./DialogoBuscarPecas.vue?vue&type=template&id=41be32ba&scoped=true&"
import script from "./DialogoBuscarPecas.vue?vue&type=script&lang=js&"
export * from "./DialogoBuscarPecas.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41be32ba",
  null
  
)

export default component.exports