<template>
    <div>
      <z-centralized-toolbar>
        <v-toolbar-items>
          <v-btn id="btn_adicionar" text small color="primary" :to="{name: 'EstoqueAdicionar'}">
            <v-icon left>mdi-swap-horizontal-circle-outline</v-icon>
            Adicionar
          </v-btn>

          <!-- <menu-relatorios-estoque @abrir:relatorio="abrirRelatorio"></menu-relatorios-estoque> -->

          <v-btn id="btn_refresh" text small @click="refresh" :loading="$store.state.loading">
            <v-icon>mdi-refresh</v-icon>
            <v-tooltip bottom activator="#btn_refresh">Refresh</v-tooltip>
          </v-btn>
        </v-toolbar-items>

        <!-- <v-spacer></v-spacer> -->

        <z-text-field-search
          v-model="busca"
          select
          class="ml-5"
          width="250px"
          @keydown.enter="buscar"
          @click:clear="limparBusca"
        ></z-text-field-search>
      </z-centralized-toolbar>

      <v-divider></v-divider>

    <z-scroll-layout :top-position="$v.TOP_POSITION">
      <z-container>
          <z-cabecalho>{{$store.state.titulo_tela}}</z-cabecalho>

          <v-card>
              <v-data-table
                mobile-breakpoint="0"
                :options.sync="paginacao"
                :server-items-length="total_itens"
                :footer-props="$v.FOOTER_PROPS"
                :headers="headers"
                :items="movimentosestoque"
                item-key="id"
                id="movimentos"
                disable-pagination
              >
                <template v-slot:item="{item: movimento}">
                  <tr>
                    <td>{{ movimento.id }}</td>
                    <td>{{ movimento.data | dataBR}}</td>
                    <td>{{ movimento.fornecedor ? movimento.fornecedor.razao : '' }}</td>
                    <td>
                      <v-chip
                        :color="movimento.tipomovimento.movimento == 'Entrada' ? 'green lighten-3' : 'red lighten-3'"
                        class="justify-center"
                        small
                        style="width: 70px"
                      >
                        {{ movimento.tipomovimento.descricao }}
                      </v-chip>
                    </td>
                    <td class="text-right">{{ movimento.valor_total | valorBR }}</td>
                    <td class="text-center">

                      <!-- <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" icon @click="imprimir(props.item)" class="ma-0">
                            <v-icon>mdi-printer</v-icon>
                          </v-btn>
                        </template>
                        <span>Imprimir Movimento</span>
                      </v-tooltip> -->

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" icon @click="editar(movimento)" class="ma-0">
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>Editar Movimento</span>
                      </v-tooltip>

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" icon @click="perguntarExcluir(movimento)" class="ma-0">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        <span>Excluir Movimento</span>
                      </v-tooltip>

                    </td>
                  </tr>
                </template>
              </v-data-table>
          </v-card>
      </z-container>
    </z-scroll-layout>



      <!-- <z-dialogo ref="dialogoExcluir" titulo="Deseja excluir esse movimento?" :dividers="true" largura="500px">
        <div slot="texto">
          <v-list>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>Movimento</v-list-item-subtitle>
                <v-list-item-title>{{ movimento.id}}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-subtitle>Fornecedor</v-list-item-subtitle>
                <v-list-item-title>{{ movimento.fornecedor ? movimento.fornecedor.razao : '' }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>

        <v-btn text @click="$refs.dialogoExcluir.fechar()" :disabled="excluindo">Cancelar</v-btn>
        <v-btn color="primary darken-1" text @click="confirmarExcluir" :loading="excluindo" :disabled="excluindo">Excluir</v-btn>
      </z-dialogo> -->

      <entradas-de-produtos ref="entradas" :relatorio="relatorio"></entradas-de-produtos>

      <z-dialogo-sim-nao ref="dialogoExcluir" titulo="Remover Movimento de Estoque?" botaoSim="Remover" botaoNao="Cancelar" :loading="excluindo">
        A movimentação de estoque será removida.
      </z-dialogo-sim-nao>

    </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from "vuex";
import config from '@/app/config/app';
import MenuRelatoriosEstoque from './MenuRelatoriosEstoque';
import EntradasDeProdutos from "./EntradasDeProdutos";

export default {
  name: 'EstoqueLista',

  components: {
    MenuRelatoriosEstoque,
    EntradasDeProdutos
  },

  async mounted() {
    this.setTitulo("Movimento de Estoque");
  },

  data() {
    return {
      montado: false,
      headers: [
        { text: '#', value: 'id', width: '100px' },
        { text: 'Data', value: 'data', width: '110px' },
        { text: 'Fornecedor', value: 'fornecedor.razao' },
        { text: 'Movimento', value: 'tipomovimento.descricao', width: '130px' },
        { text: 'Total', value: 'valor_total', align: 'right', width: '150px' },
        { text: 'Opções', value:'', width: '130px', align: 'center', sortable: false }
      ],
      excluindo: false,
      movimento: {
        fornecedor: {
          razao: ''
        }
      },
      listagem: 'todos',
      sheet: false,
      relatorio: ''
    };
  },


  computed: {
    ...mapState(['loading', 'titulo_tela']),
    ...mapState('movimentoestoque', ['movimentosestoque', 'total_itens']),

    busca: {
      get () {
        return this.$store.state.movimentoestoque.busca
      },
      set (busca) {
        this.$store.commit('movimentoestoque/SET_SEARCH', busca);
      }
    },

    paginacao: {
      get () {
        return this.$store.state.movimentoestoque.paginacao
      },
      set (paginacao) {
        this.$store.commit('movimentoestoque/UPDATE_PAGINACAO', paginacao);
      }
    },
  },

  methods: {
    ...mapMutations(['setTitulo', 'LOADING_OFF']),
    ...mapActions('movimentoestoque', ['listar', 'excluir', 'abrir']),

    async refresh() {
      try {
        await this.listar();
      } catch (error) {
        console.log(error);
        this.$eventbus.notificarErro('Não foi possível obter a lista do movimentos.', error);
        this.LOADING_OFF();
      }
    },

    async editar(movimento) {
      try {
        await this.abrir(movimento.id);
        this.$router.push({ name: "EstoqueEditar", params: { id: movimento.id } });
      } catch (error) {
        this.$eventbus.notificarErro('Não foi possível abrir este movimento.', error);
        this.LOADING_OFF();
      }
    },

    async visualizar(movimento) {
      try {
        await this.abrir(movimento.id);
        this.$router.push({ name: "EstoqueVisualizar", params: { id: movimento.id } });
      } catch (error) {
        this.$eventbus.notificarErro('Não foi possível abrir este movimento.', error);
        this.LOADING_OFF();
      }
    },

    async perguntarExcluir(movimento) {
      let resposta = await this.$refs.dialogoExcluir.abrir();
      if (resposta == true) {
        try {
          this.excluindo = true;
          await this.excluir(movimento.id);
          this.$eventbus.notificar('Movimento excluído com sucesso!');
        } catch(error) {
          this.$eventbus.notificarErro('Não foi possível excluir o movimento.', error)
        } finally {
          this.$refs.dialogoExcluir.fechar();
          this.excluindo = false;
        }
      }
    },

    limparBusca() {
      this.busca = '';
      this.$store.commit('estoque/SET_PAGE', 1);
      this.refresh();
    },

    imprimir(movimento) {
      this.abrirJanela(config.BASE_URL_PRINT + '/movimentos/'+ movimento.id +'/imprimir');
    },

    updatePagination(options) {
      this.$store.commit('estoque/UPDATE_PAGINATION', options);
    },

    abrirRelatorio(relatorio) {

      this.relatorio = relatorio;

      if (relatorio.indexOf(['entradaDeProdutosPeriodo', 'entradaDeProdutosValorCusto'] > -1)) {
        this.$refs.entradas.abrir();
      }
    }

  },

  watch: {
    paginacao: {
      handler() {
        if (this.montado) {
          this.refresh();
        } else {
          this.montado = true;
          if (this.movimentosestoque.length == 0) {
            this.refresh();
          }
        }
      },
      deep: true,
    },
  }
};
</script>

<style scoped>
  .x-small {
      font-size: 11px;
      height: 18px;
  }
</style>

