import Vue from 'vue';

import DialogoBuscar from "./DialogoBuscar";
import DialogoBuscarPecas from "./DialogoBuscarPecas";
import DialogoBuscarServicos from "./DialogoBuscarServicos";
import DialogoBuscarVeiculos from "./DialogoBuscarVeiculos";
import DialogoBuscarClientes from "./DialogoBuscarClientes";
import DialogoBuscarFornecedores from "./DialogoBuscarFornecedores";
import CampoBuscarFornecedor from "./CampoBuscarFornecedor";

Vue.component('DialogoBuscar', DialogoBuscar);
Vue.component('DialogoBuscarPecas', DialogoBuscarPecas);
Vue.component('DialogoBuscarServicos', DialogoBuscarServicos);
Vue.component('DialogoBuscarVeiculos', DialogoBuscarVeiculos);
Vue.component('DialogoBuscarClientes', DialogoBuscarClientes);
Vue.component('DialogoBuscarFornecedores', DialogoBuscarFornecedores);
Vue.component('CampoBuscarFornecedor', CampoBuscarFornecedor);