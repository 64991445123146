<template>
  <v-navigation-drawer v-bind="$attrs" fixed :clipped="clipped" stateless disable-resize-watcher :value="drawer_clientes" @input="$emit('update:drawer', $event)">
    <v-toolbar dark color="primary">
      <v-layout row>
        <v-list class="pa-0">
          <v-list-item>
            <v-list-item-avatar>
              <v-icon @click="fechar">arrow_back</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>Clientes</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-layout>
    </v-toolbar>

    <v-container>
      <z-text-field autofocus rounded solo dense label="Cliente" prepend-inner-icon="search" v-model="busca" single-line hide-details clearable></z-text-field>
    </v-container>

    <v-divider></v-divider>

    <v-card flat style="height: 500px; overflow-y: scroll; height: calc(100% - 163px)">
      <v-list two-line>
        <v-data-iterator :items="clientes" row wrap :search="busca" hide-default-footer disable-pagination>
          <template slot="item" slot-scope="props">

            <v-list-item :class="{'selecionado': cliente_selecionado.id == props.item.id}" :key="props.item.razao" @click="onClienteClick(props.item)" style="cursor: pointer">
              <v-list-item-content>
                <v-list-item-title>{{props.item.id}} - {{props.item.razao}}</v-list-item-title>
                <v-list-item-subtitle>
                  <span class="grey--text text--lighten-1">Telefone: {{props.item.telefone}}</span>
                </v-list-item-subtitle>
              </v-list-item-content>
              <!-- <v-list-item-action>
                <v-list-item-action-text>
                  <span class="grey--text text--lighten-1 ml-2">{{ props.item.id }}</span>
                </v-list-item-action-text>
              </v-list-item-action> -->
            </v-list-item>

            <v-divider v-if="props.index != clientes.length - 1" :key="props.index"></v-divider>

          </template>
        </v-data-iterator>
      </v-list>
    </v-card>
  </v-navigation-drawer>
</template>

<script>

  import { mapMutations, mapActions, mapState } from "vuex";
  import config from "@/app/config/app";
  import * as types from '@/app/store/mutation_types.js';

  export default {
    props: {
      drawer: {
        type: Boolean,
        default: true
      },
      permanent: {
        type: Boolean,
        default: true
      },
    },

    data() {
      return {
        drawer_:false,
        clipped: false,
        fixed: true,
        miniVariant: false,
        dataehora: '',
        config: config,
        busca: '',
      }
    },

    watch: {
      drawer(newValue, oldValue) {
        this.drawer_ = newValue
      }
    },

    mounted() {

    },

    methods: {
      ...mapActions({
        abrirCliente: 'clientes/abrir',
        extratocliente: 'financeiro/extratocliente',
        recebimentoDocumentos: 'financeiro/recebimentoDocumentos',
        cancelarRecebimento: 'financeiro/cancelarRecebimento'
      }),
      ...mapMutations('financeiro', [
        'limparlistas',
        types.SET_DRAWER_CLIENTES,
        types.SET_CLIENTE_SELECIONADO
      ]),

      // ...mapActions('financeiro', ['extratocliente', 'recebimentoDocumentos', 'cancelarRecebimento']),

      async filtrar(cliente) {
        try {
          // this.loading = true;
          await this.extratocliente(cliente.id);
          // this.tab = 1;
          // this.btnGroups.promissorias = 'abertos';
          // if (this.recebimentos.length > 0) {
          //   this.selecionado = this.recebimentos[0];
          //   await this.recebimentoDocumentos(this.selecionado.id);
          // }
        } catch (error) {
          this.$eventbus.notificarErro('Não foi possível carregar o extrato do cliente', error)
        } finally {
          this.loading = false;
        }

      },

      async onClienteClick(cliente) {
        // this.cliente_selecionado = cliente;
        // this.SET_CLIENTE_SELECIONADO(cliente);
        await this.abrirCliente(cliente.id);
        this.selected = [];
        this.limparlistas();
        this.filtrar(cliente);
      },

      fechar() {
        this.SET_DRAWER_CLIENTES(false);
      },
    },

    computed: {
      drawer_clientes() {
        return this.$store.state.financeiro.drawer_clientes;
      },

      clientes() {
        return this.$store.state.listas.clientes;
      },

      cliente_selecionado() {
        return this.$store.state.clientes.cliente;
      }
    },

  }
</script>

<style scoped>
.selecionado {
  background: rgba(0,0,0,0.04);
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
