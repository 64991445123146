<template>
  <v-app-bar app color="primary" dark>
    <v-app-bar-nav-icon v-if="$vuetify.breakpoint.smAndDown" @click="$emit('navIconClick')"></v-app-bar-nav-icon>

    <v-btn icon @click="goHome" v-if="$vuetify.breakpoint.mdAndUp">
      <v-icon>{{ config.APP_ICON }}</v-icon>
    </v-btn>

    <v-toolbar-title>
      <span class="mr-2 apontador" @click="goHome">{{ config.APP_NAME }}</span>
      <span class="text-caption mr-2">{{ $config.APP_VERSION }}</span>
    </v-toolbar-title>

    <template v-if="$vuetify.breakpoint.mdAndUp">
      <v-divider vertical inset class="ml-4"></v-divider>

      <menu-principal></menu-principal>
    </template>

    <v-spacer></v-spacer>

    <app-calendar-icon></app-calendar-icon>

    <v-toolbar-items>
      <app-user-menu></app-user-menu>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>

import AppUserMenu from './AppUserMenu';
import MenuPrincipal from '../menus/MenuPrincipal';
import MenuPrincipalDropdown from '../menus/MenuPrincipalDropdown';
import AppCalendarIcon from './AppCalendarIcon';

import { mapStores } from 'pinia'
import { useConfigStore } from '@/app/store/pinia/configuracoes'

export default {
  components: {
    MenuPrincipal,
    AppUserMenu,
    MenuPrincipalDropdown,
    AppCalendarIcon
  },

  data() {
    return {
      calendar_badge: {
        visible: true,
        value: 7
      },
    };
  },

  computed: {
    ...mapStores(useConfigStore),

    config() {
      return this.configStore.configuracoes;
    }
  },

  methods: {
    goHome() {
      this.$router.push({ name: 'Home' }).catch((error) => {});
    },
  },


};
</script>

<style scoped></style>
