<template>
  <div>
    <v-row dense>
      <v-col cols="12" sm="2">
        <v-text-field
          v-bind="$v.TEXT_FIELDS"
          v-model="cliente.id"
          ref="cliente_id"
          label="Código Cliente"
          append-icon="mdi-magnify"
          hint="F8 - Para pesquisar"
          placeholder=" "
          :rules_="rules"
          :loading="buscando_cliente"
          :disabled="buscando_cliente"
          @click:append="$refs.DialogoBuscarClientes.abrir()"
          @keyup.enter="buscarClientePeloCodigo"
          @keyup.f8="$refs.DialogoBuscarClientes.abrir()"
          @focus="(event) => event.target.select()"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="10">
        <z-text-field
          v-model="cliente.nome"
          label="Nome do Cliente"
          readonly
          :hint="'Telefone: ' + cliente.telefone"
          :persistent-hint="cliente.telefone != null"
        ></z-text-field>
      </v-col>
    </v-row>

    <dialogo-buscar-clientes ref="DialogoBuscarClientes" @busca:selecionar="selecionarCliente"></dialogo-buscar-clientes>

  </div>
</template>

<script>

import clientesApi from '@/app/services/clientes';

  export default {
    name: 'zCampoBuscarCliente',

    props: {
      value: {
        type: Number,
        default: null
      },

      rules: {
        type: Array,
        default: () => []
      },

      cli: {
        type: Object,
        default: () => {}
      }
    },

    data() {
      return {
        cliente: {
          id: null,
          nome: '',
          telefone: ''
        },

        buscando_cliente: false,
      }
    },

    // mounted () {
    //   if;
    // },

    methods: {
      async buscarClientePeloCodigo() {
        if (!this.cliente.id) {
          this.$refs.DialogoBuscarClientes.abrir();
          return;
        }

        try {
          this.buscando_cliente = true;
          let cliente = await clientesApi.buscarClientePeloCodigo(this.cliente.id);
          if (cliente) {
            this.selecionarCliente(cliente);
          }
        } catch (error) {
          this.$refs.DialogoBuscarClientes.busca = this.cliente.id;
          this.$refs.DialogoBuscarClientes.abrir();
          this.$refs.DialogoBuscarClientes.buscar();
        } finally {
          this.buscando_cliente = false;
        }
      },

      selecionarCliente(cliente) {
        this.cliente.id = cliente.id;
        this.cliente.nome = cliente.nome;
        this.cliente.telefone = cliente.telefone;
        this.$emit('input', this.cliente.id);
      },
    },

    // watch: {
    //   value(newValue, oldValue) {
    //     console.log('newValue', newValue)
    //     this.cliente.id = newValue;
    //     setTimeout(() => {
    //       this.buscarClientePeloCodigo();
    //     }, 0);
    //   }
    // },


    watch: {
      value: {
        immediate: true,
        deep: true,
        handler(newValue, oldValue) {
          this.cliente.id = newValue;
          // setTimeout(() => {
          //   this.buscarClientePeloCodigo();
          // }, 0);
        }
      }
    },

  }
</script>

<style scoped>

</style>
