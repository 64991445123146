<template>
  <div>
    <z-container>
      <z-cabecalho>{{ $store.state.titulo_tela }}</z-cabecalho>

      <v-form ref="formulario" v-model="form_valido" lazy-validation>
        <v-card>
          <v-toolbar flat color="white" dense>
            <h3 class="text-subtitle-2">Filtro</h3>
          </v-toolbar>
          <v-divider></v-divider>
          <v-card-text class="pb-0">
            <v-row dense>
              <v-col cols="12" sm="8">
                <z-select
                  label="Funcionário"
                  :items="funcionarios"
                  v-model="filtro.funcionario_id"
                  :rules="[regras.campoObrigatorio]"
                  @change="limparComissoes"
                ></z-select>
              </v-col>

              <v-col cols="12" sm="2">
                <z-date-picker-menu
                  left
                  v-model="filtro.data_inicial"
                  label="Data Inicial"
                  :rules="[regras.campoObrigatorio]"
                  @change="onChangeDataInicial"
                ></z-date-picker-menu>
              </v-col>

              <v-col cols="12" sm="2">
                <z-date-picker-menu
                  left
                  v-model="filtro.data_final"
                  label="Data Final"
                  :rules="[regras.campoObrigatorio]"
                  @change="limparComissoes"
                ></z-date-picker-menu>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="imprimir" :disabled="!form_valido">
              <v-icon left>mdi-printer</v-icon>
              Imprimir
            </v-btn>
            <v-btn color="primary" @click="filtrar" :loading="filtrando" :disabled="!form_valido">Filtrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </z-container>

    <z-container>
      <z-cabecalho>Resultado</z-cabecalho>

      <v-card min-height="200px">
        <v-card-text class="pa-0">
          <v-data-table mobile-breakpoint="0" hide-default-footer disable-pagination disable-sort :headers="headers" :items="comissoes">
            <template v-slot:item="{ item: comissao, index }">
              <tr>
                <td>{{ comissao.ordem_servico_id }}</td>
                <td class="text-no-wrap">
                  {{ comissao.ordemservico.data_entrada | dataBR }}
                </td>
                <td class="text-no-wrap">
                  {{ comissao.ordemservico.data_saida | dataBR }}
                </td>
                <td>{{ comissao.item }}</td>
                <td class="text-no-wrap">{{ comissao.codigo }}</td>
                <td>{{ comissao.descricao }}</td>
                <td class="text-right">
                  {{ comissao.valor_servico_original | currencyBR }}
                </td>
                <td class="text-right">
                  {{ comissao.valor_servico | currencyBR }}
                </td>
                <td class="text-right">
                  {{ comissao.valor_comissao | currencyBR }}
                </td>
                <td class="text-no-wrap">{{ comissao.data | dataBR }}</td>
              </tr>
            </template>

            <template v-slot:body.append="{ headers }">
              <tr>
                <td colspan="7" class="text-right font-weight-bold">
                  {{ total_original | currencyBR }}
                </td>
                <td class="text-right font-weight-bold">
                  {{ total_valor | currencyBR }}
                </td>
                <td class="text-right font-weight-bold">
                  {{ total_comissao | currencyBR }}
                </td>
                <td>&nbsp;</td>
              </tr>
            </template>

            <!-- <template v-slot:item.opcoes="{item}">
              <v-btn icon @click="editarComissao(item)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon @click="excluirComissao(item)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template> -->
          </v-data-table>
        </v-card-text>
      </v-card>
    </z-container>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import { hoje, abrirJanela } from '@/app/helpers/utils';

import * as regras from '@/app/helpers/validacoes';

import consultasApi from '@/app/services/consultas';
import config from '@/app/config/app';

import qs from 'qs';
import dayjs from 'dayjs';

export default {
  data() {
    return {
      filtro: {
        funcionario_id: '',
        data_inicial: '',
        data_final: '',
      },

      regras: { ...regras },
      form_valido: true,

      headers: [
        { text: 'OS', value: 'ordem_servico_id', width: '60px' },
        { text: 'Data', value: 'ordemservico.data_entrada' },
        { text: 'Saída', value: 'ordemservico.data_saida' },
        { text: 'Item', value: 'item' },
        { text: 'Código', value: 'codigo' },
        { text: 'Descrição', value: 'descricao' },
        {
          text: 'Valor Bruto',
          value: 'valor_servico_original',
          align: 'right',
        },
        { text: 'Valor Líquido', value: 'valor_servico', align: 'right' },
        { text: 'Comissão', value: 'valor_comissao', align: 'right' },
        { text: 'Data', value: 'data' },
        // { text: "Opções", value: "opcoes", align: "center" },
      ],
      comissoes: [],
      filtrando: false,
    };
  },

  computed: {
    ...mapState(['loading', 'titulo_tela']),
    ...mapGetters('listas', ['funcionarios']),

    total_original() {
      return this.comissoes.reduce((soma, comissao) => soma + comissao.valor_servico_original, 0);
    },
    total_valor() {
      return this.comissoes.reduce((soma, comissao) => soma + comissao.valor_servico, 0);
    },
    total_comissao() {
      return this.comissoes.reduce((soma, comissao) => soma + comissao.valor_comissao, 0);
    },
  },

  mounted() {
    this.setTitulo('Comissões de Serviços');
    // this.filtro.funcionario_id = 11;
    this.filtro.data_inicial = this.$dayjs()
      .startOf('month')
      .format('YYYY-MM-DD');
    this.filtro.data_final = hoje();

    this.$store.dispatch('listas/funcionarios');
  },

  methods: {
    ...mapMutations(['setTitulo', 'LOADING_OFF']),

    async filtrar() {
      if (!this.$refs.formulario.validate()) {
        return false;
      }

      try {
        this.filtrando = true;
        this.comissoes = await consultasApi.comissoesServicos(this.filtro);
      } catch (error) {
        this.$eventbus.notificarErro('', error);
        throw error;
      } finally {
        this.filtrando = false;
      }
    },

    onChangeDataInicial() {
      // this.filtro.data_final = this.filtro.data_inicial;
      this.limparComissoes();
    },

    limparComissoes() {
      this.comissoes = [];
    },

    imprimir() {
      if (!this.$refs.formulario.validate()) {
        return false;
      }

      let print_url = config.IS_PRODUCTION ? window.location.origin : config.BASE_URL_PRINT;
      abrirJanela(`${print_url}/consultas/comissoesservicos/imprimir?` + qs.stringify(this.filtro));
    },
  },
};
</script>

<style lang="scss" scoped></style>
