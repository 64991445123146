<template>
  <div>
    <z-centralized-toolbar>
      <v-toolbar-items>
        <v-btn text color="primary" :to="{ name: 'PecasLista' }" exact>
          <v-icon left>mdi-arrow-left</v-icon>
          Voltar
        </v-btn>
      </v-toolbar-items>
      <v-spacer></v-spacer>
      <v-btn
        text
        @click="$router.push({ name: 'PecasLista' })"
        :disabled="salvando"
        >Cancelar</v-btn
      >
      <v-btn
        color="primary"
        @click="confirmar"
        :loading="salvando"
        :disabled="!form_valido"
        >Salvar</v-btn
      >
    </z-centralized-toolbar>

    <v-divider></v-divider>

    <z-scroll-layout :top-position="$v.TOP_POSITION">
      <z-container>
        <v-alert
          transition="slide-y-transition"
          dismissible
          outlined
          text
          type="error"
          :value="!!error.message"
          @input="error.message = ''"
          >{{ error.message }}
        </v-alert>

        <z-cabecalho>{{ $store.state.titulo_tela }}</z-cabecalho>

        <v-form ref="formulario" v-model="form_valido" lazy-validation>
          <v-card tile class="mb-6">
            <v-card-title class="text-subtitle-2"> Dados do Peça </v-card-title>

            <v-divider></v-divider>

            <v-card-text>
              <v-row dense>
                <v-col cols="12" sm="3">
                  <z-text-field
                    label="Código"
                    v-model="peca.codigo"
                    :disabled="peca.id ? true : false"
                    :rules="[regras.campoObrigatorio]"
                  ></z-text-field>
                </v-col>
                <v-col cols="12" sm="9">
                  <z-text-field
                    label="Descrição"
                    v-model="peca.descricao"
                    :rules="[regras.campoObrigatorio]"
                  ></z-text-field>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" sm="2">
                  <z-text-field
                    label="Referência Fab."
                    v-model="peca.referencia_fabricante"
                  ></z-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <z-select
                    :items="$store.getters['listas/grupos']"
                    label="Grupo"
                    v-model="peca.grupo_id"
                    clearable
                    @click:clear="peca.grupo_id = null"
                  ></z-select>
                </v-col>
                <v-col cols="12" sm="4">
                  <z-select
                    :items="$store.getters['listas/subgrupos']"
                    label="Sub Grupo"
                    v-model="peca.subgrupo_id"
                    clearable
                    @click:clear="peca.subgrupo_id = null"
                  ></z-select>
                </v-col>
                <v-col cols="12" sm="2">
                  <z-campo-valor
                    v-model="peca.valor_custo"
                    label="Valor Compra"
                    @input="calcularValores"
                  ></z-campo-valor>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" sm="3">
                  <z-campo-valor
                    v-model="peca.icms"
                    label="ICMS"
                    suffix="%"
                    decimais="0"
                    @input="calcularValores"
                  ></z-campo-valor>
                </v-col>
                <v-col cols="12" sm="3">
                  <z-campo-valor
                    v-model="peca.frete"
                    label="Frete"
                    suffix="%"
                    decimais="0"
                    @input="calcularValores"
                  ></z-campo-valor>
                </v-col>
                <v-col cols="12" sm="3">
                  <z-campo-valor
                    v-model="peca.encargos"
                    label="Encargos"
                    suffix="%"
                    decimais="0"
                    @input="calcularValores"
                  ></z-campo-valor>
                </v-col>
                <v-col cols="12" sm="3">
                  <z-campo-valor
                    v-model="peca.custos"
                    label="Custo Total"
                    readonly
                    background-color="grey lighten-1"
                  ></z-campo-valor>
                </v-col>
                <v-col cols="12" sm="3">
                  <z-campo-valor
                    v-model="peca.lucro"
                    label="Lucro"
                    decimais="0"
                    suffix="%"
                    @input="calcularValores"
                  ></z-campo-valor>
                </v-col>
                <v-col cols="12" sm="3">
                  <z-campo-valor
                    v-model="peca.valor_venda"
                    label="Valor Venda"
                  ></z-campo-valor>
                </v-col>
                <v-col cols="12" sm="3">
                  <z-campo-valor
                    v-model="peca.estoque"
                    label="Estoque"
                    decimais="0"
                  ></z-campo-valor>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" sm="3">
                  <z-text-field
                    v-model="peca.garantia"
                    label="Garantia"
                  ></z-text-field>
                </v-col>
                <v-col cols="12" sm="9">
                  <z-text-field
                    upper
                    v-model="peca.locacao"
                    label="Locação"
                    maxlength="50"
                  ></z-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-form>
      </z-container>
    </z-scroll-layout>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { clone } from "@/app/helpers/utils";
import peca from "@/app/store/models/peca";

import * as regras from "@/app/helpers/validacoes";

export default {
  name: "pecas-form",

  inject: ['notifications'],

  data() {
    return {
      peca: { ...peca },
      salvando: false,
      tab: 0,
      form_valido: true,
      regras: { ...regras },

      error: {
        message: "",
      },
    };
  },

  computed: {
    ...mapGetters("listas", ["grupos", "subgrupos"]),

    titulo() {
      let titulo = /Editar/.test(this.$route.name) ? "Editar" : "Adicionar";
      return titulo + " " + "Peça";
    },
  },

  beforeRouteLeave(to, from, next) {
    this.ZERAR();
    next();
  },

  async mounted() {
    this.setTitulo(this.titulo);

    if (this.$route.name == "PecasEditar") {
      if (!this.$store.state.pecas.peca.id) {
        if (this.$route.params.id) {
          await this.abrir(this.$route.params.id);
        }
      }
      this.peca = clone(this.$store.state.pecas.peca);
    }

    this.$store.dispatch("listas/funcionarios");
    this.$store.dispatch("listas/grupos");
    this.$store.dispatch("listas/subgrupos");
  },

  methods: {
    ...mapMutations(["setTitulo"]),
    ...mapMutations("pecas", ["ZERAR"]),
    ...mapActions("pecas", ["abrir", "atualizar", "salvar"]),

    async confirmar() {
      this.error.message = "";

      if (!this.$refs.formulario.validate()) {
        return false;
      }

      try {
        this.salvando = true;

        if (this.$route.params.id) {
          await this.atualizar(this.peca);
        } else {
          await this.salvar(this.peca);
        }

        this.$router.push({ name: "PecasLista" });
        this.notifications.notificar("Peça salva com sucesso");
      } catch (error) {
        let error_message = '';
        const errors = error.stack.errors;
        if (error.status == 422) {
          if (errors.codigo) {
            error_message = errors.codigo[0];
          } else if (errors.descricao) {
            error_message = errors.descricao[0];
          }
          this.notifications.notificarErro(error_message);
          return;
        }

        this.notifications.notificarErro("Não foi possível salvar esta peça. Verifique os dados preenchidos.", error);
      } finally {
        this.salvando = false;
      }
    },

    calcularCustos() {
      this.peca.custos =
        this.peca.valor_custo +
        (this.peca.valor_custo * this.peca.icms) / 100 +
        (this.peca.valor_custo * this.peca.frete) / 100 +
        (this.peca.valor_custo * this.peca.encargos) / 100;
    },

    calcularVenda() {
      this.peca.valor_venda =
        this.peca.custos + (this.peca.custos * this.peca.lucro) / 100;
    },

    calcularValores() {
      this.calcularCustos();
      this.calcularVenda();
    },
  },
};
</script>

<style scoped>
</style>
