import Vue from 'vue';

import zCampoBuscarCliente from "./zCampoBuscarCliente";
import zBuscarCliente from "./zBuscarCliente";
import zSplitter from "./zSplitter";
import zTextFieldText from "./zforms/zTextFieldText";
import zDialogoImpressao from './zDialogoImpressao'
import zDialogo2 from './zDialogo2'


Vue.component('zCampoBuscarCliente', zCampoBuscarCliente);
Vue.component('zBuscarCliente', zBuscarCliente);
Vue.component('zSplitter', zSplitter);
Vue.component('zTextFieldText', zTextFieldText);
Vue.component('zDialogoImpressao', zDialogoImpressao);
Vue.component('zDialogo2', zDialogo2);
