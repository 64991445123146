<template>
  <div>
    <z-centralized-toolbar>
      <v-toolbar-items>
        <v-btn text color="primary" :to="{ name: 'ServicosLista' }" exact>
          <v-icon left>mdi-arrow-left</v-icon>
          Voltar
        </v-btn>
      </v-toolbar-items>

      <v-spacer></v-spacer>
      <v-btn
        text
        @click="$router.push({ name: 'ServicosLista' })"
        :disabled="salvando"
        >Cancelar</v-btn
      >
      <v-btn
        color="primary"
        @click="confirmar"
        :loading="salvando"
        :disabled="!form_valido"
        >Salvar</v-btn
      >
    </z-centralized-toolbar>

    <v-divider></v-divider>

    <z-scroll-layout :top-position="$v.TOP_POSITION">
      <z-container>
        <v-alert
          transition="slide-y-transition"
          dismissible
          outlined
          text
          type="error"
          :value="!!error.message"
          @input="error.message = ''"
        >
          {{ error.message }}
        </v-alert>

        <z-cabecalho>{{ $store.state.titulo_tela }}</z-cabecalho>

        <v-form ref="formulario" v-model="form_valido" lazy-validation>
          <v-card tile class="mb-6">
            <v-card-title class="text-subtitle-2">
              Dados do Serviço
            </v-card-title>
            <v-divider></v-divider>

            <v-card-text>
              <v-row dense>
                <v-col cols="12" sm="2">
                  <z-text-field
                    label="Código"
                    v-model="CODIGO_SERVICO_AUTOMATICO ? servico.id : servico.codigo"
                    :disabled="($route.name == 'ServicosEditar' || CODIGO_SERVICO_AUTOMATICO)"
                    :rules="CODIGO_SERVICO_AUTOMATICO ? [] : [regras.campoObrigatorio]"
                  ></z-text-field>
                </v-col>

                <v-col cols="12" sm="7">
                  <z-text-field
                    label="Descrição"
                    v-model="servico.descricao"
                    :rules="[regras.campoObrigatorio]"
                  ></z-text-field>
                </v-col>

                <v-col cols="12" sm="3">
                  <z-campo-valor
                    label="Valor"
                    v-model="servico.valor"
                    :rules="[regras.naoNegativo]"
                  ></z-campo-valor>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-form>
      </z-container>
    </z-scroll-layout>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { clone } from "@/app/helpers/utils";
import servico from "@/app/store/models/servico";

import * as regras from "@/app/helpers/validacoes";

export default {
  name: "servicos-form",

  data() {
    return {
      // servico: { ...servico },
      salvando: false,
      regras: {},
      tab: 0,
      form_valido: true,
      regras: { ...regras },
      text: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Distinctio beatae nobis quaerat, officiis velit delectus voluptates obcaecati veniam sunt labore eligendi harum molestiae explicabo nesciunt provident. Voluptatum alias repellendus cum.",
      error: {
        message: "",
      },
    };
  },

  computed: {
    ...mapState('servicos',['servico']),
    titulo() {
      let titulo = /Editar/.test(this.$route.name) ? "Editar" : "Adicionar";
      return titulo + " " + "Serviço";
    },

    CODIGO_SERVICO_AUTOMATICO() {
      return this.$store.state.configuracoes.APP_CODIGO_SERVICO_AUTOMATICO;
    },
  },

  beforeRouteLeave(to, from, next) {
    this.ZERAR();
    next();
  },

  async mounted() {
    this.setTitulo(this.titulo);

    if (this.$route.name == "ServicosEditar") {
      if (!this.$store.state.servicos.servico.id) {
        if (this.$route.params.id) {
          await this.abrir(this.$route.params.id);
        }
      }
      // this.servico = clone(this.$store.state.servicos.servico);
    }

    this.$store.dispatch("listas/funcionarios");
  },

  methods: {
    ...mapMutations(["setTitulo"]),
    ...mapMutations("servicos", ["ZERAR"]),
    ...mapActions("servicos", ["abrir", "atualizar", "salvar"]),

    async confirmar() {
      this.error.message = "";

      if (!this.$refs.formulario.validate()) {
        return false;
      }

      try {
        this.salvando = true;

        if (this.$route.params.id) {
          await this.atualizar(this.servico);
        } else {
          await this.salvar(this.servico);
        }

        this.$router.push({ name: "ServicosLista" });
        this.$eventbus.notificar("Serviço salvo com sucesso");
      } catch (error) {
        // if (error.status == 422) {
        //   console.log(error);
        //   this.error.message = error.stack.errors.codigo[0];
        //   return;
        // }

        this.$eventbus.notificarErro("Não foi possível salvar este Serviço. Verifique os dados preenchidos.", error);
        // throw error;
      } finally {
        this.salvando = false;
      }
    },
  },

  watch: {
    "servico.cpfcnpj"(val, oldval) {
      if (val) {
        this.servico.tipo_pessoa = val.length > 14 ? "J" : "F";
      }
    },
  },
};
</script>

<style scoped>
</style>
