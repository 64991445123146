import { render, staticRenderFns } from "./PersonalizacaoSistema.vue?vue&type=template&id=56648f2e&scoped=true&"
import script from "./PersonalizacaoSistema.vue?vue&type=script&setup=true&lang=js&"
export * from "./PersonalizacaoSistema.vue?vue&type=script&setup=true&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56648f2e",
  null
  
)

export default component.exports