import createCookie from '@/app/core/CookieCore';
import createAxios from '@/app/core/AxiosCore';
import createLocalStorage from '@/app/helpers/localStorage';
import config from '@/app/config/app';

const cookie = createCookie();
const axios = createAxios(config);
const storage = createLocalStorage(config);

function createAuth() {
  async function login(credenciais) {
    try {
      let user_data = await axios.post('/login', credenciais).then((response) => response.data);
      setUserData(user_data);
    } catch (error) {
      throw error;
    }
  }

  async function logout() {
    try {
      console.log('Efetuando o logout');
      await axios.post('/logout').then((response) => response.data);
      unsetUserData();
    } catch (error) {
      console.error(error);
    }

  }

  function check() {
    if (getToken()) {
      return true;
    } else {
      unsetUserData();
      return false;
    }
  }

  function setUserData(user) {
    storage.set('user', user);
    setToken(user.api_token);
  }

  function unsetUserData() {
    storage.remove('user');
    storage.remove('token');
    cookie.deleteCookie('api_token');
  }

  function setToken(api_token) {
    storage.set('token', api_token);
    cookie.setCookie('api_token', api_token, config.COOKIE_EXPIRATION_TIME);
  }

  function getToken() {
    let storage_token = storage.get('token');
    if (cookie.getCookie('api_token') == storage_token) {
      return cookie.getCookie('api_token');
    }

    return false;
    // return storage.get('token');
  }

  function getUser() {
    return storage.get('user');
  }

  function getAuthHeader() {
    return {
      Authorization: 'Bearer ' + getToken(),
    };
  }

  function getJWTDecode() {
    return JSON.parse(window.atob(getToken().split('.')[1]));
  }

  function getPerfil() {
    if (getUser().perfil) {
      return getUser().perfil;
    } else {
      return null;
    }
  }

  function getPermissoes() {
    if (getPerfil()) {
      return getPerfil().permissoes.split(',');
    } else {
      return [];
    }
  }

  function temPermissao(permissao) {
    if (permissao == '' || permissao == null) {
      return true;
    }

    let permissoes = getPermissoes();
    let indice = permissoes.findIndex((p) => p.trim() == permissao.trim());

    if (getPerfil().descricao == 'Administrador') {
      return true;
    }

    return indice > -1;
  }

  function isAdmin() {
    return getPerfil().descricao == 'Administrador';
  }

  function isSuperAdmin() {
    return getPerfil().descricao == 'Administrador' && getUser().email == 'admin' ;
  }

  return {
    login,
    logout,
    check,
    getToken,
    getUser,
    getAuthHeader,
    getJWTDecode,
    getPerfil,
    temPermissao,
    isAdmin,
    isSuperAdmin,
    p: temPermissao,
    can: temPermissao,
  };
}

export default createAuth;



