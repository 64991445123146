import PrazosLista from '@/pages/tabelas/prazos/PrazosLista'

export default [
  {
    path: '/tabelas/prazos',
    name: 'PrazosLista',
    component: PrazosLista,
    meta: { permissao: 'prazospagamentos_acessar' }
  },

];
