import ordemservicoApi from "@/app/services/ordemservico";
import ordemservico from "@/app/store/models/ordemservico";
import { PAGINATION_OPTIONS } from "@/app/vuetifyConfig";

import { hoje, clone } from "@/app/helpers/utils";

export default {
  namespaced: true,
  state: {
    ordemservico: {...ordemservico},
    ordensservico: [],
    comissoes: [],
    busca: "",
    total_itens: 0,
    paginacao: PAGINATION_OPTIONS,
    status: 'emaberto'
  },

  mutations: {
    LISTAR(state, ordensservico) {
      state.ordensservico = ordensservico;
    },

    ABRIR(state, ordemservico) {
      state.ordemservico = ordemservico;
      if (ordemservico.cliente == null) {
        ordemservico.cliente = {
          nome: '',
        }
      }
    },

    SET_ORDEMSERVICO(state, ordemservico) {
      state.ordemservico = ordemservico;
    },

    ADICIONAR(state, ordemservico) {
      state.ordensservico.unshift(ordemservico);
      state.ordemservico = ordemservico;
    },

    ATUALIZAR(state, ordemservico) {
      let index = state.ordensservico.findIndex(ordemservico_ => ordemservico_.id == ordemservico.id);
      if (index > -1) {
        state.ordensservico.splice(index, 1, ordemservico);
      }
    },

    EXCLUIR(state, id) {
      let index = state.ordensservico.findIndex(ordemservico => ordemservico.id == id);
      if (index > -1) {
        state.ordensservico.splice(index, 1);
      }
    },

    SET_COMISSOES(state, comissoes) {
      state.comissoes = comissoes;
    },

    SET_BUSCA(state, busca) {
      state.busca = busca;
    },

    SET_STATUS(state, status) {
      state.status = status;
    },

    SET_PAGE(state, page) {
      state.paginacao.page = page;
    },

    SET_TOTAL_ITENS(state, total_itens) {
      state.total_itens = total_itens;
    },

    UPDATE_PAGINACAO(state, paginacao) {
      state.paginacao = paginacao;
    },

    ZERAR(state) {
      state.ordemservico = clone(ordemservico);
      state.ordemservico.data_entrada = hoje();
      state.ordemservico.hora_entrada = new Date().toLocaleTimeString().substring(0, 5);
    }
  },

  actions: {
    async listar({ commit, state }) {
      commit('LOADING_ON', null, { root: true });

      const { sortBy, sortDesc, page, itemsPerPage } = state.paginacao;

      const paginacao = {
        page,
        itemsPerPage,
        sortBy: sortBy[0],
        sortDesc: sortDesc[0],
        status: state.status
      }

      if (state.busca) {
        paginacao.busca = state.busca;
      }

      let response = await ordemservicoApi.listar(paginacao);
      commit('LISTAR', response.data);
      commit('SET_TOTAL_ITENS', response.total);
      commit('LOADING_OFF', null, { root: true });
    },

    async abrir({ commit }, id) {
      commit('LOADING_ON', null, { root: true });
      let ordemservico = await ordemservicoApi.abrir(id);

      if (ordemservico.cliente == null) {
        ordemservico.cliente = {
          id: null,
          nome: null,
          telefone: null,
        }
      }

      if (ordemservico.veiculo == null) {
        ordemservico.veiculo = {
          marca: null,
          modelo: null,
          cor: null,
          combustivel: null,
          ano: null,
          chassi: null,
        }
      }

      commit('ABRIR', ordemservico);
      commit('LOADING_OFF', null, { root: true });
    },

    async salvar({ commit }, ordemservico) {
      let novaordemservico = await ordemservicoApi.salvar(ordemservico);

      if (novaordemservico.cliente == null) {
        novaordemservico.cliente = {
          id: null,
          nome: null,
          telefone: null,
        }
      }
      commit('ADICIONAR', novaordemservico);
    },

    async atualizar({ commit }, ordemservico) {
      let response = await ordemservicoApi.atualizar(ordemservico);
      commit('SET_ORDEMSERVICO', response);
    },

    async excluir({ commit }, id) {
      await ordemservicoApi.excluir(id);
      commit('EXCLUIR', id);
    },

    async listarComissoes({ commit }, ordemservico_id) {
      let comissoes = await ordemservicoApi.listarComissoes(ordemservico_id);
      commit('SET_COMISSOES', comissoes);
    },

    async salvarComissao({ commit }, request) {
      await ordemservicoApi.salvarComissao(request);
    },

    async editarComissao({ commit }, request) {
      await ordemservicoApi.editarComissao(request);
    },

  }
}
