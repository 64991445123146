import FormasPagamentoLista from '@/pages/tabelas/formaspagamento/FormasPagamentoLista'

export default [
  {
    path: '/tabelas/formaspagamento',
    name: 'FormasPagamentoLista',
    component: FormasPagamentoLista,
    meta: { permissao: 'formaspagamento_acessar' }
  },

];
