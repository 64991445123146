export default {
  // id: '',
  codigo: '',
  referencia_fabricante: '',
  descricao : '',
  valor_venda: '',
  valor_custo: '',
  garantia: '',
  grupo_id: '',
  subgrupo_id: '',
  estoque: '',
  icms: '',
  locacao: '',
  lucro: '',
  frete: '',
  custos: '',
  encargos: ''
}