<template>
  <div>
    <z-centralized-toolbar>
      <!-- <v-toolbar-items>
        <v-btn id="btn-adicionar" text small color="primary" @click="adicionar">
          <v-icon left>mdi-calendar</v-icon>Adicionar
        </v-btn>

        <v-btn id="btn-hoje" text small @click="adicionar">
          Hoje
        </v-btn>

        <v-btn text id="btn-atualizar" @click="refresh" :loading="$store.state.loading">
          <v-icon>mdi-refresh</v-icon>
          <v-tooltip bottom activator="#btn-atualizar">
            Atualizar
          </v-tooltip>
        </v-btn>
      </v-toolbar-items> -->


      <v-btn class="mr-2" id="btn-adicionar" color="primary" @click="adicionar" :disabled="disabledAdicionar">
        <v-icon left>mdi-calendar</v-icon>Adicionar
      </v-btn>
      <v-btn class="mr-2" id="btn-hoje" outlined @click="today">Hoje</v-btn>

      <v-btn fab small text @click="prior">
        <v-icon small>mdi-chevron-left</v-icon>
      </v-btn>

      <v-btn class="mr-2" fab small text @click="next">
        <v-icon small>mdi-chevron-right</v-icon>
      </v-btn>

      <v-toolbar-title>
      {{  mesAtual.currentMonthText }} {{ mesAtual.currentYear }}
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <z-dropdown-button v-model="lista" :items="items_dropdown" @input="changeLista"></z-dropdown-button>

    </z-centralized-toolbar>

    <v-divider></v-divider>

    <z-scroll-layout :top-position="$v.TOP_POSITION" padding-bottom='0px'>
      <z-container>
        <div class="d-flex">
          <z-cabecalho class="pl-3">{{ $store.state.titulo_tela }}</z-cabecalho>
          <v-spacer></v-spacer>
          <div v-if="period && eventos.length > 0" style="font-size: 14px">{{ period }}</div>
        </div>

        <!-- <calendario></calendario> -->


        <!-- <v-divider></v-divider> -->

        <div v-show="eventos.length > 0">
          <div v-show="show_agendas_dia">
            <z-cabecalho class="pl-3">Agenda de Hoje</z-cabecalho>
            <AgendamentosLista class="mb-5" ref="agendamentosLista" :eventos="agendados_dia"></AgendamentosLista>
          </div>

          <div v-show="show_agendados">
            <z-cabecalho class="pl-3">Agendamentos <span v-if="lista != 'dia'">({{ lista_text }})</span></z-cabecalho>
            <AgendamentosLista ref="agendamentosLista" :eventos="agendados"></AgendamentosLista>
          </div>
        </div>
        <div v-show="eventos.length == 0">
          <h3 class="text--disabled text-center mt-15">Nenhum agendamento encontrado</h3>
        </div>


      </z-container>
    </z-scroll-layout>

    <z-dialogo-sim-nao ref="dialogoRemover" titulo="Remover agenda?" botaoSim="Remover" botaoNao="Cancelar" :loading="excluindo">
      O agenda será removido do sistema.
    </z-dialogo-sim-nao>

    <AgendaForm ref="agendaForm"></AgendaForm>

  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from 'vuex';
import * as regras from '@/app/helpers/validacoes';

import Calendario from "./Calendario";
import AgendamentosLista from './AgendamentosLista.vue';
import AgendaForm from './AgendaForm.vue';
import zDropdownButton from '@/components/zDropdownButton.vue';


import { priorMonth, nextMonth, getCurrent } from "@/app/composables/meses";

export default {
  name: 'AgendaLista',

  components: {
    Calendario,
    AgendamentosLista,
    AgendaForm,
    zDropdownButton
  },

  data() {
    return {
      headers: [
        { text: 'Nome', value: 'nome' },
        { text: 'Data', value: 'data', width: '150px' },
        { text: 'Hora', value: 'hora', width: '150px' },
        { text: 'Opções', value: '', width: '200px', align: 'center', sortable: false },
      ],
      excluindo: false,
      salvando: false,
      form_valido: true,
      regras: { ...regras },

      mesAtual: getCurrent(),

      currentMonth: getCurrent(),
      lista: 'semana',
      items_dropdown: [
        { text: 'Dia', value: 'dia' },
        { text: 'Semana', value: 'semana' },
        { text: 'Mês', value: 'mes' },
      ]
    };
  },

  computed: {
    ...mapState(['loading']),
    ...mapState('agenda', ['eventos', 'evento', 'total_itens', 'period']),

    lista_text() {
      return this.items_dropdown.find(item => item.value == this.lista).text;
    },

    agendados_dia() {
      return this.$store.getters['agenda/agendados_dia'];
    },

    agendados() {
      return this.$store.getters['agenda/agendados'];
    },

    show_agendas_dia() {
      return this.agendados_dia.length > 0;
    },

    show_agendados() {
      return this.agendados.length > 0;
    },

    disabledAdicionar() {

      if ((this.mesAtual.currentYear == this.currentMonth.currentYear) && (this.mesAtual.currentMonth < this.currentMonth.currentMonth)) {
        return true;
      }

      if (this.mesAtual.currentYear < this.currentMonth.currentYear) {
        return true;
      }

      return false;
    },

    mesesSelecionados() {
      const mesAnoSelecionados = Number(this.mesAtual.currentMonth + this.mesAtual.currentYear);
      const mesAnoAtuais = Number(this.currentMonth.currentMonth + this.currentMonth.currentYear);
      return {mesAnoSelecionados, mesAnoAtuais};
    },

  },

  created() {
    // this.refresh();
  },

  mounted() {
    this.setTitulo('Agenda');
    this.init();
  },

  methods: {
    ...mapMutations(['setTitulo', 'LOADING_OFF']),
    ...mapActions('agenda', ['listar', 'listarByMonth', 'excluir', 'abrir', 'atualizar', 'salvar']),

    async init() {
      this.loadEvents()
    },

    async loadEvents () {

      try {
        this.mesAtual.lista = this.lista;
        await this.listarByMonth(this.mesAtual);
      } catch (error) {
        throw error;
      }

    },

    async refresh() {
      try {
        await this.listar();
      } catch (error) {
        throw error;
        this.$eventbus.notificar('Não foi possível listar os eventos.');
      }
    },

    adicionar() {
      const data = new Date();
      const nova_data = `${data.getFullYear()}-${(this.mesAtual.currentMonth).toString().padStart(2, '0')}-${data.getDate().toString().padStart(2, '0')}`;

      this.$store.commit('agenda/ZERAR', nova_data);
      this.$refs.agendaForm.abrir();
      // this.$refs.agendamentosLista.adicionar();
    },

    async editar(agenda) {
      try {
        await this.abrir(agenda.id);
        this.$refs.dialogoAdicionar.abrir();
      } catch (error) {
        console.log(error);
        this.$eventbus.notificar('Não foi possível abrir o agenda.');
      } finally {
        this.LOADING_OFF();
      }
    },

    async confirmar() {
      if (!this.$refs.formulario.validate()) {
        return false;
      }

      try {
        this.salvando = true;

        if (this.evento.id) {
          await this.atualizar(this.evento);
        } else {
          await this.salvar(this.evento);
        }

        this.$refs.dialogoAdicionar.fechar();
        this.$eventbus.notificar('Evento salvo com sucesso');
      } catch (error) {
        this.$eventbus.notificarErro('Não foi possível salvar este evento. Verifique os dados preenchidos.', error);
        throw error;
      } finally {
        this.salvando = false;
      }
    },

    async perguntarExcluir(agenda) {
      let resposta = await this.$root.dialogo_pergunta.abrir({
        titulo: 'Remover agenda?',
        texto: 'A agenda será removida da sistema.',
        botoes: ['Cancelar', 'Remover'],
        padrao: 'Remover',
        cor: 'red',
      });

      if (resposta == 'Remover') {
        this.$root.dialogo_pergunta.carregando();
        await this.confirmarExcluir(agenda);
        this.$root.dialogo_pergunta.fechar();
      } else {
        this.$root.dialogo_pergunta.fechar();
      }
    },

    async confirmarExcluir(agenda) {
      try {
        this.excluindo = true;
        await this.excluir(agenda.id);
        this.$eventbus.notificar('Agenda excluída com sucesso!');
      } catch (error) {
        this.$eventbus.notificar('Não foi possível excluir o agenda!');
        throw error;
      } finally {
        this.excluindo = false;
      }
    },

    today() {
      this.mesAtual = getCurrent();
      this.lista = "dia";
      this.loadEvents();
    },

    prior() {
      this.mesAtual = priorMonth();
      this.loadEvents();
    },

    next() {
      this.mesAtual = nextMonth();
      this.loadEvents();
    },

    changeLista(item) {
      this.loadEvents();
    }

  },

};
</script>

<style scoped></style>
