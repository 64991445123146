import ServicosLista from '@/pages/cadastros/servicos/ServicosLista'
import ServicosForm from '@/pages/cadastros/servicos/ServicosForm'

export default [
  {
    path: '/cadastros/servicos',
    name: 'ServicosLista',
    component: ServicosLista,
    meta: { permissao: 'servicos_acessar' }
  },
  {
    path: '/cadastros/servicos/adicionar',
    name: 'ServicosAdicionar',
    component: ServicosForm,
    meta: { permissao: 'servicos_adicionar' }
  },
  {
    path: '/cadastros/servicos/:id/editar',
    name: 'ServicosEditar',
    component: ServicosForm,
    meta: { permissao: 'servicos_editar' }
  },
];
