<template>
  <z-dialogo ref="dialogo" titulo="Entrada de Produtos" :dividers="true" largura="500px" persistent>

    <template #texto>
      <v-row dense>
        <v-col cols="12" sm="6">
          <z-date-picker-menu label="Data Incial" v-model="filtro.data_inicial"></z-date-picker-menu>
        </v-col>
        <v-col cols="12" sm="6">
          <z-date-picker-menu label="Data Final" v-model="filtro.data_final"></z-date-picker-menu>
        </v-col>

        <v-col cols="12" sm="12">
          <z-select :items="ordenacao" label="Ordenar por" placeholder=" " v-model="filtro.ordem"></z-select>
        </v-col>
      </v-row>
    </template>

    <template #actions_right>
      <v-btn text @click="$refs.dialogo.fechar()">
        Cancelar
      </v-btn>
      <v-btn text color="primary" @click="imprimir">
        <v-icon left>mdi-printer</v-icon>
        Imprimir
      </v-btn>
    </template>

  </z-dialogo>
</template>

<script>
import qs from "qs";
import config from "@/app/config/app";
import dayjs from "dayjs";

export default {
  name: 'EntradasDeProdutos',

  props: {
    relatorio: {
      type: String,
      default: ''
    },
  },

  data () {
    return {
      aberto: false,
      filtro: {
        data_inicial: dayjs().format('YYYY-MM-DD'),
        data_final: dayjs().format('YYYY-MM-DD'),
        ordem: 'referencia'
      },
      ordenacao: [
        {text: 'Referência', value: 'referencia'},
        {text: 'Descrição', value: 'descricao'}
      ]
    }
  },

  methods: {
    abrir() {
      this.$refs.dialogo.abrir();
    },

    fechar() {
      this.$refs.dialogo.fechar();
    },

    imprimir() {
      this.filtro.r = this.relatorio;
      if (config.IS_PRODUCTION) {
        this.abrirJanela(window.location.origin + '/relatorios/entradadeprodutos?'+ qs.stringify(this.filtro));
      } else {
        this.abrirJanela('http://localhost:8000/relatorios/entradadeprodutos?'+ qs.stringify(this.filtro));
      }
    },
  }
}
</script>

<style scoped>

</style>
