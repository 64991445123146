<template>
  <div>

    <v-card outlined tiled>
      <v-row class="fill-height">
        <v-col>
          <v-sheet height="64">
            <v-toolbar flat>
              <v-btn color="primary" class="mr-2" @click="adicionar">
                <v-icon left>mdi-calendar</v-icon>Adicionar
              </v-btn>
              <v-btn outlined class="mr-2" color="grey darken-2" @click="setToday">
                Hoje
              </v-btn>
              <v-btn fab text small color="grey darken-2" @click="prev">
                <v-icon small>
                  mdi-chevron-left
                </v-icon>
              </v-btn>
              <v-btn fab text small color="grey darken-2" @click="next">
                <v-icon small>
                  mdi-chevron-right
                </v-icon>
              </v-btn>
              <v-toolbar-title v-if="$refs.calendar">
                {{ $refs.calendar.title }}
              </v-toolbar-title>

              <v-spacer></v-spacer>

              <!-- <v-btn icon id="btn-atualizar" class="mr-2" @click="refresh" :loading="$store.state.loading">
                <v-icon>mdi-refresh</v-icon>
                <v-tooltip bottom activator="#btn-atualizar">
                  Atualizar
                </v-tooltip>
              </v-btn> -->

              <v-menu bottom right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                    <span>{{ typeToLabel[type] }}</span>
                    <v-icon right>
                      mdi-menu-down
                    </v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item @click="type = 'day'">
                    <v-list-item-title>Dia</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'week'">
                    <v-list-item-title>Semana</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'month'">
                    <v-list-item-title>Mês</v-list-item-title>
                  </v-list-item>
                </v-list>

              </v-menu>
            </v-toolbar>
          </v-sheet>
          <v-sheet height="600">
            <v-calendar
              ref="calendar"
              v-model="focus"
              color="primary"
              :events="eventos"
              :event-color="getEventColor"
              :type="type"
              :loading="loading"
              @click:event="showEvent"
              @click:more="viewDay"
              @click:date="viewDay"
              @mousedown:time="startTime"
              @change="updateRange"
            >
              <template v-slot:day-body="{ date, week }">
                <div class="v-current-time" :class="{ first: date === week[0].date }" :style="{ top: nowY }"></div>
              </template>

            </v-calendar>



            <v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement" offset-x>

              <v-card color="grey lighten-4" width="450px" flat>
                <v-toolbar :color="selectedEvent.color" dark>
                  <v-btn icon>
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon>
                    <v-icon>mdi-heart</v-icon>
                  </v-btn>
                  <v-btn icon>
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </v-toolbar>

                <v-card-text>
                  <span v-html="selectedEvent.details"></span>
                </v-card-text>

                <v-card-actions>
                  <v-btn text color="secondary" @click="selectedOpen = false">
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-card>

            </v-menu>
          </v-sheet>
        </v-col>
      </v-row>
    </v-card>

    <v-form v-model="form_valido" lazy-validation ref="formulario">
      <z-dialogo ref="dialogoAdicionar" largura="700px" :titulo="tituloForm" dividers persistent @abrir="$refs.formulario.resetValidation()">
        <template v-slot:conteudo>
          <v-row dense>
            <v-col cols="12" sm="9">
              <z-text-field upper label="Nome" v-model="evento.nome" ref="descricao" placeholder=" " :rules="[regras.campoObrigatorio]"></z-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <z-text-field label="Telefone" v-model="evento.telefone" v-mask="$v.TELEFONE_MASK"></z-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <z-text-field label="Placa" v-mask="$v.PLACA_MASK" v-model="evento.placa"></z-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <z-text-field label="Modelo" v-model="evento.modelo"></z-text-field>
            </v-col>
            <!-- <v-col cols="12" sm="9">
              <z-text-field label="Decrição Carro" placeholder=" " disabled></z-text-field>
            </v-col> -->
            <v-col cols="12" sm="3">
              <z-date-picker-menu v-model="evento.data" ref="data" label="Data" :rules="[regras.campoObrigatorio]"></z-date-picker-menu>
            </v-col>
            <v-col cols="12" sm="3">
              <z-time-picker v-model="evento.hora" ref="data" label="Hora" :rules="[regras.campoObrigatorio]"></z-time-picker>
            </v-col>

          </v-row>

          <v-row dense>
            <v-col cols="12" sm="12">
              <z-textarea label="Observações" v-model="evento.observacao" rows="3"></z-textarea>
            </v-col>
          </v-row>
        </template>

        <template v-slot:actions_left>
          <v-btn v-if="evento.id" color="red" dark @click="perguntarExcluir(evento)" :disabled="salvando">Remover</v-btn>
        </template>
        <template v-slot:actions_right>
          <v-btn text @click="$refs.dialogoAdicionar.fechar()" :disabled="salvando">Cancelar</v-btn>
          <v-btn color="primary" ref="btnConfirmar" @click="confirmar" :loading="salvando" :disabled="!form_valido">Confirmar</v-btn>
        </template>
      </z-dialogo>
    </v-form>
  </div>
</template>

<script>

  import { mapMutations, mapActions, mapState } from 'vuex';
  import * as regras from '@/app/helpers/validacoes';

  export default {
    data: () => ({
      focus: new Date().toISOString().substring(0,10),
      type: 'week',
      typeToLabel: {
        month: 'Mês',
        week: 'Semana',
        day: 'Dia',
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      events: [],
      colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
      names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
      value: '',
      ready: false,
      excluindo: false,
      salvando: false,
      form_valido: true,
      regras: { ...regras },
    }),

    mounted () {
      this.$refs.calendar.checkChange();
      this.ready = true;
      this.scrollToTime();
      this.updateTime();
    },

    computed: {
      ...mapState(['loading']),
      ...mapState('agenda', ['eventos', 'evento', 'total_itens']),

      tituloForm() {
        return this.evento.id ? 'Editar Agendamento' : 'Novo Agendamento';
      },

      cal () {
        return this.ready ? this.$refs.calendar : null
      },
      nowY () {
        return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px'
      },

      eventos() {
        return this.$store.state.agenda.eventos.map(evento => {
          return {
            id: evento.id,
            name: evento.nome,
            start: evento.data + ' ' + evento.hora.substr(0,5),
            // end: '2021-09-09 20:30:00',
            color: 'primary',
            // timed: true,
          }
        });
      }
    },

    methods: {
      ...mapActions('agenda', ['listar', 'excluir', 'abrir', 'atualizar', 'salvar']),

      adicionar() {
        this.$store.commit('agenda/ZERAR', this.focus);
        this.$refs.dialogoAdicionar.abrir();
      },

      async confirmar() {
        if (!this.$refs.formulario.validate()) {
          return false;
        }

        try {
          this.salvando = true;

          if (this.evento.id) {
            await this.atualizar(this.evento);
          } else {
            await this.salvar(this.evento);
          }

          this.$refs.dialogoAdicionar.fechar();
          this.$eventbus.notificar('Evento salvo com sucesso');
        } catch (error) {
          this.$eventbus.notificarErro('Não foi possível salvar este evento. Verifique os dados preenchidos.', error);
          throw error;
        } finally {
          this.salvando = false;
        }
      },

      async editar(evento) {
        await this.$store.dispatch('agenda/abrir', evento.id);
        this.$refs.dialogoAdicionar.abrir();
      },

      async perguntarExcluir(evento) {
        let resposta = await this.$root.dialogo_pergunta.abrir({
          titulo: 'Remover evento?',
          texto: 'O evento será removido do sistema.',
          botoes: ['Cancelar', 'Remover'],
          padrao: 'Remover',
          cor: 'red',
        });

        if (resposta == 'Remover') {
          this.$root.dialogo_pergunta.carregando();
          await this.confirmarExcluir(evento);
          this.$root.dialogo_pergunta.fechar();
        } else {
          this.$root.dialogo_pergunta.fechar();
        }
      },

      async confirmarExcluir(evento) {
        try {
          this.excluindo = true;
          await this.excluir(evento.id);
          this.$eventbus.notificar('Evento excluído com sucesso!');
          this.$refs.dialogoAdicionar.fechar();
        } catch (error) {
          this.$eventbus.notificar('Não foi possível excluir o evento!');
          throw error;
        } finally {
          this.excluindo = false;
        }
      },

      startTime(novo_evento) {

        // this.salvar({
        //   nome: 'novo_evento.nome',
        //   data: novo_evento.date,
        //   hora: novo_evento.time,
        // })

      },

      roundTime (time, down = true) {
        const roundTo = 15 // minutes
        const roundDownTime = roundTo * 60 * 1000

        return down
          ? time - time % roundDownTime
          : time + (roundDownTime - (time % roundDownTime))
      },

      toTime (tms) {
        return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
      },

      viewDay ({ date }) {
        this.focus = date
        this.type = 'day'
      },

      getEventColor (event) {
        return event.color
      },
      setToday () {
        this.focus = new Date().toISOString().substr(0,10);
      },
      prev () {
        this.$refs.calendar.prev()
      },
      next () {
        this.$refs.calendar.next()
      },
      showEvent ({ nativeEvent, event }) {

        this.editar(event);

        // const open = () => {
        //   this.selectedEvent = event
        //   this.selectedElement = nativeEvent.target
        //   requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
        // }

        // if (this.selectedOpen) {
        //   this.selectedOpen = false
        //   requestAnimationFrame(() => requestAnimationFrame(() => open()))
        // } else {
        //   open()
        // }

        // nativeEvent.stopPropagation()
      },
      async updateRange ({ start, end }) {

        const period = {
          start: start.date,
          end: end.date
        }

        try {
          await this.listar(period);
        } catch (error) {
          throw error;
          this.$eventbus.notificar('Não foi possível listar os agendas.');
        }

      },

      async refresh() {
        try {
          await this.listar(this.focus);
        } catch (error) {
          throw error;
          this.$eventbus.notificar('Não foi possível listar os agendas.');
        }
      },

      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },

      getCurrentTime () {
        return this.cal ? this.cal.times.now.hour * 60 + this.cal.times.now.minute : 0
      },

      scrollToTime () {
        this.cal.scrollToTime('08:00')
      },

      updateTime () {
        setInterval(() => this.cal.updateTimes(), 60 * 1000)
      },
    },
  }
</script>

<style lang="scss" scoped>
.v-current-time {
  height: 2px;
  background-color: #ea4335;
  position: absolute;
  left: -1px;
  right: 0;
  pointer-events: none;

  &.first::before {
    content: '';
    position: absolute;
    background-color: #ea4335;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: -5px;
    margin-left: -6.5px;
  }
}

.new-label {
  display: block;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  margin-bottom: 5px;
}
</style>
