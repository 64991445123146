export default {
  // id: '',
  tipo_funcionario_id: '',
  data_admissao: '',
  data_demissao: '',
  nome: '',
  data_nascimento: '',
  sexo: 'M',
  cpf: '',
  rg: '',
  orgao_expedidor: '',
  endereco: '',
  bairro: '',
  cidade: '',
  estado: 'PE',
  cep: '',
  telefone: '',
  salario: '',
  carteira_trabalho: '',
  serie_carteira_trabalho: '',
  estado_civil: 'Solteiro',
  ativo: 1,
};
