import createAxios from '@/app/core/AxiosCore';
import config from '@/app/config/app';

const axios = createAxios(config);

function listar(paginacao) {
  return axios.get(`grupos`, {params: paginacao})
    .then(response => response.data);
}

function buscar(busca) {
  return axios.get(`grupos/buscar?busca=${busca}`)
    .then(response => response.data);
}

function buscarPorCodigo(codigo) {
  return axios.get(`grupos/buscarCodigo?codigo=${codigo}`)
    .then(response => response.data);
}

function abrir(id) {
  return axios.get(`/grupos/${id}`)
    .then(response => response.data);
}

function salvar(grupo) {
  return axios.post('/grupos', grupo)
    .then(response => response.data);
}

function atualizar(grupo) {
  return axios.put(`/grupos/${grupo.id}`, grupo)
    .then(response => response.data)
}

function excluir(id) {
  return axios.delete(`/grupos/${id}`)
    .then(response => response.data);
}

function listarSubGrupos(grupo_id) {
  return axios.get('/subgrupos', {params: {grupo_id: grupo_id}})
    .then(response => response.data);
}

function abrirSubGrupo(id) {
  return axios.get(`/subgrupos/${id}`)
    .then(response => response.data);
}

function salvarSubGrupo(subgrupo) {
  return axios.post('/subgrupos', subgrupo)
    .then(response => response.data);
}

function atualizarSubGrupo(subgrupo) {
  return axios.put(`/subgrupos/${subgrupo.id}`, subgrupo)
    .then(response => response.data)
}

function excluirSubGrupo(id) {
  return axios.delete(`/subgrupos/${id}`)
    .then(response => response.data);
}


export default {
  listar,
  buscar,
  buscarPorCodigo,
  abrir,
  salvar,
  atualizar,
  excluir,
  listarSubGrupos,
  abrirSubGrupo,
  salvarSubGrupo,
  atualizarSubGrupo,
  excluirSubGrupo
}
