import createAxios from '@/app/core/AxiosCore';
import config from '@/app/config/app';
import qs from "qs";

const axios = createAxios(config);

export default new class MovimentoEstoqueService {

  listar(paginacao) {
    return axios.get('movimentos?' + qs.stringify(paginacao))
      .then(response => response.data);
  }

  abrir(movimento_id) {
    return axios.get('/movimentos/' + movimento_id)
      .then(response => response.data);
  }

  salvar(movimento) {
    return axios.post('/movimentos', movimento)
      .then(response => response.data);
  }

  atualizar(movimento) {
    return axios.put('/movimentos/'+ movimento.id, movimento)
      .then(response => response.data)
  }

  excluir(movimento_id) {
    return axios.delete('/movimentos/' + movimento_id)
      .then(response => response.data);
  }
}
