import createAxios from '@/app/core/AxiosCore';
import config from '@/app/config/app';
import qs from "qs";

const axios = createAxios(config);


function listar(paginacao) {
  return axios.get(`funcionarios?${qs.stringify(paginacao)}`)
    .then(response => response.data);
}

function buscar(busca) {
  return axios.get(`funcionarios/buscar?busca=${busca}`)
    .then(response => response.data);
}

function buscarPeloCodigo(codigo) {
  return axios.get(`funcionarios/buscarCodigo?codigo=${codigo}`)
    .then(response => response.data);
}

function abrir(id) {
  return axios.get(`/funcionarios/${id}`)
    .then(response => response.data);
}

function salvar(veiculo) {
  return axios.post('/funcionarios', veiculo)
    .then(response => response.data);
}

function atualizar(veiculo) {
  return axios.put(`/funcionarios/${veiculo.id}`, veiculo)
    .then(response => response.data)
}

function excluir(id) {
  return axios.delete(`/funcionarios/${id}`)
    .then(response => response.data);
}


export default {
  listar,
  buscar,
  buscarPeloCodigo,
  abrir,
  salvar,
  atualizar,
  excluir
}
