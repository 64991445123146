<template>
  <div>
    <z-centralized-toolbar>  
      <v-toolbar-items>

        <v-btn id="btn-adicionar" text small color="primary" @click="adicionar">
          <v-icon left>mdi-wallet-travel</v-icon>Adicionar
        </v-btn>

        <v-btn text id="btn-atualizar" @click="refresh" :loading="$store.state.loading">
          <v-icon>mdi-refresh</v-icon>
          <v-tooltip bottom activator="#btn-atualizar">
            Atualizar
          </v-tooltip>
        </v-btn>
      </v-toolbar-items>
      

      <!-- <v-spacer></v-spacer> -->

      <z-text-field-search
        v-model="busca"
        select
        class="ml-5"
        @keydown.enter="buscar"
        @click:clear="limparBusca"
      ></z-text-field-search>
    </z-centralized-toolbar>

    <v-divider></v-divider>

    <z-scroll-layout :top-position="$v.TOP_POSITION">
      <z-container>

        <z-cabecalho>{{$store.state.titulo_tela}}</z-cabecalho>

        <v-card>

          <v-data-table
            mobile-breakpoint="0"
            :options.sync="paginacao" 
            :server-items-length="total_itens"
            disable-sort
            :footer-props="$v.FOOTER_PROPS" 
            :headers="headers"
            :items="fornecedores"
            item-key="id"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.id }}</td>
                <td>{{ item.razao }}</td>
                <td>{{ item.telefone1 }}</td>
                <td class="text-center">
                  <v-btn icon @click="editar(item)" class="ma-0">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn icon @click="perguntarExcluir(item)" class="ma-0">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>

      </z-container>
    </z-scroll-layout>

    <z-dialogo-sim-nao ref="dialogoRemoverFornecedor" titulo="Remover fornecedor?" botaoSim="Remover" botaoNao="Cancelar" :loading="excluindo">
      O fornecedor será removido do sistema.
    </z-dialogo-sim-nao>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from "vuex";
export default {
  name: 'FornecedoresLista',
  data() {
    return {
      headers: [
        { text: "Código", value: "id", width: "150px" },
        { text: "Razão", value: "razao" },
        { text: "Telefone1", value: "telefone" },
        { text: "Opções", value: "", width: "200px", align: "center", sortable: false },
      ],
      excluindo: false
    };
  },

  created() {
    this.refresh();
  },

  mounted() {
    this.setTitulo("Fornecedores");
  },

  methods: {
    ...mapMutations(["setTitulo", 'LOADING_OFF']),
    ...mapActions("fornecedores", ["listar", "excluir", "abrir"]),

    async refresh() {
      try {
        await this.listar();
      } catch (error) {
        console.log(error);
        this.$eventbus.notificar("Não foi possível listar os fornecedores.");
      }
    },

    buscar() {
      this.$store.commit('fornecedores/SET_PAGE', 1);
      this.refresh();
    },
    
    limparBusca() {
      this.busca = null;
      this.$store.commit('fornecedores/SET_PAGE', 1);
      this.refresh();
    },

    adicionar() {
      this.$store.commit('fornecedores/ZERAR');
      this.$router.push({ name: "FornecedoresAdicionar" });
    },

    async editar(fornecedor) {
      try {

        await this.abrir(fornecedor.id);
        this.$router.push({ name: "FornecedoresEditar", params: { id: fornecedor.id } });
        
      } catch (error) {
        console.log(error);
        this.$eventbus.notificar("Não foi possível abrir o fornecedor.");
      } finally {
        this.LOADING_OFF();
      }
    },

    perguntarExcluir(fornecedor) {
      this.$refs.dialogoRemoverFornecedor.abrir()
        .then(async response => {

          if (response == true) {
            await this.confirmarExcluir(fornecedor);
            this.$refs.dialogoRemoverFornecedor.fechar();
          }
        })
    },

    async confirmarExcluir(fornecedor) {
      try {
        this.excluindo = true;
        await this.excluir(fornecedor.id);
        this.$eventbus.notificar("Fornecedor excluído com sucesso!");
      } catch (error) {
        this.$eventbus.notificar("Não foi possível excluir o fornecedor!");
        throw error;
      } finally {
        this.excluindo = false;
      }
    },
  },

  computed: {
    ...mapState(['loading']),
    ...mapState('fornecedores', ['fornecedores', 'fornecedor', 'total_itens']),

    busca: {
      get () {
        return this.$store.state.fornecedores.busca
      },
      set (busca) {
        this.$store.commit('fornecedores/SET_BUSCA', busca);
      }
    },

    paginacao: {
      get () {
        return this.$store.state.fornecedores.paginacao
      },
      set (paginacao) {
        this.$store.commit('fornecedores/UPDATE_PAGINACAO', paginacao);
      }
    },
  },

  watch: {
    paginacao: {
      handler() {
        this.refresh();
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
</style>
