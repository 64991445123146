import eventbus from "@/app/eventbus";

export default {

  start(vm) {

    let notificacao = vm.$refs.notificacao;
    let alerta = vm.$refs.alerta;

    eventbus.$on('EXIBIR_NOTIFICACAO', mensagem => {
      notificacao.exibir({
        mensagem: mensagem
      })
    });

    eventbus.$on('EXIBIR_ALERTA', (msg) => {

      let mensagem = {
        titulo: 'Atenção!',
        mensagem: ''
      }

      if (typeof msg == 'string') {
        mensagem.mensagem = msg;
      }

      if (typeof msg == 'object') {
        mensagem.titulo = msg.titulo;
        mensagem.mensagem = msg.mensagem;
      }

      alerta.alertar(mensagem);
    })

    eventbus.$on('EXIBIR_ERRO_SISTEMA', error_object => {
      let mensagem = error_object.message || 'Erro do Sistema';

      if (error_object.error) {
        // Erro de Request da Api
        if (error_object.error.status) {
          mensagem = `${mensagem} <br> "${error_object.error.status} - ${error_object.error.message}"<br> ${error_object.error.stack.message}`;

          // if (error_object.error.stack.errors) {
          //   for(error in error_object.error.stack.errors) {
          //     error_object.error.stack.errors[error].forEach(e => {
          //       mensagem += `<br> + ${error}: ${e}`;
          //     });
          //   };
          // }
        }

        // Erro de Runtime
        if (error_object.error.name) {
          mensagem = `${mensagem}<br> "${error_object.error.name} - ${error_object.error.message}"`;
        }
      }

      notificacao.exibir({
        mensagem: mensagem,
        cor     : 'error',
        timeout : 5000
      });
    })

    eventbus.$on('UNAUTHORIZED', function() {
      let mensagem = {
        titulo: 'Atenção!',
        mensagem: 'Sua sessão expirou. Efetue o login novamente.',
      }

      alerta.alertar(mensagem, function() {
        vm.$auth.logout();
        vm.$router.push('/login');
      });
    });

    eventbus.$on('FECHAR_NOTIFICACAO', () => {
      notificacao.fechar();
    });

  },

  stop(){
    eventbus.$off('EXIBIR_NOTIFICACAO');
    eventbus.$off('EXIBIR_ALERTA');
    eventbus.$off('EXIBIR_ERRO_SISTEMA');
    eventbus.$off('UNAUTHORIZED');
    eventbus.$off('FECHAR_NOTIFICACAO');
  },

}
