<template>
  <div>

    <v-container fluid>

      <v-row>
        <v-col cols="6">
          <v-card outlined>
            <v-card-title class="text-subtitle-2">
              Ordem de Serviço
            </v-card-title>
            <v-card-text>
              <v-row dense>
                <v-col cols="12">
                  <z-text-field label="Última OS" :value="resumo.ultima_os" readonly></z-text-field>
                </v-col>
                <v-col cols="12">
                  <z-date-picker-menu label="Data Última OS" :value="resumo.data_ultima_os" readonly></z-date-picker-menu>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="6">
          <v-card outlined>
            <v-card-title class="text-subtitle-2">
              Entrada
            </v-card-title>
            <v-card-text>
              <v-row dense>
                <v-col cols="12">
                  <z-text-field label="Última Entrada" :value="resumo.ultima_entrada" readonly></z-text-field>
                </v-col>
                <v-col cols="12">
                  <z-date-picker-menu label="Data Última Entrada" :value="resumo.data_ultima_entrada" readonly></z-date-picker-menu>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

    </v-container>

  </div>
</template>

<script>
  export default {
    props: {
      resumo: {
        type: Object,
        default: () => {}
      },
    },
  }
</script>

<style scoped>

</style>