<template>
  <z-dialogo ref="dialogo" titulo="Peças" scrollable dividers no-padding largura="1000px" altura="383px">

    <template v-slot:prepend>
      <v-toolbar flat color="white">
        <v-text-field
          ref="busca"
          v-model="busca"
          rounded
          filled
          single-line
          dense
          label="Pesquisar"
          clearable
          hide-details
          prepend-inner-icon="mdi-magnify"
          @keydown.enter="onBuscarPressEnter"
          @focus="onBuscaFocus"
          @blur="is_busca_focused = false"
          @click:clear="limparBusca"
        ></v-text-field>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn small fab color="primary" class="ml-3 mr-1" v-on="on" @click="adicionar">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Adicionar Peça</span>
        </v-tooltip>
      </v-toolbar>
    </template>

    <template v-slot:texto>
      <div ref="topo"></div>
      <z-loading :loading="carregando">

        <v-data-table
          mobile-breakpoint="0"
          disable-pagination
          disable-sort
          hide-default-footer
          :headers="headers"
          :items="pecas"
          :loading="carregando"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <tr
                v-for="(item, index) in items"
                ref="urls"
                :key="index"
                class="apontador noselect"
                :class="{'active': isActive(index)}"
                @click="selecionar(item)"
              >
                <td>{{ item.codigo }}</td>
                <td>{{ item.referencia_fabricante }}</td>
                <td>{{ item.descricao }}</td>
                <td class="text-right">{{ item.valor_custo | valorBR }}</td>
                <td class="text-right">{{ item.valor_venda | valorBR }}</td>
                <td class="text-right">{{ item.estoque }}</td>
                <td class="text-center">
                  <v-btn icon @click.stop="editar(item)">
                    <v-icon>{{ $icon.EDITAR }}</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>

        </v-data-table>

      </z-loading>
    </template>

    <template v-slot:actions_left>
      <span class="text-subtitle-2">Peças encontradas: {{ pecas.length }}</span>
    </template>
    <template v-slot:actions_right>
      <v-btn color="primary" @click="fechar()">Fechar</v-btn>
    </template>

    <PecasFormDialogo ref="pecasFormDialogo" @sucesso="sucesso"></PecasFormDialogo>
  </z-dialogo>

</template>

<script>

  import { mapActions, mapState, mapMutations } from "vuex";


  import pecasApi from "@/app/services/pecas";
  import { abrirJanela } from "@/app/helpers/utils";

  import buscas_mixin from "@/app/mixins/buscas";

  import PecasFormDialogo from "@/pages/cadastros/pecas/PecasFormDialogo"

  export default {
    name: 'DialogoBuscarPecas',

    components: {
      PecasFormDialogo,
    },

    mixins: [buscas_mixin],

    data() {
      return {
        headers: [
          { text: "Código", value: "codigo", width: "150px" },
          { text: "Referência Fab.", value: "referencia_fabricante" },
          { text: "Descrição", value: "descricao" },
          { text: "Valor Custo", value: "valor_custo", align: 'right' },
          { text: "Valor Venda", value: "valor_venda", align: 'right' },
          { text: "Estoque", value: "estoque", align: 'right' },
          { text: "", value: "", align: 'center' },
        ],
      };
    },

    computed: {
      ...mapState('pecas', ['pecas']),
    },

    methods: {
      ...mapActions("pecas", {
        abrirPeca: "abrir",
        buscarPecas: "buscar"
      }),

      async abrir() {
        this.$refs.dialogo.abrir();
        if (this.busca) {
          await this.buscar();
          this.setFocus('busca');
        }
      },

      async buscar() {
        try {
          if (this.busca && this.busca.length > 0) {
            this.carregando = true;
            await this.buscarPecas(this.busca);
            this.atual = 0;
            setTimeout(() => {
              this.scrollList();
            }, 100);
          }
        } catch (error) {
          this.$eventbus.notificarErro('Não foi possível efetuar a busca.');
        } finally {
          this.carregando = false;
        }
      },

      adicionar() {
        this.$refs.pecasFormDialogo.abrir();
        // abrirJanela(window.location.origin + '/#/cadastros/pecas/adicionar');
      },

      async editar(peca) {
        await this.abrirPeca(peca.id);
        this.$refs.pecasFormDialogo.abrir();
        // abrirJanela(window.location.origin + '/#/cadastros/pecas/adicionar');
      },

      sucesso(peca) {
        this.busca = peca.descricao;
        this.buscar();
      }
    },
  }
</script>

<style scoped>

</style>
