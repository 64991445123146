<template>
  <z-dialogo ref="dialogo" largura="1200px" titulo="Adicionar Peça" persistent dividers eager>

      <template v-slot:texto>
        <v-form ref="formulario" v-model="form_valido" lazy-validation>
          <v-row dense>
            <v-col cols="12" sm="3">
              <z-text-field
                label="Código"
                v-model="peca.codigo"
                :disabled="peca.id ? true : false"
                :rules="[regras.campoObrigatorio]"
              ></z-text-field>
            </v-col>
            <v-col cols="12" sm="9">
              <z-text-field
                label="Descrição"
                v-model="peca.descricao"
                :rules="[regras.campoObrigatorio]"
              ></z-text-field>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" sm="2">
              <z-text-field
                label="Referência Fab."
                v-model="peca.referencia_fabricante"
              ></z-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <z-select
                :items="$store.getters['listas/grupos']"
                label="Grupo"
                v-model="peca.grupo_id"
                clearable
                @click:clear="peca.grupo_id = null"
              ></z-select>
            </v-col>
            <v-col cols="12" sm="4">
              <z-select
                :items="$store.getters['listas/subgrupos']"
                label="Sub Grupo"
                v-model="peca.subgrupo_id"
                clearable
                @click:clear="peca.subgrupo_id = null"
              ></z-select>
            </v-col>
            <v-col cols="12" sm="2">
              <z-campo-valor
                v-model="peca.valor_custo"
                label="Valor Compra"
                @input="calcularValores"
              ></z-campo-valor>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" sm="3">
              <z-campo-valor
                v-model="peca.icms"
                label="ICMS"
                suffix="%"
                decimais="0"
                @input="calcularValores"
              ></z-campo-valor>
            </v-col>
            <v-col cols="12" sm="3">
              <z-campo-valor
                v-model="peca.frete"
                label="Frete"
                suffix="%"
                decimais="0"
                @input="calcularValores"
              ></z-campo-valor>
            </v-col>
            <v-col cols="12" sm="3">
              <z-campo-valor
                v-model="peca.encargos"
                label="Encargos"
                suffix="%"
                decimais="0"
                @input="calcularValores"
              ></z-campo-valor>
            </v-col>
            <v-col cols="12" sm="3">
              <z-campo-valor
                v-model="peca.custos"
                label="Custo Total"
                readonly
                background-color="grey lighten-1"
              ></z-campo-valor>
            </v-col>
            <v-col cols="12" sm="3">
              <z-campo-valor
                v-model="peca.lucro"
                label="Lucro"
                decimais="0"
                suffix="%"
                @input="calcularValores"
              ></z-campo-valor>
            </v-col>
            <v-col cols="12" sm="3">
              <z-campo-valor
                v-model="peca.valor_venda"
                label="Valor Venda"
              ></z-campo-valor>
            </v-col>
            <v-col cols="12" sm="3">
              <z-campo-valor
                v-model="peca.estoque"
                label="Estoque"
                decimais="0"
              ></z-campo-valor>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" sm="3">
              <z-text-field
                v-model="peca.garantia"
                label="Garantia"
              ></z-text-field>
            </v-col>
            <v-col cols="12" sm="9">
              <z-text-field
                upper
                v-model="peca.locacao"
                label="Locação"
              ></z-text-field>
            </v-col>
          </v-row>
        </v-form>
      </template>

      <template #actions_right>
        <v-btn
          text
          :disabled="salvando"
          @click="fechar()"
          >Cancelar</v-btn
        >
        <v-btn
          color="primary"
          :loading="salvando"
          :disabled="!form_valido"
          @click="confirmar"
        >
          Salvar
        </v-btn>
      </template>
  </z-dialogo>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";

import * as regras from "@/app/helpers/validacoes";

export default {
  name: "PecasFormDialogo",

  data() {
    return {
      dialogo: null,
      salvando: false,
      tab: 0,
      form_valido: true,
      regras: { ...regras },

      error: {
        message: "",
      },
    };
  },

  computed: {
    ...mapState("pecas", ["peca"]),
    ...mapGetters("listas", ["grupos", "subgrupos"]),

    titulo() {
      let titulo = /Editar/.test(this.$route.name) ? "Editar" : "Adicionar";
      return titulo + " " + "Peça";
    },
  },

  async mounted() {
    this.$store.dispatch("listas/funcionarios");
    this.$store.dispatch("listas/grupos");
    this.$store.dispatch("listas/subgrupos");

    this.dialogo = this.$refs.dialogo;
  },

  methods: {
    ...mapMutations('pecas', ['ZERAR']),
    ...mapActions('pecas', ['salvar', 'atualizar']),

    abrir() {
      this.dialogo.abrir();
    },

    fechar() {
      this.ZERAR();
      this.dialogo.fechar();
      setTimeout(() => {
        this.$refs.formulario.resetValidation();
      }, 0);

    },

    async confirmar() {
      this.error.message = "";

      if (!this.$refs.formulario.validate()) {
        return false;
      }

      try {
        if (this.peca.id) {
          await this.atualizar(this.peca);
        } else {
          await this.salvar(this.peca);
        }

        this.fechar();
        this.$eventbus.notificar("Peça salva com sucesso");
        this.$emit('sucesso', this.peca);
      } catch (error) {
        this.$eventbus.notificarErro("Peça já cadastrada. Verifique os dados preenchidos.");
        // this.$eventbus.notificarErro("Não foi possível salvar esta peça. Verifique os dados preenchidos.", error);
        // throw error;
      } finally {
        this.salvando = false;
      }
    },

    calcularCustos() {
      this.peca.custos =
        this.peca.valor_custo +
        (this.peca.valor_custo * this.peca.icms) / 100 +
        (this.peca.valor_custo * this.peca.frete) / 100 +
        (this.peca.valor_custo * this.peca.encargos) / 100;
    },

    calcularVenda() {
      this.peca.valor_venda =
        this.peca.custos + (this.peca.custos * this.peca.lucro) / 100;
    },

    calcularValores() {
      this.calcularCustos();
      this.calcularVenda();
    },
  },
};
</script>

<style scoped>
</style>
