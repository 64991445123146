<script setup>

import { ref, computed } from "vue";

const props = defineProps({
  items: {
    type: Array,
    default: () => []
  },

  value: {
    type: String,
  }
});

const emit = defineEmits(['input']);

const button_text = computed(() => {
  return props.items.find(item => item.value == props.value).text;
})

function change(item) {
  emit('input', item.value);
}

</script>

<template>
  <v-menu bottom origin="center center">
    <template v-slot:activator="{ on, attrs }">
      <v-btn outlined v-bind="attrs" v-on="on">
        {{ button_text }}
        <v-icon small right>mdi-menu-down</v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-list-item v-for="(item, i) in items" :key="i" @click="change(item)">
        <v-list-item-title>{{ item.text }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>


<style lang="scss" scoped>

</style>
