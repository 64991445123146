import { defineStore } from 'pinia'
import * as listasApi from "@/app/services/listas";

export const useListasStore = defineStore('listas', {

  state: () => ({
    clientes: [],
    vendedores: [],
  }),

  actions: {

    async fetchListas(listas) {
      const lists = await listasApi.fetchListas(listas);

      this.processLists(lists);
    },

    processLists(lists) {
      Object.keys(lists).forEach((key) => {
        this[key] = lists[key];
      });
    }
  },

})
