import Vue from 'vue'
import Vuex from 'vuex'

import config from '@/app/config/app';
import createAxios from '@/app/core/AxiosCore';

const axios = createAxios(config);

Vue.use(Vuex)

import usuarios from './modules/usuarios';
import clientes from './modules/clientes';
import veiculos from './modules/veiculos';
import servicos from './modules/servicos';
import pecas from './modules/pecas';
import ordemservico from './modules/ordemservico';
import funcionarios from './modules/funcionarios';
import fornecedores from './modules/fornecedores';
import tiposclientes from './modules/tiposclientes';
import tiposfuncionarios from './modules/tiposfuncionarios';
import tiposos from './modules/tiposos';
import prazos from './modules/prazos';
import formaspagamento from './modules/formaspagamento';
import bancos from './modules/bancos';
import grupos from './modules/grupos';
import movimentoestoque from './modules/movimentoestoque';
import agenda from './modules/agenda';
import listas from './modules/listas';


const createStore = () => {

  return new Vuex.Store({
    state: {
      titulo_tela: config.APP_NAME,
      loading: false,
      drawerRight: false,
      configuracoes: {},
      lista_configuracoes: [],
      drawer: true,
      novas_configuracoes: {}
    },

    mutations: {
      setTitulo(state, titulo) {
        state.titulo_tela = titulo;
      },

      LOADING(state, loading) {
        state.loading = loading;
      },

      LOADING_ON(state) {
        state.loading = true;
      },

      LOADING_OFF(state) {
        state.loading = false;
      },

      TOGGLE_RIGHT_DRAWER(state) {
        state.drawerRight = !state.drawerRight;
      },

      SET_CONFIGURACOES(state, configuracoes) {
        state.configuracoes = configuracoes
      },

      SET_LISTA_CONFIGURACOES(state, configuracoes) {
        state.lista_configuracoes = configuracoes
      },

      SET_NOVAS_CONFIGURACOES(state, configuracoes = []) {
        const new_config = {}
        configuracoes.forEach(config => {
          new_config[config.config] = config.valor;
        });

        state.novas_configuracoes = new_config
      }
    },

    actions: {
      carregarConfiguracoes({ commit }) {
        axios.get('configuracoes')
          .then(response => {
            commit('SET_CONFIGURACOES', response.data);
          });
      },

      carregarListaConfiguracoes({ commit }) {
        return axios.get('configuracoes/lista')
          .then(response => {
            commit('SET_LISTA_CONFIGURACOES', response.data);
            commit('SET_NOVAS_CONFIGURACOES', response.data);
          });
      },

      atualizar({ commit }, configuracao) {
        return axios.put('configuracoes', configuracao);
      },

      salvar({ commit }, configuracao) {
        return axios.post('configuracoes', configuracao);
      }
    },

    modules: {
      usuarios,
      clientes,
      veiculos,
      servicos,
      pecas,
      ordemservico,
      funcionarios,
      fornecedores,
      listas,
      tiposclientes,
      tiposfuncionarios,
      tiposos,
      prazos,
      formaspagamento,
      bancos,
      grupos,
      movimentoestoque,
      agenda
    }
  });

}

export default createStore;
