<template>
  <v-menu class="glass" offset-y transition="slide-y-transition" origin="center center" :nudge-bottom="10">
    <template v-slot:activator="{ on }">
      <v-btn rounded text v-on="on">
        <v-avatar size="36px">
          <!-- <img src="https://pt.gravatar.com/userimage/137219454/70437e2a847a7a971a437def22577a44.jpg" alt="Michael Wang"/> -->
          <v-icon>mdi-account</v-icon>
        </v-avatar>
        <span v-if="$vuetify.breakpoint.mdAndUp">{{ $auth.getUser().name }}</span>
      </v-btn>
    </template>

    <v-card class="glass">
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <!-- <img src="https://pt.gravatar.com/userimage/137219454/70437e2a847a7a971a437def22577a44.jpg" alt="John"> -->
            <v-icon>mdi-account</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ $auth.getUser().name }}</v-list-item-title>
            <v-list-item-subtitle>{{ $auth.getUser().perfil.descricao }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list class="pa-0">
        <v-list-item
          v-for="(item, index) in items2"
          :to="!item.href ? { name: item.name } : null"
          :href="item.href"
          @click="item.click"
          ripple="ripple"
          :disabled="item.disabled"
          :target="item.target"
          rel="noopener"
          :key="index"
        >
          <v-list-item-action v-if="item.icon">
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'app-user-menu',

  data() {
    return {
      items: [
        { icon: 'home', title: 'Tela Inicial', to: '/' },
        { icon: 'card_travel', title: 'Catálogo', to: '/catalogo' },
        { icon: 'shopping_cart', title: 'Vendas', to: '/vendas' },
        { icon: 'check_circle', title: 'Compras', to: '/compras' },
        { icon: 'monetization_on', title: 'Financeiro', to: '/financeiro' },
        { icon: 'assignment', title: 'Relatórios', to: '/relatorios' },
      ],
      items2: [
        // { icon: "mdi-account-circle", href: "#", title: "Meu Perfil",
        //   click: e => {
        //     console.log(e);
        //   }
        // },
        // {
        //   icon: "settings", href: "#", title: "Configurações",
        //   click: e => {
        //     console.log(e);
        //   }
        // },
        {
          icon: 'mdi-exit-to-app',
          href: '#',
          title: 'Sair',
          click: (e) => {
            this.logout();
          },
        },
      ],
    };
  },

  methods: {
    logout() {
      this.$auth.logout();
      this.$router.push('/login');
    },
  },

  computed: {
    ...mapState(['nameOfState']),
  },
};
</script>

<style scoped>
.glass {
  background: rgba(255, 255, 255, 0.3) !important;
  background: linear-gradient(to right bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.3)) !important;
  /* z-index: 2; */
  /* border-radius: 2rem !important; */
  backdrop-filter: blur(2rem) !important;
}
</style>
