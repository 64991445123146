<template>
  <z-expansion-config-item titulo="Cor padrão" :loading="isLoading">
    <v-text-field
      v-bind="{ ...$attrs, ...$v.TEXT_FIELDS }"
      v-model="store.configuracoes.APP_CORPADRAO"
      hide-details
      ref="cor"
      label=""
      readonly
      @click="$refs.dialogoEscolherCor.abrir()"
    >
      <template v-slot:append>
        <v-icon :color="store.configuracoes.APP_CORPADRAO" @click="$refs.dialogoEscolherCor.abrir()">mdi-palette</v-icon>
      </template>
    </v-text-field>
    <escolher-cor ref="dialogoEscolherCor" @input="updateConfig"></escolher-cor>
  </z-expansion-config-item>
</template>

<script setup>
import { inject, onMounted, computed, getCurrentInstance } from 'vue';
import { useConfigStore } from '@/app/store/pinia/configuracoes'
import { useVuetify } from '@/app/plugins/useVuetify.js'

import { zExpansionConfigItem } from "zcomponents";
import EscolherCor from "./parts/EscolherCor.vue";

const store = useConfigStore();
const vuetify = useVuetify();

const isLoading = computed(() => {
  return store.conf.origin == 'CorPadrao' && store.conf.loading == true;
})

const instance = getCurrentInstance();

const $storage = instance.proxy.$storage;
const $root = instance.proxy.$root;


function updateConfig(cor) {
  store.conf.origin = 'CorPadrao';

  store.configuracoes.APP_CORPADRAO = cor;

  vuetify.theme.themes.light.primary = cor;
  $storage.set('APP_CORPADRAO', cor);
}

</script>



<style lang="scss" scoped>

</style>
