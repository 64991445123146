<template>
  <div>
    <v-data-table
          mobile-breakpoint="0"
      disable-pagination
      disable-sort
      hide-default-footer
      :headers="headers"
      :items="historico"
    >
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="(item, index) in items" :key="index">
            <td>
              <v-icon color="green" v-if="item.movimento == 'Entrada'">mdi-arrow-bottom-left-thick</v-icon>
              <v-icon color="red" v-else>mdi-arrow-top-right-thick</v-icon>
              {{ item.movimento }}
            </td>
            <td>{{ item.id }}</td>
            <td>{{ item.data | dataBR }}</td>
            <td class="text-right">{{ item.quantidade }}</td>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
  export default {
    props: {
      historico: {
        type: Array,
        default: () => []
      },
    },

    data() {
      return {
        headers: [
          { text: "Movimento", value: "movimento" },
          { text: "Código", value: "id" },
          { text: "Data", value: "data" },
          { text: "Quantidade", value: "quantidade", align: "right"},
        ],
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>