<template>
  <div>
    <z-centralized-toolbar>
      <v-spacer></v-spacer>

      <v-btn id="btn-adicionar" color="primary" @click="$router.push({ name: 'UsuariosLista' })">
        <v-icon left>{{ $icon.USUARIOS }}</v-icon>
        Usuários
      </v-btn>
    </z-centralized-toolbar>

    <v-divider></v-divider>

    <z-scroll-layout :top-position="$v.TOP_POSITION">
      <z-container>
        <z-cabecalho class="mb-n1">{{ $store.state.titulo_tela }}</z-cabecalho>

        <v-row>
          <v-col cols="12" sm="6">
            <v-card>
              <v-card-title class="text-subtitle-2">Perfis de Usuário</v-card-title>
              <v-divider></v-divider>
              <div style="height: 370px; overflow-y: auto">
                <v-list subheader>
                  <template v-for="(perfil, index) in perfis">
                    <v-list-item color="primary" :class="{ 'selecionado primary--text': perfil_selecionado.descricao == perfil.descricao }" :key="perfil.descricao" @click="carregarPermissoes(perfil)">
                      <v-list-item-content>
                        <v-list-item-title v-html="perfil.descricao"></v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action v-if="perfil_selecionado.descricao == perfil.descricao">
                        <v-icon>mdi-chevron-right</v-icon>
                      </v-list-item-action>
                    </v-list-item>

                    <v-divider :key="index" v-if="index < perfis.length - 1"></v-divider>
                  </template>
                </v-list>
              </div>
              <v-divider></v-divider>
              <v-card-actions>
                <v-btn icon @click="adicionar"><v-icon>mdi-plus</v-icon></v-btn>

                <v-slide-x-transition>
                  <div v-if="!isAdmin">
                    <v-btn icon @click="editar" :disabled="isAdmin"><v-icon>mdi-pencil</v-icon></v-btn>
                    <v-btn icon @click="perguntarExcluirPerfil" :disabled="isAdmin"><v-icon>mdi-delete</v-icon></v-btn>
                  </div>
                </v-slide-x-transition>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col cols="12" sm="6">
            <v-card>
              <v-card-title class="text-subtitle-2">Permissões - {{ perfil_selecionado.descricao }}</v-card-title>
              <v-divider></v-divider>
              <v-card-text style="height: 370px; overflow-y: auto">
                <v-treeview
                  dense
                  v-model="selecionados"
                  :items="permissoes"
                  selected-color="primary"
                  :open.sync="abertos"
                  :open-all="abrirtodos"
                  activatable
                  hoverable
                  selectable
                  open-on-click
                  transition
                >
                  <template v-slot:prepend="{ item, open }">
                    <v-icon v-if="item.icon">
                      {{ item.icon }}
                    </v-icon>
                  </template>
                </v-treeview>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text :disabled="loading || isAdmin">Cancelar</v-btn>
                <v-btn text color="primary" @click="salvarPermissoes" :disabled="isAdmin" :loading="loading">Salvar</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </z-container>
    </z-scroll-layout>

    <z-dialogo ref="dialogoPerfil" titulo="Perfil" dividers>
      <template v-slot:conteudo>
        <z-text-field ref="descricao" label="Descrição" v-model="p.descricao" placeholder=" " autofocus></z-text-field>
      </template>

      <v-btn text :disabled="salvando" @click="$refs.dialogoPerfil.fechar()">Cancelar</v-btn>
      <v-btn text color="primary" @click="salvar" :loading="salvando">Salvar</v-btn>
    </z-dialogo>

    <z-dialogo ref="dialogoExcluirPerfil_" titulo="Excluir perfil" dividers>
      <v-container slot="texto">
        <h3 class="mb-2">Deseja excluir o perfil selecionado?</h3>
        <span>Perfil: {{ perfil_selecionado.descricao }}</span>
      </v-container>

      <v-btn text :disabled="excluindo" @click="$refs.dialogoExcluirPerfil.fechar()">Cancelar</v-btn>
      <v-btn text color="red" @click="confirmarExcluir" :loading="excluindo">Excluir</v-btn>
    </z-dialogo>

    <z-dialogo-sim-nao ref="dialogoExcluirPerfil" titulo="Remover perfil?" botaoSim="Remover" botaoNao="Cancelar" :loading="excluindo">
      O perfil selecionado será removido.
    </z-dialogo-sim-nao>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import permissoes from './permissoes';
import menu from '@/pages/layout/menus/menu';
import * as icon from '@/app/icons';
export default {
  name: 'usuarios-permissoes',
  data() {
    return {
      busca: '',
      loading: false,
      excluindo: false,
      salvando: false,
      permissoes: permissoes,
      selecionados: [],
      abertos: ['todas'],
      abrirtodos: false,
      ativo: null,
      perfil_selecionado: {
        descricao: '',
        permissoes: '',
      },
      p: {
        descricao: '',
        permissoes: '',
      },
    };
  },

  computed: {
    // ...mapState({
    //   usuarios: state => state.usuarios.usuarios
    // }),

    menu_permissoes() {
      let permissoes = this.permissoes[0].children;

      let menu = [];
      permissoes.forEach((item) => {
        menu.push({
          descricao: item.name,
          grupo: item.id,
          icon: item.icon,
          visible: item.visible,
          itens: item.children,
        });
      });

      return menu;
    },

    isAdmin() {
      return this.perfil_selecionado.descricao == 'Administrador';
    },

    perfis() {
      return this.$store.state.usuarios.perfis;
    },
  },

  async mounted() {
    this.$store.commit('setTitulo', 'Perfis/Permissões');
    await this.listarPerfis();
    this.carregarPermissoes(this.perfis[0]);
  },

  methods: {
    ...mapActions({
      listarPerfis: 'usuarios/perfis',
      atualizarPerfil: 'usuarios/atualizarPerfil',
      salvarPerfil: 'usuarios/salvarPerfil',
      excluirPerfil: 'usuarios/excluirPerfil',
    }),

    carregarPermissoes(perfil) {
      this.perfil_selecionado = perfil;
      this.selecionados = [];

      if (perfil.permissoes) this.selecionados = perfil.permissoes.split(',');
    },

    adicionar() {
      this.p = {
        descricao: '',
        permissoes: '',
      };
      this.$refs.dialogoPerfil.abrir();
      this.setFocus('descricao');
    },

    editar() {
      this.p = this.clone(this.perfil_selecionado);
      this.$refs.dialogoPerfil.abrir();
    },

    async salvar() {
      try {
        this.salvando = true;
        if (!this.p.id) {
          let perfil = await this.salvarPerfil(this.p);
          this.selecionados = [];
          this.perfil_selecionado = perfil;
        } else {
          await this.atualizarPerfil(this.p);
        }

        this.$refs.dialogoPerfil.fechar();
      } catch (error) {
        this.$eventbus.notificarError('Não foi possível salvar Perfil', error);
      } finally {
        this.salvando = false;
      }
    },

    async salvarPermissoes() {
      let perfil = this.perfis.find((p) => p.id == this.perfil_selecionado.id);
      if (perfil) {
        perfil.permissoes = this.selecionados.join(',');
        try {
          this.loading = true;
          await this.atualizarPerfil(perfil);
          this.$eventbus.notificar('Permissões salvas com sucesso');
        } catch (error) {
          this.$eventbus.notificarErro('Não foi possível salvar Permissões', error);
        } finally {
          this.loading = false;
        }
      }
    },

    perguntarExcluirPerfil() {
      this.$refs.dialogoExcluirPerfil.abrir().then(async (response) => {
        if (response == true) {
          await this.confirmarExcluir();
          this.$refs.dialogoExcluirPerfil.fechar();
        }
      });
    },

    async confirmarExcluir() {
      try {
        this.excluindo = true;
        await this.excluirPerfil(this.perfil_selecionado);
        // this.$refs.dialogoExcluirPerfil.fechar();
        this.selecionados = [];
      } catch (error) {
        this.$eventbus.notificarError('Não foi possível excluir Perfil', error);
      } finally {
        this.excluindo = false;
      }
    },
  },
};
</script>

<style scoped>
.selecionado {
  background: rgba(0, 0, 0, 0.04);
}
</style>
