<template>
  <v-slide-y-reverse-transition>
    <v-card dark class="primary elevation-10 posicao-card" @click="keep = !keep" :class="[keep ? 'keep' : '', position]">
      <v-card-text>
        <h3 class="headline"><b>Breakpoint</b>: {{ $vuetify.breakpoint.name }} ({{ window_width }}px)</h3>
        <h3 class="headline"><b>Route name</b>: {{ $route.name }}</h3>
        <h3 class="headline"><b>Path</b>: {{ $route.path }}</h3>
        <!-- <h3 class="headline">Mouse position: {{ x }}, {{ y }}</h3> -->
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn icon @click.stop="position = 'debug-left'">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-btn icon @click.stop="position = 'debug-center'">
          <v-icon>mdi-arrow-expand-horizontal</v-icon>
        </v-btn>
        <v-btn icon @click.stop="position = 'debug-right'">
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-slide-y-reverse-transition>
</template>

<script setup>
import { ref, onMounted, onUnmounted, computed } from 'vue';
// import { useMouse } from '@/app/composables/mouse'

const window_width = ref(window.innerWidth);
const keep = ref(false);
const position = ref('debug-center');

const resize = (event) => {
  window_width.value = window.innerWidth;
};

window.addEventListener('resize', resize);

onUnmounted(() => {
  window.removeEventListener('resize', resize);
});

</script>

<style lang="scss" scoped>

.posicao-card {
  opacity: 0.4;
  position: fixed; ;
  cursor: pointer;
  transition: all 0.3s;
}

.debug-left {
  left: 0px;
  bottom: 0px;
  transform: translate(0, 90%);

  &.keep, &:hover {
    opacity: 1;
    transform: translate(0, 0%);
  }
}

.debug-right {
  right: 0px;
  bottom: 0px;
  transform: translate(0, 90%);

  &.keep, &:hover {
    opacity: 1;
    transform: translate(0, 0%);
  }
}

.debug-center {
  left: 50%;
  bottom: 0px;
  transform: translate(-50%, 90%);

  &.keep, &:hover {
    opacity: 1;
    transform: translate(-50%, 0%);
  }
}

// .posicao-card:hover {
//   opacity: 1;
//   transform: translate(-50%, 0%);
// }

</style>
