import servicosApi from "@/app/services/servicos";
import servico from "@/app/store/models/servico";
import { PAGINATION_OPTIONS } from "@/app/vuetifyConfig";

export default {
  namespaced: true,
  state: {
    servico: {...servico},
    servicos: [],
    busca: "",
    total_itens: 0,
    paginacao: PAGINATION_OPTIONS
  },

  mutations: {
    LISTAR(state, servicos) {
      state.servicos = servicos;
    },

    ABRIR(state, servico) {
      state.servico = servico;
    },

    ADICIONAR(state, servico) {
      state.servicos.push(servico);
    },

    ATUALIZAR(state, servico) {
      let index = state.servicos.findIndex(servico_ => servico_.id == servico.id);
      if (index > -1) {
        state.servicos.splice(index, 1, servico);
      }
    },

    EXCLUIR(state, id) {
      let index = state.servicos.findIndex(servico => servico.id == id);
      if (index > -1) {
        state.servicos.splice(index, 1);
      }
    },

    SET_BUSCA(state, busca) {
      state.busca = busca;
    },

    SET_PAGE(state, page) {
      state.paginacao.page = page;
    },

    SET_TOTAL_ITENS(state, total_itens) {
      state.total_itens = total_itens;
    },

    UPDATE_PAGINACAO(state, paginacao) {
      state.paginacao = paginacao;
    },

    ZERAR(state) {
      console.log('ZERAR');
      state.servico = {...servico};
    }
  },

  actions: {
    async listar({ commit, state }) {
      commit('LOADING_ON', null, { root: true });

      const { sortBy, sortDesc, page, itemsPerPage } = state.paginacao;

      const paginacao = {
        page,
        itemsPerPage,
        sortBy: sortBy[0],
        sortDesc: sortDesc[0]
      }

      if (state.busca) {
        paginacao.busca = state.busca;
      }

      let response = await servicosApi.listar(paginacao);
      commit('LISTAR', response.data);
      commit('SET_TOTAL_ITENS', response.total);
      commit('LOADING_OFF', null, { root: true });
    },

    async abrir({ commit }, id) {
      commit('LOADING_ON', null, { root: true });
      let servico = await servicosApi.abrir(id);
      commit('ABRIR', servico);
      commit('LOADING_OFF', null, { root: true });
    },

    async salvar({ commit }, servico) {
      let novoservico = await servicosApi.salvar(servico);
      commit('ADICIONAR', novoservico);
    },

    async atualizar({ commit }, servico) {
      await servicosApi.atualizar(servico);
      commit('ATUALIZAR', servico);
    },

    async excluir({ commit }, id) {
      await servicosApi.excluir(id);
      commit('EXCLUIR', id);
    },

  }
}
