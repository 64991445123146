export default {
  // id: '',
  numero: '',
  tipo_id: 1,
  funcionario_id: '',
  data_entrada: '',
  data_saida: '',
  hora_entrada: '',
  hora_saida: '',
  pagamento_id: 1,
  cliente_id: '',
  problema: '',
  total_pecas: 0,
  total_servicos: 0,
  total_orcamento: 0,
  liquido_pecas: 0,
  liquido_servicos: 0,
  desconto_servicos: 0,
  desconto_pecas: 0,
  total_liquido: 0,
  parcelas: 0,
  valor_parcela: 0,
  total_parcelado: 0,
  aprovado: false,
  placa: '',
  km: '',
  cliente: {},
  veiculo: {
    placa: '',
    marca: '',
    modelo: '',
    km: '',
    cor: '',
    chassi: '',
    ano: '',
    combustivel: 'Gasolina'
  },
  pecas: [],
  servicos: [],
  servicos_terceiros: []
}