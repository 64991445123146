import createAxios from '@/app/core/AxiosCore';
import config from '@/app/config/app';

const axios = createAxios(config);


export function clientes() {
  return axios.get('listas/clientes')
    .then(response => response.data);
}

export function vendedores() {
  return axios.get('listas/vendedores')
    .then(response => response.data);
}

export function fornecedores() {
  return axios.get('listas/fornecedores')
    .then(response => response.data);
}

export function bancos() {
  return axios.get('listas/bancos')
    .then(response => response.data);
}

export function perfis() {
  return axios.get('listas/perfis')
    .then(response => response.data);
}

export function funcionarios() {
  return axios.get('/listas/funcionarios')
    .then(response => response.data);
}

export function prazos() {
  return axios.get('/listas/prazos')
    .then(response => response.data);
}

export function grupos() {
  return axios.get('/listas/grupos')
    .then(response => response.data);
}

export function subgrupos() {
  return axios.get('/listas/subgrupos')
    .then(response => response.data);
}

export function tipos_funcionarios() {
  return axios.get('/listas/tipos_funcionarios')
    .then(response => response.data);
}

export function tipos_os() {
  return axios.get('/listas/tipos_os')
    .then(response => response.data);
}

export function tipos_clientes() {
  return axios.get('/listas/tipos_clientes')
    .then(response => response.data);
}

export function tipos_movimento() {
  return axios.get('/listas/tiposmovimento')
    .then(response => response.data);
}

export function fetchListas(listas) {
  return axios.post("listas", {'listas': listas}).then((response) => response.data);
}
