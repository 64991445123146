import * as listasApi from "@/app/services/listas";
import { tipos_funcionarios } from "../../services/listas";

export default {
    namespaced: true,
    state: {
        clientes: [],
        vendedores: [],
        fornecedores: [],
        bancos: [],
        perfis:[],
        funcionarios:[],
        prazos:[],
        grupos: [],
        subgrupos: [],
        tipos_funcionarios: [],
        tipos_os: [],
        tipos_clientes: [],
        tipos_movimento: []
    },

    getters: {
      clientes(state) {
        return state.clientes.map(cliente => {
          return {
            text: cliente.id + ' - ' + cliente.nome,
            value: cliente.id
          }
        })
      },

      getClienteById(state) {
        return (id) => {
          return state.clientes.find(cliente => cliente.id === id)
        }
      },

      getTipoMovimentoById(state) {
        return (id) => {
          return state.tipos_movimento.find(tipo => tipo.id === id)
        }
      },

      vendedores(state) {
        return state.vendedores.map(vendedor => {
          return {
            text: vendedor.nome,
            value: vendedor.id
          }
        })
      },
      fornecedores(state) {
        return state.fornecedores.map(fornecedor => {
          return {
            text: fornecedor.id + ' - ' + fornecedor.razao,
            value: fornecedor.id
          }
        })
      },
      prazos(state) {
        return state.prazos.map(prazo => {
          return {
            text: prazo.descricao,
            value: prazo.id
          }
        })
      },
      bancos(state) {
        return state.bancos.map(banco => {
          return {
            text: banco.codigo + ' - ' + banco.descricao,
            value: banco.codigo
          }
        })
      },
      perfis(state) {
        return state.perfis.map(perfil => {
          return {
            text: perfil.descricao,
            value: perfil.id
          }
        })
      },
      funcionarios(state) {
        return state.funcionarios.map(funcionario => {
          return {
            text: funcionario.id + ' - ' + funcionario.nome,
            value: funcionario.id
          }
        })
      },
      grupos(state) {
        return state.grupos.map(grupo => {
          return {
            text: grupo.descricao,
            value: grupo.id
          }
        })
      },
      subgrupos(state) {
        return state.subgrupos.map(subgrupo => {
          return {
            text: subgrupo.descricao,
            value: subgrupo.id
          }
        })
      },
      tipos_funcionarios(state) {
        return state.tipos_funcionarios.map(tipo => {
          return {
            text: tipo.id + ' - ' + tipo.descricao + ' (' + tipo.comissao_servicos +'%)',
            value: tipo.id
          }
        })
      },
      tipos_os(state) {
        return state.tipos_os.map(tipo => {
          return {
            text: tipo.descricao,
            value: tipo.id
          }
        })
      },
      tipos_clientes(state) {
        return state.tipos_clientes.map(tipo => {
          return {
            text: tipo.id + ' - ' + tipo.descricao,
            value: tipo.id
          }
        })
      },
      tipos_movimento(state) {
        return state.tipos_movimento.map(tipo => {
          return {
            text: tipo.descricao,
            value: tipo.id
          }
        })
      },


    },

    mutations: {
      'SET_CLIENTES'(state, clientes) {
        state.clientes = clientes;
      },

      'SET_VENDEDORES'(state, vendedores) {
        state.vendedores = vendedores;
      },

      'SET_FORNECEDORES'(state, fornecedores) {
        state.fornecedores = fornecedores;
      },

      'SET_BANCOS'(state, bancos) {
        state.bancos = bancos;
      },

      'SET_PERFIS'(state, perfis) {
        state.perfis = perfis;
      },

      'SET_FUNCIONARIOS'(state, funcionarios) {
        state.funcionarios = funcionarios;
      },

      'SET_PRAZOS'(state, prazos) {
        state.prazos = prazos;
      },

      'SET_GRUPOS'(state, grupos) {
        state.grupos = grupos;
      },

      'SET_SUBGRUPOS'(state, subgrupos) {
        state.subgrupos = subgrupos;
      },

      'SET_TIPOS_FUNCIONARIOS'(state, tipos_funcionarios) {
        state.tipos_funcionarios = tipos_funcionarios;
      },

      'SET_TIPOS_OS'(state, tipos_os) {
        state.tipos_os = tipos_os;
      },

      'SET_TIPOS_CLIENTES'(state, tipos_clientes) {
        state.tipos_clientes = tipos_clientes;
      },

      'SET_TIPOS_MOVIMENTO'(state, tipos_movimento) {
        state.tipos_movimento = tipos_movimento;
      },

    },

    actions: {
      async todas({commit, dispatch}) {
        dispatch('clientes');
        dispatch('vendedores');
        dispatch('fornecedores');
      },

      async clientes({commit}) {
        // commit('LOADING_ON', null, {root: true});
        let clientes = await listasApi.clientes();
        commit('SET_CLIENTES', clientes);
        // commit('LOADING_OFF', null, {root: true});
      },

      async vendedores({commit}) {
        // commit('LOADING_ON', null, {root: true});
        let vendedores = await listasApi.vendedores();
        commit('SET_VENDEDORES', vendedores);
        // commit('LOADING_OFF', null, {root: true});
      },

      async fornecedores({commit}) {
        // commit('LOADING_ON', null, {root: true});
        let fornecedores = await listasApi.fornecedores();
        commit('SET_FORNECEDORES', fornecedores);
        // commit('LOADING_OFF', null, {root: true});
      },

      async prazos({commit}) {
        // commit('LOADING_ON', null, {root: true});
        let prazos = await listasApi.prazos();
        commit('SET_PRAZOS', prazos);
        // commit('LOADING_OFF', null, {root: true});
      },

      async bancos({commit}) {
        // commit('LOADING_ON', null, {root: true});
        let bancos = await listasApi.bancos();
        commit('SET_BANCOS', bancos);
        // commit('LOADING_OFF', null, {root: true});
      },
      async perfis({commit}) {
        // commit('LOADING_ON', null, {root: true});
        let perfis = await listasApi.perfis();
        commit('SET_PERFIS', perfis);
        // commit('LOADING_OFF', null, {root: true});
      },

      async funcionarios({commit}) {
        let funcionarios = await listasApi.funcionarios();
        commit('SET_FUNCIONARIOS', funcionarios);
      },

      async grupos({commit}) {
        let grupos = await listasApi.grupos();
        commit('SET_GRUPOS', grupos);
      },

      async subgrupos({commit}) {
        let subgrupos = await listasApi.subgrupos();
        commit('SET_SUBGRUPOS', subgrupos);
      },

      async tipos_funcionarios({commit}) {
        let tipos_funcionarios = await listasApi.tipos_funcionarios();
        commit('SET_TIPOS_FUNCIONARIOS', tipos_funcionarios);
      },

      async tipos_os({commit}) {
        let tipos_os = await listasApi.tipos_os();
        commit('SET_TIPOS_OS', tipos_os);
      },

      async tipos_clientes({commit}) {
        let tipos_clientes = await listasApi.tipos_clientes();
        commit('SET_TIPOS_CLIENTES', tipos_clientes);
      },

      async tipos_movimento({commit}) {
        let tipos_movimento = await listasApi.tipos_movimento();
        commit('SET_TIPOS_MOVIMENTO', tipos_movimento);
      },
    }
}
