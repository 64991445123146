import * as icon from '../../../app/icons';

export default [
  {
    id: 'todas',
    name: 'Acesso Total',
    icon: 'mdi-check-all',
    children: [
      {
        id: 'cadastros',
        name: 'Cadastros',
        icon: icon.CADASTROS,
        visible: true,
        dense: true,
        children: [
          {
            id: 'clientes',
            name: 'Clientes',
            icon: icon.CLIENTES,
            to: { name: 'ClientesLista' },
            permissao: 'clientes_acessar',
            disabled: false,
            children: [
              { id: 'clientes_acessar', name: 'Acessar', icon: icon.ACESSAR },
              { id: 'clientes_adicionar', name: 'Adicionar', icon: icon.ADICIONAR },
              { id: 'clientes_editar', name: 'Editar', icon: icon.EDITAR },
              { id: 'clientes_excluir', name: 'Excluir', icon: icon.EXCLUIR },
              { id: 'clientes_imprimir', name: 'Imprimir', icon: icon.IMPRIMIR },
            ],
          },
          {
            id: 'veiculos',
            name: 'Veículos',
            icon: icon.VEICULOS,
            to: { name: 'VeiculosLista' },
            permissao: 'veiculos_acessar',
            disabled: false,
            children: [
              { id: 'veiculos_acessar', name: 'Acessar', icon: icon.ACESSAR },
              { id: 'veiculos_adicionar', name: 'Adicionar', icon: icon.ADICIONAR },
              { id: 'veiculos_editar', name: 'Editar', icon: icon.EDITAR },
              { id: 'veiculos_excluir', name: 'Excluir', icon: icon.EXCLUIR },
            ],
          },
          {
            id: 'pecas',
            name: 'Peças',
            icon: icon.PECAS,
            to: { name: 'PecasLista' },
            permissao: 'pecas_acessar',
            disabled: false,
            children: [
              { id: 'pecas_acessar', name: 'Acessar', icon: icon.ACESSAR },
              { id: 'pecas_adicionar', name: 'Adicionar', icon: icon.ADICIONAR },
              { id: 'pecas_editar', name: 'Editar', icon: icon.EDITAR },
              { id: 'pecas_excluir', name: 'Excluir', icon: icon.EXCLUIR },
            ],
          },
          {
            id: 'servicos',
            name: 'Serviços',
            icon: icon.SERVICOS,
            to: { name: 'ServicosLista' },
            permissao: 'servicos_acessar',
            disabled: false,
            children: [
              { id: 'servicos_acessar', name: 'Acessar', icon: icon.ACESSAR },
              { id: 'servicos_adicionar', name: 'Adicionar', icon: icon.ADICIONAR },
              { id: 'servicos_editar', name: 'Editar', icon: icon.EDITAR },
              { id: 'servicos_excluir', name: 'Excluir', icon: icon.EXCLUIR },
            ],
          },
          {
            id: 'funcionarios',
            name: 'Funcionários',
            icon: icon.FUNCIONARIOS,
            to: { name: 'FuncionariosLista' },
            permissao: 'funcionarios_acessar',
            disabled: false,
            children: [
              { id: 'funcionarios_acessar', name: 'Acessar', icon: icon.ACESSAR },
              { id: 'funcionarios_adicionar', name: 'Adicionar', icon: icon.ADICIONAR },
              { id: 'funcionarios_editar', name: 'Editar', icon: icon.EDITAR },
              { id: 'funcionarios_excluir', name: 'Excluir', icon: icon.EXCLUIR },
            ],
          },
          {
            id: 'fornecedores',
            name: 'Fornecedores',
            icon: icon.FORNECEDORES,
            to: { name: 'FornecedoresLista' },
            permissao: 'fornecedores_acessar',
            disabled: false,
            children: [
              { id: 'fornecedores_acessar', name: 'Acessar', icon: icon.ACESSAR },
              { id: 'fornecedores_adicionar', name: 'Adicionar', icon: icon.ADICIONAR },
              { id: 'fornecedores_editar', name: 'Editar', icon: icon.EDITAR },
              { id: 'fornecedores_excluir', name: 'Excluir', icon: icon.EXCLUIR },
            ],
          }
        ],
      },
      {
        id: 'tabelas',
        name: 'Tabelas',
        icon: icon.TABELAS,
        visible: true,
        dense: true,
        children: [
          {
            id: 'bancos',
            name: 'Bancos',
            icon: icon.BANCOS,
            to: { name: 'BancosLista' },
            permissao: 'bancos_acessar',
            disabled: false,
            children: [
              { id: 'bancos_acessar', name: 'Acessar', icon: icon.ACESSAR },
              { id: 'bancos_adicionar', name: 'Adicionar', icon: icon.ADICIONAR },
              { id: 'bancos_editar', name: 'Editar', icon: icon.EDITAR },
              { id: 'bancos_excluir', name: 'Excluir', icon: icon.EXCLUIR },
            ],
          },
          {
            id: 'tiposclientes',
            name: 'Tipos de Cliente',
            icon: icon.TIPOS_CLIENTES,
            to: { name: 'TiposClientesLista' },
            permissao: 'tiposclientes_acessar',
            disabled: false,
            children: [
              { id: 'tiposclientes_acessar', name: 'Acessar', icon: icon.ACESSAR },
              { id: 'tiposclientes_adicionar', name: 'Adicionar', icon: icon.ADICIONAR },
              { id: 'tiposclientes_editar', name: 'Editar', icon: icon.EDITAR },
              { id: 'tiposclientes_excluir', name: 'Excluir', icon: icon.EXCLUIR },
            ],
          },
          {
            id: 'tiposfuncionarios',
            name: 'Tipos de Funcionário',
            icon: icon.TIPOS_FUNCIONARIOS,
            to: { name: 'TiposFuncionariosLista' },
            permissao: 'tiposfuncionarios_acessar',
            disabled: false,
            children: [
              { id: 'tiposfuncionarios_acessar', name: 'Acessar', icon: icon.ACESSAR },
              { id: 'tiposfuncionarios_adicionar', name: 'Adicionar', icon: icon.ADICIONAR },
              { id: 'tiposfuncionarios_editar', name: 'Editar', icon: icon.EDITAR },
              { id: 'tiposfuncionarios_excluir', name: 'Excluir', icon: icon.EXCLUIR },
            ],
          },
          {
            id: 'tiposos',
            name: 'Tipos de OS',
            icon: icon.TIPOS_OS,
            to: { name: 'TiposOSLista' },
            permissao: 'tiposos_acessar',
            disabled: false,
            children: [
              { id: 'tiposos_acessar', name: 'Acessar', icon: icon.ACESSAR },
              { id: 'tiposos_adicionar', name: 'Adicionar', icon: icon.ADICIONAR },
              { id: 'tiposos_editar', name: 'Editar', icon: icon.EDITAR },
              { id: 'tiposos_excluir', name: 'Excluir', icon: icon.EXCLUIR },
            ],
          },
          {
            id: 'grupospecas',
            name: 'Grupos de Peças',
            icon: icon.GRUPOS_PECAS,
            to: { name: 'GruposLista' },
            permissao: 'grupospecas_acessar',
            disabled: false,
            children: [
              { id: 'grupospecas_acessar', name: 'Acessar', icon: icon.ACESSAR },
              { id: 'grupospecas_adicionar', name: 'Adicionar', icon: icon.ADICIONAR },
              { id: 'grupospecas_editar', name: 'Editar', icon: icon.EDITAR },
              { id: 'grupospecas_excluir', name: 'Excluir', icon: icon.EXCLUIR },
            ],
          },
          {
            id: 'prazospagamentos',
            name: 'Prazos de Pagamento',
            icon: icon.PRAZOS_PAGAMENTOS,
            to: { name: 'PrazosLista' },
            permissao: 'prazospagamentos_acessar',
            disabled: false,
            children: [
              { id: 'prazospagamentos_acessar', name: 'Acessar', icon: icon.ACESSAR },
              { id: 'prazospagamentos_adicionar', name: 'Adicionar', icon: icon.ADICIONAR },
              { id: 'prazospagamentos_editar', name: 'Editar', icon: icon.EDITAR },
              { id: 'prazospagamentos_excluir', name: 'Excluir', icon: icon.EXCLUIR },
            ],
          },
          {
            id: 'formaspagamento',
            name: 'Formas de Pagamento',
            icon: icon.FORMAS_PAGAMENTOS,
            to: { name: 'FormasPagamentoLista' },
            permissao: 'formaspagamento_acessar',
            disabled: false,
            children: [
              { id: 'formaspagamento_acessar', name: 'Acessar', icon: icon.ACESSAR },
              { id: 'formaspagamento_adicionar', name: 'Adicionar', icon: icon.ADICIONAR },
              { id: 'formaspagamento_editar', name: 'Editar', icon: icon.EDITAR },
              { id: 'formaspagamento_excluir', name: 'Excluir', icon: icon.EXCLUIR },
            ],
          },
        ],
      },
      // {
      //   id: 'financeiro', name: 'Financeiro',
      //   children: [
      //     { id: 'contaspagar_acessar', name: 'Contas a Pagar' },
      //     { id: 'contasrecerber_acessar', name: 'Contas a Receber' },
      //     { id: 'caixa_acessar', name: 'Caixa Diário' },
      //   ]
      // },
      {
        id: 'movimentacoes',
        name: 'Movimentações',
        icon: icon.MOVIMENTACOES,
        visible: true,
        dense: true,
        children: [
          {
            id: 'ordens_servico',
            name: 'Ordens de Serviço',
            icon: icon.ORDENS_SERVICO,
            to: { name: 'OrdemServicoLista' },
            permissao: 'ordens_servico_acessar',
            disabled: false,
            children: [
              { id: 'ordens_servico_acessar', name: 'Acessar', icon: icon.ACESSAR },
              { id: 'ordens_servico_adicionar', name: 'Adicionar', icon: icon.ADICIONAR },
              { id: 'ordens_servico_editar', name: 'Editar', icon: icon.EDITAR },
              { id: 'ordens_servico_excluir', name: 'Excluir', icon: icon.EXCLUIR },
            ],
          },
          // { id: 'orcamentos_acessar', name: 'Orçamentos' },
          // { id: 'vendaspecas_acessar', name: 'Vendas de Peças' },
          {
            id: 'movimento_estoque',
            name: 'Movimento de Estoque',
            icon: icon.MOVIMENTO_ESTOQUE,
            to: { name: 'EstoqueLista' },
            permissao: 'movimento_estoque_acessar',
            disabled: false,
            children: [
              { id: 'movimento_estoque_acessar', name: 'Acessar', icon: icon.ACESSAR },
              { id: 'movimento_estoque_adicionar', name: 'Adicionar', icon: icon.ADICIONAR },
              { id: 'movimento_estoque_editar', name: 'Editar', icon: icon.EDITAR },
              { id: 'movimento_estoque_excluir', name: 'Excluir', icon: icon.EXCLUIR },
            ],
          },
        ],
      },
      {
        id: 'consultas',
        name: 'Consultas',
        icon: icon.CONSULTAS,
        visible: true,
        dense: true,
        children: [
          {
            id: 'comissoes_servicos_acessar',
            name: 'Comissões Serviços',
            icon: icon.CONSULTAS,
            to: { name: 'ComissoesServicos' },
            permissao: 'comissoes_servicos_acessar',
            disabled: false,
          },
          {
            id: 'comissoes_servicos_consolidada_acessar',
            name: 'Comissões Serviços Consolidada',
            icon: icon.CONSULTAS,
            to: { name: 'ComissoesServicosConsolidada' },
            permissao: 'comissoes_servicos_consolidada_acessar',
            disabled: false,
          },
        ],
      },
      {
        id: 'sistema',
        name: 'Sistema',
        icon: icon.SISTEMA,
        visible: true,
        dense: true,
        children: [
          {
            id: 'usuarios',
            name: 'Usuários',
            descricao: 'Cadastro de usuários do sistema',
            icon: icon.USUARIOS,
            to: { name: 'UsuariosLista' },
            permissao: 'usuarios_acessar',
            disabled: false,
            children: [
              { id: 'usuarios_acessar', name: 'Acessar', icon: icon.ACESSAR },
              { id: 'usuarios_adicionar', name: 'Adicionar', icon: icon.ADICIONAR },
              { id: 'usuarios_editar', name: 'Editar', icon: icon.EDITAR },
              { id: 'usuarios_excluir', name: 'Excluir', icon: icon.EXCLUIR },
            ],
          },
          {
            id: 'permissoes_acessar',
            name: 'Permissões',
            descricao: 'Permissões dos usuários',
            icon: icon.PERMISSOES,
            to: { name: 'Permissoes' },
            permissao: 'permissoes_acessar',
            disabled: false,
          },
          // {
          //   name: 'divider'
          // },
          {
            id: 'configuracoes_acessar',
            name: 'Configurações',
            descricao: 'Configurações do Sistema',
            icon: icon.SISTEMA,
            to: { name: 'ConfiguracoesSistema' },
            permissao: 'configuracoes_acessar',
            disabled: false,
          },
          // { id: 'configuracoes_acessar', name: 'Configurações' },
        ],
      },
    ],
  },
];
