import apiFormasPagamento from "@/app/services/formaspagamento";
import { PAGINATION_OPTIONS } from "@/app/vuetifyConfig";

export default {
  namespaced: true,
  state: {
    formapagamento: {
      id: '',
      descricao: ''
    },
    formaspagamento: [],
    busca: "",
    total_itens: 0,
    paginacao: {
      page: 1,
      itemsPerPage: 10,
      sortBy: ['descricao'],
      sortDesc: [false]
    }
  },

  mutations: {
    LISTAR(state, formaspagamento) {
      state.formaspagamento = formaspagamento;
    },

    ABRIR(state, formapagamento) {
      state.formapagamento = formapagamento;
    },

    ADICIONAR(state, formapagamento) {
      state.formaspagamento.push(formapagamento);
    },

    ATUALIZAR(state, formapagamento) {
      let index = state.formaspagamento.findIndex(peca_ => peca_.id == formapagamento.id);
      if (index > -1) {
        state.formaspagamento.splice(index, 1, formapagamento);
      }
    },

    EXCLUIR(state, id) {
      let index = state.formaspagamento.findIndex(formapagamento => formapagamento.id == id);
      if (index > -1) {
        state.formaspagamento.splice(index, 1);
      }
    },

    SET_BUSCA(state, busca) {
      state.busca = busca;
    },

    SET_PAGE(state, page) {
      state.paginacao.page = page;
    },

    SET_TOTAL_ITENS(state, total_itens) {
      state.total_itens = total_itens;
    },

    UPDATE_PAGINACAO(state, paginacao) {
      state.paginacao = paginacao;
    },

    ZERAR(state) {
      state.formapagamento = {
        id: '',
        descricao: ''
      };
    }
  },

  actions: {
    async listar({ commit, state }) {
      commit('LOADING_ON', null, { root: true });

      const { sortBy, sortDesc, page, itemsPerPage } = state.paginacao;

      const paginacao = {
        page, 
        itemsPerPage,
        sortBy: sortBy[0], 
        sortDesc: sortDesc[0]
      }

      if (state.busca) {
        paginacao.busca = state.busca;
      }

      let response = await apiFormasPagamento.listar(paginacao);
      commit('LISTAR', response.data);
      commit('SET_TOTAL_ITENS', response.total);
      commit('LOADING_OFF', null, { root: true });
    },

    async abrir({ commit }, id) {
      commit('LOADING_ON', null, { root: true });
      let formapagamento = await apiFormasPagamento.abrir(id);
      commit('ABRIR', formapagamento);
      commit('LOADING_OFF', null, { root: true });
    },

    async salvar({ commit }, formapagamento) {
      let novopeca = await apiFormasPagamento.salvar(formapagamento);
      commit('ADICIONAR', novopeca);
    },

    async atualizar({ commit }, formapagamento) {
      await apiFormasPagamento.atualizar(formapagamento);
      commit('ATUALIZAR', formapagamento);
    },

    async excluir({ commit }, id) {
      await apiFormasPagamento.excluir(id);
      commit('EXCLUIR', id);
    },

  }
}
