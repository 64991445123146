<template>
  <div>
    <v-text-field 
      v-bind="$v.TEXT_FIELDS" 
      v-model="ordemservico.cliente_id"
      ref="cliente_id" 
      label="Código Cliente" 
      append-icon="mdi-magnify"
      hint="F8 - Dialogo Busca"
      placeholder=" "
      :rules="[regras.campoObrigtorio]"
      :loading="buscando_veiculo" 
      :disabled="buscando_veiculo"
      @click:append="$refs.DialogoBuscarClientes.abrir()" 
      @keyup.enter="buscarClientePeloCodigo"
      @keyup.f8="$refs.DialogoBuscarClientes.abrir()"
      @focus="event => event.target.select()"
    ></v-text-field>

    <z-dialogo ref="dialogo" :titulo="titulo" scrollable dividers no-padding largura="800px" altura="383px">

      <template v-slot:prepend>
        <v-toolbar flat color="white">
          <v-text-field
            ref="busca"
            v-model="busca"
            rounded
            filled
            single-line
            dense
            label="Pesquisar"
            clearable
            hide-details
            prepend-inner-icon="mdi-magnify"
            @keydown.enter="onBuscarKeydownEnter"
            @focus="event => {event.target.select(); is_busca_focused = true}"
            @blur="is_busca_focused = false"
            @click:clear="limparBusca"
          ></v-text-field>
        </v-toolbar>
      </template>

      <template v-slot:texto>
        <v-data-table
          mobile-breakpoint="0"
          disable-pagination
          disable-sort
          hide-default-footer
          :headers="headers"
          :items="itens"
          :loading="carregando"
          fixed-header
          height="383px"
        >
          <template v-slot:body="{ items: itens }">
            <tbody>
              <tr 
                v-for="(item, index) in itens" 
                ref="urls" 
                :key="index" 
                class="apontador noselect" 
                :class="{'active': isActive(index)}" 
                @click="onListClick(index)"
                @dblclick="selecionar(item)" 
              >
                <td>{{ item.id }}</td>
                <td>{{ item.nome }}</td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </template>

      <template v-slot:actions_left>
        <span class="text-subtitle-2">{{textoRodape}} {{ itens.length }}</span>
      </template>
      <template v-slot:actions_right>
        <v-btn color="primary" @click="fechar()">Fechar</v-btn>
      </template>

    </z-dialogo>

  </div>
</template>

<script>

  export default {
    name: 'DialogoBuscar',

    props: {
      titulo: {
        type: String,
        default: ''
      },

      textoRodape: {
        type: String,
        default: ''
      },

      apiUrl: {
        type: String,
        default: ''
      }
    },

    data() {
      return {
        itens: [],
        busca: '',
        headers: [
          { text: "Código", value: "id", width: "150px" },
          { text: "Nome", value: "Nome" },
        ],
        carregando: false,
        is_busca_focused: false,
        atual: 0

      };
    },

    methods: {

      teclas(event) {
        if (event.code == 'ArrowUp') {
          event.preventDefault();
          this.$refs.busca.blur();
          this.up();
        }

        if (event.code == 'ArrowDown') {
          event.preventDefault();
          this.$refs.busca.blur();
          this.down();
        }

        if (event.code == 'Enter') {
          event.preventDefault();
          if (!this.is_busca_focused) {
            this.selecionar(this.itens[this.atual]);
          }
        }
      },

      abrir() {
        this.$refs.dialogo.abrir();
        this.setFocus('busca', 300);
        window.addEventListener('keydown', this.teclas)
      },

      fechar() {
        this.$refs.dialogo.fechar();
        window.removeEventListener('keydown', this.teclas);
      },

      onBuscarKeydownEnter(event) {
        if (this.busca && this.busca.length > 0) {
          event.target.select();
          this.buscar();
        }
      },

      async buscar() {
        try {
          if (this.busca && this.busca.length > 0) {
            this.carregando = true;
            this.itens = await this.api(this.busca);
            this.atual = 0;
            setTimeout(() => {
              this.scrollList();
            }, 0);
          }
        } catch (error) {
          this.$eventbus.notificarErro('', error);
        } finally {
          this.carregando = false;
        }
      },

      api(busca) {
        return this.$axios.get(this.apiUrl, {params: {busca: busca}})
          .then(response => response.data);
      },
      
      selecionar(item) {
        this.$emit('busca:selecionar', item);
        this.fechar();
      },

      limparBusca() {
        this.busca = '';
        this.itens = [];
      },

      up() {
        if(this.atual > 0) {
          this.atual--;
          this.$refs.urls[this.atual].scrollIntoView({behavior: "instant", block: "center", inline: "nearest"});
        }
      },

      down() {
        if(this.atual < this.itens.length - 1) {
          this.atual++;
          this.scrollList();
        }
      },

      onListClick(index) {
        this.atual = index;
        this.scrollList();
      },

      isActive(index) {
        return index === this.atual;
      },

      scrollList() {
        this.$refs.urls[this.atual].scrollIntoView({behavior: "instant", block: "nearest", inline: "nearest"});
      }
    },
  }
</script>

<style scoped>

</style>