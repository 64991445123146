import createAxios from '@/app/core/AxiosCore';
import config from '@/app/config/app';

const axios = createAxios(config);

function listar(paginacao) {
  return axios.get(`formaspagamento`, {params: paginacao})
    .then(response => response.data);
}

function buscar(busca) {
  return axios.get(`formaspagamento/buscar?busca=${busca}`)
    .then(response => response.data);
}

function buscarPorCodigo(codigo) {
  return axios.get(`formaspagamento/buscarCodigo?codigo=${codigo}`)
    .then(response => response.data);
}

function abrir(id) {
  return axios.get(`/formaspagamento/${id}`)
    .then(response => response.data);
}

function salvar(formapagamento) {
  return axios.post('/formaspagamento', formapagamento)
    .then(response => response.data);
}

function atualizar(formapagamento) {
  return axios.put(`/formaspagamento/${formapagamento.id}`, formapagamento)
    .then(response => response.data)
}

function excluir(id) {
  return axios.delete(`/formaspagamento/${id}`)
    .then(response => response.data);
}


export default {
  listar,
  buscar,
  buscarPorCodigo,
  abrir,
  salvar,
  atualizar,
  excluir
}
