import createAxios from '@/app/core/AxiosCore';
import config from '@/app/config/app';

const axios = createAxios(config);

function listar(paginacao) {
  return axios.get(`tipofuncionario`, {params: paginacao})
    .then(response => response.data);
}

function buscar(busca) {
  return axios.get(`tipofuncionario/buscar?busca=${busca}`)
    .then(response => response.data);
}

function buscarPorCodigo(codigo) {
  return axios.get(`tipofuncionario/buscarCodigo?codigo=${codigo}`)
    .then(response => response.data);
}

function abrir(id) {
  return axios.get(`/tipofuncionario/${id}`)
    .then(response => response.data);
}

function salvar(tipofuncionario) {
  return axios.post('/tipofuncionario', tipofuncionario)
    .then(response => response.data);
}

function atualizar(tipofuncionario) {
  return axios.put(`/tipofuncionario/${tipofuncionario.id}`, tipofuncionario)
    .then(response => response.data)
}

function excluir(id) {
  return axios.delete(`/tipofuncionario/${id}`)
    .then(response => response.data);
}


export default {
  listar,
  buscar,
  buscarPorCodigo,
  abrir,
  salvar,
  atualizar,
  excluir
}
