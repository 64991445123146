import BancosLista from '@/pages/tabelas/bancos/BancosLista'

export default [
  {
    path: '/tabelas/bancos',
    name: 'BancosLista',
    component: BancosLista,
    meta: { permissao: 'bancos_acessar' }
  }
];
