import fornecedoresApi from "@/app/services/fornecedores";
import fornecedor from "@/app/store/models/fornecedor";
import { PAGINATION_OPTIONS } from "@/app/vuetifyConfig";

import { hoje } from "@/app/helpers/utils";

export default {
  namespaced: true,
  state: {
    fornecedor: {...fornecedor},
    fornecedores: [],
    busca: "",
    total_itens: 0,
    paginacao: PAGINATION_OPTIONS
  },

  mutations: {
    LISTAR(state, fornecedores) {
      state.fornecedores = fornecedores;
    },

    ABRIR(state, fornecedor) {
      state.fornecedor = fornecedor;
    },

    ADICIONAR(state, fornecedor) {
      state.fornecedores.push(fornecedor);
    },

    ATUALIZAR(state, fornecedor) {
      let index = state.fornecedores.findIndex(fornecedor_ => fornecedor_.id == fornecedor.id);
      if (index > -1) {
        state.fornecedores.splice(index, 1, fornecedor);
      }
    },

    EXCLUIR(state, id) {
      let index = state.fornecedores.findIndex(fornecedor => fornecedor.id == id);
      if (index > -1) {
        state.fornecedores.splice(index, 1);
      }
    },

    SET_BUSCA(state, busca) {
      state.busca = busca;
    },

    SET_PAGE(state, page) {
      state.paginacao.page = page;
    },

    SET_TOTAL_ITENS(state, total_itens) {
      state.total_itens = total_itens;
    },

    UPDATE_PAGINACAO(state, paginacao) {
      state.paginacao = paginacao;
    },

    ZERAR(state) {
      state.fornecedor = {...fornecedor};
      state.fornecedor.data_cadastro = hoje();
    }
  },

  actions: {
    async listar({ commit, state }) {
      commit('LOADING_ON', null, { root: true });

      const { sortBy, sortDesc, page, itemsPerPage } = state.paginacao;

      const paginacao = {
        page, 
        itemsPerPage,
        sortBy: sortBy[0], 
        sortDesc: sortDesc[0]
      }

      if (state.busca) {
        paginacao.busca = state.busca;
      }

      let response = await fornecedoresApi.listar(paginacao);
      commit('LISTAR', response.data);
      commit('SET_TOTAL_ITENS', response.total);
      commit('LOADING_OFF', null, { root: true });
    },

    async abrir({ commit }, id) {
      commit('LOADING_ON', null, { root: true });
      let fornecedor = await fornecedoresApi.abrir(id);
      commit('ABRIR', fornecedor);
      commit('LOADING_OFF', null, { root: true });
    },

    async salvar({ commit }, fornecedor) {
      let novofornecedor = await fornecedoresApi.salvar(fornecedor);
      commit('ADICIONAR', novofornecedor);
    },

    async atualizar({ commit }, fornecedor) {
      await fornecedoresApi.atualizar(fornecedor);
      commit('ATUALIZAR', fornecedor);
    },

    async excluir({ commit }, id) {
      await fornecedoresApi.excluir(id);
      commit('EXCLUIR', id);
    },

  }
}
