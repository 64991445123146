<template>
  <z-expansion-config
    titulo="Imagem PWA"
    subtitulo="Configura a imagem usada pelo sistema quando funcionado como PWA"
    expanded
  >
    <z-expansion-config-item
      no-space
      empty
      titulo="Imagem"
      :loading="isLoading"
    >
      <div class="d-flex flex-wrap align-end" style="gap: 10px">
        <WebAppImagesItem
          v-for="(image, index) in images"
          :key="index"
          :size="image.size"
          :size-description="image.description"
          :image="currentImage"
        ></WebAppImagesItem>
      </div>
    </z-expansion-config-item>

    <!-- <z-expansion-config-item no-space empty titulo="Imagem">
      <div class="d-flex flex-wrap align-end" style="gap: 10px">
        <WebAppImagesItem size="50px" :image="currentImage"></WebAppImagesItem>

        <div class="d-flex flex-column" v-for="n in 4" :key="n">
          <WebAppImagesItem
            :image="`https://picsum.photos/500/300?image=${n * 5 + 10}`"
            :lazy-image="`https://picsum.photos/10/6?image=${n * 5 + 10}`"
          ></WebAppImagesItem>
        </div>
      </div>
    </z-expansion-config-item> -->

    <z-expansion-config-item
      titulo="Escolher uma imagem (Dimensões: 1024x1024 px)"
      :loading="isLoading"
    >
      <input hidden @change="onFileChange" ref="captura" type="file" accept="image/*">
      <v-btn
        color="primary"
        class="text-capitalize"
        style="letter-spacing: 1px; font-weight: normal;"
        @click="$refs.captura.click()"
      >Abrir Imagem...</v-btn>
    </z-expansion-config-item>

    <CropDialog ref="cropDialog" :current-image="currentImage" @onCrop="onCrop"></CropDialog>
  </z-expansion-config>
</template>

<script setup>
import { ref } from 'vue';
import { useConfigStore } from '@/app/store/pinia/configuracoes'

import { zExpansionConfig } from "zcomponents";
import { zExpansionConfigItem } from "zcomponents";
import WebAppImagesItem from "./parts/WebAppImagesItem";
import CropDialog from "./parts/CropDialog";

const store = useConfigStore();

const isLoading = ref(false);
const cropDialog = ref(false);

function updateConfig() {
  store.conf.origin = 'WebAppImages';
}

const images =[
  {size: '150px', description: '512px'},
  {size: '110px', description: '256px'},
  {size: '82px', description: '192px'},
  {size: '60px', description: '32px'},
]

// const currentImage = ref("http://localhost:8000/img/icons/android-chrome-512x512.png");
const currentImage = ref();
const captura = ref();
async function onFileChange() {
  if (captura.value.files[0]) {
    const image = captura.value.files[0];
    updateImage(image);
  }
}

function updateImage(image) {
  let reader = new FileReader();

  reader.onload = async (event) => {
    currentImage.value = event.target.result;
    fotoUpload();
    // cropDialog.value.abrir();
  }

  reader.readAsDataURL(image);
}

async function fotoUpload(blob) {

  const form_data = new FormData();
  // form_data.set('image', captura.value.files[0]);
  form_data.set('image', blob, 'image.jpg');

  try {
    isLoading.value = true;
    const foto = await store.imageUpload(form_data);
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
  }
}

function onCrop(image) {
  currentImage.value = image.canvas;
  fotoUpload(image.blob);
}

</script>

<style lang="scss" scoped>

</style>
