export function clone(object) {
  return JSON.parse(JSON.stringify(object));
}

export function trunc(valor, decimais = 2) {
  let dec = Math.pow(10, decimais);
  return Number((Math.round(valor * dec) / dec).toFixed(decimais));
}

export function abrirJanela(url) {
  window.open(url, '_blank');
}

export function copyText(elemento_id) {
  window.getSelection().selectAllChildren(document.getElementById(elemento_id));
  document.execCommand('copy');
  window.getSelection().removeAllRanges();
}

export function hoje() {
  let data = new Date();
  return data.getFullYear() + '-' + (data.getMonth() + 1).toString().padStart(2, '0') + '-' + data.getDate().toString().padStart(2, '0');
}

export function hora() {
  return new Date().toLocaleTimeString().substr(0, 5);
}

export function setFocus(field, timeout = 0) {
  setTimeout(() => {
    field.focus();
  }, timeout);
}

export function selectText(field) {
  setTimeout(() => {
      this.field.selectionStart = 0;
      this.field.selectionEnd   = this.field.value.length;
  }, 0)
}

export function parsePermissoes(menu) {

  const permissoes_rotas = [];
  menu.forEach(item => {

    if (item.itens?.length > 0) {
      item.itens.forEach(element => {
        if (!element.disabled) {
          permissoes_rotas.push({
            name: element.to?.name,
            permissao: element.permissao
          });
        }
      });
    }
  })

  return permissoes_rotas;
}

export function range(start, end) {
  if (start > end) {
    return Array.from({length: start - end + 1}, (_, i) => start - i);
  } else {
    return Array.from({length: end - start + 1}, (_, i) => start + i);
  }
}

export function mesExtenso(mes = (new Date()).getMonth() - 1) {
  const meses = ["Janeiro","Fevereiro","Março","Abril","Maio","Junho","Julho","Agosto","Setembro","Outubro","Novembro","Dezembro"];
  return meses[mes - 1];
}
