<template>
  <v-app>

    <app-top-bar @navIconClick="drawer = !drawer"></app-top-bar>

    <app-drawer width="250" :drawer.sync="drawer"></app-drawer>

    <v-main class="grey lighten-4">

        <v-slide-y-transition hide-on-leave_ mode='out-in'>
          <router-view></router-view>
        </v-slide-y-transition>

    </v-main>

    <v-snackbar bottom v-model="snackbar.exibir" :timeout="snackbar.timeout" :color="snackbar.color">
      {{ snackbar.mensagem }}
      <template #actions>
        <v-btn dark text color="primary" @click.native="snackbar.exibir = false">Fechar</v-btn>
        <v-btn dark text color="primary" @click.native="snackbar.exibir = false">Detalhes</v-btn>
      </template>
    </v-snackbar>

    <v-snackbar absolute bottom center vertical :value="updateExists" :timeout="-1" color="primary">
      Uma nova atualização está disponível.
      <template v-slot:action>
        <v-btn small color="primary" @click="refreshApp">
          Atualizar Agora
        </v-btn>
      </template>
    </v-snackbar>

    <z-debug-info v-show="!config.IS_PRODUCTION"></z-debug-info>

    <z-notificacao ref="notificacao"></z-notificacao>
    <z-alerta ref="alerta" persistent></z-alerta>
    <z-dialogo-pergunta ref="dialogo_pergunta"></z-dialogo-pergunta>

    <Notifications ref="notifications"></Notifications>

    <z-dialogo-impressao
      ref="dialogoImpressao"
      titulo="Impressão"
    ></z-dialogo-impressao>

  </v-app>
</template>

<script>
import { mapState } from 'vuex';
import events from "@/app/mixins/events";
import config from "@/app/config/app";

import MenuPrincipal from "./menus/MenuPrincipal";
import AppTopBar from "./parts/AppTopBar";
import AppDrawer from "./parts/AppDrawer";
import AppUserMenu from "./parts/AppUserMenu";
import zDebugInfo from "@/components/zDebugInfo";

import Notifications from "./Notifications";

import { mapStores } from 'pinia'
import { useConfigStore } from '@/app/store/pinia/configuracoes'

export default {
  name: "AppLayoutTopBar",

  components: {
    MenuPrincipal,
    AppTopBar,
    AppDrawer,
    AppUserMenu,
    zDebugInfo
  },

  data() {
    return {
      config: config,
      drawer: false,
      right: true,
      rightDrawer: false,
      title: "Tela Inicial",
      snackbar: {
        exibir: false,
        mensagem: '',
        timeout: 3000,
        color: ''
      },
      menu_cadastros: [
        { texto: 'Clientes' },
        { texto: 'Fornecedores' },
        { texto: 'Vendedores' },
      ],
      updateExists: false,
      // dialogo_pergunta: null
    };
  },

  computed: {
    ...mapStores(useConfigStore),
    ...mapState({
      tituloTela: state => state.titulo_tela,
      loading: state => state.loading,
    }),
  },

  provide() {
    return {
      'testeProvider2': '12345',
      'rootRefs': this.$refs,
      $imprimir: this.imprimir,
    }
  },

  created() {
    document.addEventListener('SOFTWARE_UPDATE', this.updateAvailable);
    this.$vuetify.theme.themes.light.primary = this.$storage.get('APP_CORPADRAO', '#3f51b5');
  },

  async mounted() {

    document.title = 'Carregando...';

    await this.configStore.carregarConfiguracoes();
    await this.configStore.carregarListaConfiguracoes();

    this.$root.dialogo_pergunta = this.$refs.dialogo_pergunta;
    this.$store.dispatch('carregarConfiguracoes');
    events.start(this);

    document.title = this.configStore.configuracoes.APP_TITLE;

  },

  beforeDestroy() {
    events.stop();
  },

  methods: {
    refreshApp() {
      this.updateExists = false;
      // // Make sure we only send a 'skip waiting' message if the SW is waiting
      if (!this.registration || !this.registration.waiting) return
      // // send message to SW to skip the waiting and activate the new SW
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
      window.location.reload();
    },

    updateAvailable(event) {
      this.registration = event.detail;
      this.updateExists = true;

      console.log(event.detail.texto);
      // this.refreshApp();
    },

    async imprimir(url, nome_arquivo) {
      this.$refs.dialogoImpressao.abrir(url, nome_arquivo);
    },
  },
};
</script>

<script setup>

  import { ref, provide } from 'vue';

  const notifications = ref();
  provide('notifications', notifications);


</script>
