<template>
  <div>
    <v-text-field 
      v-bind="$v.TEXT_FIELDS" 
      ref="fornecedor_id" 
      label="Código Fornecedor"
      append-icon="mdi-magnify"
      hint="F8 - Para pesquisar"
      placeholder=" " 
      :loading="buscando" 
      :disabled="buscando"
      @click:append="$refs.dialogo.abrir()" 
      @keyup.enter="buscarFornecedorPeloCodigo"
      @focus="event => event.target.select()"
      @keyup.f8="$refs.dialogo.abrir()"
    ></v-text-field>

    <z-dialogo ref="dialogo" titulo="Fornecedores" scrollable dividers no-padding largura="800px" altura="383px" persistent>

      <template v-slot:prepend>
        <v-toolbar flat color="white">
          <v-text-field
            ref="busca"
            v-model="busca"
            rounded
            filled
            single-line
            dense
            label="Pesquisar"
            clearable
            hide-details
            prepend-inner-icon="mdi-magnify"
            @keydown.enter="buscar"
            @focus="onBuscaFocus"
            @click:clear="limparBusca"
          ></v-text-field>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon color="primary" class="ml-3 mr-1" v-on="on" @click="adicionar">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Adicionar Fornecedor</span>
          </v-tooltip>
        </v-toolbar>
      </template>

      <template v-slot:texto>
        <v-data-table
          disable-pagination
          disable-sort
          hide-default-footer
          :headers="headers"
          :items="fornecedores"
          :loading="carregando"
          fixed-header
          height="383px"
          mobile-breakpoint="0"
        >
          <template v-slot:item="{ item: fornecedor }">
            <tr @click="selecionar(fornecedor)" style="cursor: pointer">
              <td>{{ fornecedor.id }}</td>
              <td>{{ fornecedor.razao }}</td>
              <td>{{ fornecedor.telefone1 }}</td>
            </tr>
          </template>
        </v-data-table>
      </template>

      <template v-slot:actions_left>
        <span class="text-subtitle-2">Fornecedores encontrados: {{ fornecedores.length }}</span>
      </template>
      <template v-slot:actions_right>
        <v-btn color="primary" @click="fechar()">Fechar</v-btn>
      </template>

    </z-dialogo>
  </div>
</template>

<script>

import fornecedoresApi from "@/app/services/fornecedores";
import { abrirJanela } from "@/app/helpers/utils";


  export default {
    name: 'CampoBuscarFornecedor',

    data() {
      return {
        fornecedores: [],
        busca: '',
        headers: [
          { text: "Código", value: "id", width: "150px" },
          { text: "Nome", value: "razao" },
          { text: "Telefone", value: "telefone1" },
        ],
        carregando: false
      };
    },

    methods: {
      abrir() {
        this.$refs.dialogo.abrir();
        this.setFocus('busca', 300);
      },

      fechar() {
        this.$refs.dialogo.fechar();
      },

      async buscar(event) {
        try {
          // if (this.busca && this.busca.length > 0) {
            event.target.select();
            this.carregando = true;
            this.fornecedores = await fornecedoresApi.buscar(this.busca);
          // }
        } catch (error) {
          this.$eventbus.notificarErro(error);
        } finally {
          this.carregando = false;
        }
      },

      async buscarFornecedorPeloCodigo(event) {
        if (!this.peca.fornecedor_id) {
          this.abrir()
          return false;
        }

        try {
          this.buscando = true;
          let fornecedor = await fornecedoresApi.buscarPeloCodigo(this.peca.fornecedor_id);
          if (fornecedor) {
            this.selecionarFornecedor(fornecedor);
          }
        } catch (error) {
          this.$eventbus.notificarErro('Fornecedor não encontrado.', error);
          setFocus(this.$refs['fornecedor_id']);
          event.target.select();
        } finally {
          this.buscando = false;
        }
      },
      
      selecionar(fornecedor) {
        this.$emit('busca:selecionar', fornecedor);
        this.fechar();
      },

      onBuscaFocus(event) {
        event.target.select();
      },

      limparBusca() {
        this.busca = '';
        this.fornecedores = [];
        // this.buscar();
      },

      adicionar() {
        abrirJanela(window.location.origin + '/#/cadastros/fornecedores/adicionar');
      }
    },
  }
</script>

<style scoped>

</style>