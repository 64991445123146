<template>
  <div>

    <z-centralized-toolbar>
      <v-toolbar-items>
        <v-btn text color="primary" :to="{name: 'FornecedoresLista'}" exact>
          <v-icon left>mdi-arrow-left</v-icon>
          Voltar
        </v-btn>
      </v-toolbar-items>
      <v-spacer></v-spacer>
      <v-btn text @click="$router.push({ name: 'FornecedoresLista'})" :disabled="salvando">Cancelar</v-btn>
      <v-btn color="primary" @click="confirmar" :loading="salvando" :disabled="!form_valido">Salvar</v-btn>
    </z-centralized-toolbar>

    <v-divider></v-divider>

    <z-scroll-layout :top-position="$v.TOP_POSITION">
      <z-container>

        <z-cabecalho>{{$store.state.titulo_tela}}</z-cabecalho>

        <v-form ref="formulario" v-model="form_valido" lazy-validation>

          <v-card tile class="mb-6">

            <v-card-title class="text-subtitle-2">
              Dados do Fornecedor
            </v-card-title>
            <v-divider></v-divider>

              <v-card-text>
                <v-row dense>
                  <v-col cols="12" sm="2">
                    <z-text-field label="Código" v-model="fornecedor.id" readonly></z-text-field>
                  </v-col>
                  <v-col cols="12" sm="8">
                    <z-text-field upper label="Razão" v-model="fornecedor.razao" :rules="[regras.campoObrigatorio]"></z-text-field>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <z-date-picker-menu label="Cadastro" v-model="fornecedor.data_cadastro" readonly></z-date-picker-menu>
                  </v-col>
                  <!-- <v-col cols="12" sm="2">
                    <z-select :items="$globals.ESTADOS" label="Tipo Fornecedor" v-model="fornecedor.tipo"></z-select>
                  </v-col> -->
                  <v-col cols="12" sm="2">
                    <z-cep v-model="fornecedor.cep" :buscarcep="true" @resultado="preencherEndereco"></z-cep>
                  </v-col>

                  <v-col cols="12" sm="10">
                    <z-text-field upper label="Endereço" v-model="fornecedor.endereco"></z-text-field>
                  </v-col>

                  <v-col cols="12" sm="5">
                    <z-text-field upper label="Bairro" v-model="fornecedor.bairro"></z-text-field>
                  </v-col>

                  <v-col cols="12" sm="5">
                    <z-text-field upper label="Cidade" v-model="fornecedor.cidade" ></z-text-field>
                  </v-col>

                  <v-col cols="12" sm="2">
                    <z-select :items="$globals.ESTADOS" label="Estado" v-model="fornecedor.estado"></z-select>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" sm="3">
                    <z-text-field ref="telefone1" label="Telefone 1" v-model="fornecedor.telefone1" v-mask="$v.TELEFONE_MASK"></z-text-field>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <z-text-field ref="telefone2" label="Telefone 2" v-model="fornecedor.telefone2" v-mask="$v.TELEFONE_MASK"></z-text-field>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <z-text-field ref="telefone3" label="Telefone 3" v-model="fornecedor.telefone3" v-mask="$v.TELEFONE_MASK"></z-text-field>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <z-text-field ref="fax" label="Fax" v-model="fornecedor.fax" v-mask="$v.TELEFONE_MASK"></z-text-field>
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="12" sm="5">
                    <z-text-field label="E-mail" v-model="fornecedor.email"></z-text-field>
                  </v-col>

                  <v-col cols="12" sm="3">
                    <z-cpf-cnpj label="CNPJ" v-model="fornecedor.cpf_cnpj"></z-cpf-cnpj>
                  </v-col>

                  <v-col cols="12" sm="2">
                    <z-text-field label="Inscrição Estadual" v-model="fornecedor.inscricao_estadual"></z-text-field>
                  </v-col>

                  <v-col cols="12" sm="2">
                    <z-text-field label="Inscrição Municipal" v-model="fornecedor.inscricao_municipal"></z-text-field>
                  </v-col>

                </v-row>
              </v-card-text>
          </v-card>

        </v-form>

      </z-container>
    </z-scroll-layout>

  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { clone } from '@/app/helpers/utils';
import fornecedor from "@/app/store/models/fornecedor";

import * as regras from '@/app/helpers/validacoes';


export default {
    name: 'fornecedores-form',

    data() {
        return {
          fornecedor: {...fornecedor},
          salvando: false,
          regras: {},
          tab: 0  ,
          form_valido: true,
          regras: {...regras},
        };
    },

    computed: {
      titulo() {
        let titulo = (/Editar/).test(this.$route.name) ? 'Editar' : 'Adicionar';
        return titulo + ' ' + 'Fornecedor';
      }
    },

    async mounted() {
      this.setTitulo(this.titulo);

      if (this.$route.name == "FornecedoresEditar") {
        if (!this.$store.state.fornecedores.fornecedor.id) {
          if (this.$route.params.id) {
            await this.abrir(this.$route.params.id);
          }
        }
      }

      this.fornecedor = clone(this.$store.state.fornecedores.fornecedor);
    },

    methods: {
        ...mapMutations(["setTitulo"]),
        ...mapMutations("fornecedores", ["ZERAR"]),
        ...mapActions("fornecedores", ["abrir", "atualizar", "salvar"]),

        async confirmar() {
          if (!this.$refs.formulario.validate()) {
            return false;
          }

          try {
            this.salvando = true;

            if (this.$route.params.id) {
                await this.atualizar(this.fornecedor);
            } else {
                await this.salvar(this.fornecedor);
            }
            this.$router.push({ name: "FornecedoresLista" });
            this.$eventbus.notificar("Fornecedor salvo com sucesso");
          } catch(error) {
            this.$eventbus.notificarErro('Não foi possível salvar este Fornecedor. Verifique os dados preenchidos.', error);
            throw error;
          } finally {
            this.salvando = false;
          }
        },

        preencherEndereco(endereco) {
          if (endereco.erro) {
            this.fornecedor.endereco = "";
            this.fornecedor.bairro = "";
            this.fornecedor.cidade = "";
            this.fornecedor.estado = "";
          } else {
            this.fornecedor.endereco = endereco.logradouro.toUpperCase();
            this.fornecedor.bairro = endereco.bairro.toUpperCase();
            this.fornecedor.cidade = endereco.localidade.toUpperCase();
            this.fornecedor.estado = endereco.uf.toUpperCase();
            this.$refs.telefone1.focus();
          }
        }
    },

    watch: {
      'fornecedor.cpfcnpj'(val, oldval){
        if (val) {
          this.fornecedor.tipo_pessoa = val.length > 14 ? 'J' : 'F';
        }
      }
    }
};
</script>

<style scoped>

</style>
