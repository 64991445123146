import createAxios from '@/app/core/AxiosCore';
import config from '@/app/config/app';
import qs from 'qs';

const axios = createAxios(config);

function listar(paginacao) {
  return axios.get('clientes', { params: paginacao }).then((response) => response.data);
}

function filtrarLetra(letra) {
  return axios.get('/clientes', { params: { letra } }).then((response) => response.data);
}

function buscar(busca) {
  return axios.get(`clientes/buscar?busca=${busca}`).then((response) => response.data);
}

function buscarClientePeloCodigo(codigo) {
  return axios.get(`clientes/buscarCodigo?codigo=${codigo}`).then((response) => response.data);
}

function abrir(id) {
  return axios.get(`/clientes/${id}`).then((response) => response.data);
}

function salvar(cliente) {
  return axios.post('/clientes', cliente).then((response) => response.data);
}

function atualizar(cliente) {
  return axios.put(`/clientes/${cliente.id}`, cliente).then((response) => response.data);
}

function excluir(id) {
  return axios.delete(`/clientes/${id}`).then((response) => response.data);
}

export default {
  listar,
  filtrarLetra,
  buscar,
  buscarClientePeloCodigo,
  abrir,
  salvar,
  atualizar,
  excluir,
};
