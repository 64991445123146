import {version} from '../../package.json';

const APP_VERSION    = 'v' + version;
const APP_NAME       = process.env.VUE_APP_TITLE;
const APP_TITLE      = process.env.VUE_APP_TITLE;
const APP_PREFIX     = process.env.VUE_APP_PREFIX;
const IS_PRODUCTION  = process.env.NODE_ENV === 'production';
const BASE_URL       = process.env.VUE_APP_BASE_URL;
const BASE_URL_PRINT = process.env.VUE_APP_BASE_URL_PRINT;
const API_URL        = BASE_URL + '/api/v1';
const COOKIE_EXPIRATION_TIME = 0;// 24 * 60;
const STORAGE_ENCRYPT = false;

export default {
  APP_VERSION,
  APP_NAME,
  APP_TITLE,
  APP_PREFIX,
  BASE_URL_PRINT,
  BASE_URL,
  API_URL,
  IS_PRODUCTION,
  COOKIE_EXPIRATION_TIME,
  STORAGE_ENCRYPT
}
