<template>
  <div>
    <z-container>
      <z-titulo-tela>Configurações</z-titulo-tela>
      <PersonalizacaoSistema v-if="$auth.isSuperAdmin()"></PersonalizacaoSistema>
      <WebAppImages v-if="$auth.isSuperAdmin()"></WebAppImages>
      <CorPadrao></CorPadrao>
      <CadastroServicos v-if="$auth.isSuperAdmin()"></CadastroServicos>
      <!-- <EconomiaBateria></EconomiaBateria>
      <Hotspot></Hotspot> -->
    </z-container>

    <div style="margin-bottom: 200px"></div>


  </div>
</template>

<script setup>
import { computed, watch, watchEffect } from "vue";
// import { mapStores, mapActions, mapWritableState } from 'pinia'
import { useConfigStore } from '@/app/store/pinia/configuracoes'

import PersonalizacaoSistema from "./PersonalizacaoSistema";
import EconomiaBateria from "./EconomiaBateria";
import Hotspot from "./HotSpot";
import CorPadrao from "./CorPadrao";
import CadastroServicos from "./CadastroServicos";
import WebAppImages from "./WebAppImages";

const store = useConfigStore();

const configuracoes = computed(() => {
  return store.configuracoes;
})

const salvar = async (config) => {
  try {
    store.conf.loading = true;
    await store.salvarConfiguracoes(config);
  } catch (error) {
    console.log(error);
  } finally {
    store.conf.loading = false;
  }
}

let timer = null;
watch(configuracoes, (newValue) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      salvar(newValue);
    }, 1500);
},{ deep: true })

</script>

<style lang="scss" scoped>

</style>
