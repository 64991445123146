import { defineStore } from 'pinia'

import config from '@/app/config/app';
import createAxios from '@/app/core/AxiosCore';

const axios = createAxios(config);

export const useConfigStore = defineStore('config', {

  state: () => ({
    titulo_tela: config.APP_NAME,
    loading: false,
    origin: '',
    drawerRight: false,
    configuracoes: {},
    lista_configuracoes: [],
    drawer: true,
    novas_configuracoes: {},
    conf: {
      loading: false,
      origin: ''
    }
  }),

  actions: {
    carregarConfiguracoes() {
      axios.get('configuracoes')
        .then(response => {
          this.SET_CONFIGURACOES(response.data);
        });
    },

    carregarListaConfiguracoes() {
      return axios.get('configuracoes/lista')
        .then(response => {
          this.SET_LISTA_CONFIGURACOES(response.data);
          this.SET_NOVAS_CONFIGURACOES(response.data);
        });
    },

    atualizar(configuracao) {
      return axios.put('configuracoes', configuracao);
    },

    salvar(configuracao) {
      return axios.post('configuracoes', configuracao);
    },

    salvarConfiguracoes(configuracao) {
      return axios.put('configuracoes/atualizar', configuracao);
    },

    imageUpload(dados) {
      return axios.post('imagens/upload', dados, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      });
    },

    SET_CONFIGURACOES(configuracoes) {
     this.configuracoes = configuracoes
    },

    SET_LISTA_CONFIGURACOES(configuracoes) {
     this.lista_configuracoes = configuracoes
    },

    SET_NOVAS_CONFIGURACOES(configuracoes = []) {
      const new_config = {}
      configuracoes.forEach(config => {
        new_config[config.config] = config.valor;
      });

     this.novas_configuracoes = new_config
    }
  },
})
