import * as icon from "../../../app/icons";

export default [
  // {
  //   descricao: '',
  //   icon: 'mdi-home',
  //   to: {name: 'Home'}
  // },
  {
    descricao: 'Cadastros',
    grupo: 'cadastros',
    icon: icon.CADASTROS,
    visible: true,
    itens: [
      { text: 'Clientes', icon: icon.CLIENTES, to: {name: 'ClientesLista'}, permissao: 'clientes_acessar', disabled: false },
      { text: 'Funcionários', icon: icon.FUNCIONARIOS, to: {name: 'FuncionariosLista'}, permissao: 'funcionarios_acessar', disabled: false },
      { text: 'Fornecedores', icon: icon.FORNECEDORES, to: {name: 'FornecedoresLista'}, permissao: 'fornecedores_acessar', disabled: false },
      { text: 'Peças', icon: icon.PECAS, to: {name: 'PecasLista'}, permissao: 'pecas_acessar', disabled: false },
      { text: 'Serviços', icon: icon.SERVICOS, to: {name: 'ServicosLista'}, permissao: 'servicos_acessar', disabled: false },
      { text: 'Veículos', icon: icon.VEICULOS, to: {name: 'VeiculosLista'}, permissao: 'veiculos_acessar', disabled: false }

    ],
  },
  {
    descricao: 'Tabelas',
    grupo: 'tabelas',
    icon: icon.TABELAS,
    visible: true,
    itens: [
      { text: 'Bancos', icon: icon.BANCOS, to: {name: 'BancosLista'}, permissao: 'bancos_acessar', disabled: false },
      { text: 'Tipos de Cliente', icon: icon.TIPOS_CLIENTES, to: {name: 'TiposClientesLista'}, permissao: 'tiposclientes_acessar', disabled: false },
      { text: 'Tipos de Funcionários', icon: icon.TIPOS_FUNCIONARIOS, to: {name: 'TiposFuncionariosLista'}, permissao: 'tiposfuncionarios_acessar', disabled: false },
      { text: 'Tipos de OS', icon: icon.TIPOS_OS, to: {name: 'TiposOSLista'}, permissao: 'tiposos_acessar', disabled: false },
      { text: 'Grupos de Peças', icon: icon.GRUPOS_PECAS, to: {name: 'GruposLista'}, permissao: 'grupospecas_acessar', disabled: false },
      { text: 'Prazos de Pagamento', icon: icon.PRAZOS_PAGAMENTOS, to: {name: 'PrazosLista'}, permissao: 'prazospagamentos_acessar', disabled: false },
      { text: 'Formas de Pagamento', icon: icon.FORMAS_PAGAMENTOS, to: {name: 'FormasPagamentoLista'}, permissao: 'formaspagamento_acessar', disabled: false }
    ]
  },
  {
    descricao: 'Financeiro',
    grupo: 'financeiro',
    icon: icon.FINANCEIRO,
    visible: false,
    itens: [
      { text: 'Contas a Pagar', icon: 'mdi-arrow-left-bold', to: '', permissao: '', disabled: true },
      { text: 'Contas a Receber', icon: 'mdi-arrow-right-bold', to: '', permissao: '', disabled: true },
      { text: 'Caixa Diário', icon: 'mdi-currency-usd', to: '', permissao: '', disabled: true }
    ]
  },
  {
    descricao: 'Movimentações',
    grupo: 'movimentacao',
    icon: icon.MOVIMENTACOES,
    visible: true,
    itens: [
      { text: 'Ordens de Serviço', icon: icon.ORDENS_SERVICO, to: {name: 'OrdemServicoLista'}, permissao: 'ordens_servico_acessar', disabled: false },
      // { text: 'Orçamentos', icon: 'mdi-card-account-details', to: '', permissao: '', disabled: true },
      // { text: 'Venda de Peças', icon: 'mdi-car', to: '', permissao: '', disabled: true },
      // { text: 'divider' },
      { text: 'Movimento de Estoque', icon: icon.MOVIMENTO_ESTOQUE, to: {name: 'EstoqueLista'}, permissao: 'movimento_estoque_acessar', disabled: false }
    ]
  },
  {
    descricao: 'Consultas',
    grupo: 'consultas',
    icon: icon.CONSULTAS,
    visible: true,
    itens: [
      { text: 'Comissões Serviços', icon: icon.CONSULTAS, to: {name: 'ComissoesServicos'}, permissao: 'comissoes_servicos_acessar', disabled: false },
      { text: 'Comissões Serviços - Consolidado', icon: icon.CONSULTAS, to: {name: 'ComissoesServicosConsolidada'}, permissao: 'comissoes_servicos_consolidada_acessar', disabled: false },
      // { text: 'Folha de Pagamento', icon: 'mdi-filter', to: {name: 'Permissoes'}, permissao: 'permissoes_acessar', disabled: true },
      // { text: 'Configurações', icon: 'mdi-cog', to: {name: 'Configuracoes'}, permissao: 'configuracoes_acessar', disabled: true }
    ]
  },
  {
    descricao: 'divider',
  },
  {
    descricao: 'Sistema',
    grupo: 'sistema',
    icon: icon.SISTEMA,
    visible: true,
    itens: [
      { text: 'Usuários 13', descricao: 'Cadastre usuários do sistema', icon: icon.USUARIOS, to: {name: 'UsuariosLista'}, permissao: 'usuarios_acessar', disabled: false },
      { text: 'Permissões', descricao: 'Altere permissões dos usuários', icon: icon.PERMISSOES, to: {name: 'Permissoes'}, permissao: 'permissoes_acessar', disabled: false },
      // { text: 'Configurações', icon: 'mdi-cog', to: {name: 'Configuracoes'}, permissao: 'configuracoes_acessar', disabled: true }
    ]
  }
];
