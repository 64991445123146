import ComissoesServicos from '@/pages/consultas/comissoes/ComissoesServicos'
import ComissoesServicosConsolidado from '@/pages/consultas/comissoes/ComissoesServicosConsolidado'

export default [
  {
    path: '/consultas/comissoesservicos',
    name: 'ComissoesServicos',
    component: ComissoesServicos,
    meta: { permissao: 'comissoes_servicos_acessar' }
  },

  {
    path: '/consultas/comissoesservicosconsolidada',
    name: 'ComissoesServicosConsolidada',
    component: ComissoesServicosConsolidado,
    meta: { permissao: 'comissoes_servicos_consolidada_acessar' }
  }
];
