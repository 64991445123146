<template>
  <v-dialog v-model="exibir" :max-width="largura" v-bind="$attrs" @keydown="onkeydown">
    <v-card>
      <!-- <v-card-title v-show="titulo" class="text-subtitle-2 pa-4">
        {{ titulo }}
        <v-spacer v-if="$slots.titlebuttons"></v-spacer>
        <slot name="titlebuttons"></slot>
      </v-card-title> -->

      <v-toolbar flat style="max-height: 64px;" :color="color" :dark="dark">
        <v-toolbar-title>{{ titulo }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <!-- <template v-if="$slots.titlebuttons"> -->
          <slot name="titlebuttons"></slot>
        <!-- </template> -->

        <!-- <template v-else> -->
          <v-btn icon @click="fechar">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        <!-- </template> -->
      </v-toolbar>

      <v-divider v-if="dividers"></v-divider>

      <slot name="prepend"></slot>

      <v-divider v-if="$slots.prepend"></v-divider>

      <v-card-text :style="alturaCard" :class="classes" v-if="$slots.conteudo || $slots.texto">
        <slot name="texto">
          {{ texto }}
        </slot>
        <slot name="conteudo">
          {{ texto }}
        </slot>
      </v-card-text>

      <v-divider v-if="dividers"></v-divider>

      <slot name="append"></slot>

      <v-divider v-if="$slots.append"></v-divider>

      <v-card-actions v-if="$slots.actions_left || $slots.actions_right">
      <!-- <v-card-actions> -->
        <slot name="actions_left"></slot>
        <v-spacer></v-spacer>
        <slot name="actions_right"></slot>
        <slot></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "z-dialogo",

  props: {
    titulo: {
      type: String,
      default: null,
    },
    texto: {
      type: String,
      default: null,
    },
    largura: {
      type: String,
      default: "400px",
    },
    dividers: {
      type: Boolean,
      default: false,
    },
    altura: {
      type: String,
      default: null,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: null,
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      exibir: false,
    };
  },

  methods: {
    abrir() {
      this.exibir = true;
      this.$emit("abrir");
    },

    fechar() {
      this.exibir = false;
      this.$emit("fechar");
    },

    onkeydown(event) {
      if (event.key === "Escape") {
        this.fechar();
      }
    },
  },

  computed: {
    alturaCard() {
      if (this.altura) {
        return "height: " + this.altura;
      }
      return "";
    },
    classes() {
      return {
        "pa-0": this.noPadding == true,
        "pa-4": this.noPadding == false,
      };
    },
  },
};
</script>

<style scoped></style>
