import funcionariosApi from "@/app/services/funcionarios";
import funcionario from "@/app/store/models/funcionario";
import { PAGINATION_OPTIONS } from "@/app/vuetifyConfig";

export default {
  namespaced: true,
  state: {
    funcionario: {...funcionario},
    funcionarios: [],
    busca: "",
    total_itens: 0,
    paginacao: PAGINATION_OPTIONS
  },

  mutations: {
    LISTAR(state, funcionarios) {
      state.funcionarios = funcionarios;
    },

    ABRIR(state, funcionario) {
      state.funcionario = funcionario;
    },

    ADICIONAR(state, funcionario) {
      state.funcionarios.push(funcionario);
    },

    ATUALIZAR(state, funcionario) {
      let index = state.funcionarios.findIndex(funcionario_ => funcionario_.id == funcionario.id);
      if (index > -1) {
        state.funcionarios.splice(index, 1, funcionario);
      }
    },

    EXCLUIR(state, id) {
      let index = state.funcionarios.findIndex(funcionario => funcionario.id == id);
      if (index > -1) {
        state.funcionarios.splice(index, 1);
      }
    },

    SET_BUSCA(state, busca) {
      state.busca = busca;
    },

    SET_PAGE(state, page) {
      state.paginacao.page = page;
    },

    SET_TOTAL_ITENS(state, total_itens) {
      state.total_itens = total_itens;
    },

    UPDATE_PAGINACAO(state, paginacao) {
      state.paginacao = paginacao;
    },

    ZERAR(state) {
      state.funcionario = {...funcionario};
      state.funcionario.tipo_funcionario_id = 3;
    }
  },

  actions: {
    async listar({ commit, state }) {
      commit('LOADING_ON', null, { root: true });

      const { sortBy, sortDesc, page, itemsPerPage } = state.paginacao;

      const paginacao = {
        page, 
        itemsPerPage,
        sortBy: sortBy[0], 
        sortDesc: sortDesc[0]
      }

      if (state.busca) {
        paginacao.busca = state.busca;
      }

      let response = await funcionariosApi.listar(paginacao);
      commit('LISTAR', response.data);
      commit('SET_TOTAL_ITENS', response.total);
      commit('LOADING_OFF', null, { root: true });
    },

    async abrir({ commit }, id) {
      commit('LOADING_ON', null, { root: true });
      let funcionario = await funcionariosApi.abrir(id);
      commit('ABRIR', funcionario);
      commit('LOADING_OFF', null, { root: true });
    },

    async salvar({ commit }, funcionario) {
      let novofuncionario = await funcionariosApi.salvar(funcionario);
      commit('ADICIONAR', novofuncionario);
    },

    async atualizar({ commit }, funcionario) {
      await funcionariosApi.atualizar(funcionario);
      commit('ATUALIZAR', funcionario);
    },

    async excluir({ commit }, id) {
      await funcionariosApi.excluir(id);
      commit('EXCLUIR', id);
    },

  }
}
