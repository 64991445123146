import p from '../../../package.json';

const APP_VERSION            = 'v' + p.version;
// const APP_NAME               = process.env.VUE_APP_TITLE;
// const APP_TITLE              = 'Panorama Centro Automotivo';
// const APP_SUBTITLE           = 'CONTROLE DE ORDEM DE SERVIÇO';
const APP_PREFIX             = 'OFICINA_';
const IS_PRODUCTION          = process.env.NODE_ENV === 'production';
const BASE_URL               = window.location.origin; //process.env.VUE_APP_BASE_URL;
const BASE_URL_PRINT         = process.env.VUE_APP_BASE_URL_PRINT;
const API_URL                = BASE_URL + '/api/v1';
const COOKIE_EXPIRATION_TIME = 0;// 24 * 60;
const STORAGE_ENCRYPT        = false;

export default {
  APP_VERSION,
  // APP_NAME,
  // APP_TITLE,
  // APP_SUBTITLE,
  APP_PREFIX,
  BASE_URL_PRINT,
  BASE_URL,
  API_URL,
  IS_PRODUCTION,
  COOKIE_EXPIRATION_TIME,
  STORAGE_ENCRYPT
}
