import apiTiposFuncionarios from "@/app/services/tiposfuncionarios";
import { PAGINATION_OPTIONS } from "@/app/vuetifyConfig";

export default {
  namespaced: true,
  state: {
    tipofuncionario: {
      id: '',
      descricao: ''
    },
    tiposfuncionarios: [],
    busca: "",
    total_itens: 0,
    paginacao: {
      page: 1,
      itemsPerPage: 10,
      sortBy: ['descricao'],
      sortDesc: [false]
    }
  },

  mutations: {
    LISTAR(state, tiposfuncionarios) {
      state.tiposfuncionarios = tiposfuncionarios;
    },

    ABRIR(state, tipofuncionario) {
      state.tipofuncionario = tipofuncionario;
    },

    ADICIONAR(state, tipofuncionario) {
      state.tiposfuncionarios.push(tipofuncionario);
    },

    ATUALIZAR(state, tipofuncionario) {
      let index = state.tiposfuncionarios.findIndex(peca_ => peca_.id == tipofuncionario.id);
      if (index > -1) {
        state.tiposfuncionarios.splice(index, 1, tipofuncionario);
      }
    },

    EXCLUIR(state, id) {
      let index = state.tiposfuncionarios.findIndex(tipofuncionario => tipofuncionario.id == id);
      if (index > -1) {
        state.tiposfuncionarios.splice(index, 1);
      }
    },

    SET_BUSCA(state, busca) {
      state.busca = busca;
    },

    SET_PAGE(state, page) {
      state.paginacao.page = page;
    },

    SET_TOTAL_ITENS(state, total_itens) {
      state.total_itens = total_itens;
    },

    UPDATE_PAGINACAO(state, paginacao) {
      state.paginacao = paginacao;
    },

    ZERAR(state) {
      state.tipofuncionario = {
        id: '',
        descricao: ''
      };
    }
  },

  actions: {
    async listar({ commit, state }) {
      commit('LOADING_ON', null, { root: true });

      const { sortBy, sortDesc, page, itemsPerPage } = state.paginacao;

      const paginacao = {
        page, 
        itemsPerPage,
        sortBy: sortBy[0], 
        sortDesc: sortDesc[0]
      }

      if (state.busca) {
        paginacao.busca = state.busca;
      }

      let response = await apiTiposFuncionarios.listar(paginacao);
      commit('LISTAR', response.data);
      commit('SET_TOTAL_ITENS', response.total);
      commit('LOADING_OFF', null, { root: true });
    },

    async abrir({ commit }, id) {
      commit('LOADING_ON', null, { root: true });
      let tipofuncionario = await apiTiposFuncionarios.abrir(id);
      commit('ABRIR', tipofuncionario);
      commit('LOADING_OFF', null, { root: true });
    },

    async salvar({ commit }, tipofuncionario) {
      let novopeca = await apiTiposFuncionarios.salvar(tipofuncionario);
      commit('ADICIONAR', novopeca);
    },

    async atualizar({ commit }, tipofuncionario) {
      await apiTiposFuncionarios.atualizar(tipofuncionario);
      commit('ATUALIZAR', tipofuncionario);
    },

    async excluir({ commit }, id) {
      await apiTiposFuncionarios.excluir(id);
      commit('EXCLUIR', id);
    },

  }
}
