<template>
  <div>
    <z-centralized-toolbar>
      <v-toolbar-items>
        <v-btn id="btn-adicionar" text small color="primary" @click="adicionar" :disabled="!$auth.can('clientes_adicionar')">
          <v-icon left>{{ $icon.CLIENTES }}</v-icon
          >Adicionar Cliente
          <v-tooltip bottom activator="#btn-adicionar">
            Adicionar Cliente
          </v-tooltip>
        </v-btn>

        <v-btn id="btn-atualizar" text small @click="refresh" :loading="$store.state.loading">
          <v-icon>mdi-refresh</v-icon>
          <v-tooltip bottom activator="#btn-atualizar">
            Atualizar
          </v-tooltip>
        </v-btn>
      </v-toolbar-items>

      <!-- <v-spacer></v-spacer> -->

      <z-text-field-search v-model="busca" select class="ml-5" @keydown.enter="buscar" @click:clear="limparBusca"></z-text-field-search>
    </z-centralized-toolbar>

    <v-divider></v-divider>

    <z-scroll-layout :top-position="$v.TOP_POSITION">
      <z-container>
        <v-card class="mb-4"> </v-card>

        <z-cabecalho>{{ $store.state.titulo_tela }}</z-cabecalho>

        <v-card>
          <z-filtro-alfabetico v-model="letra" @input="filtrarLetra"></z-filtro-alfabetico>
          <v-divider></v-divider>
          <v-data-table
            mobile-breakpoint="0"
            item-key="id"
            :disable-sort="!!letra"
            :options.sync="paginacao"
            :server-items-length="total_itens"
            :footer-props="$v.FOOTER_PROPS"
            :headers="headers"
            :items="clientes"
          >
            <template v-slot:item="{ item: ordemservico }">
              <tr>
                <td>{{ ordemservico.id }}</td>
                <td>{{ ordemservico.nome }}</td>
                <td class="text-center">{{ ordemservico.data_cadastro | dataBR }}</td>
                <td class="text-no-wrap">{{ ordemservico.telefone }}</td>
                <td class="text-center text-no-wrap">{{ ordemservico.ordensservico_count }}</td>
                <td class="text-center">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn icon @click="ordensServico(ordemservico)" v-on="on">
                        <v-icon>mdi-format-list-checkbox</v-icon>
                      </v-btn>
                    </template>
                    <span>Ordens de Serviço</span>
                  </v-tooltip>
                  <v-btn icon @click="editar(ordemservico)" v-if="$auth.can('clientes_editar')">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn icon @click="perguntarExcluir(ordemservico)" v-if="$auth.can('clientes_excluir')">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </z-container>
    </z-scroll-layout>

    <z-dialogo-sim-nao ref="dialogoRemoverCliente" titulo="Remover cliente?" botaoSim="Remover" botaoNao="Cancelar" :loading="excluindo">
      O cliente será removido do sistema.
    </z-dialogo-sim-nao>

    <clientes-ordens-servico ref="dialogoOrdensServico" v-if="cliente.ordensservico != undefined"></clientes-ordens-servico>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from 'vuex';
import ClientesOrdensServico from './ClientesOrdensServico';
export default {
  name: 'ClientesLista',

  components: {
    ClientesOrdensServico,
  },

  data() {
    return {
      headers: [
        { text: 'Código', value: 'id', width: '120px' },
        { text: 'Nome', value: 'Nome', width: '100%' },
        { text: 'Cadastro', value: 'data_cadastro', align: 'center' },
        { text: 'Telefone', value: 'Telefone' },
        { text: 'Serviços', value: 'ordensservico_count', align: 'center' },
        { text: 'Opções', value: '', width: '200px', align: 'center', sortable: false },
      ],
      excluindo: false,
      // letra: null,
    };
  },

  computed: {
    ...mapState(['loading']),
    ...mapState('clientes', ['clientes', 'cliente', 'total_itens']),

    busca: {
      get() {
        return this.$store.state.clientes.busca;
      },
      set(busca) {
        this.$store.commit('clientes/SET_BUSCA', busca);
      },
    },

    letra: {
      get() {
        return this.$store.state.clientes.letra;
      },
      set(letra) {
        this.$store.commit('clientes/SET_LETRA', letra);
      },
    },

    paginacao: {
      get() {
        return this.$store.state.clientes.paginacao;
      },
      set(paginacao) {
        this.$store.commit('clientes/UPDATE_PAGINACAO', paginacao);
      },
    },
  },

  mounted() {
    this.setTitulo('Clientes');
  },

  methods: {
    ...mapMutations(['setTitulo', 'LOADING_OFF']),
    ...mapActions('clientes', ['listar', 'excluir', 'abrir']),

    async refresh() {
      try {
        await this.listar();
      } catch (error) {
        this.$eventbus.notificarErro('Não foi possível listar os clientes.', error);
      } finally {
        this.LOADING_OFF();
      }
    },

    buscar() {
      this.letra = null;
      this.$store.commit('clientes/SET_PAGE', 1);
      this.refresh();
    },

    async filtrarLetra() {
      this.busca = '';
      if (!this.letra) {
        this.paginacao.sortBy = ['id'];
        this.paginacao.sortDesc = [true];
      }
      await this.listar();
    },

    limparBusca() {
      this.busca = null;
      this.$store.commit('clientes/SET_PAGE', 1);
      this.refresh();
    },

    adicionar() {
      this.$router.push({ name: 'ClientesAdicionar' });
    },

    async editar(cliente) {
      try {
        await this.abrir(cliente.id);
        this.$router.push({ name: 'ClientesEditar', params: { id: cliente.id } });
      } catch (error) {
        console.log(error);
        this.$eventbus.notificarErro('Não foi possível abrir o cliente.', error);
      } finally {
        this.LOADING_OFF();
      }
    },

    perguntarExcluir(cliente) {
      this.$refs.dialogoRemoverCliente.abrir().then(async (response) => {
        if (response == true) {
          await this.confirmarExcluir(cliente);
          this.$refs.dialogoRemoverCliente.fechar();
        }
      });
    },

    async confirmarExcluir(cliente) {
      try {
        this.excluindo = true;
        await this.excluir(cliente.id);
        this.$eventbus.notificar('Cliente excluído com sucesso!');
      } catch (error) {
        this.$eventbus.notificar('Não foi possível excluir o cliente!');
        throw error;
      } finally {
        this.excluindo = false;
      }
    },

    async ordensServico(cliente) {
      await this.abrir(cliente.id);
      this.$refs.dialogoOrdensServico.abrir();
    },
  },

  watch: {
    paginacao: {
      handler() {
        this.refresh();
      },
      deep: true,
    },
  },
};
</script>

<style scoped></style>
