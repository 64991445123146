<template>
  <div id="app">
    <div id="container" ref="container" style="height: 450px; width: 950px"></div>
  </div>
</template>



<script>

import * as echarts from 'echarts';

let cores = ['#c23531','#2f4554', '#61a0a8', '#d48265', '#91c7ae','#749f83',  '#ca8622', '#bda29a','#6e7074', '#546570', '#c4ccd3'];

let backgroundStyle = {
  color: 'rgba(180, 180, 180, 0.2)'
}

const label_options =  {
  show: true,
  position: 'top',
  distance: 15,
  verticalAlign: 'middle',
  rotate: 90,
  fontSize: 12,
}

// const ECharts = window.echarts || undefined;

/* eslint-disable no-alert, no-console */
export default {
  name: 'BarChart',

  props: {
    dados: {
      type: Object,
      default: () => {}
    },
    selecionados: {
      type: Object,
       default: () => {
        return {};
      }
    },
  },

  data: () => ({
    myChart: {},
    options: {}
  }),

  computed: {
    xAxisData() {
      return this.dados.grafico_os.map(dado => dado.mes_ano);
    },

    serieOS() {
     return this.dados.grafico_os.map(dado => dado.total);
    },

    serieEntradas() {
     return this.dados.grafico_entradas.map(dado => dado.total);
    },
  },

  mounted () {
    this.init();
  },

  methods: {
    init() {
      // const dom = this.$refs.container;
      const dom = document.getElementById('container');
      this.myChart = echarts.init(dom);

      this.configurarGrafico();

      window.onresize = function() {
        this.myChart.resize();
      };
    },

    configurarGrafico() {
      this.options = {
        legend: {
          show: true,
          type: 'scroll',
          orient: 'horizontal',
        },
        color: ['#C23531','#FAC858', '#748EDE'],
        toolbox: {
          bottom: 'bottom',
          left: 'center',
          show: true,
          showTitle: true,
          feature: {
            dataZoom: {
              yAxisIndex: 'none',
              title: {
                back: 'Reiniciar Zoom'
              }
            },
            // dataView: {readOnly: false},
            magicType: {
              type: ['line', 'bar'],
              title: {
                line: 'Gráfico de Linhas',
                bar: 'Gráfico de Barras',
              }
            },
            restore: {
              title: 'Restaurar'
            },
            saveAsImage: {
              title: 'Download'
            }
          }
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            fontSize: 10
          },
          data: this.xAxisData
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            barGap: 0,
            name: 'Ordens Serviço',
            data: this.serieOS,
            type: 'bar',
            label: label_options,
            showBackground: true,
            backgroundStyle: backgroundStyle,
            itemStyle: {
              color: '#C23531'
            }
          },
          // {
          //   name: 'Vendas',
          //   data: this.serieData,
          //   type: 'bar',
          //   label: label_options,
          //   showBackground: true,
          //   backgroundStyle: backgroundStyle
          // },
          {
            name: 'Entradas',
            data: this.serieEntradas,
            type: 'bar',
            label: label_options,
            showBackground: true,
            backgroundStyle: backgroundStyle,
            itemStyle: {
              color: '#FAC858'
            }
          }
        ]
      }

      this.myChart.setOption(this.options, true);
    },

    configurarGrafico2() {
      let option = {
        color: ["#3398DB"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          }
        },
        grid: {},
        xAxis: [{
          type: "category",
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
        }],
        yAxis: [{
          type: "value",
          name: "uyffghfhgfjhg",
          nameGap: 29.5,
          position: "left"
        }],
        series: [{
          name: "直接访问",
          type: "bar",
          barWidth: "60%",
          data: [10, 52, 200, 334, 390, 330, 220]
        }]
      }

      this.myChart.setOption(option, true);
    }
  },

  watch: {
    dados(value, oldvalue) {
      this.configurarGrafico();
      // this.options.series[0].data = this.serieData;
      // this.options.xAxis.data = this.xAxisData;
      // this.options.legend.selected = this.selecionados;
      // this.myChart.setOption(this.options, true);
    }
  },
}
</script>

<style>
</style>