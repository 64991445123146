import Vue from "vue"

export default new Vue({
  methods: {

    notificar(mensagem){
      this.$emit('EXIBIR_NOTIFICACAO', mensagem)
    },

    alertar(mensagem){
      this.$emit('EXIBIR_ALERTA', mensagem)
    },

    notificarErro(message, error) {
      if (error && error.status == 401) {
        this.$emit('UNAUTHORIZED');
        return false;
      }

      this.$emit('EXIBIR_ERRO_SISTEMA', {
        message: message || '',
        error: error || null
      });

    }
  }
})
