export const TEXT_FIELDS = {
  outlined: false,
  filled: true,
  dense: true,
  placeholder: ' ',
  caps: 'upper',
};

export const FOOTER_PROPS = {
  'items-per-page-options': [5, 10, 15, 20, 30, 50],
};

export const PAGINATION_OPTIONS = {
  page: 1,
  itemsPerPage: 20,
  sortBy: ['id'],
  sortDesc: [true],
};

export const TELEFONE_MASK = ['(##)####-####', '(##)#####-####'];
export const CEP_MASK = '#####-###';
export const PLACA_MASK = 'AAA-#X##';
export const DISABLE_TRANSITIONS = false;
export const TOP_POSITION = 129;

export default {
  TEXT_FIELDS,
  FOOTER_PROPS,
  PAGINATION_OPTIONS,
  TELEFONE_MASK,
  CEP_MASK,
  PLACA_MASK,
  DISABLE_TRANSITIONS,
  TOP_POSITION,
};
