<template>
  <v-card flat>
    <v-toolbar dense flat tile color="white" v-if="ordemservico.aprovado != 1">
      <v-btn depressed small color="primary" @click="adicionarServico">Adicionar Serviço Terceiros</v-btn>
    </v-toolbar>

    <v-divider v-if="ordemservico.aprovado != 1"></v-divider>

    <v-card-text class="pa-0">
      <v-data-table mobile-breakpoint="0" hide-default-footer disable-pagination disable-sort :headers="headers" :items="ordemservico.servicos_terceiros" fixed-header height="383px">
        <template v-slot:item="{ item: servico, index }">
          <tr>
            <td>{{ index + 1 }}</td>
            <td>{{ servico.codigo }}</td>
            <td>{{ servico.descricao || '' }}</td>
            <td class="text-right">{{ servico.quantidade }}</td>
            <td class="text-right">{{ servico.valor | valorBR }}</td>
            <td class="text-right">{{ servico.subtotal | valorBR }}</td>
            <td class="text-center">
              <v-btn icon @click="editar(servico, index)" class="ma-0">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn v-show="ordemservico.aprovado != 1" icon @click="perguntarExcluir(index)" class="ma-0">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>
    <dialogo-buscar-servicos ref="DialogoBuscarServicos" @busca:selecionar="selecionarServico"></dialogo-buscar-servicos>

    <z-dialogo-sim-nao ref="dialogoRemover" titulo="Remover Serviço" botaoSim="Remover" botaoNao="Cancelar">
      O serviço será removido desta ordem de serviço.
    </z-dialogo-sim-nao>

    <z-dialogo ref="dialogoAdicionarServico" largura="900px" titulo="Serviço" dividers persistent>
      <template v-slot:conteudo>
        <v-form ref="formulario" v-model="form_valido" lazy-validation>
          <v-row dense>
            <v-col cols="12" sm="3">
              <v-text-field
                v-bind="$v.TEXT_FIELDS"
                v-model="servico.codigo"
                :rules="[regras.campoObrigatorio]"
                autofocus
                ref="codigo"
                label="Código"
                append-icon="mdi-magnify"
                hint="F8 - Para pesquisar"
                placeholder=" "
                :loading="buscando"
                :disabled="buscando"
                @click:append="$refs.DialogoBuscarServicos.abrir()"
                @keyup.enter="buscarServicoPorCodigo"
                @keyup.esc="onKeyUpEscAdicionarServico"
                @focus="(event) => event.target.select()"
                @keyup.f8="$refs.DialogoBuscarServicos.abrir()"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="9">
              <z-text-field v-model="servico.descricao" :rules="[regras.campoObrigatorio]" ref="descricao" label="Descrição" placeholder=" " readonly></z-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <z-campo-valor
                v-model="servico.quantidade"
                :rules="[regras.maiorQueZero]"
                :suffix="servico.unidade"
                ref="quantidade"
                label="Quantidade"
                placeholder=" "
                :decimais="0"
                @keyup.enter="$refs.valor.focus()"
                @keyup.esc="onKeyUpEscAdicionarServico"
                @input="calcularSubTotalServico"
              ></z-campo-valor>
            </v-col>
            <v-col cols="12" sm="3">
              <z-campo-valor
                v-model="servico.valor"
                :rules="[regras.maiorQueZero]"
                ref="valor"
                label="Valor"
                placeholder=" "
                @keyup.enter="$refs.data.focus()"
                @keyup.esc="onKeyUpEscAdicionarProduto"
                @input="calcularSubTotalServico"
              ></z-campo-valor>
            </v-col>
            <v-col cols="12" sm="3">
              <z-campo-valor v-model="servico.valor_custo" ref="valor_custo" label="Valor Custo" placeholder=" " readonly></z-campo-valor>
            </v-col>
            <v-col cols="12" sm="3">
              <z-campo-valor v-model="servico.subtotal" ref="subtotal_servico" label="SubTotal" placeholder=" " readonly></z-campo-valor>
            </v-col>

            <v-col cols="12" sm="3">
              <z-date-picker-menu v-model="servico.data" :rules="[regras.campoObrigatorio]" ref="data" label="Data" placeholder=" " @keyup.enter="confirmarServico"></z-date-picker-menu>
            </v-col>
          </v-row>
        </v-form>
      </template>

      <template v-slot:actions_right>
        <v-btn text @click=" editando_index = -1; $refs.dialogoAdicionarServico.fechar(); ">Cancelar</v-btn>
        <v-btn
          v-if="ordemservico.aprovado != 1"
          text
          color="primary"
          ref="btnConfirmar"
          :disabled="!form_valido"
          @click="confirmarServico"
        >
          {{ editando_index == -1 ? "Adicionar" : "Salvar" }}
        </v-btn>
      </template>
    </z-dialogo>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';

import servicosApi from '@/app/services/servicos';

import { trunc } from '@/app/helpers/conversoes';
import { clone } from '@/app/helpers/utils';
import '@/components/buscas';

import * as regras from '@/app/helpers/validacoes';

const servico = {
  codigo: '',
  descricao: '',
  quantidade: 0,
  valor: 0,
  subtotal: 0,
  data: '',
  valor_custo: 0,
};

export default {
  name: 'TabServicosTerceiros',
  props: {
    servicos: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      servico: { ...servico },
      headers: [
        { text: 'Item', value: '', width: '50px' },
        { text: 'Código', value: 'codigo' },
        { text: 'Descrição', value: 'descricao' },
        { text: 'Quantidade', value: 'quantidade', align: 'right' },
        { text: 'Valor', value: 'valor', align: 'right' },
        { text: 'SubTotal', value: 'subtotal', align: 'right' },
        { text: 'Opções', value: '', width: '200px', align: 'center' },
      ],
      buscando: false,
      editando_index: -1,
      regras: { ...regras },
      form_valido: true,
    };
  },

  computed: {
    ...mapState('ordemservico', ['ordemservico']),
  },

  methods: {
    selecionarServico(servico) {
      // console.log(JSON.stringify(servico));

      this.servico.codigo = servico.codigo;
      this.servico.descricao = servico.descricao;
      this.servico.quantidade = 1;
      this.servico.valor = servico.valor;
      this.servico.subtotal = this.servico.quantidade * servico.valor;

      // this.$refs.quantidade.focus();
      this.setFocus('quantidade');

      // this.ordemservico.servicos_terceiros.push(servico);
    },

    async buscarServicoPorCodigo() {
      if (!this.servico.codigo) {
        this.$refs.DialogoBuscarServicos.abrir();
        return;
      }

      try {
        this.buscando = true;
        let servico = await servicosApi.buscarServicoPorCodigo(this.servico.codigo);
        if (servico) {
          this.selecionarServico(servico);
        }
      } catch (error) {
        this.$refs.DialogoBuscarServicos.busca = this.servico.codigo;
        this.$refs.DialogoBuscarServicos.abrir();
        this.$refs.DialogoBuscarServicos.buscar();
        // this.$eventbus.notificarErro('Serviço não encontrado.', error);
        // this.setFocus('codigo');
        // this.selectText('codigo');
      } finally {
        this.buscando = false;
      }
    },

    confirmarServico() {
      if (!this.$refs.formulario.validate()) {
        return false;
      }

      let servico = clone(this.servico);
      // delete servico.estoque;

      if (this.estaAdicionado(servico.codigo, this.ordemservico.servicos_terceiros)) {
        this.$eventbus.alertar('Serviço já foi adicionado a lista de serviços.');
        return false;
      }

      if (this.editando_index > -1) {
        this.ordemservico.servicos_terceiros.splice(this.editando_index, 1, servico);
      } else {
        this.ordemservico.servicos_terceiros.push(servico);
      }
      // this.calcularTotais();

      this.zerarServico();
      this.$refs.formulario.resetValidation();
      this.setFocus('codigo');

      this.$emit('update:servicos');

      if (this.editando_index > -1) {
        this.$refs.dialogoAdicionarServico.fechar();
      }

      this.editando_index = -1;
      // this.hidedetails = true;
    },

    validarQuantidade() {
      if (this.servico.quantidade <= 0) {
        this.$eventbus.notificar('A quantidade não pode ser zero.');
        this.setFocus('quantidade');
        return false;
      }
      return true;
    },

    estaAdicionado(codigo, servicos) {
      if (this.editando_index > -1) {
        return false;
      }

      const index = servicos.findIndex((servico) => servico.codigo === codigo);
      return index > -1 ? true : false;
    },

    adicionarServico() {
      this.zerarServico();
      this.$refs.formulario.resetValidation();
      this.$refs.dialogoAdicionarServico.abrir();
    },

    calcularSubTotalServico() {
      this.servico.subtotal = trunc(Number(this.servico.quantidade) * Number(this.servico.valor));
    },

    onKeyUpEscAdicionarServico() {
      this.zerarServico();
      this.setFocus('codigo');
    },

    editar(servico, index) {
      this.editando_index = index;
      this.servico = clone(servico);
      this.$refs.dialogoAdicionarServico.abrir();
    },

    async perguntarExcluir(index) {
      let resposta = await this.$refs.dialogoRemover.abrir();

      if (resposta == true) {
        this.ordemservico.servicos_terceiros.splice(index, 1);
        this.$emit('update:servicos');
        this.$refs.dialogoRemover.fechar();
      }
    },

    zerarServico() {
      this.servico = { ...servico };
      this.servico.data = this.hoje();
    },
  },
};
</script>

<style scoped></style>
