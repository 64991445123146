<template>
  <div>
    <z-container>
      <z-cabecalho>{{ $store.state.titulo_tela }}</z-cabecalho>

      <v-form ref="formulario" v-model="form_valido" lazy-validation>
        <v-card>
          <v-toolbar flat color="white" dense>
            <h3 class="text-subtitle-2">Período</h3>
          </v-toolbar>
          <v-divider></v-divider>
          <v-card-text class="pb-0">
            <v-row dense>
              <v-col cols="12" sm="3">
                <z-date-picker-menu left v-model="filtro.data_inicial" label="Data Inicial" :rules="[regras.campoObrigatorio]"></z-date-picker-menu>
              </v-col>

              <v-col cols="12" sm="3">
                <z-date-picker-menu left v-model="filtro.data_final" label="Data Final" :rules="[regras.campoObrigatorio]"></z-date-picker-menu>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="imprimir" :disabled="!form_valido">
              <v-icon left>mdi-printer</v-icon>
              Imprimir
            </v-btn>
            <v-btn color="primary" @click="filtrar" :loading="filtrando" :disabled="!form_valido">Filtrar</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </z-container>

    <z-container>
      <z-cabecalho>Resultado</z-cabecalho>

      <v-card min-height="200px">
        <v-card-text class="pa-0">
          <v-data-table mobile-breakpoint="0" hide-default-footer disable-pagination disable-sort :headers="headers" :items="comissoes">
            <template v-slot:item="{ item: comissao }">
              <tr>
                <td>{{ comissao.id }}</td>
                <td>{{ comissao.nome }}</td>
                <!-- <td class="text-right">{{ comissao.salario | currencyBR }}</td> -->
                <td class="text-right">{{ comissao.total_servico_original | currencyBR }}</td>
                <td class="text-right">{{ comissao.total_servico | currencyBR }}</td>
                <td class="text-right">{{ comissao.total_comissao | currencyBR }}</td>
                <!-- <td class="text-right">{{ comissao.salario + comissao.total_comissao | currencyBR }}</td> -->
              </tr>
            </template>

            <template v-slot:body.append>
              <tr>
                <td colspan="3" class="text-right font-weight-bold">{{ total_servico_original | currencyBR }}</td>
                <td class="text-right font-weight-bold">{{ total_servico | currencyBR }}</td>
                <td class="text-right font-weight-bold">{{ total_comissao | currencyBR }}</td>
                <!-- <td>&nbsp;</td> -->
                <!-- <td class="text-right font-weight-bold">{{ total_salario | currencyBR}}</td> -->
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </z-container>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import { hoje, abrirJanela } from '@/app/helpers/utils';

import * as regras from '@/app/helpers/validacoes';

import consultasApi from '@/app/services/consultas';
import config from '@/app/config/app';

import qs from 'qs';

export default {
  data() {
    return {
      filtro: {
        data_inicial: '',
        data_final: '',
      },

      regras: { ...regras },
      form_valido: true,

      headers: [
        { text: 'Código', value: 'id', width: '60px' },
        { text: 'Nome', value: 'nome' },
        // { text: "Salário", value: "salario", align: 'right' },
        { text: 'Total Bruto', value: 'total_servico_original', align: 'right' },
        { text: 'Total Líquido', value: 'total_servico', align: 'right' },
        { text: 'Comissão', value: 'total_comissao', align: 'right' },
        // { text: "Total", value: "total", align: 'right' },
      ],
      comissoes: [],
      filtrando: false,
    };
  },

  computed: {
    ...mapState(['loading', 'titulo_tela']),
    ...mapGetters('listas', ['funcionarios']),

    total_servico_original() {
      return this.comissoes.reduce((soma, comissao) => soma + comissao.total_servico_original, 0);
    },

    total_servico() {
      return this.comissoes.reduce((soma, comissao) => soma + comissao.total_servico, 0);
    },

    total_comissao() {
      return this.comissoes.reduce((soma, comissao) => soma + comissao.total_comissao, 0);
    },

    total_salario() {
      return this.comissoes.reduce((soma, comissao) => soma + comissao.salario + comissao.total_comissao, 0);
    },
  },

  mounted() {
    this.setTitulo('Comissões de Serviços - Consolidado');
    // this.filtro.funcionario_id = 11;
    this.filtro.data_inicial = this.$dayjs()
      .startOf('month')
      .format('YYYY-MM-DD');
    this.filtro.data_final = hoje();
  },

  methods: {
    ...mapMutations(['setTitulo', 'LOADING_OFF']),

    async filtrar() {
      if (!this.$refs.formulario.validate()) {
        return false;
      }

      try {
        this.filtrando = true;
        this.comissoes = await consultasApi.comissoesServicosConsolidado(this.filtro);
      } catch (error) {
        this.$eventbus.notificarErro('', error);
        throw error;
      } finally {
        this.filtrando = false;
      }
    },

    imprimir() {
      if (!this.$refs.formulario.validate()) {
        return false;
      }

      let print_url = config.IS_PRODUCTION ? window.location.origin : config.BASE_URL_PRINT;
      abrirJanela(`${print_url}/consultas/comissoesservicosconsolidado/imprimir?` + qs.stringify(this.filtro));
    },
  },
};
</script>

<style lang="scss" scoped></style>
