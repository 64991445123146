<template>
  <div>

    <z-centralized-toolbar>
      <v-toolbar-items>
        <v-btn id="btn-adicionar" text small color="primary" @click="adicionar">
          <v-icon left>mdi-account-cog</v-icon>Adicionar
        </v-btn>

        <v-btn text id="btn-atualizar" @click="refresh" :loading="$store.state.loading">
          <v-icon>mdi-refresh</v-icon>
          <v-tooltip bottom activator="#btn-atualizar">
            Atualizar
          </v-tooltip>
        </v-btn>
      </v-toolbar-items>

      <!-- <v-spacer></v-spacer> -->

      <z-text-field-search
        v-model="busca"
        select
        class="ml-5"
        @keydown.enter="buscar"
        @click:clear="limparBusca"
      ></z-text-field-search>
    </z-centralized-toolbar>

    <v-divider></v-divider>

    <z-scroll-layout :top-position="$v.TOP_POSITION">
      <z-container>
            
        <z-cabecalho>{{$store.state.titulo_tela}}</z-cabecalho>
        
        <v-card>
          <!-- <v-toolbar flat color="white">
            <v-toolbar-items>
                
              <v-btn id="btn-adicionar" text small color="primary" @click="adicionar">
                <v-icon left>mdi-tools</v-icon>Adicionar Tipo
              </v-btn>

              <v-btn id="btn-atualizar" text small @click="refresh" :loading="$store.state.loading">
                <v-icon>mdi-refresh</v-icon>
                <v-tooltip bottom activator="#btn-atualizar">
                  Atualizar
                </v-tooltip>
              </v-btn>
            </v-toolbar-items>


            <div style="width: 300px;" class="ml-5">
              <z-text-field
                v-model="busca"
                rounded
                filled
                single-line
                dense
                label="Pesquisar"
                autofocus
                clearable
                hide-details
                prepend-inner-icon="mdi-magnify"
                @keydown.enter="buscar"
                @click:clear="limparBusca"
              ></z-text-field>
            </div>
          </v-toolbar>

          <v-divider></v-divider> -->

          <v-data-table
          mobile-breakpoint="0"
            :options.sync="paginacao" 
            :server-items-length="total_itens" 
            :footer-props="$v.FOOTER_PROPS" 
            :headers="headers"
            :items="tiposclientes"
            item-key="id"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.id }}</td>
                <td>{{ item.descricao }}</td>
                <td class="text-center">
                  <v-btn icon @click="editar(item)" class="ma-0">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn icon @click="perguntarExcluir(item)" class="ma-0">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
        
      </z-container>
    </z-scroll-layout>

    <z-dialogo-sim-nao ref="dialogoRemover" titulo="Remover tipo do cliente?" botaoSim="Remover" botaoNao="Cancelar" :loading="excluindo">
      A tipo do cliente será removido do sistema.
    </z-dialogo-sim-nao>

    <z-dialogo ref="dialogoAdicionar" largura="500px" titulo="Tipo Cliente" dividers persistent>
      <template v-slot:conteudo>
        <v-row>
          <v-col cols="12">
            <z-text-field caps="upper" v-model="tipocliente.descricao" ref="descricao" label="Descrição" placeholder=" " hide-details></z-text-field>
          </v-col>
        </v-row>
      </template>

      <template v-slot:actions_right>
        <v-btn text @click="$refs.dialogoAdicionar.fechar()">Cancelar</v-btn>
        <v-btn color="primary" ref="btnConfirmar" @click="confirmar">Confirmar</v-btn>
      </template>
    </z-dialogo>

  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from "vuex";
export default {
  name: 'TiposClientesLista',
  data() {
    return {
      headers: [
        { text: "Código", value: "id", width: "150px" },
        { text: "Descrição", value: "descricao" },
        { text: "Opções", value: "", width: "200px", align: "center", sortable: false },
      ],
      excluindo: false
    };
  },

  computed: {
    ...mapState(['loading']),
    ...mapState('tiposclientes', ['tiposclientes', 'tipocliente', 'total_itens']),

    busca: {
      get () {
        return this.$store.state.tiposclientes.busca
      },
      set (busca) {
        this.$store.commit('tiposclientes/SET_BUSCA', busca);
      }
    },

    paginacao: {
      get () {
        return this.$store.state.tiposclientes.paginacao
      },
      set (paginacao) {
        this.$store.commit('tiposclientes/UPDATE_PAGINACAO', paginacao);
      }
    },
  },

  created() {
    this.refresh();
  },

  mounted() {
    this.setTitulo("Tipos de Cliente");
  },

  methods: {
    ...mapMutations(["setTitulo", 'LOADING_OFF']),
    ...mapActions("tiposclientes", ["listar", "excluir", "abrir", "atualizar", "salvar"]),

    async refresh() {
      try {
        await this.listar();
      } catch (error) {
        console.log(error);
        this.$eventbus.notificarErro("Não foi possível listar os tipos de clientes.");
      }
    },

    buscar() {
      this.$store.commit('tiposclientes/SET_PAGE', 1);
      this.refresh();
    },
    
    limparBusca() {
      this.busca = null;
      this.$store.commit('tiposclientes/SET_PAGE', 1);
      this.refresh();
    },

    adicionar() {
      this.$store.commit('tiposclientes/ZERAR');
      this.$refs.dialogoAdicionar.abrir();
    },

    async editar(tipocliente) {
      try {

        await this.abrir(tipocliente.id);
        this.$refs.dialogoAdicionar.abrir();
        
      } catch (error) {
        console.log(error);
        this.$eventbus.notificarErro("Não foi possível abrir a tipo do cliente.");
      } finally {
        this.LOADING_OFF();
      }
    },

    async confirmar() {
      try {
        this.salvando = true;

        if (this.tipocliente.id) {
          await this.atualizar(this.tipocliente);
        } else {
          await this.salvar(this.tipocliente);
        }

        this.$refs.dialogoAdicionar.fechar();
        this.$eventbus.notificar("Tipo de cliente salvo com sucesso");
      } catch(error) {
        this.$eventbus.notificarErro('Não foi possível salvar esta tipo de cliente. Verifique os dados preenchidos.', error);
        throw error;
      } finally {
          this.salvando = false;
      }
    },

    perguntarExcluir(tipocliente) {
      this.$refs.dialogoRemover.abrir()
        .then(async response => {
          if (response == true) {
            await this.confirmarExcluir(tipocliente);
            this.$refs.dialogoRemover.fechar();
          }
        });
    },

    async confirmarExcluir(tipocliente) {
      try {
        this.excluindo = true;
        await this.excluir(tipocliente.id);
        this.$eventbus.notificar("Tipo excluído com sucesso!");
      } catch (error) {
        this.$eventbus.notificarErro("Não foi possível excluir o tipo do cliente!");
        throw error;
      } finally {
        this.excluindo = false;
      }
    },
  },

  watch: {
    paginacao: {
      handler() {
        this.refresh();
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
</style>
