import FolhaPagamentoLista from '@/pages/movimentacoes/folhapagamento/FolhaPagamentoLista'
import FolhaPagamentoForm from '@/pages/movimentacoes/folhapagamento/FolhaPagamentoForm'
import ProcessarFolhaPagamento from '@/pages/movimentacoes/folhapagamento/ProcessarFolhaPagamento'

export default [
  {
    path: '/movimentacao/folhapagamento',
    name: 'FolhaPagamentoLista',
    component: FolhaPagamentoLista,
    meta: { permissao: 'folhapagamento:acessar' }
  },
  {
    path: '/movimentacao/folhapagamento/adicionar',
    name: 'FolhaPagamentoAdicionar',
    component: FolhaPagamentoForm,
    meta: { permissao: 'folhapagamento:adicionar' }
  },
  {
    path: '/movimentacao/folhapagamento/:id',
    name: 'ProcessarFolhaPagamento',
    component: ProcessarFolhaPagamento,
    meta: { permissao: 'folhapagamento:processar' }
  },
];
