<template>
  <v-form v-model="form_valido" lazy-validation ref="formulario">

    <z-centralized-toolbar>
      <v-toolbar-items>
        <v-btn text color="primary" :to="{ name: 'FolhaPagamentoLista' }" exact>
          <v-icon left>mdi-arrow-left</v-icon>
          Voltar
        </v-btn>
      </v-toolbar-items>

      <v-spacer></v-spacer>

      <!-- <v-btn text @click="$router.push({ name: 'FolhaPagamentoLista' })" :disabled="salvando">Cancelar</v-btn>
      <v-btn color="primary" @click="confirmar" :loading="salvando" :disabled="!form_valido">Salvar</v-btn> -->
    </z-centralized-toolbar>

    <v-divider></v-divider>

    <z-scroll-layout :top-position="$v.TOP_POSITION">
      <z-container>

        <v-card class="mb-4">
          <v-card-title>Folha de Pagamento</v-card-title>
          <v-divider></v-divider>
          <v-card-text class="text--primary">
            <v-row dense>
              <v-col cols="12" sm="3">
                <z-text-field-text
                  v-model="folhapagamento.mes_extenso"
                  label="Mês"
                ></z-text-field-text>
              </v-col>
              <v-col cols="12" sm="3">
                <z-text-field-text
                  v-model="folhapagamento.ano"
                  label="Ano"
                ></z-text-field-text>
              </v-col>
              <v-col cols="12" sm="6">
                <z-text-field-text
                  v-model="folhapagamento.descricao"
                  label="Descrição"
                ></z-text-field-text>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card style="height: 600px;">
          <v-card-title class="d-flex" style="gap: 10px;">
            <span>Funcionários</span>
            <v-spacer></v-spacer>
            <v-btn color="primary" rounded depressed @click="imprimirContraCheque">Imprimir Contra-Cheque</v-btn>
            <!-- <v-btn color="primary" rounded depressed @click="novoLancamento">Imprimir Todos</v-btn> -->
          </v-card-title>

          <v-divider></v-divider>

          <v-card-text class="pa-0">

            <z-splitter left-width="300px">
              <template v-slot:panel1>
                <v-card flat class="d-flex flex-column fill-height">
                  <v-card-text ref="cardScroll" class="pa-0 fill-height nice-scrollbar">

                    <v-list dense nav>
                      <v-subheader>Funcionários</v-subheader>
                      <v-list-item-group mandatory v-model="funcionario" color="primary">
                        <template v-for="(funcionario, i) in listasStore.funcionarios">
                          <v-list-item :key="i" :value="funcionario" @click="carregarLancamentos(funcionario)">
                            <v-list-item-content>
                              <v-list-item-title class="text-uppercase" v-text="funcionario.nome"></v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-list-item-group>
                    </v-list>

                  </v-card-text>
                </v-card>

              </template>

              <template v-slot:panel2>

                <v-card flat class="d-flex flex-column fill-height">
                  <v-card-text class="text--primary">
                    <v-row dense>
                      <v-col cols="12">
                        <z-text-field-text
                          :value="funcionario.nome"
                          label="Nome"
                        ></z-text-field-text>
                      </v-col>
                      <v-col>
                        <z-text-field-text
                          :value="$filters.dataBR(funcionario.data_admissao)"
                          label="Data Admissão"
                        ></z-text-field-text>
                      </v-col>
                      <v-col>
                        <z-text-field-text
                          :value="funcionario.tipo_funcionario?.descricao"
                          label="Função"
                        ></z-text-field-text>
                      </v-col>
                      <v-col>
                        <z-text-field-text
                          :value="$filters.valorBR(funcionario.salario)"
                          label="Salário"
                        ></z-text-field-text>
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-text class="pa-2">
                    <v-btn color="primary" rounded depressed @click="novoLancamento">Novo Lançamento</v-btn>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-text class="pa-0 fill-height">


                    <v-data-table
                      mobile-breakpoint="0"
                      :footer-props="$v.FOOTER_PROPS"
                      :headers="headers"
                      :items="folhaPagamentoStore.lancamentos"
                      disabled-pagination
                      hide-default-footer
                      disable-sort
                      item-key="id"
                      fixed-header
                      height="350px"
                    >
                      <template v-slot:item="{item: lancamento}">
                        <tr>
                          <td>{{ lancamento.descricao }}</td>
                          <td class="text-right">{{ lancamento.valor | valorBR }}</td>
                          <td>{{ lancamento.tipo }}</td>
                          <td class="text-center">
                            <v-btn icon @click="editarLancamento(lancamento)">
                              <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                            <v-btn icon @click="perguntarExcluirLancamento(lancamento)">
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>


                  </v-card-text>
                </v-card>

              </template>
            </z-splitter>

          </v-card-text>
        </v-card>
      </z-container>
    </z-scroll-layout>

    <dialogo-lancamento ref="dialogoLancamento"></dialogo-lancamento>

  </v-form>
</template>

<script setup>
import { ref, inject, onMounted, getCurrentInstance } from 'vue';
import { storeToRefs } from 'pinia';
import { useFolhaPagamentoStore } from '@/app/store/pinia/folhapagamento';
import { useListasStore } from '@/app/store/pinia/listas';
import DialogoLancamento from './DialogoLancamento.vue';
import queryBuilder from '@/app/helpers/QueryBuilder';

const { proxy } = getCurrentInstance();

const folhaPagamentoStore = useFolhaPagamentoStore();
const listasStore = useListasStore();
const notifications = inject('notifications');
const imprimir = inject('$imprimir');

const form_valido = ref(true);
const funcionario = ref({});
const salvando = ref(false);
const dialogoLancamento = ref();
const { folhapagamento } = storeToRefs(folhaPagamentoStore);

const headers = [
  { text: "Descrição", value: "descricao" },
  { text: "Valor", value: "valor", width: "200px", align: "right" },
  { text: "Tipo", value: "tipo", width: "150px" },
  { text: "Opções", value: "", width: "200px", align: "center", sortable: false },
];

onMounted(async () => {
  await folhaPagamentoStore.abrir(proxy.$route.params.id);
  await getListas();

  await carregarLancamentos(listasStore.funcionarios[0]);
});

const getListas = async () => {
  await listasStore.fetchListas([
    queryBuilder()
      .from("Funcionario")
      .with("tipo_funcionario:id,descricao,comissao_servicos")
      .where("ativo=true")
      .orderBy("nome")
      .build(),
  ]);
}

const novoLancamento = () => {
  folhaPagamentoStore.novoLancamento();
  folhaPagamentoStore.lancamento.funcionario_id = funcionario.value.id;
  folhaPagamentoStore.lancamento.folha_pagamento_id = folhapagamento.value.id;
  folhaPagamentoStore.lancamento.tipo = 'Provento';
  // folhaPagamentoStore.lancamento.data = folhapagamento.value.mes;
  folhaPagamentoStore.lancamento.valor = 0;
  dialogoLancamento.value.abrir();
}

const editarLancamento = (lancamento) => {
  folhaPagamentoStore.editarLancamento(lancamento);
  dialogoLancamento.value.abrir();
}

const carregarLancamentos = async (funcionario) => {
  await folhaPagamentoStore.carregarLancamentos(funcionario);
}

const perguntarExcluirLancamento = async (lancamento) => {
  let resposta = await notifications.value.dialogo_pergunta.abrir({
    titulo: 'Remover folha de pagamento',
    texto: 'A folha de pagamento selecionada será excluída. Deseja continuar?',
    botoes: ['Cancelar', 'Remover'],
    padrao: 'Remover',
    cor: 'red',
  });

  if (resposta == 'Remover') {
    notifications.value.dialogo_pergunta.carregando();
    await folhaPagamentoStore.excluirLancamento(lancamento);
  }
  notifications.value.dialogo_pergunta.fechar();
}

const imprimirContraCheque = async () => {
  imprimir(
    `folhapagamento/${folhapagamento.value.id}/imprimir/${funcionario.value.id}`,
    `${funcionario.value.nome}-Contra-Cheque-${folhapagamento.value.mes}-${folhapagamento.value.ano}.pdf`
  );
  // imprimir(`http://localhost:8000/api/folhapagamento/imprimir/${folhapagamento.value.id}`);
}
</script>

<style lang="scss" scoped>

</style>
