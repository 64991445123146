<template>
  <div>
    <v-row dense>
      <v-col>
        <v-text-field
          v-bind="{...$v.TEXT_FIELDS, ...$attrs}"
          v-model="cliente.nome"
          ref="cliente_id"
          append-icon="mdi-magnify"
          hint="F8 - Para pesquisar"
          placeholder=" "
          :rules_="rules"
          :loading="buscando_cliente"
          :disabled="buscando_cliente"
          readonly
          @click:append="$refs.DialogoBuscarClientes.abrir()"
          @keyup.enter="buscarCliente"
          @keyup.f8="$refs.DialogoBuscarClientes.abrir()"
          @focus="(event) => event.target.select()"
        ></v-text-field>
      </v-col>
    </v-row>

    <dialogo-buscar-clientes ref="DialogoBuscarClientes" @busca:selecionar="selecionarCliente"></dialogo-buscar-clientes>

  </div>
</template>

<script>

import clientesApi from '@/app/services/clientes';

  export default {
    name: 'zBuscarCliente',

    props: {
      value: {
        type: Number,
        default: null
      },
    },

    data() {
      return {
        cliente: {
          id: null,
          nome: '',
          telefone: ''
        },

        buscando_cliente: false,
      }
    },

    methods: {
      async buscarCliente() {
        if (!this.cliente.nome) {
          this.$refs.DialogoBuscarClientes.abrir();
          return;
        }

        this.$refs.DialogoBuscarClientes.busca = this.cliente.nome;
        this.$refs.DialogoBuscarClientes.abrir();
        this.$refs.DialogoBuscarClientes.buscar();
      },


      async buscarClientePeloCodigo(id) {
        try {
          this.buscando_cliente = true;
          let cliente = await clientesApi.buscarClientePeloCodigo(id);
          if (cliente) {
            this.selecionarCliente(cliente);
          }
        } catch (error) {
          console.log("Cliente não localizado.");
        } finally {
          this.buscando_cliente = false;
        }
      },

      selecionarCliente(cliente) {
        this.cliente.id = cliente.id;
        this.cliente.nome = cliente.nome;
        this.cliente.telefone = cliente.telefone;
        this.$emit('input', this.cliente.id);
      },
    },

  }
</script>

<style scoped>

</style>
