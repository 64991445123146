import createAxios from '@/app/core/AxiosCore';
import config from '@/app/config/app';
import qs from "qs";

const axios = createAxios(config);


function listar(paginacao) {
  return axios.get(`pecas?${qs.stringify(paginacao)}`)
    .then(response => response.data);
}

function buscar(busca) {
  return axios.get(`pecas/buscar?busca=${busca}`)
    .then(response => response.data);
}

function buscarPecaPorCodigo(codigo) {
  return axios.get(`pecas/buscarCodigo?codigo=${codigo}`)
    .then(response => response.data);
}

function abrir(id) {
  return axios.get(`/pecas/${id}`)
    .then(response => response.data);
}

function salvar(peca) {
  return axios.post('/pecas', peca)
    .then(response => response.data);
}

function atualizar(peca) {
  return axios.put(`/pecas/${peca.id}`, peca)
    .then(response => response.data)
}

function excluir(id) {
  return axios.delete(`/pecas/${id}`)
    .then(response => response.data);
}


export default {
  listar,
  buscar,
  buscarPecaPorCodigo,
  abrir,
  salvar,
  atualizar,
  excluir
}
