<template>
  <div>
    <z-centralized-toolbar>
      <v-toolbar-items>
        <v-btn text color="primary" :to="{name: 'EstoqueLista'}" exact>
          <v-icon left>mdi-arrow-left</v-icon>
          Voltar
        </v-btn>
      </v-toolbar-items>
      <v-spacer></v-spacer>
      <v-btn text  @click="$router.push({name: 'EstoqueLista'})" :disabled="salvando">Cancelar</v-btn>
      <v-btn color="primary" @click="confirmar" :loading="salvando" :disabled="!form_valido">Salvar</v-btn>
    </z-centralized-toolbar>

    <v-divider></v-divider>

    <z-scroll-layout :top-position="$v.TOP_POSITION">
      <span id="topo"></span>
      <v-form ref="formulario" v-model="form_valido" lazy-validation>
        <z-container>
            <z-cabecalho>{{$store.state.titulo_tela}}</z-cabecalho>

            <v-card class="mb-3">
              <v-card-title class="text-subtitle-2">Dados do Pedido</v-card-title>

              <v-divider></v-divider>

              <v-container>

                <v-row dense>
                  <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : null">
                    <z-text-field label="Número" v-model="movimentoestoque.id" disabled></z-text-field>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : null">
                    <z-select
                      v-model="movimentoestoque.tipomovimento_id"
                      label="Tipo Movimento"
                      item-text="text"
                      item-value="value"
                      return-object
                      :rules="[regras.campoObrigatorio]"
                      :items="tipos_movimento"
                      :disabled="movimentoestoque.id != undefined"
                      @change="movimento => movimentoestoque.tipomovimento_id = movimento.value"
                    ></z-select>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : null" v-if="!isVenda">
                    <z-text-field label="Nota Fiscal" v-model="movimentoestoque.notafiscal"></z-text-field>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : null">
                    <z-date-picker-menu label="Data" :rules="[regras.campoObrigatorio]" v-model="movimentoestoque.data" :disabled="$route.name == 'PedidosEditar'"></z-date-picker-menu>
                  </v-col>
                  <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : null">
                    <z-campo-valor label="Parcelas" v-model="movimentoestoque.parcelas" :decimais="0"></z-campo-valor>
                  </v-col>

                  <v-col sm="12" v-if="!isVenda">
                    <v-autocomplete v-bind="$v.TEXT_FIELDS" v-model="movimentoestoque.fornecedor_id" label="Fornecedor" :rules="[regras.campoObrigatorio]" :items="fornecedores"></v-autocomplete>
                  </v-col>

                  <v-col sm="12" v-if="isVenda">
                    <v-autocomplete v-bind="$v.TEXT_FIELDS" v-model="movimentoestoque.cliente_id" label="Cliente" :rules="[regras.campoObrigatorio]" :items="clientes"></v-autocomplete>
                  </v-col>


                </v-row>


                <!-- <v-row> -->
                  <z-campo-buscar-cliente
                    :cli="movimentoestoque.cliente"
                    v-model="movimentoestoque.cliente_id"
                    :rules_="[regras.campoObrigatorio]"
                  ></z-campo-buscar-cliente>
                <!-- </v-row> -->

                <!-- <v-row dense>
                  <v-col cols="12" sm="3">
                    <CampoBuscarFornecedor v-model="movimentoestoque.fornecedor_id"></CampoBuscarFornecedor>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <z-text-field v-model="movimentoestoque.fornecedor.razao" ref="fornecedor" label="Fornecedor" :rules="[regras.campoObrigatorio]" placeholder=" "  :disabled="buscando" readonly></z-text-field>
                  </v-col>
                </v-row> -->
              </v-container>

            </v-card>

            <v-card class="mb-3">
              <v-toolbar flat color="white" height="56">
                <h3 class="text-subtitle-2">Adicionar Produto</h3>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-divider></v-divider>
              <v-card-text class="py-1 pb-0">
                <v-row dense>
                  <v-col sm="2">
                    <z-text-field
                      v-model="peca.codigo"
                      ref="codigo"
                      label="Código"
                      append-icon="mdi-magnify"
                      hint="F8 - Para pesquisar"
                      placeholder="Código"
                      :loading="buscando"
                      :disabled="buscando"
                      @click:append="$refs.DialogoBuscarPecas.abrir()"
                      @keyup.enter="buscarProdutoPorReferencia"
                      @keyup.esc="onKeyUpEscAdicionarProduto"
                      @keyup.f8="$refs.DialogoBuscarPecas.abrir()"
                    ></z-text-field>
                  </v-col>
                  <v-col sm="4">
                    <z-text-field label="Descrição" v-model="peca.descricao" ref="descricao" placeholder="Descrição" :disabled="buscando" readonly></z-text-field>
                  </v-col>
                  <v-col sm="2">
                    <z-campo-valor label="Quantidade" v-model="peca.quantidade" ref="quantidade" placeholder="Quantidade" :decimais="0" :hint="'Estoque: '+peca.estoque" :persistent-hint="peca.descricao.length > 0" @keyup.enter="$refs.valor.focus()" @keyup.esc="onKeyUpEscAdicionarProduto" @input="calcularSubTotal" :disabled="buscando || !peca.descricao"></z-campo-valor>
                  </v-col>
                  <v-col sm="2">
                    <z-campo-valor append-icon="mdi-send" @click:append="adicionarPeca" label="Valor" v-model="peca.valor" ref="valor" placeholder="Valor" @keyup.enter="adicionarPeca" @keyup.esc="onKeyUpEscAdicionarProduto" @input="calcularSubTotal" :disabled="buscando || !peca.descricao"></z-campo-valor>
                  </v-col>
                  <v-col sm="2">
                    <z-campo-valor label="SubTotal" v-model="peca.subtotal" ref="subtotal" placeholder="SubTotal" readonly :disabled="buscando || !peca.descricao"></z-campo-valor>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-card class="mb-3">
              <v-toolbar flat color="white" height="56">
                <h3 class="text-subtitle-2">Produtos</h3>
              </v-toolbar>
              <v-divider></v-divider>

              <v-data-table
                mobile-breakpoint="0"
                :headers="headers"
                :items="movimentoestoque.itens"
                hide-default-footer
                disable-pagination
                disable-sort
                item-key="id"
                :loading="false"
              >
                <template v-slot:item="{ item: peca, index }">
                  <tr>
                    <td>{{ peca.codigo }}</td>
                    <td>{{ peca.descricao }}</td>
                    <td class="text-right">{{ peca.quantidade}} {{ peca.unidade }}</td>
                    <td class="text-right">{{ peca.valor | valorBR }}</td>
                    <td class="text-right">{{ peca.subtotal | valorBR }}</td>
                    <td class="text-center">
                      <v-btn icon @click="excluirPeca(index)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
                <template v-slot:body.append="{ headers }">
                  <tr>
                    <td></td>
                    <td></td>
                    <td class="text-right"><b>{{ movimentoestoque.quantidade_total }}</b></td>
                    <td></td>
                    <td class="text-right"><b>{{ movimentoestoque.valor_total | valorBR }}</b></td>
                    <td></td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>

            <v-card class="mb-3" v-if="false">
              <v-toolbar flat color="white" height="56">
                <h3 class="text-subtitle-2">Observações</h3>
              </v-toolbar>
              <v-divider></v-divider>
              <v-card-text>
                <v-row dense>
                  <v-col sm12>
                    <z-textarea label="Observações" v-model="movimentoestoque.observacoes" placeholder="Observações sobre o movimentoestoque" hide-details></z-textarea>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-card class="mb-3">
              <v-toolbar flat color="white" height="56">
                <h3 class="text-subtitle-2">Totais</h3>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-divider></v-divider>
              <v-card-text>
                <v-row dense>
                  <v-col sm="2" offset-sm="6">
                    <z-campo-valor decimais="0" label="Itens" :value="itens_total" readonly hide-details></z-campo-valor>
                  </v-col>
                  <v-col sm="2">
                    <z-campo-valor decimais="0" label="Quantidade" :value="quantidade_total" readonly hide-details></z-campo-valor>
                  </v-col>
                  <v-col sm="2">
                    <z-campo-valor label="Valor Total" v-model="movimentoestoque.valor_total" readonly hide-details></z-campo-valor>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

        </z-container>
      </v-form>
    </z-scroll-layout>

    <dialogo-buscar-pecas ref="DialogoBuscarPecas" @busca:selecionar="selecionarPeca"></dialogo-buscar-pecas>

    <z-dialogo-sim-nao ref="dialogoRemover" titulo="Remover Item" botaoSim="Remover" botaoNao="Cancelar">
      O item seleciondo será removido deste movimento.
    </z-dialogo-sim-nao>

    <dialogo-buscar-clientes ref="DialogoBuscarClientes" @busca:selecionar="selecionarCliente"></dialogo-buscar-clientes>


    <!-- <busca-peca ref="buscaproduto" @selecionar:peca="selecionarPeca"></busca-peca> -->

  </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from "vuex";
import "@/components/buscas";
import "@/components";


import pecasApi from "@/app/services/pecas";
import clientesApi from '@/app/services/clientes';

import * as regras from '@/app/helpers/validacoes';

export default {
    name: 'EstoqueForm',

    data() {
        return {
          pecas: [],
          peca: {
            codigo: '',
            descricao: '',
            quantidade: 0,
            valor: 0,
            subtotal: 0
          },
          salvando: false,
          buscando: false,
          estornando: false,
          form_valido: false,
          regras: {...regras},
          tab: '',
          headers: [
            { text: "Referência", value: "codigo", width: "150px", sortable: false },
            { text: "Descrição", value: "descricao", sortable: false },
            { text: "Quantidade", value: "quantidade", align: 'right', sortable: false },
            { text: "Valor", value: "valor", align: 'right', sortable: false },
            { text: "SubTotal", value: "subtotal", align: 'right', sortable: false },
            { text: "Opções", value: "", width: "200px", align: "center", sortable: false }
          ],
          loading: false,
          drawerProdutos: false,
          buscarproduto: '',
          produtosBusca: [],
          exibirMensagem: false,
          hidedetails: true,
          itens_total: 0,
          quantidade_total: 0,
          buscando_cliente: false
        };
    },

    computed: {
      ...mapState(['titulo_tela']),
      ...mapGetters('listas', ['fornecedores', 'clientes', 'tipos_movimento', 'getTipoMovimentoById']),
      ...mapState('movimentoestoque', ['movimentoestoque']),

      config() {
        return this.$store.state.configuracoes;
      },

      titulo() {
        let titulo = (/Editar/).test(this.$route.name) ? 'Editar' : 'Adicionar';

        return titulo + ' ' + 'Movimento de Estoque';
      },

      tipoMovimento() {
        if (this.movimentoestoque.tipomovimento_id) {
          return this.getTipoMovimentoById(this.movimentoestoque.tipomovimento_id)
        }

        return null;
      },

      isVenda() {
        return this.tipoMovimento != null && this.tipoMovimento.movimento == 'Venda';
      }
    },

    async mounted() {
      this.setTitulo(this.titulo);

      if (this.$route.name == 'EstoqueEditar' || this.$route.name == 'EstoqueVisualizar') {
          if (!this.$store.state.movimentoestoque.movimentoestoque.id) {
              if (this.$route.params.id) {
                try {
                  await this.abrir(this.$route.params.id);
                } catch (error) {
                  this.$eventbus.notificarErro('Não foi possível abrir este movimento de estoque.', error);
                  this.LOADING_OFF();
                }
              }
          }
          // this.movimentoestoque = this.clone(this.$store.state.movimentoestoque.movimentoestoque);
          // this.pecas = this.clone(this.$store.state.movimentoestoque.pecas);
      } else {
        this.$refs.formulario.resetValidation();
        this.$store.commit('movimentoestoque/ZERAR');
      }

      this.$store.dispatch('listas/fornecedores');
      this.$store.dispatch('listas/clientes');
      this.$store.dispatch('listas/tipos_movimento');
      this.calcularTotais();
    },

    methods: {
      ...mapMutations(['setTitulo', 'LOADING_OFF']),
      ...mapActions('movimentoestoque', ['abrir', 'atualizar', 'salvar']),

      async confirmar() {
        if (this.$refs.formulario.validate()) {

          if (this.movimentoestoque.itens.length == 0) {
            this.$eventbus.alertar('O movimento de estoque está vazio. É necessário adicionar itens ao movimentoestoque.');
            return false;
          }

          try {
              this.salvando = true;

              // this.movimentoestoque.pecas = this.pecas;
              if (this.$route.params.id) {
                await this.atualizar(this.movimentoestoque);
              } else {
                await this.salvar(this.movimentoestoque);
              }

              this.$eventbus.notificar('Movimento salvo com sucesso.');
              this.$router.push({ name: 'EstoqueLista' });
          } catch (error) {
            console.log(error);
            this.$eventbus.notificarErro('Não foi possível salvar este movimento de estoque. Verifique os dados do movimento e tente novamente.', error);
          } finally {
            this.salvando = false;
          }
        } else {
          this.$vuetify.goTo('#topo', {offset: -100});
        }
      },

      async buscarProdutoPorReferencia() {

        if (!this.peca.codigo) {
          this.buscarPeca();
          return false;
        }

        try {
          this.buscando = true;
          let peca = await pecasApi.buscarPecaPorCodigo(this.peca.codigo);
          if (peca) {
            this.selecionarPeca(peca);
          }
        } catch (error) {
          this.$refs.DialogoBuscarPecas.busca = this.peca.codigo;
          this.$refs.DialogoBuscarPecas.abrir();
          this.$refs.DialogoBuscarPecas.buscar();

          // this.$eventbus.notificarErro('Produto não encontrado.');
          // this.setFocus('codigo');
          // this.selectText('codigo');
          // throw error;
        } finally {
          this.buscando = false;
        }
      },

      async buscarProdutoPorDescricao() {
        this.exibirMensagem = false;
        this.buscando = true;
        this.produtosBusca = await this.$store.dispatch('pecas/buscarProdutoPorDescricao', this.buscarproduto);
        if (this.produtosBusca.length == 0) {
          this.exibirMensagem = true;
        }
        this.buscando = false;
      },

      buscarPeca() {
        this.$refs.DialogoBuscarPecas.abrir();
        // this.drawerProdutos = true
        // this.setFocus('buscarproduto');
        // this.$store.commit('TOGGLE_RIGHT_DRAWER')
      },

      selecionarPeca(peca) {
        // this.peca.produto_id       = peca.id;
        this.peca.codigo           = peca.codigo;
        this.peca.descricao        = peca.descricao;
        this.peca.valor            = peca.valor_custo;
        this.peca.quantidade       = 1;
        this.peca.estoque          = peca.estoque;
        this.peca.movimentoestoque =  peca.movimentoestoque;
        this.calcularSubTotal();

        this.drawerProdutos = false;
        this.setFocus('quantidade');
        this.hidedetails = false;
      },

      adicionarPeca() {
        let peca = this.clone(this.peca);
        // delete peca.movimentoestoque;
        this.movimentoestoque.itens.push(peca);
        // this.calcularTotais();
        this.limparForm();
        this.$refs.codigo.focus();
      },

      calcularSubTotal() {
        this.peca.subtotal = this.trunc(Number(this.peca.quantidade) * Number(this.peca.valor));
      },

      calcularTotais() {
        this.movimentoestoque.valor_total = this.trunc(this.movimentoestoque.itens.reduce((soma, item) => soma + item.subtotal, 0));
        this.itens_total      = this.movimentoestoque.itens.length;
        this.quantidade_total = this.movimentoestoque.itens.reduce((soma, item) => soma + Number(item.quantidade), 0);
      },

      async excluirPeca(index) {
        let resposta = await this.$refs.dialogoRemover.abrir();

        if (resposta == true) {
          this.movimentoestoque.itens.splice(index, 1);
          this.$refs.dialogoRemover.fechar();
        }
      },

      editarQuantidade(peca) {
        console.log(peca);
      },

      onKeyUpEscAdicionarProduto() {
        this.limparForm();
        this.setFocus('codigo');
      },

      limparForm() {
        this.peca = {
          codigo: '',
          descricao: '',
          quantidade: 0,
          unidade: '',
          valor: 0,
          subtotal: 0
        }
        this.hidedetails = true;
      },

      async buscarClientePeloCodigo() {
        if (!this.movimentoestoque.cliente_id) {
          this.$refs.DialogoBuscarClientes.abrir();
          return;
        }

        try {
          this.buscando_cliente = true;
          let cliente = await clientesApi.buscarClientePeloCodigo(this.movimentoestoque.cliente_id);
          if (cliente) {
            this.selecionarCliente(cliente);
          }
        } catch (error) {
          this.$refs.DialogoBuscarClientes.busca = this.movimentoestoque.cliente_id;
          this.$refs.DialogoBuscarClientes.abrir();
          this.$refs.DialogoBuscarClientes.buscar();
        } finally {
          this.buscando_cliente = false;
        }
      },

      selecionarCliente(cliente) {
        this.movimentoestoque.cliente_id = cliente.id;
        this.movimentoestoque.cliente.nome = cliente.nome;
        this.movimentoestoque.cliente.telefone = cliente.telefone;
      },
    },

    watch: {
      'movimentoestoque.itens'() {
        this.calcularTotais();
      }
    }
};
</script>

<style scoped>
/* .container.grid-list-md .layout .flex{
    padding-top: 0px;
    padding-right: 4px;
    padding-bottom: 0px;
    padding-left: 4px;
} */

  .trasparente >>> .v-input__slot {
    background: none;
  }

</style>
