import createAxios from '@/app/core/AxiosCore';
import config from '@/app/config/app';

const axios = createAxios(config);
import qs from "qs";


function listar(paginacao) {
  return axios.get(`servicos?${qs.stringify(paginacao)}`)
    .then(response => response.data);
}

function buscar(busca) {
  return axios.get(`servicos/buscar?busca=${busca}`)
    .then(response => response.data);
}

function buscarServicoPorCodigo(codigo) {
  return axios.get(`servicos/buscarCodigo?codigo=${codigo}`)
    .then(response => response.data);
}

function abrir(id) {
  return axios.get(`/servicos/${id}`)
    .then(response => response.data);
}

function salvar(servico) {
  return axios.post('/servicos', servico)
    .then(response => response.data);
}

function atualizar(servico) {
  return axios.put(`/servicos/${servico.id}`, servico)
    .then(response => response.data)
}

function excluir(id) {
  return axios.delete(`/servicos/${id}`)
    .then(response => response.data);
}


export default {
  listar,
  buscar,
  buscarServicoPorCodigo,
  abrir,
  salvar,
  atualizar,
  excluir
}
