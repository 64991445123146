<template>
  <v-card flat>
    <v-toolbar dense flat tile color="white">
      <v-btn depressed small v-if="ordemservico.aprovado != 1" color="primary" @click="adicionarServico" class="mr-3">Adicionar Serviço</v-btn>
      <v-btn depressed small color="indigo lighten-5" class="primary--text" @click="abrirComissoes" :disabled="ordemservico.servicos.length == 0">
        <v-icon left>{{ $icon.COMISSOES }}</v-icon>
        Comissões
      </v-btn>
    </v-toolbar>

    <v-divider></v-divider>

    <v-card-text class="pa-0">
      <v-data-table
        mobile-breakpoint="0"
        hide-default-footer
        disable-pagination
        disable-sort
        fixed-header
        height="383px"
        :headers="headers"
        :items="ordemservico.servicos"
      >
        <template v-slot:item="{ item: servico, index }">
          <tr>
            <td>{{ index + 1 }}</td>
            <td>{{ servico.codigo }}</td>
            <td>{{ servico.descricao || '' }}</td>
            <td class="text-right">{{ servico.quantidade }}</td>
            <td class="text-right">{{ servico.valor | valorBR }}</td>
            <td class="text-right">{{ servico.subtotal | valorBR }}</td>
            <td>{{ servico.data | dataBR }}</td>
            <td class="text-center">
              <v-btn fab x-small dark elevation="1" :title="servico.tem_comissao == 1 ? 'Comissão Informada' : 'Comissão Não Informada'" :color="servico.tem_comissao == 1 ? 'green' : 'red'">
                <v-icon >{{ $icon.COMISSOES }}</v-icon>
              </v-btn>
              <v-btn icon @click="editar(servico, index)">
                <v-icon>{{ $icon.EDITAR }}</v-icon>
              </v-btn>
              <span v-show="ordemservico.aprovado != 1">
                <v-btn icon @click="perguntarExcluir(servico, index)">
                  <v-icon>{{ $icon.EXCLUIR }}</v-icon>
                </v-btn>
              </span>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>

    <dialogo-buscar-servicos ref="DialogoBuscarServicos" @busca:selecionar="selecionarServico"></dialogo-buscar-servicos>

    <z-dialogo-sim-nao ref="dialogoRemover" titulo="Remover Serviço?" botaoSim="Remover" botaoNao="Cancelar">
      O serviço será removido desta ordem de serviço.
    </z-dialogo-sim-nao>

    <z-dialogo-sim-nao ref="dialogoRemoverComissao" titulo="Remover Comissão?" botaoSim="Remover" botaoNao="Cancelar">
      A comissão será removida.
    </z-dialogo-sim-nao>

    <z-dialogo ref="dialogoAdicionarServico" largura="1000px" titulo="Serviço" dividers persistent>
      <template v-slot:conteudo>
        <v-form ref="formulario" v-model="form_valido" lazy-validation>
          <v-row dense>
            <v-col cols="12" sm="3">
              <z-text-field
                v-model="servico.codigo"
                :rules="[regras.campoObrigatorio]"
                autofocus
                ref="codigo"
                label="Código"
                append-icon="mdi-magnify"
                hint="F8 - Para pesquisar"
                placeholder=" "
                :loading="buscando"
                :disabled="buscando"
                @click:append="$refs.DialogoBuscarServicos.abrir()"
                @keyup.enter="buscarServicoPorCodigo"
                @keyup.esc="onKeyUpEscAdicionarServico"
                @focus="(event) => event.target.select()"
                @keyup.f8="$refs.DialogoBuscarServicos.abrir()"
              ></z-text-field>
            </v-col>

            <v-col cols="12" sm="9">
              <z-text-field v-model="servico.descricao" :rules="[regras.campoObrigatorio]" ref="descricao" label="Descrição" placeholder=" " :disabled="buscando" readonly></z-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <z-campo-valor
                v-model="servico.quantidade"
                :rules="[regras.maiorQueZero]"
                :suffix="servico.unidade"
                ref="quantidade"
                label="Quantidade"
                placeholder=" "
                :decimais="0"
                @keyup.enter="$refs.valor_servico.focus()"
                @keyup.esc="onKeyUpEscAdicionarServico"
                @input="calcularSubTotalServico"
              ></z-campo-valor>
            </v-col>
            <v-col cols="12" sm="3">
              <z-campo-valor
                v-model="servico.valor"
                :rules="[regras.maiorQueZero]"
                ref="valor_servico"
                label="Valor"
                placeholder=" "
                @keyup.enter="$refs.data1.focus()"
                @keyup.esc="onKeyUpEscAdicionarProduto"
                @input="calcularSubTotalServico"
              ></z-campo-valor>
            </v-col>
            <v-col cols="12" sm="3">
              <z-campo-valor v-model="servico.subtotal" ref="subtotal_servico" label="SubTotal" placeholder=" " readonly></z-campo-valor>
            </v-col>

            <v-col cols="12" sm="3">
              <z-date-picker-menu v-model="servico.data" :rules="[regras.campoObrigatorio]" ref="data1" label="Data" placeholder=" " @keyup.enter="confirmarServico"></z-date-picker-menu>
            </v-col>
          </v-row>
        </v-form>
      </template>

      <template v-slot:actions_right>
        <v-btn
          text
          @click="editando_index = -1;$refs.dialogoAdicionarServico.fechar();"
        >
          Cancelar
        </v-btn>
        <v-btn
          v-if="ordemservico.aprovado != 1"
          text
          color="primary"
          ref="btnConfirmar"
          :disabled="!form_valido"
          @click="confirmarServico"
        >
          {{ editando_index == -1 ? "Adicionar" : "Salvar" }}
        </v-btn>
      </template>
    </z-dialogo>

    <z-dialogo ref="dialogoComissao" largura="900px" titulo="Comissão" dividers persistent>
      <template v-slot:conteudo>
        <v-form ref="formulario_comissao" v-model="form_valido_comissao" lazy-validation>
          <v-row dense>
            <v-col cols="12" sm="9">
              <z-select
                v-model="comissao.item"
                :rules="[regras.campoObrigatorio]"
                :items="lista_servicos"
                ref="servicos"
                label="Serviços"
                placeholder=" "
                :readonly="comissao.id != null"
                @change="carregarItem"
              ></z-select>
            </v-col>
            <v-col cols="12" sm="3">
              <z-campo-valor v-model="comissao.valor_servico" label="Valor Serviço" placeholder=" " readonly></z-campo-valor>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" sm="12">
              <z-select
                v-model="comissao.funcionario_id"
                :rules="[regras.campoObrigatorio]"
                :items="$store.getters['listas/funcionarios']"
                ref="funcionario"
                label="Funcionário"
                placeholder=" "
                @change="carregarComissao"
                :disabled="!comissao.item"
              ></z-select>
            </v-col>
            <v-col cols="12" sm="3">
              <z-campo-valor
                v-model="comissao.comissao"
                :rules="[regras.maiorQueZero]"
                suffix="%"
                ref="comissao"
                label="Comissão"
                placeholder=" "
                :decimais="0"
                @input="calcularValorComissao"
                :disabled="!comissao.funcionario_id"
              ></z-campo-valor>
            </v-col>
            <v-col cols="12" sm="3">
              <z-campo-valor
                v-model="comissao.valor_comissao"
                ref="valor"
                label="Valor Comissão"
                placeholder=" "
                :disabled="!comissao.funcionario_id"
                @input="calcularPercentualComissao"
              ></z-campo-valor>
            </v-col>
            <v-col cols="12" sm="3">
              <z-date-picker-menu v-model="comissao.data" ref="data2" label="Data" placeholder=" " :disabled="!comissao.funcionario_id"></z-date-picker-menu>
            </v-col>
          </v-row>
        </v-form>
      </template>

      <template v-slot:actions_right>
        <v-btn
          text
          :disabled="salvando_comissao"
          @click=" editando_index = -1; $refs.dialogoComissao.fechar(); "
        >
          Fechar
        </v-btn>
        <!-- <v-btn v-show="ordemservico.aprovado != 1" color="primary" ref="btnConfirmar" @click="salvarComissao" :disabled="!form_valido_comissao" :loading="salvando_comissao">Confirmar</v-btn> -->
      </template>
    </z-dialogo>

    <z-dialogo ref="dialogoComissoes" largura="1000px" titulo="Comissões" dividers persistent>
      <template v-slot:conteudo>
        <v-data-table mobile-breakpoint="0" hide-default-footer disable-pagination disable-sort :headers="headers_comissoes" :items="comissoes" fixed-header height="383px">
          <template v-slot:item="{ item: comissao }">
            <tr>
              <td class="no-wrap">
                {{ comissao.item }}
              </td>
              <td>{{ comissao.funcionario_id }}</td>
              <td>{{ comissao.funcionario.nome }}</td>
              <td class="text-right">{{ comissao.comissao | valorBR(0) }}%</td>
              <td class="text-right">{{ comissao.valor_comissao | valorBR }}</td>
              <td class="text-right">{{ comissao.valor_servico | valorBR }}</td>
              <td>{{ comissao.data | dataBR }}</td>
              <td>
                <v-btn icon @click="editarComissao(comissao)">
                  <v-icon>{{ $icon.VISUALIZAR }}</v-icon>
                </v-btn>
                <v-btn v-show="ordemservico.aprovado != 1" icon @click="excluirComissao(comissao)">
                  <v-icon>{{ $icon.EXCLUIR }}</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </template>

      <template v-slot:actions_left>
        <!-- <v-btn color="primary" @click="adicionarComissao" v-show="ordemservico.aprovado != 1">Adicionar Comissão</v-btn> -->
        <v-btn color="primary" @click="adicionarComissoes" v-show="ordemservico.aprovado != 1">Adicionar Comissões</v-btn>
      </template>

      <template v-slot:actions_right>
        <v-btn color="primary" @click="$refs.dialogoComissoes.fechar()">Fechar</v-btn>
      </template>
    </z-dialogo>

    <z-alerta ref="dialogoAlerta" persistent></z-alerta>

    <comissoes-form ref="comissoesForm" @fechar="fecharAdicionarComissoes"></comissoes-form>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import servicosApi from '@/app/services/servicos';
import ordemservicoApi from '@/app/services/ordemservico';
import ComissoesForm from './ComissoesForm';

import { trunc } from '@/app/helpers/conversoes';
import { clone, hoje } from '@/app/helpers/utils';
import '@/components/buscas';

import * as regras from '@/app/helpers/validacoes';

const servico = {
  item: null,
  codigo: '',
  descricao: '',
  quantidade: 1,
  valor: 0,
  subtotal: 0,
  data: '',
};

const comissao = {
  funcionario_id: '',
  valor_servico: '',
  comissao: '',
  valor_comissao: '',
  data: '',
};

export default {
  name: 'TabServicos',

  props: {
    servicos: {
      type: Array,
      default: () => [],
    },
  },

  components: {
    ComissoesForm,
  },

  data() {
    return {
      servico: { ...servico },
      comissao: { ...comissao },
      // comissoes: [],
      headers: [
        { text: 'Item', value: '', width: '50px' },
        { text: 'Código', value: 'codigo' },
        { text: 'Descrição', value: 'descricao' },
        { text: 'Quantidade', value: 'quantidade', align: 'right' },
        { text: 'Valor', value: 'valor', align: 'right' },
        { text: 'SubTotal', value: 'subtotal', align: 'right' },
        { text: 'Data', value: 'data' },
        { text: 'Opções', value: '', width: '200px', align: 'center' },
      ],
      headers_comissoes: [
        { text: 'Item', value: 'item', width: '60px' },
        { text: 'Código', value: 'funcionario_id' },
        { text: 'Mecânico', value: 'funcionario.nome' },
        { text: 'Comissão', value: 'comissao', align: 'right' },
        { text: 'Valor Comissão', value: 'valor_comissao', align: 'right' },
        { text: 'Valor Serviço', value: 'valor_servico', align: 'right' },
        { text: 'Data', value: 'data' },
        { text: 'Opções', value: 'opcoes', align: 'center' },
      ],
      buscando: false,
      editando_index: -1,
      servico_valor: 0,
      regras: { ...regras },
      form_valido: true,
      form_valido_comissao: true,
      salvando_comissao: false,
    };
  },

  computed: {
    ...mapState('ordemservico', ['ordemservico']),

    comissoes() {
      return this.$store.state.ordemservico.comissoes;
    },

    config() {
      return this.$store.state.configuracoes;
    },

    lista_servicos() {
      return this.ordemservico.servicos.map((servico) => {
        return {
          text: `${servico.item} - ${servico.descricao}`,
          value: servico.item,
        };
      });
    },
  },

  methods: {
    ...mapActions('ordemservico', ['listarComissoes']),

    selecionarServico(servico) {
      this.servico.codigo = servico.codigo;
      this.servico.descricao = servico.descricao;
      this.servico.quantidade = 1;
      this.servico.valor = servico.valor;
      this.servico.subtotal = this.servico.quantidade * servico.valor;

      this.setFocus('quantidade');
    },

    async buscarServicoPorCodigo() {
      if (!this.servico.codigo) {
        this.$refs.DialogoBuscarServicos.abrir();
        return false;
      }

      try {
        this.buscando = true;
        let servico = await servicosApi.buscarServicoPorCodigo(this.servico.codigo);
        if (servico) {
          this.selecionarServico(servico);
        }
      } catch (error) {
        this.$refs.DialogoBuscarServicos.busca = this.servico.codigo;
        this.$refs.DialogoBuscarServicos.abrir();
        this.$refs.DialogoBuscarServicos.buscar();
        // this.$eventbus.notificarErro('Serviço não encontrado.', error);
        // this.setFocus('codigo');
        // this.selectText('codigo');
      } finally {
        this.buscando = false;
      }
    },

    confirmarServico() {
      if (!this.$refs.formulario.validate()) {
        return false;
      }

      let servico = clone(this.servico);

      if (this.estaAdicionado(servico.codigo, this.ordemservico.servicos)) {
        this.$eventbus.alertar('Este serviço já foi adicionado a ordem de serviço.');
        return false;
      }

      if (this.editando_index > -1) {
        this.ordemservico.servicos.splice(this.editando_index, 1, servico);
      } else {
        servico.item = this.ordemservico.servicos.length + 1;
        // this.$set(servico, 'tem_servico', 0);
        servico.tem_comissao = 0;
        this.ordemservico.servicos.push(servico);
      }
      // this.calcularTotais();

      this.zerarServico();
      this.$refs.formulario.resetValidation();
      this.setFocus('codigo');

      this.$emit('update:servicos');

      if (this.editando_index > -1) {
        this.$refs.dialogoAdicionarServico.fechar();
      }

      this.editando_index = -1;
      // this.hidedetails = true;
    },

    estaAdicionado(codigo, servicos) {
      if (this.editando_index > -1) {
        return false;
      }

      const index = servicos.findIndex((servico) => servico.codigo === codigo);
      return index > -1 ? true : false;
    },

    adicionarServico() {
      this.zerarServico();
      this.$refs.formulario.resetValidation();
      this.$refs.dialogoAdicionarServico.abrir();
    },

    calcularSubTotalServico() {
      this.servico.subtotal = trunc(Number(this.servico.quantidade) * Number(this.servico.valor));
    },

    onKeyUpEscAdicionarServico() {
      this.zerarServico();
      this.setFocus('codigo');
    },

    editar(servico, index) {
      this.editando_index = index;
      this.servico = clone(servico);
      this.$refs.formulario.resetValidation();
      this.$refs.dialogoAdicionarServico.abrir();
    },

    carregarComissao(funcionario_id) {
      let funcionario = this.$store.state.listas.funcionarios.find((funcionario) => funcionario.id == funcionario_id);
      let comissao = funcionario.tipo_funcionario ? funcionario.tipo_funcionario.comissao_servicos : 0;

      this.comissao.comissao = comissao;
      this.calcularValorComissao();
    },

    carregarItem(item) {
      let servico = this.ordemservico.servicos.find((servico) => servico.item == item);
      if (servico) {
        this.comissao.ordem_servico_id = this.ordemservico.id;
        this.comissao.data = servico.data;
        this.comissao.valor_servico = servico.subtotal;
      }
    },

    calcularValorComissao() {
      if (this.config.USAR_DESCONTO_ORCAMENTO_COMISSAO) {
        this.comissao.valor_comissao = this.servico_valor * (this.comissao.comissao / 100);
      } else {
        this.comissao.valor_comissao = this.comissao.valor_servico * (this.comissao.comissao / 100);
      }
    },

    calcularPercentualComissao() {
      if (this.config.USAR_DESCONTO_ORCAMENTO_COMISSAO) {
        this.comissao.comissao = (this.comissao.valor_comissao / this.servico_valor) * 100;
      } else {
        this.comissao.comissao = (this.comissao.valor_comissao / this.comissao.valor_servico) * 100;
      }
    },

    calcularDescontoOrcamento() {
      this.servico_valor = this.servico.valor - this.servico.valor * (this.ordemservico.desconto_servicos / 100);
    },

    async abrirComissoes() {
      await this.listarComissoes(this.ordemservico.id);
      this.$refs.dialogoComissoes.abrir();
    },

    adicionarComissao() {
      this.zerarComissao();
      this.$refs.formulario_comissao.resetValidation();
      this.$refs.dialogoComissao.abrir();
      this.calcularDescontoOrcamento();
    },

    adicionarComissoes() {
      // this.zerarComissao();
      // this.$refs.formulario_comissao.resetValidation();
      this.$refs.comissoesForm.abrir();
      // this.calcularDescontoOrcamento();
    },

    fecharAdicionarComissoes() {
      this.comissoes.forEach((comissao) => {
        let servico = this.ordemservico.servicos.find((servico) => servico.item == comissao.item);
        servico.tem_comissao = 1;
      });
    },

    async editarComissao(comissao) {
      this.comissao = clone(comissao);

      this.$refs.dialogoComissao.abrir();
      this.calcularDescontoOrcamento();
    },

    async salvarComissao() {
      if (!this.$refs.formulario_comissao.validate()) {
        return false;
      }

      let index = this.comissoes.findIndex((comissao) => {
        return comissao.item == this.comissao.item && comissao.funcionario_id == this.comissao.funcionario_id;
      });

      if (index > -1) {
        this.$eventbus.alertar({
          titulo: 'Comissão já existe',
          mensagem: 'Já existe uma comissão inserida para este serviço e funcionário.',
        });
        return false;
      }

      try {
        this.salvando_comissao = true;
        if (this.comissao.id) {
          delete this.comissao.funcionario;
          await ordemservicoApi.atualizarComissao(this.comissao);
        } else {
          await ordemservicoApi.salvarComissao(this.comissao);
        }

        let servico = this.ordemservico.servicos.find((servico) => servico.item == this.comissao.item);
        servico.tem_comissao = 1;
        // this.$set(this.ordemservico.servicos[this.editando_index], 'tem_comissao', 1);
        await this.listarComissoes(this.ordemservico.id);

        this.$refs.dialogoComissao.fechar();
      } catch (error) {
        throw error;
      } finally {
        this.salvando_comissao = false;
      }
    },

    async excluirComissao(comissao) {
      let resposta = await this.$refs.dialogoRemoverComissao.abrir();

      if (resposta == true) {
        await ordemservicoApi.excluirComissao(comissao.id);
        await this.listarComissoes(this.ordemservico.id);

        let itemIndex = this.comissoes.findIndex((comissao_) => comissao_.item == comissao.item);
        if (itemIndex == -1) {
          let servico = this.ordemservico.servicos.find((servico) => servico.item == comissao.item);
          if (servico) {
            servico.tem_comissao = 0;
          }
        }

        this.$refs.dialogoRemoverComissao.fechar();
      }
    },

    // async listarComissoes() {
    //   this.comissoes = await ordemservicoApi.listarComissoes(this.ordemservico.id);
    // },

    async perguntarExcluir(servico, index) {
      //  await this.listarComissoes();
      //   let comissoes = this.comissoes.filter(comissao => comissao.item == servico.item);
      //   console.log(comissoes);
      if (servico.tem_comissao == 1) {
        this.$eventbus.alertar({
          titulo: 'Excluir',
          mensagem: 'Não é possível excluir este serviço, as comissões já foram lançadas.',
        });
        return false;
      }

      let resposta = await this.$refs.dialogoRemover.abrir();

      if (resposta == true) {
        this.ordemservico.servicos.splice(index, 1);
        this.$emit('update:servicos');
        this.$refs.dialogoRemover.fechar();
      }
    },

    zerarServico() {
      this.servico = { ...servico };
      this.servico.data = this.hoje();
    },

    zerarComissao() {
      this.comissao = { ...comissao };
    },
  },
};
</script>

<style scoped></style>
