import Vue from 'vue';
import mixins from '@/app/mixins/mixins';
import createAxios from '@/app/core/AxiosCore';
import createAuth from '@/app/core/AuthCore';
import createCookie from '@/app/core/CookieCore';
import createLocalStorage from '@/app/helpers/localStorage';
// import createZComponents from '@/components/zComponents';
import globals from '@/app/globals';
import eventbus from '@/app/eventbus';
import vuetifyConfig from '@/app/vuetifyConfig';
import * as icon from '@/app/icons';
import config from '@/app/config/app';
import '@/assets/estilos.css';

import dayjs from 'dayjs';
import zComponents from 'zcomponents';
import VueTheMask from 'vue-the-mask';

// Carregar Plugins
Vue.prototype.$axios = createAxios(config);
Vue.prototype.$auth = createAuth();
Vue.prototype.$cookie = createCookie();
Vue.prototype.$storage = createLocalStorage(config);

Vue.prototype.$globals = globals;
Vue.prototype.$eventbus = eventbus;
Vue.prototype.$v = vuetifyConfig;
Vue.prototype.$icon = icon;
Vue.prototype.$log = console;
Vue.prototype.$config = config;
Vue.prototype.$dayjs = dayjs;

// Carregar Mixins e Plugins
Vue.mixin(mixins);

// Carregamento global dos zComponents
// const zComponents = createZComponents();
Vue.use(zComponents);

Vue.use(VueTheMask);
