import FuncionariosLista from '@/pages/cadastros/funcionarios/FuncionariosLista'
import FuncionariosForm from '@/pages/cadastros/funcionarios/FuncionariosForm'

export default [
  {
    path: '/cadastros/funcionarios',
    name: 'FuncionariosLista',
    component: FuncionariosLista,
    meta: { permissao: 'funcionarios_acessar' }
  },
  {
    path: '/cadastros/funcionarios/adicionar',
    name: 'FuncionariosAdicionar',
    component: FuncionariosForm,
    meta: { permissao: 'funcionarios_adicionar' }
  },
  {
    path: '/cadastros/funcionarios/:id/editar',
    name: 'FuncionariosEditar',
    component: FuncionariosForm,
    meta: { permissao: 'funcionarios_editar' }
  },
];
