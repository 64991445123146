import createAxios from '@/app/core/AxiosCore';
import config from '@/app/config/app';

const axios = createAxios(config);

function listar(paginacao) {
  return axios.get(`folhapagamento`, {params: paginacao})
    .then(response => response.data);
}

function buscar(busca) {
  return axios.get(`folhapagamento/buscar?busca=${busca}`)
    .then(response => response.data);
}

function buscarPorCodigo(codigo) {
  return axios.get(`folhapagamento/buscarCodigo?codigo=${codigo}`)
    .then(response => response.data);
}

function abrir(id) {
  return axios.get(`/folhapagamento/${id}`)
    .then(response => response.data);
}

function salvar(folha) {
  return axios.post('/folhapagamento', folha)
    .then(response => response.data);
}

function atualizar(folha) {
  return axios.put(`/folhapagamento/${folha.id}`, folha)
    .then(response => response.data)
}

function excluir(id) {
  return axios.delete(`/folhapagamento/${id}`)
    .then(response => response.data);
}

function salvarItem(folha, item) {
  return axios.post(`/folhapagamento/${folha.id}/item`, item)
    .then(response => response.data);
}

function lancamentosFolha(folha, funcionario) {
  return axios.get(`/folhapagamento/${folha.id}/${funcionario.id}`)
    .then(response => response.data);
}

function salvarLancamento(folha, lancamento) {
  return axios.post(`/folhapagamento/${folha.id}/lancamentos`, lancamento)
    .then(response => response.data);
}

function atualizarLancamento(folha, lancamento) {
  return axios.put(`/folhapagamento/${folha.id}/lancamentos/${lancamento.id}`, lancamento)
    .then(response => response.data);
}

function excluirLancamento(folha, lancamento) {
  return axios.delete(`/folhapagamento/${folha.id}/lancamentos/${lancamento.id}`)
    .then(response => response.data);
}

export default {
  listar,
  buscar,
  buscarPorCodigo,
  abrir,
  salvar,
  atualizar,
  excluir,
  salvarItem,
  lancamentosFolha,
  salvarLancamento,
  atualizarLancamento,
  excluirLancamento
}
