<template>
  <div>

    <z-centralized-toolbar>  
      <v-toolbar-items>

        <v-btn id="btn-adicionar" text small color="primary" @click="adicionar">
          <v-icon left>mdi-card-account-details-outline</v-icon>Adicionar
        </v-btn>

        <v-btn text id="btn-atualizar" @click="refresh" :loading="$store.state.loading">
          <v-icon>mdi-refresh</v-icon>
          <v-tooltip bottom activator="#btn-atualizar">
            Atualizar
          </v-tooltip>
        </v-btn>
      </v-toolbar-items>
      

      <!-- <v-spacer></v-spacer> -->

      <z-text-field-search
        v-model="busca"
        select
        class="ml-5"
        @keydown.enter="buscar"
        @click:clear="limparBusca"
      ></z-text-field-search>
    </z-centralized-toolbar>

    <v-divider></v-divider>

    <z-scroll-layout :top-position="$v.TOP_POSITION">
      <z-container>
            
        <z-cabecalho>{{$store.state.titulo_tela}}</z-cabecalho>
        
        <v-card>
          <v-data-table
          mobile-breakpoint="0"
            :options.sync="paginacao" 
            :server-items-length="total_itens" 
            :footer-props="$v.FOOTER_PROPS" 
            :headers="headers"
            :items="tiposfuncionarios"
            item-key="id"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.id }}</td>
                <td class="text-no-wrap">{{ item.descricao }}</td>
                <td class="text-right">{{ item.comissao_servicos | valorBR(0) }} %</td>
                <td class="text-right">{{ item.comissao_pecas | valorBR(0) }} %</td>
                <td class="text-center">
                  <v-btn icon @click="editar(item)" class="ma-0">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn icon @click="perguntarExcluir(item)" class="ma-0">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
        
      </z-container>
    </z-scroll-layout>

    <z-dialogo-sim-nao ref="dialogoRemover" titulo="Remover tipo do funcionário?" botaoSim="Remover" botaoNao="Cancelar" :loading="excluindo">
      A tipo do funcionário será removido do sistema.
    </z-dialogo-sim-nao>

    <v-form v-model="form_valido" lazy-validation ref="formulario">
      <z-dialogo ref="dialogoAdicionar" largura="600px" titulo="Tipo Funcionário" dividers persistent @fechar="$refs.formulario.resetValidation()">
        <template v-slot:conteudo>
          <v-row dense>
            <v-col cols="12">
              <z-text-field caps="upper" v-model="tipofuncionario.descricao" ref="descricao" label="Descrição" placeholder=" " :rules="[regras.campoObrigatorio]"></z-text-field>
            </v-col>
            <v-col cols="6">
              <z-campo-valor v-model="tipofuncionario.comissao_servicos" decimais="0" suffix="%" ref="servicos" label="Comissão Serviços" placeholder=" "></z-campo-valor>
            </v-col>
            <v-col cols="6">
              <z-campo-valor v-model="tipofuncionario.comissao_pecas" decimais="0" suffix="%" ref="pecas" label="Comissão Peças" placeholder=" "></z-campo-valor>
            </v-col>
          </v-row>
        </template>

        <template v-slot:actions_right>
          <v-btn text @click="fecharDialogo" :disabled="salvando">Cancelar</v-btn>
          <v-btn color="primary" ref="btnConfirmar" @click="confirmar" :loading="salvando" :disabled="!form_valido">Confirmar</v-btn>
        </template>
      </z-dialogo>
    </v-form>

  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from "vuex";
import * as regras from '@/app/helpers/validacoes';

export default {
  name: 'TiposFuncionariosLista',
  data() {
    return {
      headers: [
        { text: "Código", value: "id", width: "100px" },
        { text: "Descrição", value: "descricao" },
        { text: "Comissão Serviços", value: "comissao_servicos", width: "200px", align: "right" },
        { text: "Comissão Peças", value: "comissao_pecas", width: "200px", align: "right" },
        { text: "Opções", value: "", width: "200px", align: "center", sortable: false },
      ],
      excluindo: false,
      salvando: false,
      form_valido: true,
      regras: {...regras}
    };
  },

  computed: {
    ...mapState(['loading']),
    ...mapState('tiposfuncionarios', ['tiposfuncionarios', 'tipofuncionario', 'total_itens']),

    busca: {
      get () {
        return this.$store.state.tiposfuncionarios.busca
      },
      set (busca) {
        this.$store.commit('tiposfuncionarios/SET_BUSCA', busca);
      }
    },

    paginacao: {
      get () {
        return this.$store.state.tiposfuncionarios.paginacao
      },
      set (paginacao) {
        this.$store.commit('tiposfuncionarios/UPDATE_PAGINACAO', paginacao);
      }
    },
  },

  created() {
    this.refresh();
  },

  mounted() {
    this.setTitulo("Tipos de Funcionários");
  },

  methods: {
    ...mapMutations(["setTitulo", 'LOADING_OFF']),
    ...mapActions("tiposfuncionarios", ["listar", "excluir", "abrir", "atualizar", "salvar"]),

    async refresh() {
      try {
        await this.listar();
      } catch (error) {
        console.log(error);
        this.$eventbus.notificar("Não foi possível listar os tipos de funcionários.");
      }
    },

    buscar() {
      this.$store.commit('tiposfuncionarios/SET_PAGE', 1);
      this.refresh();
    },
    
    limparBusca() {
      this.busca = null;
      this.$store.commit('tiposfuncionarios/SET_PAGE', 1);
      this.refresh();
    },

    adicionar() {
      // this.$store.commit('tiposfuncionarios/ZERAR');
      this.$refs.dialogoAdicionar.abrir();
    },

    fecharDialogo() {
      this.$refs.dialogoAdicionar.fechar();
      this.$store.commit('tiposfuncionarios/ZERAR');
    },

    async editar(tipofuncionario) {
      try {

        await this.abrir(tipofuncionario.id);
        this.$refs.dialogoAdicionar.abrir();
        
      } catch (error) {
        console.log(error);
        this.$eventbus.notificar("Não foi possível abrir a tipo do funcionário.");
      } finally {
        this.LOADING_OFF();
      }
    },

    async confirmar() {
      if (!this.$refs.formulario.validate()) {
        return false;
      }

      try {
        this.salvando = true;

        if (this.tipofuncionario.id) {
          await this.atualizar(this.tipofuncionario);
        } else {
          await this.salvar(this.tipofuncionario);
        }

        this.fecharDialogo();
        this.$eventbus.notificar("Tipo de funcionário salvo com sucesso");
      } catch(error) {
        this.$eventbus.notificarErro('Não foi possível salvar esta tipo de funcionário. Verifique os dados preenchidos.', error);
        throw error;
      } finally {
          this.salvando = false;
      }
    },

    perguntarExcluir(tipofuncionario) {
      this.$refs.dialogoRemover.abrir()
        .then(async response => {
          if (response == true) {
            await this.confirmarExcluir(tipofuncionario);
            this.$refs.dialogoRemover.fechar();
          }
        })
    },

    async confirmarExcluir(tipofuncionario) {
      try {
        this.excluindo = true;
        await this.excluir(tipofuncionario.id);
        this.$eventbus.notificar("Tipo excluído com sucesso!");
      } catch (error) {
        this.$eventbus.notificar("Não foi possível excluir o tipo do funcionário!");
        throw error;
      } finally {
        this.excluindo = false;
      }
    },
  },

  watch: {
    paginacao: {
      handler() {
        this.refresh();
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
</style>
