<template>
  <div>
    <z-centralized-toolbar>
      <v-toolbar-items>
        <v-btn id="btn-adicionar" text small color="primary" @click="adicionar"> <v-icon left>mdi-format-list-bulleted-type</v-icon>Adicionar </v-btn>

        <v-btn text id="btn-atualizar" @click="refresh" :loading="$store.state.loading">
          <v-icon>mdi-refresh</v-icon>
          <v-tooltip bottom activator="#btn-atualizar">
            Atualizar
          </v-tooltip>
        </v-btn>
      </v-toolbar-items>

      <!-- <v-spacer></v-spacer> -->

      <z-text-field-search v-model="busca" select class="ml-5" @keydown.enter="buscar" @click:clear="limparBusca"></z-text-field-search>
    </z-centralized-toolbar>

    <v-divider></v-divider>

    <z-scroll-layout :top-position="$v.TOP_POSITION">
      <z-container>
        <z-cabecalho>{{ $store.state.titulo_tela }}</z-cabecalho>

        <v-card>
          <v-data-table
            mobile-breakpoint="0"
            :options.sync="paginacao"
            :server-items-length="total_itens"
            :footer-props="$v.FOOTER_PROPS"
            :headers="headers"
            :items="grupos"
            item-key="id"
            disabled-pagination
            hide-default-footer
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.id }}</td>
                <td>{{ item.descricao }}</td>
                <td class="text-center">
                  <v-btn icon @click="editar(item)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn icon @click="perguntarExcluir(item)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </z-container>
    </z-scroll-layout>

    <z-dialogo-sim-nao ref="dialogoRemover" titulo="Remover grupo?" botaoSim="Remover" botaoNao="Cancelar" :loading="excluindo">
      O grupo será removido do sistema.
    </z-dialogo-sim-nao>

    <z-dialogo-sim-nao ref="dialogoRemoverSubGrupo" titulo="Remover subgrupo?" botaoSim="Remover" botaoNao="Cancelar" :loading="excluindo">
      O subgrupo será removido do sistema.
    </z-dialogo-sim-nao>

    <v-form v-model="form_valido" lazy-validation ref="formulario">
      <z-dialogo ref="dialogoAdicionar" largura="700px" titulo="Grupo" dividers persistent @abrir="$refs.formulario.resetValidation()">
        <template v-slot:conteudo>
          <v-row dense>
            <!-- <v-col cols="3">
              <z-text-field v-model="grupo.codigo" ref="descricao" label="Código" placeholder=" " :rules="[regras.campoObrigatorio]"></z-text-field>
            </v-col> -->
            <v-col cols="12">
              <z-text-field v-model="grupo.descricao" ref="descricao" label="Descrição" placeholder=" " :rules="[regras.campoObrigatorio]"></z-text-field>
            </v-col>
          </v-row>

          <v-row v-if="grupo.id != null">
            <v-col cols="12">
              <h3 class="text-body-2 mb-3 text--primary">SubGrupos</h3>
              <v-data-table mobile-breakpoint="0" :headers="headers_subgrupos" :items="subgrupos" disabled-pagination hide-default-footer>
                <template v-slot:item="{ item }">
                  <tr>
                    <td>{{ item.descricao }}</td>
                    <td class="text-center">
                      <v-btn icon @click="editarSubGrupo(item)">
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                      <v-btn icon @click="perguntarExcluirSubGrupo(item)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </template>

        <template v-slot:actions_left>
          <v-btn text small color="primary" @click="adicionarSubGrupo"> <v-icon left>mdi-plus</v-icon>Adicionar SubGrupo </v-btn>
        </template>

        <template v-slot:actions_right>
          <v-btn text @click="$refs.dialogoAdicionar.fechar()" :disabled="salvando">Cancelar</v-btn>
          <v-btn color="primary" ref="btnConfirmar" @click="confirmar" :loading="salvando" :disabled="!form_valido">Confirmar</v-btn>
        </template>
      </z-dialogo>
    </v-form>

    <v-form v-model="form_valido_subgrupo" lazy-validation ref="formularioSubGrupo">
      <z-dialogo ref="dialogoAdicionarSubGrupo" largura="700px" titulo="Grupo" dividers persistent @abrir="$refs.formularioSubGrupo.resetValidation()">
        <template v-slot:conteudo>
          <v-row dense>
            <!-- <v-col cols="3">
              <z-text-field v-model="grupo.codigo" ref="descricao" label="Código" placeholder=" " :rules="[regras.campoObrigatorio]"></z-text-field>
            </v-col> -->
            <v-col cols="12">
              <z-text-field v-model="subgrupo.descricao" ref="descricao" label="Descrição" placeholder=" " :rules="[regras.campoObrigatorio]"></z-text-field>
            </v-col>
          </v-row>
        </template>

        <template v-slot:actions_right>
          <v-btn text @click="$refs.dialogoAdicionarSubGrupo.fechar()" :disabled="salvando">Cancelar</v-btn>
          <v-btn color="primary" ref="btnConfirmarSubGrupo" @click="confirmarSubGrupo" :loading="salvando" :disabled="!form_valido_subgrupo">Confirmar</v-btn>
        </template>
      </z-dialogo>
    </v-form>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from 'vuex';
import * as regras from '@/app/helpers/validacoes';

export default {
  name: 'GruposLista',
  data() {
    return {
      headers: [
        { text: 'Código', value: 'id', width: '150px' },
        { text: 'Descrição', value: 'descricao' },
        { text: 'Opções', value: '', width: '200px', align: 'center', sortable: false },
      ],
      headers_subgrupos: [
        { text: 'Descrição', value: 'descricao' },
        { text: 'Opções', value: '', width: '200px', align: 'center', sortable: false },
      ],
      excluindo: false,
      salvando: false,
      form_valido: true,
      form_valido_subgrupo: true,
      regras: { ...regras },
    };
  },

  computed: {
    ...mapState(['loading']),
    ...mapState('grupos', ['grupos', 'grupo', 'total_itens', 'subgrupos', 'subgrupo']),

    busca: {
      get() {
        return this.$store.state.grupos.busca;
      },
      set(busca) {
        this.$store.commit('grupos/SET_BUSCA', busca);
      },
    },

    paginacao: {
      get() {
        return this.$store.state.grupos.paginacao;
      },
      set(paginacao) {
        this.$store.commit('grupos/UPDATE_PAGINACAO', paginacao);
      },
    },
  },

  created() {
    this.refresh();
  },

  mounted() {
    this.setTitulo('Grupos / SubGrupos');
  },

  methods: {
    ...mapMutations(['setTitulo', 'LOADING_OFF']),
    ...mapActions('grupos', ['listar', 'excluir', 'abrir', 'atualizar', 'salvar', 'listarSubGrupos', 'excluirSubGrupo', 'abrirSubGrupo', 'atualizarSubGrupo', 'salvarSubGrupo']),

    async refresh() {
      try {
        await this.listar();
      } catch (error) {
        console.log(error);
        this.$eventbus.notificar('Não foi possível listar os grupos.');
      }
    },

    buscar() {
      this.$store.commit('grupos/SET_PAGE', 1);
      this.refresh();
    },

    limparBusca() {
      this.busca = null;
      this.$store.commit('grupos/SET_PAGE', 1);
      this.refresh();
    },

    adicionar() {
      this.$store.commit('grupos/ZERAR');
      this.$refs.dialogoAdicionar.abrir();
    },

    async editar(grupo) {
      try {
        await this.abrir(grupo.id);
        // await this.listarSubGrupos(grupo.id);
        this.$refs.dialogoAdicionar.abrir();
      } catch (error) {
        console.log(error);
        this.$eventbus.notificar('Não foi possível abrir o grupo.');
      } finally {
        this.LOADING_OFF();
      }
    },

    async confirmar() {
      if (!this.$refs.formulario.validate()) {
        return false;
      }

      try {
        this.salvando = true;

        if (this.grupo.id) {
          await this.atualizar(this.grupo);
        } else {
          await this.salvar(this.grupo);
        }

        this.$refs.dialogoAdicionar.fechar();
        this.$eventbus.notificar('Grupo salvo com sucesso');
      } catch (error) {
        this.$eventbus.notificarErro('Não foi possível salvar esta grupo. Verifique os dados preenchidos.', error);
        throw error;
      } finally {
        this.salvando = false;
      }
    },

    perguntarExcluir(grupo) {
      this.$refs.dialogoRemover.abrir().then(async (response) => {
        if (response == true) {
          await this.confirmarExcluir(grupo);
          this.$refs.dialogoRemover.fechar();
        }
      });
    },

    async confirmarExcluir(grupo) {
      try {
        this.excluindo = true;
        await this.excluir(grupo.id);
        this.$eventbus.notificar('Grupo excluído com sucesso!');
      } catch (error) {
        this.$eventbus.notificar('Não foi possível excluir o grupo!');
        throw error;
      } finally {
        this.excluindo = false;
      }
    },

    adicionarSubGrupo() {
      this.$store.commit('grupos/ZERAR_SUBGRUPO');
      this.$refs.dialogoAdicionarSubGrupo.abrir();
    },

    async editarSubGrupo(subgrupo) {
      try {
        await this.abrirSubGrupo(subgrupo.id);
        this.$refs.dialogoAdicionarSubGrupo.abrir();
      } catch (error) {
        console.log(error);
        this.$eventbus.notificar('Não foi possível abrir o subgrupo.');
      } finally {
        this.LOADING_OFF();
      }
    },

    async confirmarSubGrupo() {
      if (!this.$refs.formularioSubGrupo.validate()) {
        return false;
      }

      try {
        this.salvando = true;

        if (this.subgrupo.id) {
          await this.atualizarSubGrupo(this.subgrupo);
        } else {
          this.subgrupo.grupo_id = this.grupo.id;
          await this.salvarSubGrupo(this.subgrupo);
        }

        this.$refs.dialogoAdicionarSubGrupo.fechar();
        this.$eventbus.notificar('Subgrupo salvo com sucesso');
      } catch (error) {
        this.$eventbus.notificarErro('Não foi possível salvar esta subgrupo. Verifique os dados preenchidos.', error);
        throw error;
      } finally {
        this.salvando = false;
      }
    },

    perguntarExcluirSubGrupo(subgrupo) {
      this.$refs.dialogoRemoverSubGrupo.abrir().then(async (response) => {
        if (response == true) {
          try {
            this.excluindo = true;
            await this.excluirSubGrupo(subgrupo.id);
            this.$refs.dialogoRemoverSubGrupo.fechar();
            this.$eventbus.notificar('SubGrupo excluído com sucesso!');
          } catch (error) {
            this.$eventbus.notificar('Não foi possível excluir o subgrupo!');
            throw error;
          } finally {
            this.excluindo = false;
          }
        }
      });
    },
  },

  watch: {
    paginacao: {
      handler() {
        this.refresh();
      },
      deep: true,
    },
  },
};
</script>

<style scoped></style>
