<template>
  <z-dialogo2
    v-bind="$attrs"
    ref="dialogo"
    scrollable
    largura="1200px"
    altura="600px"
    persistent
    color="primary"
    dark
    :fullscreen="fullscreen"
    :dividers="true"
    @fechar="fechar"
  >

    <template #titlebuttons>
      <v-btn icon :loading="loading" :disabled="loading" @click="carregarImpressao(url_impressao)">
        <v-icon >mdi-refresh</v-icon>
      </v-btn>
      <v-btn icon @click="download" :disabled="loading">
        <v-icon >mdi-download</v-icon>
      </v-btn>
      <v-btn icon @click="imprimir" :disabled="loading">
        <v-icon>mdi-printer</v-icon>
      </v-btn>
      <v-divider inset vertical class="mx-3"></v-divider>
      <v-btn icon @click="fullscreen = !fullscreen" :disabled="loading">
        <v-icon v-if="fullscreen">mdi-window-restore</v-icon>
        <v-icon v-else>mdi-window-maximize</v-icon>
      </v-btn>
    </template>

    <template #texto>

      <z-loading height="500px" :loading="loading"></z-loading>

      <!-- <div class="fill-height" v-if="open && !show_error">
        <iframe
          v-show="!loading"
          width="100%"
          height="100%"
          id="print_frame"
          name="print_frame"
          ref="print_frame"
          :src="url + pdfOptions"
          @load="onLoadFrame"
        ></iframe>
      </div> -->
      <div class="fill-height" v-if="open && !show_error">
        <iframe
          v-show="!loading"
          width="100%"
          height="100%"
          id="print_frame"
          name="print_frame"
          ref="print_frame"
          :src="url + pdfOptions"
          @load="onLoadFrame"
        ></iframe>
      </div>

      <div v-if="show_error" class="d-flex fill-height align-center justify-center">
        <h1 class="primary--text">Não foi possível carregar a impressão.</h1>
      </div>
    </template>

    <!-- <template #actions_left>
      <v-btn text color="primary" :disabled="loading" @click="carregarImpressao(url_impressao)">
        Recarregar
      </v-btn>
    </template>

    <template #actions_right>
      <v-btn text color="primary" @click="download" :disabled="loading || show_error">
        <v-icon left>mdi-download</v-icon>
        Download
      </v-btn>
      <v-btn color="primary" depressed @click="imprimir" :disabled="loading || show_error">
        <v-icon left>mdi-printer</v-icon>
        Imprimir
      </v-btn>
    </template> -->
  </z-dialogo2>
</template>

<script>
import config from "@/app/config";

export default {
  name: 'zDialogoImpressao',

  props: {
    urlImpressao: {
      type: String,
      default: ''
    },
  },

  data() {
    return {
      open: false,
      // pdfOptions: '#zoom=100&scrollbar=0&toolbar=1&navpanes=0',
      pdfOptions: '#view=fitH&zoom=50&scrollbar=0&toolbar=0&navpanes=0',
      loading: false,
      url: '',
      paisagem: false,
      nomeArquivo: 'impressao.pdf',
      show_error: false,
      url_impressao: '',
      fullscreen: false,
    };
  },

  mounted () {

  },

  methods: {
    async abrir(url, nome_arquivo, opcoes = {}) {
      this.paisagem = opcoes.paisagem || false;
      this.nomeArquivo = nome_arquivo || 'impressao.pdf';
      this.$refs.dialogo.abrir();
      this.url_impressao = url;
      await this.carregarImpressao(url, opcoes);
    },

    fechar() {
      console.log('fechar');
      this.paisagem = false;
      this.open = false;
      this.url = "";
      this.show_error = false;
      this.fullscreen = false;
      this.$emit('fechar');
    },

    imprimir() {

      // setTimeout(function(){
        window.frames["print_frame"].focus();
        window.frames["print_frame"].print();
      // },2000);

      this.$emit('imprimir');
    },

    download() {
      this.downloadPDF(this.url);
    },

    onLoadFrame() {
      this.loading = false;
    },

    loadIFrameURL(url) {
      this.open = false;
      this.loading = true;
      setTimeout(() => {
        this.url = url;
        this.open = true;
      }, 0);
    },

    downloadPDF(url) {
      const link = document.createElement('a');
      link.href = url;
      link.download = this.nomeArquivo; //'impressao-venda.pdf'; // Nome do arquivo para o download
      link.click();
    },

    async carregarImpressao(url, opcoes = {}) {
      try {
        this.show_error = false;
        this.loading = true;

        if (config.IS_PRODUCTION) {
          const response = await this.$axios.get(url, { responseType: 'blob' }).then(response => response.data);
          const pdfBlob = new Blob([response], { type: 'application/pdf' });
          const pdfUrl = URL.createObjectURL(pdfBlob);

          this.loadIFrameURL(pdfUrl);
        } else {
          // const response = await this.$axios.get(url, { responseType: 'blob' }).then(response => response.data);
          // const iframe = document.getElementById('print_frame');
          // iframe.onload = () => {
          //     const doc = iframe.contentDocument || iframe.contentWindow.document;
          //     doc.open();
          //     doc.write(response.data);
          //     doc.close();
          // };

          this.loadIFrameURL(`http://localhost:8000/api/v1/${url}`);
        }

        this.loading = false;
      } catch (error) {
        this.show_error = true;
        console.error(error);
      } finally {
        this.loading = false;
      }

    },

    // unloadIFrame() {
    //   var iframe = document.getElementById("print_frame");
    //   var html = "";

    //   iframe.contentWindow.document.open();
    //   iframe.contentWindow.document.write(html);
    //   iframe.contentWindow.document.close();
    //   window.frames["print_frame"].document.close()
    // }
  },
};
</script>

<style scoped>

</style>
