<template>
  <div>
    <z-centralized-toolbar>
      <v-toolbar-items>
        <v-btn text color="primary" :to="{ name: 'FuncionariosLista' }" exact>
          <v-icon left>mdi-arrow-left</v-icon>
          Voltar
        </v-btn>
      </v-toolbar-items>

      <v-spacer></v-spacer>

      <v-btn text @click="$router.push({ name: 'FuncionariosLista' })" :disabled="salvando">Cancelar</v-btn>
      <v-btn color="primary" @click="confirmar" :loading="salvando" :disabled="!form_valido">Salvar</v-btn>
    </z-centralized-toolbar>

    <v-divider></v-divider>

    <z-scroll-layout :top-position="$v.TOP_POSITION">
      <z-container>
        <z-cabecalho>{{ $store.state.titulo_tela }}</z-cabecalho>

        <v-form v-model="form_valido" lazy-validation ref="formulario">
          <v-card tile class="mb-6">
            <v-card-title class="text-subtitle-2">
              Dados do Funcionário
            </v-card-title>
            <v-divider></v-divider>

            <v-card-text>
              <v-row dense>
                <v-col cols="12" sm="2">
                  <z-text-field label="Código" v-model="funcionario.id" disabled></z-text-field>
                </v-col>
                <v-col cols="12" sm="7">
                  <z-text-field upper label="Nome" v-model="funcionario.nome" :rules="[regras.campoObrigatorio]"></z-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <z-select :items="tipos_funcionarios" label="Tipo Funcionário" v-model="funcionario.tipo_funcionario_id" :rules="[regras.campoObrigatorio]"></z-select>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="2">
                  <z-cep v-model="funcionario.cep" :buscarcep="true" @resultado="preencherEndereco"></z-cep>
                </v-col>

                <v-col cols="12" sm="10">
                  <z-text-field label="Endereço" v-model="funcionario.endereco"></z-text-field>
                </v-col>

                <v-col cols="12" sm="5">
                  <z-text-field label="Bairro" v-model="funcionario.bairro"></z-text-field>
                </v-col>

                <v-col cols="12" sm="5">
                  <z-text-field label="Cidade" v-model="funcionario.cidade"></z-text-field>
                </v-col>

                <v-col cols="12" sm="2">
                  <z-select :items="$globals.ESTADOS" label="Estado" v-model="funcionario.estado"></z-select>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" sm="3">
                  <z-text-field ref="telefone" label="Telefone" v-model="funcionario.telefone" v-mask="$v.TELEFONE_MASK"></z-text-field>
                </v-col>

                <v-col cols="12" sm="3">
                  <z-select :items="$globals.SEXO" label="Sexo" v-model="funcionario.sexo"></z-select>
                </v-col>
                <v-col cols="12" sm="3">
                  <z-select :items="$globals.ESTADO_CIVIL" label="Estado Civil" v-model="funcionario.estado_civil"></z-select>
                </v-col>
                <v-col cols="12" sm="3">
                  <z-date-picker-menu birthday label="Nascimento" v-model="funcionario.data_nascimento"></z-date-picker-menu>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" sm="3">
                  <z-cpf-cnpj label="CPF" v-model="funcionario.cpf"></z-cpf-cnpj>
                </v-col>

                <v-col cols="12" sm="3">
                  <z-text-field label="RG" v-model="funcionario.rg"></z-text-field>
                </v-col>

                <v-col cols="12" sm="3">
                  <z-text-field label="Orgão Expedidor" v-model="funcionario.orgao_expedidor"></z-text-field>
                </v-col>

                <v-col cols="12" sm="3">
                  <z-text-field label="Carteira Profissional" v-model="funcionario.carteira_trabalho"></z-text-field>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" sm="3">
                  <z-text-field label="Carteira Prof. Série" v-model="funcionario.serie_carteira_trabalho"></z-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <z-date-picker-menu label="Admissão" v-model="funcionario.data_admissao" :rules="[regras.dataValida]"></z-date-picker-menu>
                </v-col>

                <v-col cols="12" sm="3">
                  <z-date-picker-menu label="Demissão" v-model="funcionario.data_demissao" validate-format clearable @change="alternarAtivo"></z-date-picker-menu>
                </v-col>

                <v-col cols="12" sm="3">
                  <z-campo-valor label="Salário" v-model="funcionario.salario"></z-campo-valor>
                </v-col>

                <v-col cols="12" sm="3">
                  <z-text-field label="Número de Filhos" v-model="funcionario.numero_de_filhos" decimais="0" type="number"></z-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="2">
                  <v-switch class="mt-0" label="Ativo" v-model="funcionario.ativo" single-line hide-details @change="limparDemissao"></v-switch>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-form>
      </z-container>
    </z-scroll-layout>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { clone } from '@/app/helpers/utils';
import funcionario from '@/app/store/models/funcionario';

import * as regras from '@/app/helpers/validacoes';

export default {
  name: 'funcionarios-form',

  data() {
    return {
      funcionario: { ...funcionario },
      salvando: false,
      regras: {},
      tab: 0,
      form_valido: true,
      regras: { ...regras },
    };
  },

  computed: {
    ...mapGetters('listas', ['tipos_funcionarios']),
    titulo() {
      let titulo = /Editar/.test(this.$route.name) ? 'Editar' : 'Adicionar';
      return titulo + ' ' + 'Funcionário';
    },
  },

  async mounted() {
    this.setTitulo(this.titulo);
    await this.$store.dispatch('listas/tipos_funcionarios');

    if (this.$route.name == 'FuncionariosEditar') {
      if (!this.$store.state.funcionarios.funcionario.id) {
        if (this.$route.params.id) {
          await this.abrir(this.$route.params.id);
        }
      }
    }

    this.funcionario = clone(this.$store.state.funcionarios.funcionario);
  },

  methods: {
    ...mapMutations(['setTitulo']),
    ...mapMutations('funcionarios', ['ZERAR']),
    ...mapActions('funcionarios', ['abrir', 'atualizar', 'salvar']),

    async confirmar() {
      if (!this.$refs.formulario.validate()) {
        return false;
      }

      try {
        this.salvando = true;

        if (this.$route.params.id) {
          await this.atualizar(this.funcionario);
        } else {
          await this.salvar(this.funcionario);
        }
        this.$router.push({ name: 'FuncionariosLista' });
        this.$eventbus.notificar('Funcionário salvo com sucesso');
      } catch (error) {
        this.$eventbus.notificarErro('Não foi possível salvar este Funcionário. Verifique os dados preenchidos.', error);
        throw error;
      } finally {
        this.salvando = false;
      }
    },

    alternarAtivo() {
      if (this.funcionario.data_demissao) {
        this.funcionario.ativo = 0;
      }
    },

    async limparDemissao() {
      if (this.funcionario.ativo == 1 && this.funcionario.data_demissao) {
        let resposta = await this.$root.dialogo_pergunta.abrir({
          titulo: 'Funcionário demitido',
          texto: 'O funcionário está demitido. Deseja limpar a data de demissão?',
          botoes: ['Cancelar', 'Limpar'],
          padrao: 'Limpar',
        });

        if (resposta == 'Limpar') {
          this.funcionario.data_demissao = null;
          this.$root.dialogo_pergunta.fechar();
        } else {
          this.funcionario.ativo = 0;
          this.$root.dialogo_pergunta.fechar();
        }
      }
    },

    preencherEndereco(endereco) {
      if (endereco.erro) {
        this.funcionario.endereco = '';
        this.funcionario.bairro = '';
        this.funcionario.cidade = '';
        this.funcionario.estado = '';
      } else {
        this.funcionario.endereco = endereco.logradouro.toUpperCase();
        this.funcionario.bairro = endereco.bairro.toUpperCase();
        this.funcionario.cidade = endereco.localidade.toUpperCase();
        this.funcionario.estado = endereco.uf.toUpperCase();
        this.$refs.telefone.focus();
      }
    },
  },

  watch: {
    'funcionario.cpfcnpj'(val, oldval) {
      if (val) {
        this.funcionario.tipo_pessoa = val.length > 14 ? 'J' : 'F';
      }
    },
  },
};
</script>

<style scoped></style>
