<template>
  <v-container>
    <v-row>
      <v-col cols="12" align="center">
        <div class="d-flex flex-wrap justify-center" style="gap: 1rem">
          <v-btn large rounded dark color="purple" @click="alertar">Alertar</v-btn>
          <v-btn large rounded dark color="purple" @click="outroAlerta">outroAlerta</v-btn>
          <v-btn large rounded dark color="purple" @click="perguntar">Perguntar</v-btn>
        </div>
      </v-col>
      <v-col cols="12" class="d-flex flex-wrap justify-center" style="gap: 1rem">
        <v-btn large rounded dark color="purple" @click="$router.push({name: 'ConfiguracoesSistema'})">Configurações</v-btn>
        <v-btn large rounded dark color="purple" @click="chamarEvento">Software Update</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
  import { inject } from 'vue';

  const notifications = inject('notifications');

  const alertar = () => {
    notifications.value.alertar('Isso é um alerta PROVIDED');
  }

  const outroAlerta = () => {
    notifications.value.notificar('UNAUTHORIZED');
  }

  const perguntar = async () => {
    // console.log(rootRefs);
    let resposta = await notifications.value.dialogo_pergunta.abrir({
      titulo: 'Remover Ordem de Serviço?',
      texto: 'A ordem de serviço será removida.',
      botoes: ['Sim', 'Não', 'Cancelar', 'Remover'],
      padrao: 'Cancelar',
      cor: 'red',
    });

    if (resposta == 'Não')
      notifications.value.alertar('Você respondeu não');
    if (resposta == 'Sim')
      notifications.value.alertar('Você respondeu Sim');
    if (resposta == 'Cancelar')
      notifications.value.alertar('Você respondeu Cancelar');
    if (resposta == 'Remover')
      notifications.value.alertar('Você respondeu Remover');

      notifications.value.dialogo_pergunta.fechar();
  }

  const chamarEvento = () => {
    document.dispatchEvent(new CustomEvent('SOFTWARE_UPDATE', {
      'detail': { 'texto': 'NOVO SOFTWARE DISPONÍVEL'}
    }));
  }

</script>

<style lang="scss" scoped>

</style>
