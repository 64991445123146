import pecasApi from "@/app/services/pecas";
import peca from "@/app/store/models/peca";
import { PAGINATION_OPTIONS } from "@/app/vuetifyConfig";

export default {
  namespaced: true,
  state: {
    peca: {...peca},
    pecas: [],
    busca: "",
    total_itens: 0,
    paginacao: PAGINATION_OPTIONS
  },

  mutations: {
    LISTAR(state, pecas) {
      state.pecas = pecas;
    },

    ABRIR(state, peca) {
      state.peca = peca;
    },

    ADICIONAR(state, peca) {
      state.pecas.push(peca);
    },

    ATUALIZAR(state, peca) {
      let index = state.pecas.findIndex(peca_ => peca_.id == peca.id);
      if (index > -1) {
        state.pecas.splice(index, 1, peca);
      }
    },

    EXCLUIR(state, id) {
      let index = state.pecas.findIndex(peca => peca.id == id);
      if (index > -1) {
        state.pecas.splice(index, 1);
      }
    },

    SET_BUSCA(state, busca) {
      state.busca = busca;
    },

    SET_PAGE(state, page) {
      state.paginacao.page = page;
    },

    SET_TOTAL_ITENS(state, total_itens) {
      state.total_itens = total_itens;
    },

    UPDATE_PAGINACAO(state, paginacao) {
      state.paginacao = paginacao;
    },

    ZERAR(state) {
      state.peca = {...peca};
      console.log('ZERAR');
    },

    ZERAR_PECAS(state) {
      state.pecas = [];
    }
  },

  actions: {
    async listar({ commit, state }) {
      commit('LOADING_ON', null, { root: true });

      const { sortBy, sortDesc, page, itemsPerPage } = state.paginacao;

      const paginacao = {
        page,
        itemsPerPage,
        sortBy: sortBy[0],
        sortDesc: sortDesc[0]
      }

      if (state.busca) {
        paginacao.busca = state.busca;
      }

      let response = await pecasApi.listar(paginacao);
      commit('LISTAR', response.data);
      commit('SET_TOTAL_ITENS', response.total);
      commit('LOADING_OFF', null, { root: true });
    },

    async abrir({ commit }, id) {
      commit('LOADING_ON', null, { root: true });
      let peca = await pecasApi.abrir(id);
      commit('ABRIR', peca);
      commit('LOADING_OFF', null, { root: true });
    },

    async salvar({ commit }, peca) {
      let novopeca = await pecasApi.salvar(peca);
      commit('ADICIONAR', novopeca);
    },

    async atualizar({ commit }, peca) {
      await pecasApi.atualizar(peca);
      commit('ATUALIZAR', peca);
    },

    async excluir({ commit }, id) {
      await pecasApi.excluir(id);
      commit('EXCLUIR', id);
    },

    async buscar({ commit }, busca) {
      const pecas = await pecasApi.buscar(busca);
      commit('LISTAR', pecas);
    },

  }
}
