<template>
  <div>
    <z-centralized-toolbar>
      <v-toolbar-items>
        <v-btn id="btn-adicionar" small text color="primary" class="mr-2" :class="{ 'mr-4': mostrar_busca }" @click="adicionar">
          <v-icon left>mdi-format-list-checkbox</v-icon>Adicionar OS
          <v-tooltip bottom activator="#btn-adicionar">
            Adicionar Ordem de Serviço
          </v-tooltip>
        </v-btn>

        <v-btn icon color="primary" id="btn-atualizar" @click="refresh" :loading="$store.state.loading">
          <v-icon>mdi-refresh</v-icon>
          <v-tooltip bottom activator="#btn-atualizar">
            Atualizar
          </v-tooltip>
        </v-btn>
      </v-toolbar-items>

      <z-text-field-search v-model="busca" select class="ml-5" width="250px" @keydown.enter="buscar" @click:clear="limparBusca"></z-text-field-search>
    </z-centralized-toolbar>

    <v-divider></v-divider>

    <z-scroll-layout :top-position="$v.TOP_POSITION">
      <z-container>
        <h3 class="text-body-1 mb-3">{{ titulo_tela }}</h3>

        <v-card>
          <v-toolbar flat color="white" dense>
            <v-btn-toggle v-model="status" mandatory @change="onChangeStatus">
              <v-btn text small value="emaberto">
                Em Aberto
              </v-btn>

              <!-- <v-btn text small value="entregue">
                Entregue
              </v-btn> -->

              <v-btn text small value="todos">
                Todos
              </v-btn>
            </v-btn-toggle>
          </v-toolbar>

          <v-divider></v-divider>

          <v-data-table
            mobile-breakpoint="0"
            :options.sync="paginacao"
            :server-items-length="total_itens"
            :footer-props="$v.FOOTER_PROPS"
            :headers="headers"
            :items="ordensservico"
            :disabled-pagination="esconder_paginacao"
            :hide-default-footer="esconder_paginacao"
            disable-sort
            item-key="id"
          >
            <template v-slot:item="{ item: ordemservico }">
              <tr>
                <td>{{ ordemservico.id }}</td>
                <td>{{ ordemservico.data_entrada | dataBR }}</td>
                <td class="text-no-wrap">{{ ordemservico.placa }}</td>
                <td>{{ ordemservico.cliente ? ordemservico.cliente.nome : '' }}</td>
                <td class="text-no-wrap">{{ ordemservico.cliente ? ordemservico.cliente.telefone : '' }}</td>
                <td class="text-right">{{ ordemservico.total_liquido | valorBR }}</td>
                <td class="text-center">
                  <v-chip v-if="ordemservico.aprovado == 0" color="green" small dark>Em Aberto</v-chip>
                  <v-chip v-if="ordemservico.aprovado == 1" color="indigo" small dark>Finalizado</v-chip>
                  <v-chip v-if="ordemservico.aprovado == 2" color="orange" small dark>Entregue</v-chip>
                </td>
                <td class="text-center">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-on="on" v-bind="attrs" icon @click="imprimir(ordemservico)" class="ma-0">
                        <v-icon>mdi-printer</v-icon>
                      </v-btn>
                    </template>
                    <span>Imprimir</span>
                  </v-tooltip>
                  <v-btn icon @click="editar(ordemservico)" class="ma-0">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn icon @click="perguntarExcluir(ordemservico)" class="ma-0">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </z-container>
    </z-scroll-layout>

    <z-dialogo-sim-nao
      ref="dialogoRemoverOrdemServico"
      titulo="Remover Ordem de Serviço?"
      botaoSim="Remover"
      botaoNao="Cancelar"
      :loading="excluindo"
    >
      A ordem de serviço será removida.
    </z-dialogo-sim-nao>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from 'vuex';

import { abrirJanela } from '@/app/helpers/utils';
import config from '@/app/config/app';

export default {
  name: 'OrdemServicoLista',

  // beforeRouteLeave(to, from, next) {
  //   if (to.name != 'OrdemServicoEditar') {
  //     this.limparBusca();
  //   }
  //   next();
  // },

  async mounted() {
    this.setTitulo('Ordens de Serviço');
  },

  data() {
    return {
      mostrar_busca: false,
      montado: false,
      headers: [
        { text: 'Número', value: 'id', width: '90px' },
        { text: 'Data', value: 'data_entrada' },
        { text: 'Placa', value: 'placa' },
        { text: 'Cliente', value: 'cliente.nome' },
        { text: 'Telefone', value: 'cliente.telefone' },
        { text: 'Total', value: 'total_liquido', align: 'right' },
        { text: 'Status', value: 'aprovado', align: 'center' },
        { text: 'Opções', value: '', width: '200px', align: 'center' },
      ],
      excluindo: false,
      esconder_paginacao: true,
    };
  },

  computed: {
    ...mapState(['loading', 'titulo_tela']),
    ...mapState('ordemservico', ['ordensservico', 'ordemservico', 'total_itens']),

    busca: {
      get() {
        return this.$store.state.ordemservico.busca;
      },
      set(busca) {
        this.$store.commit('ordemservico/SET_BUSCA', busca);
      },
    },

    paginacao: {
      get() {
        return this.$store.state.ordemservico.paginacao;
      },
      set(paginacao) {
        this.$store.commit('ordemservico/UPDATE_PAGINACAO', paginacao);
      },
    },

    status: {
      get() {
        return this.$store.state.ordemservico.status;
      },
      set(status) {
        this.$store.commit('ordemservico/SET_STATUS', status);
      },
    },
  },

  methods: {
    ...mapMutations(['setTitulo', 'LOADING_OFF']),
    ...mapActions('ordemservico', ['listar', 'excluir', 'abrir']),

    async refresh() {
      try {
        await this.listar();
      } catch (error) {
        console.log(error);
        this.$eventbus.notificar('Não foi possível listar as ordens de serviço.');
      }
    },

    async onChangeStatus() {
      this.mostrar_busca = false;
      this.busca = '';
      this.$store.commit('ordemservico/SET_PAGE', 1);
      await this.refresh();
      this.esconder_paginacao = this.status == 'emaberto';
    },

    buscar() {
      this.status = 'todos';
      this.$store.commit('ordemservico/SET_PAGE', 1);
      this.refresh();
    },

    limparBusca() {
      this.status = 'emaberto';
      this.mostrar_busca = false;
      this.busca = '';
      this.$store.commit('ordemservico/SET_PAGE', 1);
      this.refresh();
    },

    adicionar() {
      // this.$store.commit('ordemservico/ZERAR');
      this.$router.push({ name: 'OrdemServicoAdicionar' });
    },

    async editar(ordemservico) {
      try {
        await this.abrir(ordemservico.id);
        this.$router.push({ name: 'OrdemServicoEditar', params: { id: ordemservico.id } });
      } catch (error) {
        console.log(error);
        this.$eventbus.notificar('Não foi possível abrir o ordem de serviço.');
      } finally {
        this.LOADING_OFF();
      }
    },

    perguntarExcluir(ordemservico) {
      this.$refs.dialogoRemoverOrdemServico.abrir().then(async (response) => {
        if (response == true) {
          await this.confirmarExcluir(ordemservico);
          this.$refs.dialogoRemoverOrdemServico.fechar();
        }
      });
    },

    async confirmarExcluir(ordemservico) {
      try {
        this.excluindo = true;
        await this.excluir(ordemservico.id);
        this.$eventbus.notificar('Ordem de serviço excluído com sucesso!');
      } catch (error) {
        this.$eventbus.notificar('Não foi possível excluir a ordem de serviço!');
        throw error;
      } finally {
        this.excluindo = false;
      }
    },

    imprimir(ordemservico) {
      const print_url = config.IS_PRODUCTION ? window.location.origin : config.BASE_URL_PRINT;
      abrirJanela(`${print_url}/ordemservico/${ordemservico.id}/imprimir`);
    },
  },

  watch: {
    paginacao: {
      handler() {
        if (this.montado) {
          this.refresh();
        } else {
          this.montado = true;
          if (this.ordensservico.length == 0) {
            this.refresh();
          }
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped></style>
