import PecasLista from '@/pages/cadastros/pecas/PecasLista'
import PecasForm from '@/pages/cadastros/pecas/PecasForm'

export default [
  {
    path: '/cadastros/pecas',
    name: 'PecasLista',
    component: PecasLista,
    meta: { permissao: 'pecas_acessar' }
  },
  {
    path: '/cadastros/pecas/adicionar',
    name: 'PecasAdicionar',
    component: PecasForm,
    meta: { permissao: 'pecas_adicionar' }
  },
  {
    path: '/cadastros/pecas/:id/editar',
    name: 'PecasEditar',
    component: PecasForm,
    meta: { permissao: 'pecas_editar' }
  },
];
