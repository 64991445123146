export default {
  // id: null,
  placa: '',
  marca: '',
  modelo: '',
  km: '',
  cor: '',
  chassi: '',
  ano: '',
  combustivel: 'Gasolina'
}