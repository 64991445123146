<template>
  <z-dialogo ref="dialogo" largura="900px" titulo="Comissão" dividers persistent>
    <template v-slot:conteudo>
      <v-form ref="formulario_comissao" v-model="form_valido_comissao" lazy-validation>
        <v-row dense>
          <v-col cols="12" sm="9">
            <z-select
              v-model="funcionario.id"
              :rules="[regras.campoObrigatorio]"
              :items="$store.getters['listas/funcionarios']"
              ref="funcionario"
              label="Funcionário"
              placeholder=" "
              @change="carregarComissao"
            ></z-select>
          </v-col>
          <v-col cols="12" sm="3">
            <z-campo-valor v-model="funcionario.comissao" suffix="%" ref="comissao" label="Comissão" placeholder=" " :decimais="0" :readonly="!funcionario.id"></z-campo-valor>
          </v-col>
        </v-row>
      </v-form>

      <!-- <v-divider class="mb-4"></v-divider> -->

      <z-cabecalho class="text--primary">Serviços</z-cabecalho>
      <v-divider></v-divider>
      <v-row>
        <v-col cols="12">
          <v-data-table
            v-model="servicos_selecionados"
            show-select
            dense
            mobile-breakpoint="0"
            hide-default-footer
            disable-pagination
            disable-sort
            :headers="headers_servicos_comissoes"
            :items="servicos"
            fixed-header
            height="300px"
            @input="form_valido_comissao = true"
          >
            <template v-slot:item="{ item: servico, index, isSelected, select }">
              <tr>
                <td>
                  <v-simple-checkbox color="primary" :value="isSelected" @input="select($event)" :ripple="false"></v-simple-checkbox>
                </td>
                <td>{{ servico.item }}</td>
                <td>{{ servico.codigo }}</td>
                <td>{{ servico.descricao || '' }}</td>
                <td class="text-right">{{ servico.quantidade }}</td>
                <td class="text-right">{{ servico.valor | valorBR }}</td>
                <td class="text-right">{{ servico.subtotal | valorBR }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </template>

    <template v-slot:actions_right>
      <v-btn text @click="cancelar" :disabled="salvando_comissao">Cancelar</v-btn>
      <v-btn color="primary" ref="btnConfirmar" @click="salvarComissao" :disabled="!form_valido_comissao" :loading="salvando_comissao">Confirmar</v-btn>
    </template>
  </z-dialogo>
</template>

<script>
import { mapActions } from 'vuex';

import ordemservicoApi from '@/app/services/ordemservico';

import * as regras from '@/app/helpers/validacoes';

export default {
  data() {
    return {
      comissoes: [],
      headers_servicos_comissoes: [
        { text: 'Item', value: '', width: '50px' },
        { text: 'Código', value: 'codigo' },
        { text: 'Descrição', value: 'descricao' },
        { text: 'Quantidade', value: 'quantidade', align: 'right' },
        { text: 'Valor', value: 'valor', align: 'right' },
        { text: 'SubTotal', value: 'subtotal', align: 'right' },
      ],
      buscando: false,
      editando_index: -1,
      servico_valor: 0,
      regras: { ...regras },
      form_valido: true,
      form_valido_comissao: true,
      salvando_comissao: false,
      servicos_selecionados: [],
      funcionario: {
        id: null,
        comissao: 0,
      },
    };
  },

  computed: {
    ordemservico() {
      return this.$store.state.ordemservico.ordemservico;
    },

    config() {
      return this.$store.state.configuracoes;
    },

    servicos() {
      return this.$store.state.ordemservico.ordemservico.servicos.filter((servico) => {
        let index = this.comissoes_adicionadas.findIndex((comissao) => {
          return comissao.item == servico.item && comissao.funcionario_id == this.funcionario.id;
        });

        return index == -1;
      });
    },

    comissoes_adicionadas() {
      return this.$store.state.ordemservico.comissoes;
    },
  },

  methods: {
    ...mapActions('ordemservico', ['listarComissoes']),

    abrir() {
      this.$refs.formulario_comissao.resetValidation();
      this.zerarComissoes();
      this.$refs.dialogo.abrir();
    },

    fechar() {
      this.$refs.dialogo.fechar();
      this.$emit('fechar');
    },

    cancelar() {
      this.editando_index = -1;
      this.fechar();
    },

    async salvarComissao() {
      try {
        if (!this.$refs.formulario_comissao.validate()) {
          return false;
        }

        if (this.servicos_selecionados.length == 0) {
          this.form_valido_comissao = false;
          return false;
        }

        this.salvando_comissao = true;

        this.comissoes = [];
        this.servicos_selecionados.forEach((servico) => {
          let valor_servico_original = servico.valor;
          let subtotal = servico.subtotal - (servico.subtotal * this.ordemservico.desconto_servicos) / 100;

          this.comissoes.push({
            codigo: servico.codigo,
            ordem_servico_id: servico.ordem_servico_id,
            item: servico.item,
            valor_servico: subtotal,
            valor_servico_original: valor_servico_original,
            funcionario_id: this.funcionario.id,
            comissao: this.funcionario.comissao,
            valor_comissao: subtotal * (this.funcionario.comissao / 100),
            data: servico.data,
          });
        });

        await ordemservicoApi.salvarComissoes(this.comissoes);
        await this.listarComissoes(this.ordemservico.id);

        this.fechar();
      } catch (error) {
        throw error;
      } finally {
        this.salvando_comissao = false;
      }
    },

    carregarComissao(funcionario_id) {
      let funcionario = this.$store.state.listas.funcionarios.find((funcionario) => funcionario.id == funcionario_id);
      let comissao = funcionario.tipo_funcionario ? funcionario.tipo_funcionario.comissao_servicos : 0;

      this.funcionario.comissao = comissao;
      // this.calcularValorComissao();
    },

    calcularValorComissao() {
      if (this.config.USAR_DESCONTO_ORCAMENTO_COMISSAO) {
        this.comissao.valor_comissao = this.servico_valor * (this.comissao.comissao / 100);
      } else {
        this.comissao.valor_comissao = this.comissao.valor_servico * (this.comissao.comissao / 100);
      }
    },

    zerarComissoes() {
      this.funcionario = {
        id: null,
        comissao: 0,
      };

      this.servicos_selecionados = [];
    },
  },
};
</script>

<style scoped></style>
