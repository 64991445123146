<template>
  <v-slide-y-transition mode="out-in">
    <template v-if="$route.meta.layout == 'full'">
      <transition>
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </transition>
    </template>
    <AppLayoutTopBar v-else></AppLayoutTopBar>
  </v-slide-y-transition>
</template>

<script>
import AppLayoutTopBar from "@/pages/layout/AppLayoutTopBar.vue";
import AppLayoutSideBar from "@/pages/layout/AppLayoutSideBar.vue";

export default {
  name: "App",
  components: {
    AppLayoutTopBar,
    AppLayoutSideBar
  },

};
</script>

<style>

</style>
