<template>
  <v-badge :value="events_count > 0" :content="events_count" color="red" overlap offset-x="20" offset-y="20">
    <v-btn icon @click="goToAgenda">
      <v-icon>mdi-calendar</v-icon>
    </v-btn>
  </v-badge>
</template>

<script lang="js">
  export default {
    name: 'AppCalendarIcon',

    mounted () {
      this.$store.dispatch('agenda/todayEventsCount');
      this.startCounter();
    },

    computed: {
      events_count() {
        return this.$store.state.agenda.today_events_count;
      }
    },

    methods: {
      startCounter() {
        let interval = null;
        interval = setInterval(async () => {
          try {
            await this.$store.dispatch('agenda/todayEventsCount');
          } catch (error) {
            clearInterval(interval);
          }
        }, 1000 * 60);
      },

      goToAgenda() {
        this.$router.push({ name: 'AgendaLista' }).catch((error) => {});
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>
