<template>
  <z-dialogo ref="dialogo" largura="1000px" titulo="Ordens de Serviço" dividers persistent scrollable no-padding>
    <template v-slot:conteudo>
      <v-data-table
          mobile-breakpoint="0"
        disabled-pagination
        hide-default-footer
        :server-items-length="ordensservico.length"
        :headers="headers"
        :items="ordensservico"
        fixed-header_
        height="383px"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>{{ item.id }}</td>
            <td class="text-no-wrap">{{ item.cliente.nome }}</td>
            <td>{{ item.data_entrada | dataBR }}</td>
            <td>{{ item.data_saida | dataBR }}</td>
            <td class="text-right">{{ item.total_liquido | valorBR(2) }}</td>
            <td class="text-center">
              <v-btn icon @click="abrirOrdemServico(item)">
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
    </template>

    <template v-slot:actions_right>
      <v-btn color="primary" @click="fechar">Fechar</v-btn>
    </template>
  </z-dialogo>
</template>

<script>

  import { abrirJanela } from "@/app/helpers/utils";

  export default {
    name: 'VeiculosOrdensServico',
    data() {
      return {
        headers: [
          { text: "Número OS", value: "id", width: "120px" },
          { text: "Cliente", value: "cliente.nome" },
          { text: "Data Entrada", value: "data_entrada" },
          { text: "Data Saída", value: "data_saida" },
          { text: "Valor", value: "total_liquido", align: "right" },
          { text: "Opções", value: "", align: "center", sortable: false },
        ],
      }
    },


    methods: {
      abrir() {
        this.$refs.dialogo.abrir();
      },

      fechar() {
        this.$refs.dialogo.fechar();
      },

      abrirOrdemServico(ordemservico) {
        // http://localhost:8080/#/movimentacao/ordemservico/27894/editar
        abrirJanela(window.location.origin + '/#/movimentacao/ordemservico/' + ordemservico.id + '/editar' )
      }
    },

    computed: {
      ordensservico() {
        return this.$store.state.veiculos.veiculo.ordensservico; 
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>