import Vue from 'vue';
import App from '@/App.vue';

import '@/registerServiceWorker';
import router from '@/app/router';
import createStore from '@/app/store';
import { createPinia, PiniaVuePlugin } from 'pinia';
import vuetify from '@/app/plugins/vuetify';

// Bootstrap
import '@/app/bootstrap';

// Vuex
const store = createStore();

// Pinia
Vue.use(PiniaVuePlugin)
const pinia = createPinia()

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  pinia,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
