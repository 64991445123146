<template>
    <div>
      <z-centralized-toolbar>
        <v-toolbar-items>
          <v-btn id="btn_adicionar" text small color="primary" @click="adicionarFolhaPagamento">
            <v-icon left>mdi-swap-horizontal-circle-outline</v-icon>
            Adicionar
          </v-btn>

          <!-- <menu-relatorios-estoque @abrir:relatorio="abrirRelatorio"></menu-relatorios-estoque> -->

          <v-btn id="btn_refresh" text small @click="refresh" :loading="folhaPagamentoStore.loading">
            <v-icon>mdi-refresh</v-icon>
            <v-tooltip bottom activator="#btn_refresh">Refresh</v-tooltip>
          </v-btn>
        </v-toolbar-items>

        <!-- <v-spacer></v-spacer> -->

        <z-text-field-search
          v-model="busca"
          select
          class="ml-5"
          width="250px"
          @keydown.enter="buscar"
          @click:clear="limparBusca"
        ></z-text-field-search>
      </z-centralized-toolbar>

      <v-divider></v-divider>

    <z-scroll-layout :top-position="$v.TOP_POSITION">
      <z-container>
          <z-cabecalho>{{folhaPagamentoStore.titulo}}</z-cabecalho>

          <v-card>
              <v-data-table
                mobile-breakpoint="0"
                :footer-props="$v.FOOTER_PROPS"
                :headers="headers"
                :items="folhasPagamento"
                item-key="id"
                id="folhasPagamento"
                disable-sort
              >
                <template v-slot:item="{item: folha}">
                  <tr>
                    <td>{{ folha.id }}</td>
                    <td>{{ folha.mes_extenso }}</td>
                    <td>{{ folha.ano }}</td>
                    <td>{{ folha.descricao }}</td>
                    <!-- <td>{{ folha.status }}</td> -->
                    <td class="text-center">

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" icon @click="gerarFolha(folha)" class="ma-0">
                            <v-icon>mdi-list-box-outline</v-icon>
                          </v-btn>
                        </template>
                        <span>Gerar Folha</span>
                      </v-tooltip>

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" icon @click="editar(folha)" class="ma-0">
                            <v-icon>mdi-pencil</v-icon>
                          </v-btn>
                        </template>
                        <span>Editar Folha</span>
                      </v-tooltip>

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" icon @click="perguntarExcluir(folha)" class="ma-0">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        <span>Excluir Folha</span>
                      </v-tooltip>

                    </td>
                  </tr>
                </template>
              </v-data-table>
          </v-card>
      </z-container>
    </z-scroll-layout>



      <v-form v-model="form_valido" lazy-validation ref="formulario">
        <z-dialogo ref="dialogoAdicionar" largura="500px" titulo="Criar Folha de Pagamento" dividers persistent @abrir="$refs.formulario.resetValidation()">
          <template v-slot:conteudo>
            <v-row dense>
              <v-col cols="6">
                <z-select v-model="folhapagamento.mes" maxlength="3" ref="descricao" label="Mês" :items="$globals.MESES" :rules="[regras.campoObrigatorio]" @change="atualizarDescricao"></z-select>
              </v-col>
              <v-col cols="6">
                <z-select v-model="folhapagamento.ano" ref="descricao" label="Ano" :items="anos" :rules="[regras.campoObrigatorio]" @change="atualizarDescricao"></z-select>
              </v-col>
              <v-col cols="12">
                <z-text-field v-model="folhapagamento.descricao" ref="descricao" label="Descrição" :rules="[regras.campoObrigatorio]"></z-text-field>
              </v-col>
            </v-row>
          </template>

          <template v-slot:actions_right>
            <v-btn text @click="$refs.dialogoAdicionar.fechar()" :disabled="salvando">Cancelar</v-btn>
            <v-btn color="primary" ref="btnConfirmar" @click="salvar" :loading="salvando" :disabled="!form_valido">Confirmar</v-btn>
          </template>
        </z-dialogo>
      </v-form>

      <z-dialogo-sim-nao ref="dialogoExcluir" titulo="Remover Movimento de Estoque?" botaoSim="Remover" botaoNao="Cancelar" :loading="excluindo">
        A movimentação de estoque será removida.
      </z-dialogo-sim-nao>

    </div>
</template>

<script setup>
import { onMounted, computed, inject, ref, getCurrentInstance } from 'vue';
import { storeToRefs } from 'pinia';
import { useFolhaPagamentoStore } from '@/app/store/pinia/folhapagamento';
import { range, mesExtenso } from '@/app/helpers/utils';
import * as regras from '@/app/helpers/validacoes';

const { proxy } = getCurrentInstance();

const folhaPagamentoStore = useFolhaPagamentoStore();
const notifications = inject('notifications');

const headers = [
  { text: "#", value: "id", width: "50px" },
  { text: "Mês", value: "mes", width: "120px" },
  { text: "Ano", value: "ano", width: "120px" },
  { text: "Descrição", value: "descricao" },
  // { text: "Status", value: "status" },
  { text: "Opções", value: "", width: "200px", align: "center", sortable: false },
];

const paginacao = ref();
const total_itens = ref(0);
const busca = ref();
const excluindo = ref(false);
const salvando = ref(false);
const form_valido = ref(true);
const dialogoAdicionar = ref();
const dialogoExcluir = ref();
const dialogoProcessarFolha = ref();

const { folhapagamento } = storeToRefs(folhaPagamentoStore);

const limparBusca = () => {
  busca.value = "";
  folhaPagamentoStore.listar();
};

const atualizarDescricao = () => {
  folhapagamento.value.descricao = `Folha de pagamento ${mesExtenso(folhapagamento.value.mes)} de ${folhapagamento.value.ano}`;
}

const buscar = async () => {
  await folhaPagamentoStore.listar();
};

const refresh = () => {
  folhaPagamentoStore.listar();
};

const adicionarFolhaPagamento = () => {
  folhaPagamentoStore.novo();
  dialogoAdicionar.value.abrir();
};

const salvar = async () => {
  try {

    salvando.value = true;
    if (folhapagamento.value.id) {
      await folhaPagamentoStore.atualizar(folhapagamento.value);
    } else {
      await folhaPagamentoStore.salvar(folhapagamento.value);
    }

    dialogoAdicionar.value.fechar();
  } catch (error) {
    console.log(error);
  } finally {
    salvando.value = false;
  }
};

const editar = async (folha) => {
  await folhaPagamentoStore.abrir(folha.id);
  dialogoAdicionar.value.abrir();
};

const perguntarExcluir = async (folha) => {

  let resposta = await notifications.value.dialogo_pergunta.abrir({
    titulo: 'Remover folha de pagamento',
    texto: 'A folha de pagamento selecionada será excluída. Deseja continuar?',
    botoes: ['Cancelar', 'Remover'],
    padrao: 'Remover',
    cor: 'red',
  });

  if (resposta == 'Remover') {
    notifications.value.dialogo_pergunta.carregando();
    await folhaPagamentoStore.excluir(folha.id);
  }
  notifications.value.dialogo_pergunta.fechar();

};

const anos = computed(() => {
  return range(new Date().getFullYear(), 2023);
});

onMounted(() => {
  folhaPagamentoStore.listar();
});

const folhasPagamento = computed(() => {
  return folhaPagamentoStore.folhaspagamento;
});

const gerarFolha = async (folha) => {
  await folhaPagamentoStore.abrir(folha.id);
  proxy.$router.push({ name: 'ProcessarFolhaPagamento', params: { id: folha.id } });
  // await folhaPagamentoStore.abrir(folha.id);
  // dialogoProcessarFolha.value.abrir();
};

</script>

<style scoped>
  .x-small {
      font-size: 11px;
      height: 18px;
  }
</style>

