import { render, staticRenderFns } from "./DialogoUsuariosForm.vue?vue&type=template&id=48a8aa06&scoped=true&"
import script from "./DialogoUsuariosForm.vue?vue&type=script&lang=js&"
export * from "./DialogoUsuariosForm.vue?vue&type=script&lang=js&"
import style0 from "./DialogoUsuariosForm.vue?vue&type=style&index=0&id=48a8aa06&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48a8aa06",
  null
  
)

export default component.exports