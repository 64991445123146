<template>
  <z-dialogo ref="dialogo" titulo="Escolher Cor" largura="600px" altura="400px" persistent>
    <template v-slot:conteudo>

      <vue-cropper
        ref="cropper"
        class="image-container"
        :aspect-ratio="1/1"
        :guides="true"
        :background="false"
        :view-mode="3"
        drag-mode="move"
        :src="currentImage"
        alt="Image not available"
      >
      </vue-cropper>

    </template>

    <template v-slot:actions_right>
      <v-btn text @click="fechar">Cancelar</v-btn>
      <v-btn text color="primary" @click="crop">Cortar</v-btn>
    </template>
  </z-dialogo>
</template>

<script>

import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

  export default {
    name: "CropDialog",

    components: {
        VueCropper,
    },

    props: ['currentImage'],

    data() {
      return {
        cores: 'cores',
      }
    },

    methods: {
      abrir() {
        this.$refs.dialogo.abrir();
        console.log('Abrir...');
        setTimeout(() => {
          this.$refs.cropper.replace(this.currentImage);
        }, 0);
      },

      fechar() {
        this.$refs.dialogo.fechar();
        console.log('Fechar...');
      },

      crop() {
        const image = {
          canvas: null,
          blob: null
        }
        this.$refs.cropper.getCroppedCanvas().toBlob(blob => {
          image.blob = blob;
          image.canvas = this.$refs.cropper.getCroppedCanvas().toDataURL();
          this.$emit('onCrop', image);
        });
        // this.$emit('onCrop', this.$refs.cropper.getCroppedCanvas().toDataURL());

        this.fechar();
        // this.$emit('input', imagem);
      },
    },
  }
</script>

<style lang="css" scoped>
.image-container {
  width: 100%;
  height: 368px;
}

.image-container img {
  display: block;

  /* This rule is very important, please don't ignore this */
  max-width: 100%;
}
</style>
