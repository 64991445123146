import OrdemServicoLista from '@/pages/movimentacoes/ordemservico/OrdemServicoLista';
import OrdemServicoForm from '@/pages/movimentacoes/ordemservico/OrdemServicoForm';
import OrdemServicoForm2 from '@/pages/movimentacoes/ordemservico/OrdemServicoForm2';

export default [
  {
    path: '/movimentacao/ordemservico',
    name: 'OrdemServicoLista',
    component: OrdemServicoLista,
    meta: { permissao: 'ordens_servico_acessar' },
  },
  {
    path: '/movimentacao/ordemservico/adicionar',
    name: 'OrdemServicoAdicionar',
    component: OrdemServicoForm,
    meta: { permissao: 'ordens_servico_adicionar' },
  },
  {
    path: '/movimentacao/ordemservico/:id/editar',
    name: 'OrdemServicoEditar',
    component: OrdemServicoForm,
    meta: { permissao: 'ordens_servico_editar' },
  },
  {
    path: '/movimentacao/ordemservico/adicionar2',
    name: 'OrdemServicoAdicionar2',
    component: OrdemServicoForm2,
    meta: { permissao: 'ordens_servico_adicionar' },
  },
  {
    path: '/movimentacao/ordemservico/:id/editar2',
    name: 'OrdemServicoEditar2',
    component: OrdemServicoForm2,
    meta: { permissao: 'ordens_servico_editar' },
  },
];
