import TiposFuncionariosLista from '@/pages/tabelas/tiposfuncionarios/TiposFuncionariosLista'

export default [
  {
    path: '/tabelas/tiposfuncionarios',
    name: 'TiposFuncionariosLista',
    component: TiposFuncionariosLista,
    meta: { permissao: 'tiposfuncionarios_acessar' }
  },

];
