import axios from 'axios';
import createCookie from '@/app/core/CookieCore';

const cookie = createCookie();

function createAxios(config) {
  const options = {
    baseURL: config.API_URL,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      Accept: 'application/json',
      // 'Content-Type': 'application/json'
    },
  };

  const instance = axios.create(options);

  instance.interceptors.request.use(
    (request) => {
      if (cookie.getCookie('api_token')) {
        request.headers.Authorization = 'Bearer ' + cookie.getCookie('api_token');
      }
      return request;
    },
    (error) => Promise.reject(error)
  );

  instance.interceptors.response.use(
    (response) => response,
    (error) => {

      if (error.response.status == 401) {
        window.location.href = config.BASE_URL + '/#/login'
      }

      const response_error = {
        status: error.response.status,
        message: error.response.statusText,
        stack: error.response.data,
      };

      return Promise.reject(response_error);
    }
  );

  return instance;
}

export default createAxios;
