import EstoqueLista from '@/pages/movimentacoes/estoque/EstoqueLista'
import EstoqueForm from '@/pages/movimentacoes/estoque/EstoqueForm'

export default [
  {
    path: '/movimentacao/estoque',
    name: 'EstoqueLista',
    component: EstoqueLista,
    meta: { permissao: 'movimento_estoque_acessar' }
  },
  {
    path: '/movimentacao/estoque/adicionar',
    name: 'EstoqueAdicionar',
    component: EstoqueForm,
    meta: { permissao: 'movimento_estoque_adicionar' }
  },
  {
    path: '/movimentacao/estoque/:id/editar',
    name: 'EstoqueEditar',
    component: EstoqueForm,
    meta: { permissao: 'movimento_estoque_editar' }
  },
  {
    path: '/movimentacao/estoque/:id/visualizar',
    name: 'EstoqueVisualizar',
    component: EstoqueForm,
    meta: { permissao: 'movimento_estoque_visualizar' }
  },
];
