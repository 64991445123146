import Vue, { computed, reactive, getCurrentInstance } from 'vue';
import Router from 'vue-router';

import Home from '@/pages/Home';

import UsuariosRoutes from './usuarios';

import ClientesRoutes from './clientes';
import VeiculosRoutes from './veiculos';
import FuncionariosRoutes from './funcionarios';
import FornecedoresRoutes from './fornecedores';
import PecasRoutes from './pecas';
import ServicosRoutes from './servicos';
import OrdemServicoRoutes from './ordemservico';
import TiposClientesRoutes from './tiposclientes';
import TiposFuncionariosRoutes from './tiposfuncionarios';
import TiposOSRoutes from './tiposos';
import PrazosRoutes from './prazos';
import FormasPagamentoRoutes from './formaspagamento';
import BancosRoutes from './bancos';
import GruposRoutes from './grupos';
import EstoqueRoutes from './estoque';
import ComissoesRoutes from './comissoes';
import AgendaRoutes from './agenda';

import PublicoRoutes from './publico';
import SistemaRoutes from './sistema';

import { guards } from './guards.js';

Vue.use(Router);

const router = new Router({
  mode: 'hash',

  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },

  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
    },

    {
      path: '*',
      meta: { public: true },
      redirect: { path: '/404' },
    },

    ...UsuariosRoutes,

    ...ClientesRoutes,
    ...VeiculosRoutes,
    ...FuncionariosRoutes,
    ...FornecedoresRoutes,
    ...PecasRoutes,
    ...ServicosRoutes,
    ...OrdemServicoRoutes,
    ...TiposClientesRoutes,
    ...TiposFuncionariosRoutes,
    ...TiposOSRoutes,
    ...PrazosRoutes,
    ...FormasPagamentoRoutes,
    ...BancosRoutes,
    ...GruposRoutes,
    ...EstoqueRoutes,
    ...ComissoesRoutes,
    ...AgendaRoutes,

    ...PublicoRoutes,
    ...SistemaRoutes,
  ],
});

router.beforeEach(guards);

export default router;


// Adaptação para usar o Vue-router no setup vue 2.7
// import { computed, reactive, getCurrentInstance } from 'vue'

export function useRouter() {
  return getCurrentInstance().proxy.$router
}

export function useRoute() {
  // return getCurrentInstance().proxy.$route;
  const currentRoute = computed(() => getCurrentInstance().proxy.$route)

  const protoRoute = Object.keys(currentRoute.value).reduce((acc, key) => {
      acc[key] = computed(() => currentRoute.value[key])
      return acc
  },{})

  return reactive(protoRoute)
}
