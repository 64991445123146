export function dataBR (date) {
  return (new Date(date)).toLocaleDateString('pt-br', { timeZone: 'America/Recife' });
}

export function dataHoraBR(datahora) {
  if (datahora) {
    const data = (new Date(datahora)).toLocaleDateString();
    const hora = (new Date(datahora)).toLocaleTimeString();
    return data + ' ' + hora;
  }

  return null;
}

export function valorBR(valor, decimais = 2) {
  if (valor) {
    return valor.toLocaleString('pt-BR', { minimumFractionDigits: decimais, maximumFractionDigits: decimais } )
  }

  return (0).toLocaleString('pt-BR', { minimumFractionDigits: decimais, maximumFractionDigits: decimais } );
}

export function valorUS(valor) {
  if (valor) {
    let valor_us = valor.replace(/\./g, '').replace(/\,/g, '.');
    return Number(valor_us);
  }

  return 0;
}

export function datasParaIntervaloBR(datas = []) {

  if (!Array.isArray(datas)) {
    return 'Não é um array';
  }

  if (datas.length != 2) {
    return 'Informe um array com duas datas';
  }

  let intervalo = [dataBR(datas[0]), dataBR(datas[1])];
  return intervalo.join(' a ');
}
