import FornecedoresLista from '@/pages/cadastros/fornecedores/FornecedoresLista'
import FornecedoresForm from '@/pages/cadastros/fornecedores/FornecedoresForm'

export default [
  {
    path: '/cadastros/fornecedores',
    name: 'FornecedoresLista',
    component: FornecedoresLista,
    meta: { permissao: 'fornecedores_acessar' }
  },
  {
    path: '/cadastros/fornecedores/adicionar',
    name: 'FornecedoresAdicionar',
    component: FornecedoresForm,
    meta: { permissao: 'fornecedores_adicionar' }
  },
  {
    path: '/cadastros/fornecedores/:id/editar',
    name: 'FornecedoresEditar',
    component: FornecedoresForm,
    meta: { permissao: 'fornecedores_editar' }
  },
];
