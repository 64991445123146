import createAuth from '@/app/core/AuthCore'

const auth = createAuth();

export function guards (to, from, next) {

  if (to.meta.public) {
    next();
    return false;
  }

  if (!auth.check()) {
    next({ name: 'Login' });
    return false;
  }

  if (!to.meta?.permissao) {
    next();
    return false;
  }

  auth.can(to.meta.permissao) ? next() : next({ name: 'Home' });

  // next();

}