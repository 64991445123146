export default {
  // id: '',
  data_cadastro: '',
  razao: '',
  endereco: '',
  bairro: '',
  cidade: '',
  estado: 'PE',
  cep: '',
  telefone1: '',
  telefone2: '',
  telefone3: '',
  fax: '',
  email: '',
  cpf_cnpj: '',
  inscricao_estadual: '',
  inscricao_municipal: ''
}
