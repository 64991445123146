import TiposOSLista from '@/pages/tabelas/tiposos/TiposOSLista'

export default [
  {
    path: '/tabelas/tiposos',
    name: 'TiposOSLista',
    component: TiposOSLista,
    meta: { permissao: 'tiposos_acessar' }
  },

];
