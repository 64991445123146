<template>
  <div>
    <z-centralized-toolbar>
      <v-toolbar-items>
        <v-btn id="btn-adicionar" text small color="primary" @click="adicionar">
          <v-icon left>mdi-car</v-icon>Adicionar
        </v-btn>

        <v-btn text id="btn-atualizar" @click="refresh" :loading="$store.state.loading">
          <v-icon>mdi-refresh</v-icon>
          <v-tooltip bottom activator="#btn-atualizar">
            Atualizar
          </v-tooltip>
        </v-btn>
      </v-toolbar-items>

      <!-- <v-spacer></v-spacer> -->

      <z-text-field-search
        v-model="busca"
        select
        class="ml-5"
        @keydown.enter="buscar"
        @click:clear="limparBusca"
      ></z-text-field-search>
    </z-centralized-toolbar>

    <v-divider></v-divider>

    <z-scroll-layout :top-position="$v.TOP_POSITION">
      <z-container>
        <z-cabecalho>{{ $store.state.titulo_tela }}</z-cabecalho>

        <v-card>
          <v-data-table
            mobile-breakpoint="0"
            :options.sync="paginacao"
            :server-items-length="total_itens"
            :footer-props="$v.FOOTER_PROPS"
            :headers="headers"
            :items="veiculos"
            item-key="id"
          >
            <template v-slot:item="{ item: veiculo }">
              <tr>
                <td>{{ veiculo.placa }}</td>
                <td>{{ veiculo.marca }}</td>
                <td>{{ veiculo.modelo }}</td>
                <td>{{ veiculo.ano }}</td>
                <td>{{ veiculo.combustivel }}</td>
                <td class="text-center text-no-wrap">{{ veiculo.ordensservico_count }}</td>
                <td class="text-center">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn icon @click="ordensServico(veiculo)" v-on="on">
                        <v-icon>mdi-format-list-checkbox</v-icon>
                      </v-btn>
                    </template>
                    <span>Ordens de Serviço</span>
                  </v-tooltip>
                  <v-btn icon @click="editar(veiculo)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn icon @click="perguntarExcluir(veiculo)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </z-container>
    </z-scroll-layout>

    <z-dialogo-sim-nao
      ref="dialogoRemoverVeiculo"
      titulo="Remover veiculo?"
      botaoSim="Remover"
      botaoNao="Cancelar"
      :loading="excluindo"
    >
      O veículo será removido do sistema.
    </z-dialogo-sim-nao>

    <veiculos-ordens-servico
      ref="dialogoOrdensServico"
      v-if="veiculo.ordensservico != undefined"
    ></veiculos-ordens-servico>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from 'vuex';
import VeiculosOrdensServico from './VeiculosOrdensServico';

export default {
  name: 'VeiculosLista',

  components: {
    VeiculosOrdensServico,
  },

  data() {
    return {
      headers: [
        { text: 'Placa', value: 'Placa', width: '150px' },
        { text: 'Marca', value: 'Marca' },
        { text: 'Modelo', value: 'Modelo' },
        { text: 'Ano', value: 'Ano' },
        { text: 'Combustivel', value: 'Combustivel' },
        { text: 'Serviços', value: 'ordensservico_count', align: 'center' },
        {
          text: 'Opções',
          value: '',
          width: '200px',
          align: 'center',
          sortable: false,
        },
      ],
      excluindo: false,
    };
  },

  created() {
    this.refresh();
  },

  mounted() {
    this.setTitulo('Veículos');
  },

  methods: {
    ...mapMutations(['setTitulo', 'LOADING_OFF']),
    ...mapActions('veiculos', ['listar', 'excluir', 'abrir']),

    async refresh() {
      try {
        await this.listar();
      } catch (error) {
        console.log(error);
        this.$eventbus.notificar('Não foi possível listar os veículos.');
      }
    },

    buscar() {
      this.$store.commit('veiculos/SET_PAGE', 1);
      this.refresh();
    },

    limparBusca() {
      this.busca = null;
      this.$store.commit('veiculos/SET_PAGE', 1);
      this.refresh();
    },

    adicionar() {
      this.$store.commit('veiculos/ZERAR');
      this.$router.push({ name: 'VeiculosAdicionar' });
    },

    async editar(veiculo) {
      try {
        this.$store.commit('veiculos/ZERAR');
        await this.abrir(veiculo.id);
        this.$router.push({
          name: 'VeiculosEditar',
          params: { id: veiculo.id },
        });
      } catch (error) {
        console.log(error);
        this.$eventbus.notificar('Não foi possível abrir o veículo.');
      } finally {
        this.LOADING_OFF();
      }
    },

    perguntarExcluir(veiculo) {
      this.$refs.dialogoRemoverVeiculo.abrir().then(async (response) => {
        if (response == true) {
          await this.confirmarExcluir(veiculo);
          this.$refs.dialogoRemoverVeiculo.fechar();
        }
      });
    },

    async confirmarExcluir(veiculo) {
      try {
        this.excluindo = true;
        await this.excluir(veiculo.id);
        this.$eventbus.notificar('Veículo excluído com sucesso!');
      } catch (error) {
        this.$eventbus.notificar('Não foi possível excluir o automóvel!');
        throw error;
      } finally {
        this.excluindo = false;
      }
    },

    async ordensServico(veiculo) {
      await this.abrir(veiculo.id);
      this.$refs.dialogoOrdensServico.abrir();
    },
  },

  computed: {
    ...mapState(['loading']),
    ...mapState('veiculos', ['veiculos', 'veiculo', 'total_itens']),

    busca: {
      get() {
        return this.$store.state.veiculos.busca;
      },
      set(busca) {
        this.$store.commit('veiculos/SET_BUSCA', busca);
      },
    },

    paginacao: {
      get() {
        return this.$store.state.veiculos.paginacao;
      },
      set(paginacao) {
        this.$store.commit('veiculos/UPDATE_PAGINACAO', paginacao);
      },
    },
  },

  watch: {
    paginacao: {
      handler() {
        this.refresh();
      },
      deep: true,
    },
  },
};
</script>

<style scoped></style>
