import { valorUS } from "@/app/helpers/formatacoes";

export function validarCPF(cpf) {
  cpf = cpf.replace(/[^\d]+/g, "");
  if (cpf == "") return false;
  // Elimina CPFs invalidos conhecidos
  if (
    cpf.length != 11 ||
    cpf == "00000000000" ||
    cpf == "11111111111" ||
    cpf == "22222222222" ||
    cpf == "33333333333" ||
    cpf == "44444444444" ||
    cpf == "55555555555" ||
    cpf == "66666666666" ||
    cpf == "77777777777" ||
    cpf == "88888888888" ||
    cpf == "99999999999"
  )
    return false;
  // Valida 1o digito
  let add = 0;
  let rev = 0;
  let i = 0;
  for (i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
  rev = 11 - (add % 11);
  if (rev == 10 || rev == 11) rev = 0;
  if (rev != parseInt(cpf.charAt(9))) return false;
  // Valida 2o digito
  add = 0;
  for (i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);

  rev = 11 - (add % 11);
  if (rev == 10 || rev == 11) rev = 0;
  if (rev != parseInt(cpf.charAt(10))) return false;
  return true;
}

export function validarCNPJ(cnpj) {
  cnpj = cnpj.replace(/[^\d]+/g, "");

  if (cnpj == "") return false;

  if (cnpj.length != 14) return false;

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj == "00000000000000" ||
    cnpj == "11111111111111" ||
    cnpj == "22222222222222" ||
    cnpj == "33333333333333" ||
    cnpj == "44444444444444" ||
    cnpj == "55555555555555" ||
    cnpj == "66666666666666" ||
    cnpj == "77777777777777" ||
    cnpj == "88888888888888" ||
    cnpj == "99999999999999"
  )
    return false;

  // Valida DVs
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  let i = 0;
  let resultado = 0;

  for (i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }

  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(0)) return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;

  for (i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }

  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado != digitos.charAt(1)) return false;

  return true;
}

export function validarCPFCNPJ(cpfcnpj) {
  if (cpfcnpj) {
    if (cpfcnpj.replace(/\D/g, "").length > 11) {
      return validarCNPJ(cpfcnpj);
    } else {
      return validarCPF(cpfcnpj);
    }
  }
}

export function campoObrigatorio(v) {
  return !!v || 'Campo obrigatório';
}

export function maiorQueZero(v) {
  return (valorUS(v) > 0) || 'Valor inválido';
}

export function naoNegativo(v) {
  return (valorUS(v) >= 0) || 'Valor negativo';
}

export function emailValido(v) {
  return /.+@.+\..+/.test(v) || 'E-mail inválido';
}


export function dataValida(date) {
  if (!date) {
    return true;
  }

  const message = "Data inválida. (DD/MM/AAAA)";

  date = date.split('-').reverse().join('/');

  if (date.length < 10) {
    return message;
  }

  let [ dia, mes, ano ] = date.split('/');

  if (Number(dia) > 31 || Number(mes) > 12 || Number(ano) == 0) {
    return message;
  }

  let pattern = /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/;
  return pattern.test(date) || message;
}
