import createAxios from '@/app/core/AxiosCore';
import config from '@/app/config/app';

const axios = createAxios(config);
import qs from "qs";


function listar(paginacao) {
  return axios.get(`veiculos?${qs.stringify(paginacao)}`)
    .then(response => response.data);
}

function buscar(busca) {
  return axios.get(`veiculos/buscar?busca=${busca}`)
    .then(response => response.data);
}

function buscarVeiculoPelaPlaca(placa) {
  return axios.get(`veiculos/buscarPlaca?placa=${placa}`)
    .then(response => response.data);
}

function abrir(id) {
  return axios.get(`/veiculos/${id}`)
    .then(response => response.data);
}

function salvar(veiculo) {
  return axios.post('/veiculos', veiculo)
    .then(response => response.data);
}

function atualizar(veiculo) {
  return axios.put(`/veiculos/${veiculo.id}`, veiculo)
    .then(response => response.data)
}

function excluir(id) {
  return axios.delete(`/veiculos/${id}`)
    .then(response => response.data);
}


export default {
  listar,
  buscar,
  buscarVeiculoPelaPlaca,
  abrir,
  salvar,
  atualizar,
  excluir
}
