<template>
  <div>

    <z-centralized-toolbar>

      <v-toolbar-items>
        <v-btn id="btn-adicionar" text small color="primary" @click="adicionar">
          <v-icon left>mdi-clipboard-list-outline</v-icon>Adicionar
        </v-btn>

        <v-btn text id="btn-atualizar" @click="refresh" :loading="$store.state.loading">
          <v-icon>mdi-refresh</v-icon>
          <v-tooltip bottom activator="#btn-atualizar">
            Atualizar
          </v-tooltip>
        </v-btn>
      </v-toolbar-items>

      <!-- <v-spacer></v-spacer> -->

      <z-text-field-search
        v-model="busca"
        select
        class="ml-5"
        @keydown.enter="buscar"
        @click:clear="limparBusca"
      ></z-text-field-search>
    </z-centralized-toolbar>

    <v-divider></v-divider>

    <z-scroll-layout :top-position="$v.TOP_POSITION">
      <z-container>
            
        <z-cabecalho>{{$store.state.titulo_tela}}</z-cabecalho>
        
        <v-card>
          <v-data-table
          mobile-breakpoint="0"
            :options.sync="paginacao" 
            :server-items-length="total_itens" 
            :footer-props="$v.FOOTER_PROPS" 
            :headers="headers"
            :items="tiposos"
            item-key="id"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.id }}</td>
                <td>{{ item.descricao }}</td>
                <td class="text-center">{{ item.comissao_peca }}</td>
                <td class="text-center">{{ item.comissao_servico }}</td>
                <td class="text-center">{{ item.cobra_peca }}</td>
                <td class="text-center">{{ item.cobra_servico }}</td>
                <td class="text-center">
                  <v-btn icon @click="editar(item)" class="ma-0">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn icon @click="perguntarExcluir(item)" class="ma-0">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
        
      </z-container>
    </z-scroll-layout>

    <z-dialogo-sim-nao ref="dialogoRemover" titulo="Remover tipo de OS?" botaoSim="Remover" botaoNao="Cancelar" :loading="excluindo">
      A tipo de OS será removido do sistema.
    </z-dialogo-sim-nao>

    <v-form v-model="form_valido" lazy-validation ref="formulario">
      <z-dialogo ref="dialogoAdicionar" largura="800px" titulo="Tipo de OS" dividers persistent @fechar="$refs.formulario.resetValidation()">

          <template v-slot:conteudo>
            <v-row dense>
              <v-col cols="12">
                <z-text-field caps="upper" v-model="tipoos.descricao" ref="descricao" label="Descrição" placeholder=" " :rules="[regras.campoObrigatorio]"></z-text-field>
              </v-col>
              <v-col cols="3">
                <z-select v-model="tipoos.comissao_peca" :items="['S', 'N']" label="Comissão Peça" placeholder=" "></z-select>
              </v-col>
              <v-col cols="3">
                <z-select v-model="tipoos.comissao_servico" :items="['S', 'N']" label="Comissão Serviço" placeholder=" "></z-select>
              </v-col>
              <v-col cols="3">
                <z-select v-model="tipoos.cobra_peca" :items="['S', 'N']" label="Cobra Peça" placeholder=" "></z-select>
              </v-col>
              <v-col cols="3">
                <z-select v-model="tipoos.cobra_servico" :items="['S', 'N']" label="Cobra Serviçco" placeholder=" "></z-select>
              </v-col>
            </v-row>
          </template>

          <template v-slot:actions_right>
            <v-btn text @click="$refs.dialogoAdicionar.fechar()" :disabled="salvando">Cancelar</v-btn>
            <v-btn color="primary" ref="btnConfirmar" @click="confirmar" :loading="salvando" :disabled="!form_valido">Confirmar</v-btn>
          </template>
      </z-dialogo>
    </v-form>

  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from "vuex";

import * as regras from '@/app/helpers/validacoes';

export default {
  name: 'TiposOSLista',
  data() {
    return {
      headers: [
        { text: "Código", value: "id", width: "150px" },
        { text: "Descrição", value: "descricao" },
        { text: "Comissão Peças", value: "comissao_peca", align: "center" },
        { text: "Comissão Serviços", value: "comissao_servico", align: "center" },
        { text: "Cobra  Peças", value: "cobra_peca", align: "center" },
        { text: "Cobra Serviços", value: "cobra_servico", align: "center" },
        { text: "Opções", value: "", width: "200px", align: "center", sortable: false },
      ],
      excluindo: false,
      salvando: false,
      form_valido: true,
      regras: {...regras}
    };
  },

  computed: {
    ...mapState(['loading']),
    ...mapState('tiposos', ['tiposos', 'tipoos', 'total_itens']),

    busca: {
      get () {
        return this.$store.state.tiposos.busca
      },
      set (busca) {
        this.$store.commit('tiposos/SET_BUSCA', busca);
      }
    },

    paginacao: {
      get () {
        return this.$store.state.tiposos.paginacao
      },
      set (paginacao) {
        this.$store.commit('tiposos/UPDATE_PAGINACAO', paginacao);
      }
    },
  },

  created() {
    this.refresh();
  },

  mounted() {
    this.setTitulo("Tipos de OS");
  },

  methods: {
    ...mapMutations(["setTitulo", 'LOADING_OFF']),
    ...mapActions("tiposos", ["listar", "excluir", "abrir", "atualizar", "salvar"]),

    async refresh() {
      try {
        await this.listar();
      } catch (error) {
        console.log(error);
        this.$eventbus.notificar("Não foi possível listar os tipos de funcionários.");
      }
    },

    buscar() {
      this.$store.commit('tiposos/SET_PAGE', 1);
      this.refresh();
    },
    
    limparBusca() {
      this.busca = null;
      this.$store.commit('tiposos/SET_PAGE', 1);
      this.refresh();
    },

    adicionar() {
      this.$store.commit('tiposos/ZERAR');
      this.$refs.dialogoAdicionar.abrir();
    },

    async editar(tipoos) {
      try {

        await this.abrir(tipoos.id);
        this.$refs.dialogoAdicionar.abrir();
        
      } catch (error) {
        console.log(error);
        this.$eventbus.notificar("Não foi possível abrir a tipo de OS.");
      } finally {
        this.LOADING_OFF();
      }
    },

    async confirmar() {
      if (!this.$refs.formulario.validate()) {
        return false;
      }

      try {
        this.salvando = true;

        if (this.tipoos.id) {
          await this.atualizar(this.tipoos);
        } else {
          await this.salvar(this.tipoos);
        }

        this.$refs.dialogoAdicionar.fechar();
        this.$eventbus.notificar("Tipo de OS salvo com sucesso");
      } catch(error) {
        this.$eventbus.notificarErro('Não foi possível salvar esta tipo de OS. Verifique os dados preenchidos.', error);
        throw error;
      } finally {
          this.salvando = false;
      }
    },

    perguntarExcluir(tipoos) {
      this.$refs.dialogoRemover.abrir()
        .then(async response => {
          if (response == true) {
            await this.confirmarExcluir(tipoos);
            this.$refs.dialogoRemover.fechar();
          }
        })
    },

    async confirmarExcluir(tipoos) {
      try {
        this.excluindo = true;
        await this.excluir(tipoos.id);
        this.$eventbus.notificar("Tipo excluído com sucesso!");
      } catch (error) {
        this.$eventbus.notificar("Não foi possível excluir o tipo de OS!");
        throw error;
      } finally {
        this.excluindo = false;
      }
    },
  },

  watch: {
    paginacao: {
      handler() {
        this.refresh();
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
</style>
