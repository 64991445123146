import VeiculosLista from '@/pages/cadastros/veiculos/VeiculosLista'
import VeiculosForm from '@/pages/cadastros/veiculos/VeiculosForm'

export default [
  {
    path: '/cadastros/veiculos',
    name: 'VeiculosLista',
    component: VeiculosLista,
    meta: { permissao: 'veiculos_acessar' }
  },
  {
    path: '/cadastros/veiculos/adicionar',
    name: 'VeiculosAdicionar',
    component: VeiculosForm,
    meta: { permissao: 'veiculos_adicionar' }
  },
  {
    path: '/cadastros/veiculos/:id/editar',
    name: 'VeiculosEditar',
    component: VeiculosForm,
    meta: { permissao: 'veiculos_editar' }
  },
];
