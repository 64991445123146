<template>
  <div style="margin-bottom: 200px;">
    <v-card class="mb-1 rounded-lg" outlined v-for="(evento, index) in eventos" :key="index">
      <v-list class="pa-0" three-line>

          <v-list-item :key="evento.id" @click="editar(evento)" :ripple="false">
            <template v-slot:default="{ active }">
              <v-list-item-content>
                <v-list-item-title>{{ evento.nome }} <span class="text--disabled" v-if="evento.telefone"> - {{ evento.telefone }}</span></v-list-item-title>
                <v-list-item-subtitle class="text--primary" v-text="evento.placa"></v-list-item-subtitle>
                <v-list-item-subtitle v-text="evento.observacao"></v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action class="align-center">
                <v-list-item-action-text class="mb-3">Agendado para: {{ evento.data | dataBR }} </v-list-item-action-text>

                <v-chip
                  v-if="!evento.confirmado"
                  small
                  label
                  outlined
                  color="green lighten-1"
                  @click.stop="confirmarEvento(evento)"
                >Não Confirmado</v-chip>
                <v-chip
                  v-else
                  small
                  label
                  color="green lighten-1"
                  class="white--text"
                  @click.stop="confirmarEvento(evento)"
                >Confirmado</v-chip>
              </v-list-item-action>
            </template>
          </v-list-item>
      </v-list>
    </v-card>

    <AgendaForm ref="agendaForm"></AgendaForm>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from 'vuex';
import AgendaForm from './AgendaForm.vue';



export default {
  name: 'CalendarioListagem',

  props: {
    eventos: {
      type: Array,
      default: () => []
    },
  },

  components: {
    AgendaForm
  },

  data() {
    return {
      selected: []
    }
  },


  computed: {
    ...mapState(['loading']),
    ...mapState('agenda', ['evento', 'total_itens']),
  },

  methods: {

    ...mapActions('agenda', ['listar', 'excluir', 'abrir', 'atualizar', 'salvar', 'confirmarEvento']),

    // adicionar() {
    //   this.$store.commit('agenda/ZERAR', this.focus);
    //   this.$refs.agendaForm.abrir();
    // },

    async editar(evento) {
      await this.abrir(evento.id);
      this.$refs.agendaForm.abrir();
    },

  },
}

</script>

<style scoped>

</style>
