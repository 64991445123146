<template>
  <div>
    <z-centralized-toolbar>
      <v-toolbar-items>

        <v-btn id="btn-adicionar" text small color="primary" @click="adicionar">
          <v-icon left>{{ $icon.FUNCIONARIOS }}</v-icon>Adicionar
        </v-btn>

        <v-btn text id="btn-atualizar" @click="refresh" :loading="$store.state.loading">
          <v-icon>mdi-refresh</v-icon>
          <v-tooltip bottom activator="#btn-atualizar">
            Atualizar
          </v-tooltip>
        </v-btn>
      </v-toolbar-items>


      <!-- <v-spacer></v-spacer> -->

      <z-text-field-search
        v-model="busca"
        select
        class="ml-5"
        @keydown.enter="buscar"
        @click:clear="limparBusca"
      ></z-text-field-search>
    </z-centralized-toolbar>

    <v-divider></v-divider>

    <z-scroll-layout :top-position="$v.TOP_POSITION">
      <z-container>

        <z-cabecalho>{{$store.state.titulo_tela}}</z-cabecalho>

        <v-card>
          <v-data-table
            mobile-breakpoint="0"
            :options.sync="paginacao"
            :server-items-length="total_itens"
            :footer-props="$v.FOOTER_PROPS"
            :headers="headers"
            :items="funcionarios"
            item-key="id"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>{{ item.id }}</td>
                <td>{{ item.nome }}</td>
                <td>{{ item.telefone }}</td>
                <td>{{ item.tipo_funcionario.descricao }} <span>({{ item.tipo_funcionario.comissao_servicos }}%)</span></td>
                <td>
                  <v-chip v-if="item.data_demissao != null" small color="orange" dark>Desligado</v-chip>
                  <v-chip v-else-if="item.ativo == 1" class="mr-1" small color="green" dark>Ativo</v-chip>
                  <v-chip v-else-if="item.ativo == 0" class="mr-1" small color="red" dark>Inativo</v-chip>
                </td>
                <td class="text-center">
                  <v-btn icon @click="editar(item)" class="ma-0">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn icon @click="perguntarExcluir(item)" class="ma-0">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>

      </z-container>
    </z-scroll-layout>

    <z-dialogo-sim-nao ref="dialogoRemoverFuncionario" titulo="Remover funcionario?" botaoSim="Remover" botaoNao="Cancelar" :loading="excluindo">
      O funcionário será removido do sistema.
    </z-dialogo-sim-nao>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from "vuex";
export default {
  name: 'FuncionariosLista',
  data() {
    return {
      headers: [
        { text: "Código", value: "id", width: "150px" },
        { text: "Nome", value: "nome" },
        { text: "Telefone", value: "telefone", sortable: false },
        { text: "Tipo", value: "tipo_funcionario.descricao", sortable: false },
        { text: "Status", sortable: false },
        { text: "Opções", width: "200px", align: "center", sortable: false },
      ],
      excluindo: false
    };
  },

  created() {
    this.refresh();
  },

  mounted() {
    this.setTitulo("Funcionários");
  },

  methods: {
    ...mapMutations(["setTitulo", 'LOADING_OFF']),
    ...mapActions("funcionarios", ["listar", "excluir", "abrir"]),

    async refresh() {
      try {
        await this.listar();
      } catch (error) {
        console.log(error);
        this.$eventbus.notificar("Não foi possível listar os funcionários.");
      }
    },

    buscar() {
      this.$store.commit('funcionarios/SET_PAGE', 1);
      this.refresh();
    },

    limparBusca() {
      this.busca = null;
      this.$store.commit('funcionarios/SET_PAGE', 1);
      this.refresh();
    },

    adicionar() {
      this.$store.commit('funcionarios/ZERAR');
      this.$router.push({ name: "FuncionariosAdicionar" });
    },

    async editar(funcionario) {
      try {

        await this.abrir(funcionario.id);
        this.$router.push({ name: "FuncionariosEditar", params: { id: funcionario.id } });

      } catch (error) {
        console.log(error);
        this.$eventbus.notificar("Não foi possível abrir o funcionário.");
      } finally {
        this.LOADING_OFF();
      }
    },

    perguntarExcluir(funcionario) {
      this.$refs.dialogoRemoverFuncionario.abrir()
        .then(async response => {

          if (response == true) {
            await this.confirmarExcluir(funcionario);
            this.$refs.dialogoRemoverFuncionario.fechar();
          }
        })
    },

    async confirmarExcluir(funcionario) {
      try {
        this.excluindo = true;
        await this.excluir(funcionario.id);
        this.$eventbus.notificar("Funcionário excluído com sucesso!");
      } catch (error) {
        this.$eventbus.notificar("Não foi possível excluir o funcionário!");
        throw error;
      } finally {
        this.excluindo = false;
      }
    },
  },

  computed: {
    ...mapState(['loading']),
    ...mapState('funcionarios', ['funcionarios', 'funcionario', 'total_itens']),

    busca: {
      get () {
        return this.$store.state.funcionarios.busca
      },
      set (busca) {
        this.$store.commit('funcionarios/SET_BUSCA', busca);
      }
    },

    paginacao: {
      get () {
        return this.$store.state.funcionarios.paginacao
      },
      set (paginacao) {
        this.$store.commit('funcionarios/UPDATE_PAGINACAO', paginacao);
      }
    },
  },

  watch: {
    paginacao: {
      handler() {
        this.refresh();
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
</style>
