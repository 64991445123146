import TiposClientesLista from '@/pages/tabelas/tiposclientes/TiposClientesLista'

export default [
  {
    path: '/tabelas/tiposclientes',
    name: 'TiposClientesLista',
    component: TiposClientesLista,
    meta: { permissao: 'tiposclientes_acessar' }
  }
];
