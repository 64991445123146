import apiPrazos from "@/app/services/prazos";
import { PAGINATION_OPTIONS } from "@/app/vuetifyConfig";

export default {
  namespaced: true,
  state: {
    prazo: {
      id: '',
      descricao: '',
      parcelas: 1,
      juros: 0
    },
    prazos: [],
    busca: "",
    total_itens: 0,
    paginacao: {
      page: 1,
      itemsPerPage: 10,
      sortBy: ['descricao'],
      sortDesc: [false]
    }
  },

  mutations: {
    LISTAR(state, prazos) {
      state.prazos = prazos;
    },

    ABRIR(state, prazo) {
      state.prazo = prazo;
    },

    ADICIONAR(state, prazo) {
      state.prazos.push(prazo);
    },

    ATUALIZAR(state, prazo) {
      let index = state.prazos.findIndex(peca_ => peca_.id == prazo.id);
      if (index > -1) {
        state.prazos.splice(index, 1, prazo);
      }
    },

    EXCLUIR(state, id) {
      let index = state.prazos.findIndex(prazo => prazo.id == id);
      if (index > -1) {
        state.prazos.splice(index, 1);
      }
    },

    SET_BUSCA(state, busca) {
      state.busca = busca;
    },

    SET_PAGE(state, page) {
      state.paginacao.page = page;
    },

    SET_TOTAL_ITENS(state, total_itens) {
      state.total_itens = total_itens;
    },

    UPDATE_PAGINACAO(state, paginacao) {
      state.paginacao = paginacao;
    },

    ZERAR(state) {
      state.prazo = {
        id: '',
        descricao: '',
        parcelas: 1,
        juros: 0
      };
    }
  },

  actions: {
    async listar({ commit, state }) {
      commit('LOADING_ON', null, { root: true });

      const { sortBy, sortDesc, page, itemsPerPage } = state.paginacao;

      const paginacao = {
        page, 
        itemsPerPage,
        sortBy: sortBy[0], 
        sortDesc: sortDesc[0]
      }

      if (state.busca) {
        paginacao.busca = state.busca;
      }

      let response = await apiPrazos.listar(paginacao);
      commit('LISTAR', response.data);
      commit('SET_TOTAL_ITENS', response.total);
      commit('LOADING_OFF', null, { root: true });
    },

    async abrir({ commit }, id) {
      commit('LOADING_ON', null, { root: true });
      let prazo = await apiPrazos.abrir(id);
      commit('ABRIR', prazo);
      commit('LOADING_OFF', null, { root: true });
    },

    async salvar({ commit }, prazo) {
      let novopeca = await apiPrazos.salvar(prazo);
      commit('ADICIONAR', novopeca);
    },

    async atualizar({ commit }, prazo) {
      await apiPrazos.atualizar(prazo);
      commit('ATUALIZAR', prazo);
    },

    async excluir({ commit }, id) {
      await apiPrazos.excluir(id);
      commit('EXCLUIR', id);
    },

  }
}
