<template>
  <div>
    <z-notificacao ref="notificacao"></z-notificacao>
    <z-alerta ref="alerta" persistent></z-alerta>
    <z-dialogo-pergunta ref="dialogo_pergunta"></z-dialogo-pergunta>
  </div>
</template>

<script setup>

  import { ref, getCurrentInstance } from 'vue';

  const instante = getCurrentInstance();
  const { $auth, $router }= instante.proxy;

  const alerta = ref();
  const notificacao = ref();
  const dialogo_pergunta = ref();

  let mensagemSessao = {
    titulo: 'Atenção!',
    mensagem: 'Sua sessão expirou. Efetue o login novamente.',
  }

  function logar(mensagem) {
    console.log(mensagem);
  }

  function notificar(mensagem){
    notificacao.value.exibir({
      mensagem: mensagem
    })
  }

  function alertar(msg){
    let mensagem = {
      titulo: 'Atenção!',
      mensagem: ''
    }

    if (typeof msg == 'string') {
      mensagem.mensagem = msg;
    }

    if (typeof msg == 'object') {
      mensagem.titulo = msg.titulo;
      mensagem.mensagem = msg.mensagem;
    }

    alerta.value.alertar(mensagem);
  }


  function notificarErro(message, error) {
    if (error && error.status == 401) {
      alerta.value.alertar(mensagemSessao, function() {
        $auth.logout();
        $router.push('/login');
      });
      return false;
    }

    erroSistema({
      message: message || '',
      error: error || null
    });

  }

  function erroSistema(error_object) {
    let mensagem = error_object.message || 'Erro do Sistema';

    if (error_object.error) {

      // Erro de Request da Api
      if (error_object.error.status) {
        let { message, status, stack } = error_object.error;
        mensagem = `${mensagem} <br>`;
        mensagem += `Status: ${status} - Mensagem: ${message}<br>`;
        mensagem += `${stack.message}`;
        // if (error_object.error.stack.errors) {
        //   for(error in error_object.error.stack.errors) {
        //     error_object.error.stack.errors[error].forEach(e => {
        //       mensagem += `<br> + ${error}: ${e}`;
        //     });
        //   };
        // }
      }

      // Erro de Runtime
      if (error_object.error.name) {
        let { message, code, name } = error_object.error;
        mensagem = `${mensagem} <br>`;
        mensagem += `Status: ${code}<br>Nome: ${name}<br>`;
        mensagem += `Mensagem: ${message}`;
        // mensagem = `${mensagem}<br> Code: ${error_object.error.code} <br> " ${error_object.error.name} - ${error_object.error.message}"`;
      }
    }

    notificacao.value.exibir({
      mensagem: mensagem,
      cor     : 'error',
      timeout : 5000
    });
  }

  // async function perguntar(opcoes) {
  //   return await dialogo_pergunta.value.abrir(opcoes);
  // }

  defineExpose({
    alerta,
    logar,
    notificar,
    alertar,
    notificarErro,
    dialogo_pergunta
    // perguntar
  })
</script>

<style lang="scss" scoped>

</style>
