<template>

  <div class="splitter">
    <div v-show="!hideLeft" ref="panel1" id="panel1" tile class="splitter-panels" :style="panel_left">
      <slot name="panel1" v-bind:left="left"></slot>
    </div>

    <div v-show="!hideLeft" ref="splitter" id="splitter" class="gutter"></div>

    <div ref="panel2" id="panel2" tile class="splitter-panels" :style="panel_right">
      <slot name="panel2" v-bind:right="right"></slot>
    </div>
  </div>

</template>

<script>
  export default {
    name: 'z-splitter',

    props: {
      leftWidth: {
        type: [Number, String],
        default: 300
      },

      hideLeft: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        splitter: null,
        panel1: null,
        panel2: null,
        window_width: null,
        percentage: null,
        left: null,
        right: null,
        active: false,
        gutterSize: 1
      }
    },

    mounted () {
      this.init();
    },

    methods: {

      init() {

        this.splitter = this.$refs.splitter;
        this.panel1   = this.$refs.panel1;
        this.panel2   = this.$refs.panel2;

      },

    },

    computed: {
      panel_left() {
        return {
          width: this.leftWidth,
          maxWidth: this.leftWidth,
          minWidth: this.leftWidth,
        }
      },

      panel_right() {

        if (this.hideLeft) {
          return {
            width: '100%',
            maxWidth: '100%',
            minWidth: '100%',
          }
        }
        return {
          width: 'calc(100% - 1px - ' + this.leftWidth +')',
          maxWidth: 'calc(100% - 1px - ' + this.leftWidth +')',
          minWidth: 'calc(100% - 1px - ' + this.leftWidth +')',
        }
      },

    },

  }
</script>


<style lang="scss" scoped>

.splitter {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.splitter > .splitter-panels {
  flex: 0 1 auto;
  overflow-y: auto;
  min-height: 0px;
  height: 100%;
  transition: display 2s ease-in-out;

  &:hover {
    overflow-y: auto;
  }
}


.splitter > .gutter {
  width: 1px;
  position: relative;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  flex-shrink: 0;
  flex-grow: 0;
  z-index: 1;
}
</style>

<style>
.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
