<template>
  <v-form v-model="form_valido" lazy-validation ref="formulario">
    <z-dialogo ref="dialogoAdicionar" largura="800px" titulo="Lançamento na Folha" dividers persistent @abrir="$refs.formulario.resetValidation()">
      <template v-slot:conteudo>
        <v-row dense>
          <!-- <v-col cols="3">
            <z-date-picker-menu
              v-model="lancamento.data"
              ref="data"
              label="Data"
              :rules="[regras.campoObrigatorio]"
            ></z-date-picker-menu>
          </v-col> -->
          <v-col cols="12">
            <z-text-field :value="funcionario.nome" ref="funcionario" label="Funcionário" readonly></z-text-field>
          </v-col>
          <v-col cols="12">
            <z-text-field v-model="lancamento.descricao" ref="descricao" label="Descrição" :rules="[regras.campoObrigatorio]" autofocus></z-text-field>
          </v-col>
          <v-col cols="6">
            <z-campo-valor
              v-model="lancamento.valor"
              ref="valor"
              label="Valor"
              :rules="[regras.campoObrigatorio]"
            ></z-campo-valor>
          </v-col>
          <v-col cols="6">
            <z-select v-model="lancamento.tipo" ref="descricao" label="Tipo" :items="tipos" :rules="[regras.campoObrigatorio]"></z-select>
          </v-col>
        </v-row>
      </template>

      <template v-slot:actions_right>
        <v-btn text @click="$refs.dialogoAdicionar.fechar()" :disabled="salvando">Cancelar</v-btn>
        <v-btn depressed color="primary" ref="btnConfirmar" @click="salvar" :loading="salvando" :disabled="!form_valido">Confirmar</v-btn>
      </template>
    </z-dialogo>
  </v-form>
</template>

<script setup>

import { ref } from 'vue'
import { storeToRefs } from 'pinia';

import { hoje } from '@/app/helpers/utils'
import { useFolhaPagamentoStore } from '@/app/store/pinia/folhapagamento';

const folhaPagamentoStore = useFolhaPagamentoStore();
const { lancamento, funcionario } = storeToRefs(folhaPagamentoStore);

const form_valido = ref(false)
const salvando = ref(false)

const dialogoAdicionar = ref()
const tipos = [
  { text: 'Provento', value: 'Provento' },
  { text: 'Desconto', value: 'Desconto' },
]

const regras = {
  campoObrigatorio: (v) => !!v || 'Campo obrigatório',
}

const abrir = () => {
  dialogoAdicionar.value.abrir()
}

const fechar = () => {
  dialogoAdicionar.value.fechar()
}

const salvar = async () => {
  if (form_valido.value) {
    salvando.value = true;
    if (lancamento.value.id) {
      await folhaPagamentoStore.atualizarLancamento();
    } else {
      await folhaPagamentoStore.salvarLancamento();
    }
    salvando.value = false
    fechar();
  }
}

defineExpose({
  abrir,
  fechar
})

</script>

<style lang="scss" scoped>

</style>
