<template>
  <z-dialogo ref="dialogo" largura="1000px" altura="500px" titulo="Histórico Peça" persistent no-padding scrollable>

      <template v-slot:prepend>
        <v-tabs color="primary" v-model="tab">
          <v-tab>Histórico</v-tab>
          <v-tab>Resumo</v-tab>
          <v-tab>Gráfico</v-tab>
          <v-tabs-slider color="primary"></v-tabs-slider>
        </v-tabs>
      </template>

      <template v-slot:conteudo>

        <z-loading v-if="loading" :loading="loading"></z-loading>

        <v-tabs-items v-model="tab" v-else>
          
          <v-tab-item :key="0">
            <historico-lista :historico="historico"></historico-lista>
          </v-tab-item>

          <v-tab-item :key="1">
            <historico-resumo :resumo="resumo"></historico-resumo>
          </v-tab-item>

          <v-tab-item :key="2">
            <div class="d-flex justify-center pt-4">
              <bar-chart :dados="dados_grafico"></bar-chart>
            </div>
          </v-tab-item>

        </v-tabs-items>

      </template>

      <template v-slot:actions_right>
        <v-btn color="primary" @click="fechar">Fechar</v-btn>
      </template>
  </z-dialogo>
</template>

<script>

  import BarChart from "@/components/graficos/BarChart";
  import HistoricoResumo from './HistoricoResumo';
  import HistoricoLista from './HistoricoLista';

  export default {
    
    components: {
      BarChart,
      HistoricoResumo,
      HistoricoLista
    },

    props: {
      codigo: {
        type: [String, Number],
        default: ''
      }
    },

    data() {
      return {
        tab: 0,
        loading: false,
        value: [ 423, 446, 675, 510, 590, 610, 760 ],
        dados_grafico: {},
        resumo: {
          ultima_os: 1234,
          data_ultima_os: '16/03/2021',
          ultima_venda: 14123,
          data_ultima_venda: '16/03/2021',
          ultima_entrada: 14123,
          data_ultima_entrada: '16/03/2021',
        },
        historico: []
      }
    },

    methods: {
      abrir() {
        this.tab = 0;
        // console.log(this.codigo);
        // await this.getDadosGrafico(this.codigo);
        this.$refs.dialogo.abrir();
      },

      fechar() {
        this.$refs.dialogo.fechar();
        this.tab = 0;
      },

      teste() {
        console.log("teste: ", new Date());
      },

      async getDadosGrafico(codigo) {
        this.dados_grafico = await this.$axios.get(`graficos/historicopecas?codigo=${codigo}`).then(response => response.data);
      },

      async getHistorico(codigo) {
        this.historico = await this.$axios.get(`pecas/historico?codigo=${codigo}`).then(response => response.data);
      },

      async getResumo(codigo) {
        this.resumo = await this.$axios.get(`pecas/resumo?codigo=${codigo}`).then(response => response.data);
      },
    },

    watch: {
      async codigo(newValue, oldValue) {

        this.loading = true;
        await this.getDadosGrafico(newValue);
        await this.getHistorico(newValue);
        await this.getResumo(newValue);
        this.loading = false;
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>