export default {
  // id: null,
  codigo: '',
  data: '',
  notafiscal: '',
  fornecedor_id: '',
  cliente_id: '',
  cliente: {
    nome:'',
    telefone: ''
  },
  prestacoes: '',
  valor_total: '',
  tipomovimento_id: null,
  itens: []
}
