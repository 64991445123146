import apiGrupos from "@/app/services/grupos";
import { PAGINATION_OPTIONS } from "@/app/vuetifyConfig";

export default {
  namespaced: true,
  state: {
    grupo: {
      id: '',
      descricao: ''
    },
    subgrupo: {
      id: '',
      descricao: ''
    },
    grupos: [],
    subgrupos: [],
    busca: "",
    total_itens: 0,
    paginacao: {
      page: 1,
      itemsPerPage: 10,
      sortBy: ['descricao'],
      sortDesc: [false]
    }
  },

  mutations: {
    LISTAR(state, grupos) {
      state.grupos = grupos;
    },

    ABRIR(state, grupo) {
      state.grupo = grupo;
    },

    ADICIONAR(state, grupo) {
      state.grupos.push(grupo);
    },

    ATUALIZAR(state, grupo) {
      let index = state.grupos.findIndex(grupo_ => grupo_.id == grupo.id);
      if (index > -1) {
        state.grupos.splice(index, 1, grupo);
      }
    },

    EXCLUIR(state, id) {
      let index = state.grupos.findIndex(grupo => grupo.id == id);
      if (index > -1) {
        state.grupos.splice(index, 1);
      }
    },

    LISTAR_SUBGRUPOS(state, subgrupos) {
      state.subgrupos = subgrupos;
    },

    ABRIR_SUBGRUPO(state, subgrupo) {
      state.subgrupo = subgrupo;
    },

    ADICIONAR_SUBGRUPO(state, subgrupo) {
      state.subgrupos.push(subgrupo);
    },

    ATUALIZAR_SUBGRUPO(state, subgrupo) {
      let index = state.subgrupos.findIndex(subgrupo_ => subgrupo_.id == subgrupo.id);
      if (index > -1) {
        state.subgrupos.splice(index, 1, subgrupo);
      }
    },

    EXCLUIR_SUBGRUPO(state, id) {
      let index = state.subgrupos.findIndex(subgrupo => subgrupo.id == id);
      if (index > -1) {
        state.subgrupos.splice(index, 1);
      }
    },

    SET_BUSCA(state, busca) {
      state.busca = busca;
    },

    SET_PAGE(state, page) {
      state.paginacao.page = page;
    },

    SET_TOTAL_ITENS(state, total_itens) {
      state.total_itens = total_itens;
    },

    UPDATE_PAGINACAO(state, paginacao) {
      state.paginacao = paginacao;
    },

    ZERAR(state) {
      state.grupo = {
        id: null,
        descricao: ''
      };
    },

    ZERAR_SUBGRUPO(state) {
      state.subgrupo = {
        id: null,
        descricao: ''
      };
    }
  },

  actions: {
    async listar({ commit, state }) {
      commit('LOADING_ON', null, { root: true });

      const { sortBy, sortDesc, page, itemsPerPage } = state.paginacao;

      const paginacao = {
        page, 
        itemsPerPage,
        sortBy: sortBy[0], 
        sortDesc: sortDesc[0]
      }

      if (state.busca) {
        paginacao.busca = state.busca;
      }

      let response = await apiGrupos.listar(paginacao);
      commit('LISTAR', response.data);
      commit('SET_TOTAL_ITENS', response.total);
      commit('LOADING_OFF', null, { root: true });
    },

    async abrir({ commit }, id) {
      commit('LOADING_ON', null, { root: true });
      let grupo = await apiGrupos.abrir(id);
      commit('ABRIR', grupo);
      commit('LISTAR_SUBGRUPOS', grupo.subgrupos);
      commit('LOADING_OFF', null, { root: true });
    },

    async salvar({ commit }, grupo) {
      let novopeca = await apiGrupos.salvar(grupo);
      commit('ADICIONAR', novopeca);
    },

    async atualizar({ commit }, grupo) {
      await apiGrupos.atualizar(grupo);
      commit('ATUALIZAR', grupo);
    },

    async excluir({ commit }, id) {
      await apiGrupos.excluir(id);
      commit('EXCLUIR', id);
    },

    async listarSubGrupos({ commit, state }, grupo_id) {
      commit('LOADING_ON', null, { root: true });

      let response = await apiGrupos.listarSubGrupos(grupo_id);
      commit('LISTAR_SUBGRUPOS', response);
      commit('LOADING_OFF', null, { root: true });
    },

    async abrirSubGrupo({ commit }, id) {
      commit('LOADING_ON', null, { root: true });
      let subgrupo = await apiGrupos.abrirSubGrupo(id);
      commit('ABRIR_SUBGRUPO', subgrupo);
      commit('LOADING_OFF', null, { root: true });
    },

    async salvarSubGrupo({ commit }, subgrupo) {
      let novo_subgrupo = await apiGrupos.salvarSubGrupo(subgrupo);
      commit('ADICIONAR_SUBGRUPO', novo_subgrupo);
    },

    async atualizarSubGrupo({ commit }, subgrupo) {
      await apiGrupos.atualizarSubGrupo(subgrupo);
      commit('ATUALIZAR_SUBGRUPO', subgrupo);
    },

    async excluirSubGrupo({ commit }, id) {
      await apiGrupos.excluirSubGrupo(id);
      commit('EXCLUIR_SUBGRUPO', id);
    },

  }
}
