import AgendaLista from '@/pages/movimentacoes/agenda/AgendaLista';

export default [
  {
    path: '/agenda',
    name: 'AgendaLista',
    component: AgendaLista,
    meta: { permissao: 'agenda_acessar' },
  },
];
