import createAxios from '@/app/core/AxiosCore';
import config from '@/app/config/app';
import qs from "qs";

const axios = createAxios(config);


function listar(paginacao) {
  return axios.get(`/ordemservico?${qs.stringify(paginacao)}`)
    .then(response => response.data);
}

function abrir(id) {
  return axios.get(`/ordemservico/${id}`)
    .then(response => response.data);
}

function salvar(peca) {
  return axios.post('/ordemservico', peca)
    .then(response => response.data);
}

function atualizar(peca) {
  return axios.put(`/ordemservico/${peca.id}`, peca)
    .then(response => response.data)
}

function excluir(id) {
  return axios.delete(`/ordemservico/${id}`)
    .then(response => response.data);
}

function salvarComissoes(comissoes) {
  return axios.post('/ordemservico/salvarcomissoes', comissoes)
    .then(response => response.data);
}

function salvarComissao(comissao) {
  return axios.post('/ordemservico/comissoes', comissao)
    .then(response => response.data);
}

function atualizarComissao(comissao) {
  return axios.put('/ordemservico/comissoes', comissao)
    .then(response => response.data);
}

function editarComissao(comissao) {
  return axios.get(`/ordemservico/comissoes/${comissao.id}`)
    .then(response => response.data);
}

function excluirComissao(id) {
  return axios.delete(`/ordemservico/comissoes/${id}`)
    .then(response => response.data);
}

function listarComissoes(ordem_servico_id) {
  return axios.get('/ordemservico/comissoes', {params: {ordem_servico_id: ordem_servico_id}})
    .then(response => response.data);
}

export default {
  listar,
  abrir,
  salvar,
  atualizar,
  excluir,
  salvarComissoes,
  salvarComissao,
  atualizarComissao,
  editarComissao,
  excluirComissao,
  listarComissoes
}
