<template>
  <z-dialogo ref="dialogo" titulo="Serviços" scrollable dividers no-padding largura="800px" altura="383px">

    <template v-slot:prepend>
      <v-toolbar flat color="white">
        <v-text-field
          ref="busca"
          v-model="busca"
          rounded
          filled
          single-line
          dense
          label="Pesquisar"
          clearable
          hide-details
          prepend-inner-icon="mdi-magnify"
          @keydown.enter="onBuscarPressEnter"
          @focus="onBuscaFocus"
          @blur="is_busca_focused = false"
          @click:clear="limparBusca"
        ></v-text-field>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn small fab color="primary" class="ml-3 mr-1" v-on="on" @click="adicionar">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Adicionar Serviço</span>
        </v-tooltip>
      </v-toolbar>
    </template>

    <template v-slot:texto>
      <div ref="topo"></div>
      <z-loading :loading="carregando">
        <v-data-table
          mobile-breakpoint="0"
          disable-pagination
          disable-sort
          hide-default-footer
          :headers="headers"
          :items="itens_busca"
          :loading="carregando"
          fixed-header_
          height_="383px"
        >
          <template v-slot:body="{ items }">
            <tbody>
              <tr 
                v-for="(item, index) in items" 
                ref="urls" 
                :key="index" 
                class="apontador noselect" 
                :class="{'active': isActive(index)}" 
                @click="selecionar(item)" 
              >
                <td>{{ item.codigo }}</td>
                <td>{{ item.descricao }}</td>
                <td class="text-right">{{ item.valor | valorBR }}</td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </z-loading>
    </template>

    <template v-slot:actions_left>
      <span class="text-subtitle-2">Serviços encontrados: {{ itens_busca.length }}</span>
    </template>
    <template v-slot:actions_right>
      <v-btn color="primary" @click="fechar()">Fechar</v-btn>
    </template>

  </z-dialogo>
</template>

<script>

import servicosApi from "@/app/services/servicos";
import { abrirJanela } from "@/app/helpers/utils";

import buscas_mixin from "@/app/mixins/buscas";

  export default {
    name: 'DialogoBuscarServicos',

    mixins: [buscas_mixin],

    data() {
      return {
        headers: [
          { text: "Código", value: "codigo", width: "150px" },
          { text: "Descrição", value: "descricao" },
          { text: "Valor", value: "valor", align: 'right' },
        ],
      };
    },

    methods: {
      async buscar() {
        try {
          if (this.busca && this.busca.length > 0) {
            this.carregando = true;
            this.itens_busca = await servicosApi.buscar(this.busca);
            this.atual = 0;
            setTimeout(() => {
              this.scrollList();
            }, 100);
          }
        } catch (error) {
          this.$eventbus.notificarErro('', error);
        } finally {
          this.carregando = false;
        }
      },

      adicionar() {
        abrirJanela(window.location.origin + '/#/cadastros/servicos/adicionar');
      }
    },
  }
</script>

<style scoped>

</style>