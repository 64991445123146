const MESES = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

let currentMonth = (new Date).getMonth();
let currentYear = (new Date).getFullYear();

function priorMonth() {
  if (currentMonth == 0) {
    currentYear = priorYear();
    currentMonth = 11;
  } else {
    currentMonth -= 1;
  }

  return {
    currentMonth: (currentMonth + 1).toString().padStart(2, "0"),
    currentMonthText: MESES[currentMonth],
    currentYear
  }
}

function nextMonth() {
  if (currentMonth == 11) {
    currentYear = nextYear();
    currentMonth = 0;
  } else {
    currentMonth += 1;
  }

  return {
    currentMonth: (currentMonth + 1).toString().padStart(2, "0"),
    currentMonthText: MESES[currentMonth],
    currentYear
  }
}

function priorYear() {
  return currentYear - 1;
}

function nextYear() {
  return currentYear + 1;
}

function getCurrent() {
  currentMonth = (new Date).getMonth();
  currentYear = (new Date).getFullYear();

  return {
    currentMonth: (currentMonth + 1).toString().padStart(2, "0"),
    currentMonthText: MESES[currentMonth],
    currentYear: currentYear
  }
}

export {
  priorMonth,
  nextMonth,
  getCurrent
}
