<template>
  <div>

        <z-centralized-toolbar>
      <v-toolbar-items>

        <v-btn id="btn-adicionar" text small color="primary" @click="adicionar">
          <v-icon left>mdi-face-agent</v-icon>Adicionar
        </v-btn>

        <v-btn text id="btn-atualizar" @click="refresh" :loading="$store.state.loading">
          <v-icon>mdi-refresh</v-icon>
          <v-tooltip bottom activator="#btn-atualizar">
            Atualizar
          </v-tooltip>
        </v-btn>
      </v-toolbar-items>


      <!-- <v-spacer></v-spacer> -->

      <z-text-field-search
        v-model="busca"
        select
        class="ml-5"
        @keydown.enter="buscar"
        @click:clear="limparBusca"
      ></z-text-field-search>
    </z-centralized-toolbar>

    <v-divider></v-divider>

    <z-scroll-layout :top-position="$v.TOP_POSITION">
      <z-container>

        <z-cabecalho>{{$store.state.titulo_tela}}</z-cabecalho>

        <v-card>

          <v-data-table
          mobile-breakpoint="0"
            :options.sync="paginacao"
            :server-items-length="total_itens"
            :footer-props="$v.FOOTER_PROPS"
            disable-sort
            :headers="headers"
            :items="servicos"
            item-key="id"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>{{ CODIGO_SERVICO_AUTOMATICO ? item.id : item.codigo }}</td>
                <td>{{ item.descricao }}</td>
                <td class="text-right">{{ item.valor | valorBR }}</td>
                <td class="text-center">
                  <v-btn icon @click="editar(item)" class="ma-0">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn icon @click="perguntarExcluir(item)" class="ma-0">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>

      </z-container>
    </z-scroll-layout>


    <z-dialogo-sim-nao ref="dialogoRemoverServico" titulo="Remover serviço?" botaoSim="Remover" botaoNao="Cancelar" :loading="excluindo">
      O serviço será removido do sistema.
    </z-dialogo-sim-nao>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from "vuex";
export default {
  name: 'ServicosLista',
  data() {
    return {
      headers: [
        { text: "Código", value: "codigo", width: "150px" },
        { text: "Descrição", value: "descricao" },
        { text: "Valor", value: "valor", align: 'right'},
        { text: "Opções", value: "", width: "200px", align: "center", sortable: false },
      ],
      excluindo: false
    };
  },

  created() {
    this.refresh();
  },

  mounted() {
    this.setTitulo("Serviços");
  },

  methods: {
    ...mapMutations(["setTitulo", 'LOADING_OFF']),
    ...mapActions("servicos", ["listar", "excluir", "abrir"]),

    async refresh() {
      try {
        await this.listar();
      } catch (error) {
        console.log(error);
        this.$eventbus.notificar("Não foi possível listar os servicos.");
      }
    },

    buscar() {
      this.$store.commit('servicos/SET_PAGE', 1);
      this.refresh();
    },

    limparBusca() {
      this.busca = null;
      this.$store.commit('servicos/SET_PAGE', 1);
      this.refresh();
    },

    adicionar() {
      this.$store.commit('servicos/ZERAR');
      this.$router.push({ name: "ServicosAdicionar" });
    },

    async editar(servico) {
      try {

        await this.abrir(servico.id);
        this.$router.push({ name: "ServicosEditar", params: { id: servico.id } });

      } catch (error) {
        console.log(error);
        this.$eventbus.notificar("Não foi possível abrir o serviço.");
      } finally {
        this.LOADING_OFF();
      }
    },

    perguntarExcluir(servico) {
      this.$refs.dialogoRemoverServico.abrir()
        .then(async response => {

          if (response == true) {
            await this.confirmarExcluir(servico);
            this.$refs.dialogoRemoverServico.fechar();
          }
        })
    },

    async confirmarExcluir(servico) {
      try {
        this.excluindo = true;
        await this.excluir(servico.id);
        this.$eventbus.notificar("Serviço excluído com sucesso!");
      } catch (error) {
        this.$eventbus.notificar("Não foi possível excluir o serviço!");
        throw error;
      } finally {
        this.excluindo = false;
      }
    },
  },

  computed: {
    ...mapState(['loading']),
    ...mapState('servicos', ['servicos', 'servico', 'total_itens']),

    busca: {
      get () {
        return this.$store.state.servicos.busca
      },
      set (busca) {
        this.$store.commit('servicos/SET_BUSCA', busca);
      }
    },

    paginacao: {
      get () {
        return this.$store.state.servicos.paginacao
      },
      set (paginacao) {
        this.$store.commit('servicos/UPDATE_PAGINACAO', paginacao);
      }
    },

    CODIGO_SERVICO_AUTOMATICO() {
      return this.$store.state.configuracoes.APP_CODIGO_SERVICO_AUTOMATICO;
    },
  },

  watch: {
    paginacao: {
      handler() {
        this.refresh();
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
</style>
