<template>
  <div>
    <z-centralized-toolbar>
      <v-toolbar-items>
        <v-btn small text color="primary" :to="{ name: 'OrdemServicoLista' }" exact>
          <v-icon left>{{ $icon.VOLTAR }}</v-icon>
          Voltar
        </v-btn>
      </v-toolbar-items>

      <v-spacer></v-spacer>
      <v-chip v-if="ordemservico.aprovado == 0" color="green" dark class="mr-4">Em Aberto</v-chip>
      <v-chip v-if="ordemservico.aprovado == 1" color="indigo" dark close @click:close="reabrir" class="mr-4">Finalizado</v-chip>
      <v-chip v-if="ordemservico.aprovado == 2" color="orange" dark close @click:close="cancelarSaida" class="mr-4">Entregue</v-chip>

      <v-toolbar-items>
        <v-btn v-show="ordemservico.aprovado != 1" small text @click="finalizar" :disabled="salvando">Finalizar</v-btn>
        <v-btn v-show="ordemservico.aprovado == 0" small text @click="entregar" :disabled="salvando">Entregar</v-btn>
        <v-divider vertical inset></v-divider>
        <v-btn v-show="$route.name == 'OrdemServicoEditar'" small text @click="imprimir(ordemservico)"
          ><v-icon left>{{ $icon.IMPRIMIR }}</v-icon
          >Imprmir</v-btn
        >
        <v-btn v-show="ordemservico.aprovado != 1" small text @click="cancelar" :disabled="salvando">Cancelar</v-btn>
        <v-btn v-show="ordemservico.aprovado != 1" small text color="primary" @click="confirmar(false)" :loading="salvando" :disabled="!form_valido">Salvar</v-btn>
      </v-toolbar-items>
    </z-centralized-toolbar>

    <v-divider></v-divider>

    <z-scroll-layout :top-position="$v.TOP_POSITION">
      <v-form ref="formulario" v-model="form_valido" lazy-validation>
        <z-container fluid>
          <h3 class="text-body-1 mb-3">{{ titulo_tela }}</h3>

          <v-row>
            <v-col cols="12" sm="3">
              <v-card class="mb-5">
                <v-toolbar flat color="white" dense>
                  <h3 class="text-subtitle-2">Cliente</h3>
                </v-toolbar>
                <v-divider></v-divider>
                <v-list>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="primary">
                        mdi-account
                      </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-subtitle>Cliente</v-list-item-subtitle>
                      <v-list-item-title :title="ordemservico.cliente.nome">{{ ordemservico.cliente.nome }}</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-icon @click="$refs.DialogoBuscarClientes.abrir()">mdi-pencil</v-icon>
                    </v-list-item-action>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="primary">
                        mdi-phone
                      </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-subtitle>Telefone</v-list-item-subtitle>
                      <v-list-item-title>{{ ordemservico.cliente.telefone }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>

              <v-card class="mb-5">
                <v-toolbar flat color="white" dense>
                  <h3 class="text-subtitle-2">Veículo</h3>
                </v-toolbar>
                <v-divider></v-divider>
                <v-list>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon color="primary">
                        mdi-car
                      </v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                      <v-list-item-subtitle>Placa</v-list-item-subtitle>
                      <v-list-item-title :title="ordemservico.placa">{{ ordemservico.placa }}</v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-icon @click="$refs.DialogoBuscarVeiculos.abrir()">mdi-pencil</v-icon>
                    </v-list-item-action>
                  </v-list-item>

                  <v-divider inset></v-divider>

                  <v-list-item>
                    <v-list-item-action> </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-subtitle>Marca</v-list-item-subtitle>
                      <v-list-item-title>{{ ordemservico.veiculo.marca }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-action> </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-subtitle>Modelo</v-list-item-subtitle>
                      <v-list-item-title>{{ ordemservico.veiculo.modelo }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-col>
            <v-col cols="12" sm="9">
              <v-card class="mb-5">
                <v-toolbar flat color="white" dense>
                  <h3 class="text-subtitle-2">Ordem de Serviço</h3>
                </v-toolbar>
                <v-divider></v-divider>
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" sm="3">
                      <z-text-field v-model="ordemservico.id" label="Número OS" readonly></z-text-field>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <z-date-picker-menu v-model="ordemservico.data_entrada" label="Data Entrada" :rules="[regras.campoObrigatorio]"></z-date-picker-menu>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <z-time-picker v-model="ordemservico.hora_entrada" label="Hora Entrada" :rules="[regras.campoObrigatorio]"></z-time-picker>
                      <!-- <z-text-field v-model="ordemservico.hora_entrada" v-mask="'##:##'" label="Hora Entrada"></z-text-field> -->
                    </v-col>
                    <v-col cols="12" sm="3">
                      <z-date-picker-menu v-model="ordemservico.data_saida" label="Data Saída"></z-date-picker-menu>
                    </v-col>
                    <v-col cols="12" sm="3">
                      <z-time-picker v-model="ordemservico.hora_saida" label="Hora Saída"></z-time-picker>
                      <!-- <z-text-field v-model="ordemservico.hora_saida" v-mask="'##:##'" label="Hora Saída"></z-text-field> -->
                    </v-col>
                    <v-col cols="12" sm="3">
                      <z-select label="Tipo OS" :items="$store.getters['listas/tipos_os']" v-model="ordemservico.tipo_id"></z-select>
                      <!-- <v-autocomplete v-model="ordemservico.funcionario_id" label="Funcionário" :items="['Fulano', 'Cicrano', 'Beltrano']"></v-autocomplete> -->
                    </v-col>

                    <v-col cols="12" sm="4">
                      <z-select label="Funcionário" :items="funcionarios" v-model="ordemservico.funcionario_id" :rules="[regras.campoObrigatorio]"></z-select>
                      <!-- <v-autocomplete v-model="ordemservico.funcionario_id" label="Funcionário" :items="['Fulano', 'Cicrano', 'Beltrano']"></v-autocomplete> -->
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-card class="mb-5">
                <v-toolbar flat color="white" dense>
                  <h3 class="text-subtitle-2">Problema</h3>
                </v-toolbar>
                <v-divider></v-divider>

                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" sm="12">
                      <z-textarea v-model="ordemservico.problema" label="Problema apresentado" placeholder="Descreva o problema apresentado pelo veículo" rows="3"> </z-textarea>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <v-card class="mb-6" v-if="ordemservico.id">
                <v-toolbar flat color="white" dense>
                  <h3 class="text-subtitle-2">Peças e Serviços</h3>
                </v-toolbar>
                <v-divider></v-divider>

                <v-tabs v-model="tab" dark background-color="indigo" grow hide-slider>
                  <v-tab :ripple="false" class="text-capitalize_" active-class="indigo darken-2">Peças</v-tab>
                  <v-tab :ripple="false" class="text-capitalize_" active-class="indigo darken-2">Serviços</v-tab>
                  <v-tab :ripple="false" class="text-capitalize_" active-class="indigo darken-2">Serviços Terceiros</v-tab>
                  <!-- <v-tabs-slider color="white"></v-tabs-slider> -->
                </v-tabs>

                <v-divider></v-divider>

                <v-tabs-items v-model="tab" touchless>
                  <v-tab-item name="Peças" :reverse-transition="false" :transition="false">
                    <tab-pecas :pecas.sync="ordemservico.pecas" @update:pecas="confirmar(false)"></tab-pecas>
                  </v-tab-item>

                  <v-tab-item name="Serviços" :reverse-transition="false" :transition="false">
                    <tab-servicos :servicos.sync="ordemservico.servicos" @update:servicos="confirmar(false)"></tab-servicos>
                  </v-tab-item>

                  <v-tab-item name="Serviços Terceiros" :reverse-transition="false" :transition="false">
                    <tab-servicos-terceiros :servicos.sync="ordemservico.servicos_terceiros" @update:servicos="confirmar(false)"></tab-servicos-terceiros>
                  </v-tab-item>
                </v-tabs-items>
              </v-card>

              <v-card class="mb-5">
                <v-toolbar flat color="white" dense>
                  <h3 class="text-subtitle-2">Cliente</h3>
                </v-toolbar>
                <v-divider></v-divider>
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" sm="2">
                      <z-text-field v-model="ordemservico.id" label="Número OS" readonly></z-text-field>
                    </v-col>
                    <v-col cols="12" sm="2">
                      <z-date-picker-menu v-model="ordemservico.data_entrada" label="Data Entrada" :rules="[regras.campoObrigatorio]"></z-date-picker-menu>
                    </v-col>
                    <v-col cols="12" sm="2">
                      <z-time-picker v-model="ordemservico.hora_entrada" label="Hora Entrada" :rules="[regras.campoObrigatorio]"></z-time-picker>
                      <!-- <z-text-field v-model="ordemservico.hora_entrada" v-mask="'##:##'" label="Hora Entrada"></z-text-field> -->
                    </v-col>
                    <v-col cols="12" sm="2">
                      <z-date-picker-menu v-model="ordemservico.data_saida" label="Data Saída"></z-date-picker-menu>
                    </v-col>
                    <v-col cols="12" sm="2">
                      <z-time-picker v-model="ordemservico.hora_saida" label="Hora Saída"></z-time-picker>
                      <!-- <z-text-field v-model="ordemservico.hora_saida" v-mask="'##:##'" label="Hora Saída"></z-text-field> -->
                    </v-col>
                    <v-col cols="12" sm="2">
                      <z-select label="Tipo OS" :items="$store.getters['listas/tipos_os']" v-model="ordemservico.tipo_id"></z-select>
                      <!-- <v-autocomplete v-model="ordemservico.funcionario_id" label="Funcionário" :items="['Fulano', 'Cicrano', 'Beltrano']"></v-autocomplete> -->
                    </v-col>
                  </v-row>

                  <v-row dense>
                    <v-col cols="12" sm="4">
                      <z-select label="Funcionário" :items="funcionarios" v-model="ordemservico.funcionario_id" :rules="[regras.campoObrigatorio]"></z-select>
                      <!-- <v-autocomplete v-model="ordemservico.funcionario_id" label="Funcionário" :items="['Fulano', 'Cicrano', 'Beltrano']"></v-autocomplete> -->
                    </v-col>
                    <v-col cols="12" sm="2">
                      <v-text-field
                        v-bind="$v.TEXT_FIELDS"
                        v-model="ordemservico.cliente_id"
                        ref="cliente_id"
                        label="Código Cliente"
                        append-icon="mdi-magnify"
                        hint="F8 - Para pesquisar"
                        placeholder=" "
                        :rules_="[regras.campoObrigatorio]"
                        :loading="buscando_veiculo"
                        :disabled="buscando_veiculo"
                        @click:append="$refs.DialogoBuscarClientes.abrir()"
                        @keyup.enter="buscarClientePeloCodigo"
                        @keyup.f8="$refs.DialogoBuscarClientes.abrir()"
                        @focus="(event) => event.target.select()"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <z-text-field
                        v-model="ordemservico.cliente.nome"
                        label="Nome do Cliente"
                        readonly
                        :hint="'Telefone: ' + ordemservico.cliente.telefone"
                        :persistent-hint="ordemservico.cliente.telefone != null"
                      ></z-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-card class="mb-5">
            <v-toolbar flat color="white" dense>
              <h3 class="text-subtitle-2">Cliente</h3>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text>
              <v-row dense>
                <v-col cols="12" sm="2">
                  <z-text-field v-model="ordemservico.id" label="Número OS" readonly></z-text-field>
                </v-col>
                <v-col cols="12" sm="2">
                  <z-date-picker-menu v-model="ordemservico.data_entrada" label="Data Entrada" :rules="[regras.campoObrigatorio]"></z-date-picker-menu>
                </v-col>
                <v-col cols="12" sm="2">
                  <z-time-picker v-model="ordemservico.hora_entrada" label="Hora Entrada" :rules="[regras.campoObrigatorio]"></z-time-picker>
                  <!-- <z-text-field v-model="ordemservico.hora_entrada" v-mask="'##:##'" label="Hora Entrada"></z-text-field> -->
                </v-col>
                <v-col cols="12" sm="2">
                  <z-date-picker-menu v-model="ordemservico.data_saida" label="Data Saída"></z-date-picker-menu>
                </v-col>
                <v-col cols="12" sm="2">
                  <z-time-picker v-model="ordemservico.hora_saida" label="Hora Saída"></z-time-picker>
                  <!-- <z-text-field v-model="ordemservico.hora_saida" v-mask="'##:##'" label="Hora Saída"></z-text-field> -->
                </v-col>
                <v-col cols="12" sm="2">
                  <z-select label="Tipo OS" :items="$store.getters['listas/tipos_os']" v-model="ordemservico.tipo_id"></z-select>
                  <!-- <v-autocomplete v-model="ordemservico.funcionario_id" label="Funcionário" :items="['Fulano', 'Cicrano', 'Beltrano']"></v-autocomplete> -->
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" sm="4">
                  <z-select label="Funcionário" :items="funcionarios" v-model="ordemservico.funcionario_id" :rules="[regras.campoObrigatorio]"></z-select>
                  <!-- <v-autocomplete v-model="ordemservico.funcionario_id" label="Funcionário" :items="['Fulano', 'Cicrano', 'Beltrano']"></v-autocomplete> -->
                </v-col>
                <v-col cols="12" sm="2">
                  <v-text-field
                    v-bind="$v.TEXT_FIELDS"
                    v-model="ordemservico.cliente_id"
                    ref="cliente_id"
                    label="Código Cliente"
                    append-icon="mdi-magnify"
                    hint="F8 - Para pesquisar"
                    placeholder=" "
                    :rules_="[regras.campoObrigatorio]"
                    :loading="buscando_veiculo"
                    :disabled="buscando_veiculo"
                    @click:append="$refs.DialogoBuscarClientes.abrir()"
                    @keyup.enter="buscarClientePeloCodigo"
                    @keyup.f8="$refs.DialogoBuscarClientes.abrir()"
                    @focus="(event) => event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <z-text-field
                    v-model="ordemservico.cliente.nome"
                    label="Nome do Cliente"
                    readonly
                    :hint="'Telefone: ' + ordemservico.cliente.telefone"
                    :persistent-hint="ordemservico.cliente.telefone != null"
                  ></z-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card class="mb-5">
            <v-toolbar flat color="white" dense>
              <h3 class="text-subtitle-2">Veículo</h3>
            </v-toolbar>
            <v-divider></v-divider>

            <v-card-text>
              <v-row dense>
                <v-col cols="12" sm="3">
                  <v-text-field
                    v-bind="$v.TEXT_FIELDS"
                    v-model="ordemservico.placa"
                    v-mask="$v.PLACA_MASK"
                    ref="placa"
                    label="Placa"
                    append-icon="mdi-magnify"
                    hint="F8 - Para pesquisar"
                    placeholder=" "
                    :rules="[regras.campoObrigatorio]"
                    :loading="buscando_veiculo"
                    :disabled="buscando_veiculo"
                    @click:append="$refs.DialogoBuscarVeiculos.abrir()"
                    @keyup.f8="$refs.DialogoBuscarVeiculos.abrir()"
                    @keyup.enter="buscarVeiculoPelaPlaca"
                    @focus="(event) => event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <z-text-field v-model="ordemservico.veiculo.marca" label="Marca" readonly></z-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <z-text-field v-model="ordemservico.veiculo.modelo" label="Modelo" readonly></z-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <z-text-field v-model="ordemservico.veiculo.cor" label="Cor" readonly></z-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="3">
                  <z-text-field v-model="ordemservico.veiculo.ano" label="Ano" readonly></z-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <z-text-field v-model="ordemservico.veiculo.combustivel" label="Combustível" readonly></z-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <z-text-field v-model="ordemservico.veiculo.chassi" label="Chassi" readonly></z-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <z-text-field v-model="ordemservico.km" ref="km" label="Quilometragem" suffix="KM" placeholder=" "></z-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card class="mb-5">
            <v-toolbar flat color="white" dense>
              <h3 class="text-subtitle-2">Problema</h3>
            </v-toolbar>
            <v-divider></v-divider>

            <v-card-text>
              <v-row dense>
                <v-col cols="12" sm="12">
                  <z-textarea v-model="ordemservico.problema" label="Problema apresentado" placeholder="Descreva o problema apresentado pelo veículo" rows="3"> </z-textarea>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card class="mb-6" v-if="ordemservico.id">
            <v-toolbar flat color="white" dense>
              <h3 class="text-subtitle-2">Peças e Serviços</h3>
            </v-toolbar>
            <v-divider></v-divider>

            <v-tabs v-model="tab" color="primary" background-color="white">
              <v-tab class="text-capitalize">Peças</v-tab>
              <v-tab class="text-capitalize">Serviços</v-tab>
              <v-tab class="text-capitalize">Serviços Terceiros</v-tab>
              <v-tabs-slider color="primary"></v-tabs-slider>
            </v-tabs>

            <v-divider></v-divider>

            <v-tabs-items v-model="tab" touchless>
              <v-tab-item name="Peças" :reverse-transition="false" :transition="false">
                <tab-pecas :pecas.sync="ordemservico.pecas" @update:pecas="confirmar(false)"></tab-pecas>
              </v-tab-item>

              <v-tab-item name="Serviços" :reverse-transition="false" :transition="false">
                <tab-servicos :servicos.sync="ordemservico.servicos" @update:servicos="confirmar(false)"></tab-servicos>
              </v-tab-item>

              <v-tab-item name="Serviços Terceiros" :reverse-transition="false" :transition="false">
                <tab-servicos-terceiros :servicos.sync="ordemservico.servicos_terceiros" @update:servicos="confirmar(false)"></tab-servicos-terceiros>
              </v-tab-item>
            </v-tabs-items>
          </v-card>

          <v-card class="mb-5" v-if="ordemservico.id">
            <v-toolbar flat color="white" dense>
              <h3 class="text-subtitle-2">Totais</h3>
            </v-toolbar>
            <v-divider></v-divider>

            <v-card-text>
              <v-row dense>
                <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : null">
                  <z-campo-valor v-model="ordemservico.total_pecas" label="Total Peças" readonly></z-campo-valor>
                </v-col>
                <!-- <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : null">
                  <z-campo-valor v-model="ordemservico.desconto_pecas" label="Desconto Peças" suffix="%" @input="calcularTotais"></z-campo-valor>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : null">
                  <z-campo-valor v-model="ordemservico.liquido_pecas" label="Líquido Peças" readonly></z-campo-valor>
                </v-col> -->
                <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : null">
                  <z-campo-valor v-model="ordemservico.total_servicos" label="Total Serviços" readonly></z-campo-valor>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : null">
                  <z-campo-valor v-model="ordemservico.total_orcamento" label="Total OS" readonly></z-campo-valor>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : null">
                  <z-campo-valor v-model="ordemservico.desconto_servicos" label="Desconto" suffix="%" decimais="0" @input="calcularTotais"></z-campo-valor>
                </v-col>
                <!-- <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : null">
                  <z-campo-valor v-model="ordemservico.liquido_servicos" label="Líquido Serviços" readonly></z-campo-valor>
                </v-col> -->
                <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : null">
                  <z-campo-valor v-model="ordemservico.total_liquido" label="Total Líquido" readonly></z-campo-valor>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12" sm="3">
                  <z-select :items="prazos" v-model="ordemservico.pagamento_id" label="Prazo" placeholder=" " item-text="descricao" item-value="id" return-object @change="onChangePrazo"></z-select>
                </v-col>
                <v-col cols="12" sm="3">
                  <z-campo-valor v-model="ordemservico.parcelas" label="Parcelas" decimais="0" placeholder=" " readonly></z-campo-valor>
                </v-col>
                <v-col cols="12" sm="3">
                  <z-campo-valor v-model="ordemservico.valor_parcela" label="Valor Parcela" placeholder=" " readonly></z-campo-valor>
                </v-col>
                <v-col cols="12" sm="3">
                  <z-campo-valor v-model="ordemservico.total_parcelado" label="Total Parcelado" placeholder=" " readonly></z-campo-valor>
                </v-col>
              </v-row>
            </v-card-text>

            <!-- <v-divider></v-divider>

          <v-card-actions>
            <v-switch v-model="ordemservico.aprovado" class="my-0" color="primary" label="Serviço Finalizado" v-show="$route.name == 'OrdemServicoEditar'" hide-details @change="onChangeAprovado" :disabled="ordemservico.aprovado == 1"></v-switch>
            <v-spacer></v-spacer>
            <v-btn text @click="$router.push({ name: 'OrdemServicoLista'})" :disabled="salvando">
              <span v-if="ordemservico.aprovado == 0">Cancelar</span>
              <span v-else>Voltar</span>
            </v-btn>
            <v-btn v-show="ordemservico.aprovado == 0" color="primary" @click="confirmar" :loading="salvando" :disabled="!form_valido">Salvar</v-btn>
          </v-card-actions> -->
          </v-card>
        </z-container>
      </v-form>
    </z-scroll-layout>

    <dialogo-buscar-clientes ref="DialogoBuscarClientes" @busca:selecionar="selecionarCliente"></dialogo-buscar-clientes>
    <dialogo-buscar-veiculos ref="DialogoBuscarVeiculos" @busca:selecionar="selecionarVeiculo"></dialogo-buscar-veiculos>

    <z-dialogo-sim-nao ref="dialogoSelecionarVeiculo" titulo="Selecionar Veículo?" largura="500px">
      Clique "Sim" selecionar o último veículo usado para este cliente.
    </z-dialogo-sim-nao>

    <z-dialogo-sim-nao ref="dialogoSelecionarCliente" titulo="Selecionar Cliente?" largura="500px">
      Clique "Sim" selecionar o último cliente usado para este veículo.
    </z-dialogo-sim-nao>

    <z-dialogo-sim-nao ref="dialogoRecuperarOrdemServico" titulo="Recuperar ordem de serviço?" botaoSim="Recuperar" botaoNao="Dispensar" largura="500px">
      Uma ordem de serviço não foi salva. Deseja recuperar-la?
    </z-dialogo-sim-nao>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { hoje, hora, abrirJanela } from '@/app/helpers/utils';
import '@/components/buscas';

import TabPecas from './TabPecas';
import TabServicos from './TabServicos.vue';
import TabServicosTerceiros from './TabServicosTerceiros.vue';

import veiculosApi from '@/app/services/veiculos';
import clientesApi from '@/app/services/clientes';
// import ordemservico from "@/app/store/models/ordemservico";
import veiculo from '@/app/store/models/veiculo';

import * as regras from '@/app/helpers/validacoes';

export default {
  name: 'OrdemServicoForm',

  components: {
    TabPecas,
    TabServicos,
    TabServicosTerceiros,
  },

  data() {
    return {
      // ordemservico: {...ordemservico},
      veiculo: { ...veiculo },
      cliente: {
        cliente_id: '',
        nome: '',
      },
      buscando_veiculo: false,
      salvando: false,
      tab: 0,
      form_valido: true,
      regras: { ...regras },
      pecas: [],
      servicos: [],
      servicos_terceiros: [],
      buscando: false,
      dialogo_sim_nao: {
        titulo: '',
        texto: '',
        botaoSim: '',
        botaoNao: '',
      },
      alteracoes: 0,
    };
  },

  computed: {
    ...mapState(['titulo_tela']),
    ...mapGetters('listas', ['funcionarios']),
    ...mapState('listas', ['prazos']),
    ...mapState('ordemservico', ['ordemservico']),

    titulo() {
      let titulo = /Editar/.test(this.$route.name) ? 'Editar' : 'Adicionar';
      return titulo + ' ' + 'Ordem de Serviço';
    },

    total_pecas() {
      return this.ordemservico.pecas.reduce((soma, peca) => {
        return soma + peca.subtotal;
      }, 0);
    },

    total_servicos() {
      return this.ordemservico.servicos.reduce((soma, servico) => {
        return soma + servico.subtotal;
      }, 0);
    },

    total_servicos_terceiros() {
      return this.ordemservico.servicos_terceiros.reduce((soma, servico) => {
        return soma + servico.subtotal;
      }, 0);
    },
  },

  async beforeRouteLeave(to, from, next) {
    if (this.alteracoes > 1) {
      let resposta = await this.$root.dialogo_pergunta.abrir({
        titulo: 'Salvar ordem de serviço',
        texto: 'A ordem de serviço não está salva. Deseja salvar?',
        botoes: ['Não', 'Sim'],
        padrao: 'Sim',
      });

      if (resposta == 'Sim') {
        if (!this.$refs.formulario.validate()) {
          this.$root.dialogo_pergunta.fechar();
          return false;
        } else {
          if (this.$route.name == 'OrdemServicoAdicionar') {
            await this.salvar(this.ordemservico);
          } else {
            await this.atualizar(this.ordemservico);
          }
          this.$storage.remove('ordem_servico');
          this.$eventbus.notificar('Ordem de Serviço salva com sucesso');
          this.$root.dialogo_pergunta.fechar();
          next();
        }
      } else {
        this.$storage.remove('ordem_servico');
        this.$root.dialogo_pergunta.fechar();
        next();
      }
    } else {
      this.$storage.remove('ordem_servico');
      next();
    }
  },

  async mounted() {
    this.setTitulo(this.titulo);

    if (this.$route.name == 'OrdemServicoEditar2') {
      if (!this.$store.state.ordemservico.ordemservico.id) {
        if (this.$route.params.id) {
          await this.abrir(this.$route.params.id);
        }
      }
    } else {
      this.$refs.formulario.resetValidation();
      this.$store.commit('ordemservico/ZERAR');

      let ordem_servico = this.$storage.get('ordem_servico');

      if (ordem_servico) {
        let response = await this.$refs.dialogoRecuperarOrdemServico.abrir();
        if (response == true) {
          this.$store.commit('ordemservico/ABRIR', ordem_servico);
        } else {
          this.$storage.remove('ordem_servico');
        }

        this.$refs.dialogoRecuperarOrdemServico.fechar();
      }
    }

    // this.ordemservico = clone(this.$store.state.ordemservico.ordemservico);

    this.$store.dispatch('listas/funcionarios');
    this.$store.dispatch('listas/prazos');
    this.$store.dispatch('listas/tipos_os');

    this.calcularTotais();
  },

  methods: {
    ...mapMutations(['setTitulo']),
    ...mapActions('ordemservico', ['abrir', 'atualizar', 'salvar']),

    async confirmar(fechar = true) {
      if (!this.$refs.formulario.validate()) {
        return false;
      }

      try {
        this.salvando = true;

        if (this.ordemservico.id) {
          await this.atualizar(this.ordemservico);
        } else {
          await this.salvar(this.ordemservico);
        }

        this.$storage.remove('ordem_servico');
        this.alteracoes = 1;

        this.$eventbus.notificar('Ordem de Serviço salva com sucesso');

        if (fechar) {
          this.$router.push({ name: 'OrdemServicoLista' });
        }

        if (this.$route.name == 'OrdemServicoAdicionar') {
          // await this.abrir(this.ordemservico.id);
          this.$router.push({ name: 'OrdemServicoEditar', params: { id: this.ordemservico.id } });
        }
      } catch (error) {
        this.$eventbus.notificarErro('Não foi possível salvar esta Ordem de Serviço. Verifique os dados preenchidos.', error);
        throw error;
      } finally {
        this.salvando = false;
      }
    },

    cancelar() {
      this.$storage.remove('ordem_servico');
      this.alteracoes = 1;
      this.$router.push({ name: 'OrdemServicoLista' });
    },

    async buscarVeiculoPelaPlaca() {
      if (!this.ordemservico.placa) {
        this.$refs.DialogoBuscarVeiculos.abrir();
        return;
      }

      try {
        this.buscando = true;
        let veiculo = await veiculosApi.buscarVeiculoPelaPlaca(this.ordemservico.placa);
        if (veiculo) {
          this.selecionarVeiculo(veiculo);
        }
      } catch (error) {
        this.$refs.DialogoBuscarVeiculos.busca = this.ordemservico.placa;
        this.$refs.DialogoBuscarVeiculos.abrir();
        this.$refs.DialogoBuscarVeiculos.buscar();
        // this.$eventbus.notificarErro('Placa não encontrada.', error);
        // this.setFocus('placa');
        // this.selectText('placa');
        // throw error;
      } finally {
        this.buscando = false;
      }
    },

    async buscarClientePeloCodigo() {
      if (!this.ordemservico.cliente_id) {
        this.$refs.DialogoBuscarClientes.abrir();
        return;
      }

      try {
        this.buscando = true;
        let cliente = await clientesApi.buscarClientePeloCodigo(this.ordemservico.cliente_id);
        if (cliente) {
          this.selecionarCliente(cliente);
        }
      } catch (error) {
        this.$refs.DialogoBuscarClientes.busca = this.ordemservico.cliente_id;
        this.$refs.DialogoBuscarClientes.abrir();
        this.$refs.DialogoBuscarClientes.buscar();
        // this.$eventbus.notificarErro('Cliente não encontrado.', error);
        // this.setFocus('placa');
        // this.selectText('placa');
        // throw error;
      } finally {
        this.buscando = false;
      }
    },

    selecionarVeiculo(veiculo) {
      this.ordemservico.placa = veiculo.placa;
      // this.ordemservico.veiculo.descricao   = veiculo.descricao;
      this.ordemservico.veiculo.ano = veiculo.ano;
      this.ordemservico.veiculo.marca = veiculo.marca;
      this.ordemservico.veiculo.modelo = veiculo.modelo;
      this.ordemservico.veiculo.cor = veiculo.cor;
      this.ordemservico.veiculo.combustivel = veiculo.combustivel;
      this.ordemservico.veiculo.chassi = veiculo.chassi;

      if (veiculo.ultimo_cliente_id) {
        this.$refs.dialogoSelecionarCliente.abrir().then((resposta) => {
          if (resposta == true) {
            this.ordemservico.cliente_id = veiculo.ultimo_cliente_id;
            this.ordemservico.cliente.nome = veiculo.ultimo_cliente_nome;
            this.ordemservico.cliente.telefone = veiculo.ultimo_cliente_telefone;
          }
          this.$refs.dialogoSelecionarCliente.fechar();
          this.setFocus('km');
        });
      } else {
        this.setFocus('km');
      }
    },

    limparVeiculo() {
      this.ordemservico.placa = '';
      // this.ordemservico.veiculo.descricao   = veiculo.descricao;
      this.ordemservico.veiculo.ano = '';
      this.ordemservico.veiculo.marca = '';
      this.ordemservico.veiculo.modelo = '';
      this.ordemservico.veiculo.combustivel = '';
      this.ordemservico.veiculo.chassi = '';
      this.$refs.formulario.resetValidation();
    },

    selecionarCliente(cliente) {
      this.ordemservico.cliente_id = cliente.id;
      this.ordemservico.cliente.nome = cliente.nome;
      this.ordemservico.cliente.telefone = cliente.telefone;

      // setTimeout(() => {
      //   this.$refs.cliente_id.focus();
      // }, 0);

      if (cliente.ultimo_veiculo) {
        this.$refs.dialogoSelecionarVeiculo.abrir().then((resposta) => {
          if (resposta == true) {
            this.selecionarVeiculo(cliente.ultimo_veiculo);
            this.setFocus('km');
          } else {
            // this.limparVeiculo();
            this.setFocus('placa');
          }
          this.$refs.dialogoSelecionarVeiculo.fechar();
        });
      } else {
        this.setFocus('placa');
      }
    },

    onChangePlaca() {},

    onChangePrazo(prazo) {
      this.ordemservico.parcelas = prazo.parcelas;
      let juros = (this.ordemservico.total_liquido * prazo.juros) / 100;
      this.ordemservico.valor_parcela = (this.ordemservico.total_liquido + juros) / prazo.parcelas;
      this.ordemservico.total_parcelado = this.ordemservico.valor_parcela * prazo.parcelas;
      this.ordemservico.pagamento_id = prazo.id;
    },

    calcularTotais() {
      this.ordemservico.total_orcamento = this.total_pecas + this.total_servicos + this.total_servicos_terceiros;
      this.ordemservico.total_pecas = this.total_pecas;
      this.ordemservico.total_servicos = this.total_servicos + this.total_servicos_terceiros;

      let valor_desconto_pecas = (this.ordemservico.total_pecas * this.ordemservico.desconto_pecas) / 100;
      let valor_desconto_servicos = (this.ordemservico.total_servicos * this.ordemservico.desconto_servicos) / 100;
      let valor_desconto_total = (this.ordemservico.total_orcamento * this.ordemservico.desconto_servicos) / 100;

      this.ordemservico.liquido_pecas = this.ordemservico.total_pecas - valor_desconto_pecas;
      this.ordemservico.liquido_servicos = this.ordemservico.total_servicos - valor_desconto_servicos;

      // this.ordemservico.total_liquido = this.ordemservico.liquido_pecas + this.ordemservico.liquido_servicos;
      this.ordemservico.total_liquido = this.ordemservico.total_orcamento - valor_desconto_total;
    },

    async reabrir() {
      let resposta = await this.$root.dialogo_pergunta.abrir({
        titulo: 'Reabrir orçamento?',
        texto: 'O orçamento será reaberto para alterções. Deseja reabrir?',
        botoes: ['Cancelar', 'Reabrir'],
        padrao: 'Cancelar',
        largura: '500px',
      });

      if (resposta == 'Reabrir') {
        this.ordemservico.aprovado = 0;
        this.confirmar(false);
      }

      this.$root.dialogo_pergunta.fechar();
    },

    async cancelarSaida() {
      let resposta = await this.$root.dialogo_pergunta.abrir({
        titulo: 'Cancelar saída',
        texto: 'A saída desta ordem de serviço será cancelada. Cancelar?',
        botoes: ['Não', 'Sim'],
        padrao: 'Sim',
        largura: '500px',
      });

      if (resposta == 'Sim') {
        this.ordemservico.data_saida = null;
        this.ordemservico.hora_saida = null;
        this.ordemservico.aprovado = 0;
        this.confirmar(false);
      }

      this.$root.dialogo_pergunta.fechar();
    },

    async finalizar() {
      let resposta = await this.$root.dialogo_pergunta.abrir({
        titulo: 'Finalizar orçamento',
        texto: 'O orçamento será finalizado. Deseja finalizar?',
        botoes: ['Cancelar', 'Finalizar'],
        padrao: 'Finalizar',
        largura: '500px',
      });

      if (resposta == 'Finalizar') {
        this.ordemservico.data_saida = this.ordemservico.data_saida || hoje();
        this.ordemservico.hora_saida = this.ordemservico.hora_saida || hora();
        this.ordemservico.aprovado = 1;
        this.confirmar(false);
      }

      this.$root.dialogo_pergunta.fechar();
    },

    async entregar() {
      let resposta = await this.$root.dialogo_pergunta.abrir({
        titulo: 'Entregar veículo',
        texto: 'Esta ordem de serviço será marcada como entregue. Entregar?',
        botoes: ['Cancelar', 'Entregar'],
        padrao: 'Entregar',
        largura: '500px',
      });

      if (resposta == 'Entregar') {
        this.ordemservico.data_saida = hoje();
        this.ordemservico.hora_saida = hora();
        this.ordemservico.aprovado = 2;
        this.confirmar(false);
      }

      this.$root.dialogo_pergunta.fechar();
    },

    imprimir(ordemservico) {
      let print_url = this.$config.IS_PRODUCTION ? window.location.origin : this.$config.BASE_URL_PRINT;
      abrirJanela(`${print_url}/ordemservico/${ordemservico.id}/imprimir`);
    },
  },

  watch: {
    'ordemservico.pecas'(value, oldValue) {
      this.calcularTotais();
    },

    'ordemservico.servicos'(value, oldValue) {
      this.calcularTotais();
    },

    'ordemservico.servicos_terceiros'(value, oldValue) {
      this.calcularTotais();
    },

    ordemservico: {
      deep: true,
      handler(newValue, oldValue) {
        if (this.ordemservico.aprovado != 1) {
          this.alteracoes++;
          // if (this.$route.name == 'OrdemServicoAdicionar') {
          this.$storage.set('ordem_servico', newValue);
          // }
        }
      },
    },
  },
};
</script>

<style scoped></style>
