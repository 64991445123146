import clientesApi from '@/app/services/clientes';
import cliente from '@/app/store/models/cliente';
import { PAGINATION_OPTIONS } from '@/app/vuetifyConfig';
import { hoje } from '@/app/helpers/utils';

export default {
  namespaced: true,
  state: {
    cliente: { ...cliente },
    clientes: [],
    busca: '',
    letra: null,
    total_itens: 0,
    paginacao: PAGINATION_OPTIONS,
  },

  mutations: {
    LISTAR(state, clientes) {
      state.clientes = clientes;
    },

    ABRIR(state, cliente) {
      state.cliente = cliente;
    },

    ADICIONAR(state, cliente) {
      state.clientes.push(cliente);
    },

    ATUALIZAR(state, cliente) {
      let index = state.clientes.findIndex((cliente_) => cliente_.id == cliente.id);
      if (index > -1) {
        state.clientes.splice(index, 1, cliente);
      }
    },

    EXCLUIR(state, id) {
      let index = state.clientes.findIndex((cliente) => cliente.id == id);
      if (index > -1) {
        state.clientes.splice(index, 1);
      }
    },

    SET_BUSCA(state, busca) {
      state.busca = busca;
    },

    SET_LETRA(state, letra) {
      state.letra = letra;
    },

    SET_PAGE(state, page) {
      state.paginacao.page = page;
    },

    SET_TOTAL_ITENS(state, total_itens) {
      state.total_itens = total_itens;
    },

    UPDATE_PAGINACAO(state, paginacao) {
      state.paginacao = paginacao;
    },

    ZERAR(state) {
      state.cliente = { ...cliente };
      state.cliente.data_cadastro = hoje();
    },
  },

  actions: {
    async listar({ commit, state }) {
      commit('LOADING_ON', null, { root: true });

      const { sortBy, sortDesc, page, itemsPerPage } = state.paginacao;

      const paginacao = {
        page,
        itemsPerPage,
        sortBy: sortBy[0],
        sortDesc: sortDesc[0],
      };

      if (state.letra) {
        paginacao.letra = state.letra;
      }

      if (state.busca) {
        paginacao.busca = state.busca;
      }

      let clientes = await clientesApi.listar(paginacao);
      commit('LISTAR', clientes.data);
      commit('SET_TOTAL_ITENS', clientes.total);
      commit('LOADING_OFF', null, { root: true });
    },

    async filtrarLetra({ commit }, letra) {
      commit('LOADING_ON', null, { root: true });
      let clientes = await clientesApi.filtrarLetra(letra);
      commit('LISTAR', clientes.data);
      commit('LOADING_OFF', null, { root: true });
    },

    async abrir({ commit }, id) {
      commit('LOADING_ON', null, { root: true });
      let cliente = await clientesApi.abrir(id);
      commit('ABRIR', cliente);
      commit('LOADING_OFF', null, { root: true });
    },

    async salvar({ commit }, cliente) {
      let novocliente = await clientesApi.salvar(cliente);
      commit('ADICIONAR', novocliente);
    },

    async atualizar({ commit }, cliente) {
      await clientesApi.atualizar(cliente);
      commit('ATUALIZAR', cliente);
    },

    async excluir({ commit }, id) {
      await clientesApi.excluir(id);
      commit('EXCLUIR', id);
    },
  },
};
