<template>
  <BemVindo></BemVindo>
</template>

<script>
  import { mapMutations } from "vuex";
  import BemVindo from './BemVindo';

  export default {
    name: 'Home',

    components: {
      BemVindo
    },

    data() {
      return {
        data: null,
      }
    },
    mounted() {
      this.setTitulo('Home');
    },
    methods: {
      ...mapMutations(['setTitulo']),
    }
  }
</script>

<style scoped>
  .input-trasparente >>> .v-input__slot {
    background: none;
  }
</style>
